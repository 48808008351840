import { HTTP_REQUEST_METHODS } from '@utils/ApiCodex';
import { appAPIHeaders, appAPIUri } from '@utils/Url';
import axios from 'axios';

export default class DashboardService {
  env = {};

  /**
   * Set env from app loading with server.
   * @param {JSON} env APP Envs
   */
  constructor(env) {
    this.env = env;
  }

  /**
   * Fetch dashboard slideshow
   * @returns API Request
   */
  getDashboardSlides() {
    // Req URL
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.DASHBOARD_SLIDES}`;
    // Default headers
    const headers = appAPIHeaders.DEFAULT_RAW_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.GET,
      url,
      headers,
    });
  }
}
