/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Image from '@core/Image';
import { useNavigate } from 'react-router-dom';

import './style.scss';
import Button from '@core/Button';
import { appRoute, buttonLooks, buttonTypes, dashboardSliderBtnType } from '@utils/Constant';
import { useLayout } from '@context/Layout';
import { sanitizedDOM } from '@utils/Validation';

function ImageSlider({ data }) {
  // let index = 0;
  const [index, setIndex] = React.useState(0);

  const [sliderData, setSliderData] = useState(data || []);
  const navigate = useNavigate();
  const { isSM } = useLayout();

  const defaultSliderChangeTimer = 7000;

  const changeSlider = (i) => {
    if (sliderData.length > 1) {
      setIndex(i);
    }
  };

  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      // eslint-disable-next-line no-nested-ternary
      () => setIndex((prevIndex) => (prevIndex + 1 < sliderData.length ? prevIndex + 1 : 0)),
      defaultSliderChangeTimer,
    );
    return () => {
      resetTimeout();
    };
  }, [index]);

  // useInterval(() => {
  //   index = index + 1 === sliderData.length ? 0 : 1;
  // }, defaultSliderChangeTimer)

  useEffect(() => {
    if (data) {
      setSliderData(data);
    }
  }, [data]);

  const navigateTo = (url, type) => {
    if (type === dashboardSliderBtnType.PARTENR) {
      navigate(appRoute.PARTNER_DETAIL(url));
    } else if (type === dashboardSliderBtnType.LINK) {
      window.open(url, '__blank');
    }
  };

  return (
    <div className="image-slider">
      <div className="slideshow-container">
        {sliderData.map((slide, i) => (
          <div
            className={index === i ? 'mySlides fade' : 'hidden '}
            key={`slider-image-${slide.title}`}
          >
            {/* <div className="numbertext">1 / 3</div> */}
            <Image className="w-full" src={isSM ? slide.imgApp : slide.imgWeb} alt="Slider" />
            <div className="text">
              <span className="text-24 text-ed-bold text-ed-white w">{slide.title}</span>
              {slide?.description && (
                <div
                  className="text-14 text-ed-regular text-ed-white hidden sm:block "
                  dangerouslySetInnerHTML={{ __html: sanitizedDOM(slide?.description) }}
                />
              )}
              {slide.buttonText && slide.buttonUrl && (
                <div className="action-button w-full flex-center mt-1 sm:mt-5">
                  <Button
                    className="relative bg-ed-white text-ed-regular border-ed text-ed-gray w-8/12 xs:w-5/12 py-2 flex-center rounded-full"
                    type={buttonTypes.DEFAULT}
                    look={buttonLooks.PILL}
                    label={slide.buttonText}
                    onClick={() => navigateTo(slide.buttonUrl, slide.buttonType)}
                  />
                </div>
              )}
            </div>
            {/* <div className=""></div> */}
          </div>
        ))}
        {/*  <div className="mySlides fade">
          <div className="numbertext">2 / 3</div>
          <Image src={sliderData.imgWeb} alt="Slider" />
          <div className="text">Caption Two</div>
        </div>

        <div className="mySlides fade">
          <div className="numbertext">3 / 3</div>
          <Image src={sliderData.imgWeb} alt="Slider" />
          <div className="text">Caption Three</div>
        </div> */}
      </div>
      {/* <br> */}

      <div className="slideshow-dots text-center">
        {sliderData.map((slide, i) => (
          <span
            key={`slider-dot-${slide.title}`}
            className={index === i ? 'dot active' : 'dot'}
            onClick={() => changeSlider(i)}
          />
        ))}
      </div>
    </div>
  );
}

ImageSlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      imgWeb: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      buttonUrl: PropTypes.string,
      buttonText: PropTypes.string,
    }),
  ),
};
ImageSlider.defaultProps = {
  data: [],
};

export default ImageSlider;
