import { createContext, useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  appAlert,
  appAudit,
  appDefaultErrorCode,
  appSortTypes,
  appStorage,
  defaultPartnerFilter,
} from '@utils/Constant';
import PartnerDetailConfirm from '@part/Modal/PartnerDetailCofirm';
import PartnerFilteration from '@part/Filteration/PartnerFilteration';

import { useAppConfig } from '@context/AppConfig';
import { useAuth } from '@context/AuthProvider';
import PartnerService from '@service/Partner';
import { StatusCodes, validateResponse } from '@utils/ApiCodex';
import { keysToCamel, onlyUnique, sortAsc, sortDes } from '@utils/Validation';
import { useLoading } from '@context/Loading';
import { useAlert } from '@context/Alert';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';
import { useErrorHandler } from '@context/ErrorHandler';
import { useLayout } from '@context/Layout';
import OnboardService from '@service/Onboard';
import Storage from '@utils/Storage';

// A context to load all app configuration from server
const PartnerContext = createContext();

// The top level component that will wrap our app's core features
export const PartnerProvider = ({ children }) => {
  const [filter, setFilter] = useState(defaultPartnerFilter);
  const [appliedFilter, setAppliedFilter] = useState(defaultPartnerFilter);

  const [confirmPartner, setConfirmPartner] = useState(null);
  const [showConfirmPartner, setShowConfirmPartner] = useState(null);

  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);

  const [partnerList, setPartnerList] = useState([]);
  const [viewPartnerList, setViewPartnerList] = useState([]);
  const [viewPartnerFaq, setViewPartnerFaq] = useState([]);

  const { env } = useAppConfig();
  const { isUserLoggedIn } = useAuth();
  const { showLoader, hideLoader } = useLoading();
  const { showAlert } = useAlert();
  const { translate } = useLanguage();
  const { setErrorStatusCode } = useErrorHandler();
  const { isMobile } = useLayout();

  // Render the children as normal
  // TODO: Implemente loading if required.
  const renderContent = () => children;

  const sortFilter = () => {
    let sort = appSortTypes.NONE;
    if (filter.sort === appSortTypes.ASC) {
      sort = appSortTypes.DESC;
    } else if (filter.sort === appSortTypes.DESC) {
      sort = appSortTypes.NONE;
    } else {
      sort = appSortTypes.ASC;
    }
    setFilter({ ...filter, sort });
  };

  const openFilter = () => setFilter({ ...filter, isOpen: true });
  const closeFilter = () => setFilter({ ...filter, isOpen: false });

  const searchPartner = (value) => {
    setFilter({ ...filter, searchText: value });
    const search = partnerList.filter((partner) =>
      `${partner.partnerName}`.toLocaleLowerCase().includes(`${value}`.toLocaleLowerCase()),
    );
    setViewPartnerList(
      value
        ? search || []
        : partnerList.filter(
            (partner) => parseInt(partner.partnerId, 10) !== parseInt(selectedPartnerId, 10),
          ),
    );
  };
  // const setCategory = (value) => setFilter({ ...filter, selectedCategories: value });
  const selectCategory = (index) => {
    const value = filter.categories.map((cat, i) => ({
      ...cat,
      isActive: i === index ? !cat.isActive : cat.isActive,
    }));
    setFilter({ ...filter, categories: value });
  };

  const changePartnerView = (applyView) => setFilter({ ...filter, view: applyView });

  const openPartnerConfirm = (partner) => {
    setShowConfirmPartner(true);
    setConfirmPartner(partner);
  };
  const closePartnerConfirm = (confirm) => {
    // console.log({ confirm });
    if (confirm && confirmPartner && confirmPartner.partnerId) {
      showLoader();
      const partnerService = new PartnerService(env);
      partnerService
        .saveSelectedPartner(confirmPartner.partnerId)
        .then((result) => {
          if (result.status >= StatusCodes.OK && result.status <= StatusCodes.MULTI_STATUS) {
            const onboardService = new OnboardService(env);
            onboardService.saveUserAudit({
              user_email: Storage.get(appStorage.EMAIL),
              type: `${appAudit.PARTENR_CHANGE} to ${confirmPartner.partnerName}`,
              partherChange: true,
            });
            setSelectedPartnerId(confirmPartner.partnerId);
            searchPartner();
            showAlert({
              type: appAlert.SUCCESS,
              message: translate(LANGUAGE.partnerChangedSuccessfully),
            });
          }
          setShowConfirmPartner(false);
          setConfirmPartner(false);
          hideLoader();
        })
        .catch((err) => {
          console.log(err);
          hideLoader();
          // showAlert({
          //   type: appAlert.ERROR,
          //   message: err.response?.data || translate(LANGUAGE.invalidRequest),
          // });
          setErrorStatusCode(err?.response?.status || err?.status || appDefaultErrorCode.CODE);
        });
    } else {
      setShowConfirmPartner(false);
      setConfirmPartner(false);
    }
  };

  // useEffect(() => { console.log(confirmPartner); }, [confirmPartner])

  const selectPartner = () => {
    const partnerAvailable = partnerList.find(
      (part) => parseInt(part.partnerId, 10) === parseInt(selectedPartnerId, 10),
    );
    // console.log({ partnerAvailable, partnerList, selectedPartnerId });
    if (partnerAvailable) setSelectedPartner(partnerAvailable);
  };

  const getPartnerFaq = (id) => {
    const searchPartnerFaq = viewPartnerFaq.find((faq) => faq.partnerId === id);

    if (!searchPartnerFaq) {
      const partnerService = new PartnerService(env);
      partnerService
        .partnerFaq(id)
        .then((result) => {
          if (result.status === StatusCodes.OK && validateResponse(result)) {
            // if (id === selectedPartnerId) {
            //   setSelectedPartnerFaq({ partnerId: id, faqList: keysToCamel((result.data?.data || [])) });
            // } else {
            setViewPartnerFaq([
              ...viewPartnerFaq,
              { partnerId: id, faqList: keysToCamel(result.data?.data || []) },
            ]);
            // }
          }
        })
        .catch((err) => {
          hideLoader();
          // showAlert({
          //   type: appAlert.ERROR,
          //   message: err.response?.data || translate(LANGUAGE.invalidRequest),
          // });
          setErrorStatusCode(err?.response?.status || err?.status || appDefaultErrorCode.CODE);
        });
    }
  };

  const getPartners = () => {
    const partnerService = new PartnerService(env);
    partnerService
      .getPartner()
      .then((result) => {
        if (result.status === StatusCodes.OK) {
          setPartnerList((keysToCamel(result.data) || []).map((part, i) => ({ ...part, i })));
          // setViewPartnerList(keysToCamel(result.data));
          setViewPartnerList(keysToCamel(result.data));
          const categories =
            (keysToCamel(result.data) || [])
              .map((partner) => partner.categoryName)
              .filter(onlyUnique)
              .map((cat) => ({ name: cat, isActive: false })) || [];
          setAppliedFilter({ ...appliedFilter, categories });
          setFilter({ ...filter, categories });
          // selectPartner();
          hideLoader();
        }
      })
      .catch((err) => {
        hideLoader();
        setErrorStatusCode(err?.response?.status || err?.status || appDefaultErrorCode.CODE);
        // showAlert({
        //   type: appAlert.ERROR,
        //   message: err.response?.data || translate(LANGUAGE.invalidRequest),
        // });
      });
  };

  const getSelection = () => {
    const partnerService = new PartnerService(env);
    partnerService
      .getSelectedPartner()
      .then((result) => {
        if (result.status === StatusCodes.OK) {
          if (result.data && result.data.selection) {
            setSelectedPartnerId(result.data.selection);
            getPartnerFaq(result.data.selection);
          }
          // selectPartner();
        }
      })
      .catch((err) => {
        hideLoader();
        // showAlert({
        //   type: appAlert.ERROR,
        //   message: err.response?.data || translate(LANGUAGE.invalidRequest),
        // });
        setErrorStatusCode(err?.response?.status || err?.status || appDefaultErrorCode.CODE);
      });
  };

  const filterPartners = (val) => {
    setAppliedFilter({ ...val, isOpen: false, searchText: '' });
    closeFilter();
    // setViewPartnerList((viewPartnerList.length === partnerList.length))
    let list = sortAsc(partnerList, 'i');
    if (val.sort === appSortTypes.ASC) list = sortAsc(list, 'partnerName');
    if (val.sort === appSortTypes.DESC) list = sortDes(list, 'partnerName');
    const activeCat = (val.categories || []).filter((category) => category.isActive);
    setViewPartnerList(
      list
        .filter((partner) => parseInt(partner.partnerId, 10) !== parseInt(selectedPartnerId, 10))
        .filter((partner) =>
          activeCat.length > 0
            ? activeCat.map((category) => category.name).indexOf(partner.categoryName) > -1
            : partner,
        ),
    );
  };

  const updateSelectedPartner = (partnerId) => {
    const partnerService = new PartnerService(env);
    partnerService
      .saveSelectedPartner(partnerId)
      .then((result) => {
        if (result.status === StatusCodes.OK) {
          if (result.data && result.data.selection) {
            setSelectedPartnerId(result.data.selection);
            getPartnerFaq(result.data.selection);
          }
          // selectPartner();
        }
      })
      .catch((err) => {
        hideLoader();
        // showAlert({
        //   type: appAlert.ERROR,
        //   message: err.response?.data || translate(LANGUAGE.invalidRequest),
        // });
        setErrorStatusCode(err?.response?.status || err?.status || appDefaultErrorCode.CODE);
      });
  };

  // Effect
  useEffect(() => {
    if (isUserLoggedIn) {
      getPartners();
      getSelection();
    }
    return () => {};
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (partnerList && selectedPartnerId) {
      selectPartner();
      setViewPartnerList(
        partnerList.filter(
          (partner) => parseInt(partner.partnerId, 10) !== parseInt(selectedPartnerId, 10),
        ),
      );
    }
    return () => {};
  }, [partnerList, selectedPartnerId]);

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(
    () => ({
      filter,
      setFilter,

      appliedFilter,
      filterPartners,

      openFilter,
      closeFilter,
      searchPartner,
      sortFilter,
      // setCategory,
      selectCategory,

      changePartnerView,
      confirmPartner,
      setConfirmPartner,
      openPartnerConfirm,
      closePartnerConfirm,

      selectedPartnerId,
      setSelectedPartnerId,
      selectedPartner,
      setSelectedPartner,
      getPartnerFaq,
      viewPartnerFaq,

      partnerList,
      setPartnerList,
      viewPartnerList,
      setViewPartnerList,

      updateSelectedPartner,
    }),
    [
      filter,
      setFilter,

      appliedFilter,
      filterPartners,

      openFilter,
      closeFilter,
      sortFilter,

      searchPartner,
      // setCategory,
      selectCategory,

      changePartnerView,
      confirmPartner,
      setConfirmPartner,
      openPartnerConfirm,
      closePartnerConfirm,

      selectedPartnerId,
      setSelectedPartnerId,
      getPartnerFaq,
      viewPartnerFaq,

      selectedPartner,
      setSelectedPartner,
      partnerList,
      setPartnerList,
      viewPartnerList,
      setViewPartnerList,

      updateSelectedPartner,
    ],
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <PartnerContext.Provider value={contextPayload}>
      {filter.isOpen && (
        <div className="partner-filter-modal absolute w-full h-full inset-0 z-50 overflow-x-hidden">
          <PartnerFilteration
            {...{
              isOpen: filter.isOpen,
              filter,
              closeFilter,
              changePartnerView,
              selectCategory,
              filterPartners,
              sortFilter,
            }}
          />
        </div>
      )}
      {(showConfirmPartner || confirmPartner) && (
        <div
          className={`${
            isMobile ? 'partner-mobile-modal' : 'h-full '
          }  absolute w-full inset-0 z-50 overflow-x-hidden`}
        >
          <PartnerDetailConfirm
            {...{
              open: showConfirmPartner,
              partner: confirmPartner,
              closePartnerConfirm,
              isMobile,
            }}
          />
        </div>
      )}
      {renderContent()}
    </PartnerContext.Provider>
  );
};

PartnerProvider.propTypes = {
  children: PropTypes.node,
};

PartnerProvider.defaultProps = {
  children: null,
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const usePartner = () => useContext(PartnerContext);
