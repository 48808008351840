import { HTTP_REQUEST_METHODS } from '@utils/ApiCodex';
import { appAPIHeaders, appAPIUri } from '@utils/Url';
import axios from 'axios';

export default class MyAccountService {
  env = {};

  /**
   * Set env from app loading with server.
   * @param {JSON} env APP Envs
   */
  constructor(env) {
    this.env = env;
  }

  /**
   * Fetch user information
   * @returns API Request
   */
  getUserPersonalInfo() {
    // Req URL
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.MY_ACCOUNT.USER_PERSONAL_INFO}`;
    // Default headers
    const headers = appAPIHeaders.DEFAULT_RAW_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.GET,
      url,
      headers,
    });
  }

  /**
   * Store user  information.
   * @param {Object} data Onboard user data
   * @returns API Request
   */
  saveUserPersonalInfo(data) {
    // Req Url
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.MY_ACCOUNT.USER_PERSONAL_INFO}`;

    // Default headers
    const headers = appAPIHeaders.DEFAULT_RAW_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.POST,
      url,
      headers,
      data,
    });
  }

  /**
   * Store user  information.
   * @param {Object} data Onboard user data
   * @returns API Request
   */
  setUserPassword(data) {
    // Req Url
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.MY_ACCOUNT.USER_PASSWORD}`;

    // Default headers
    const headers = appAPIHeaders.DEFAULT_RAW_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.POST,
      url,
      headers,
      data,
    });
  }

  /**
   * Store user  information.
   * @param {Object} data Onboard user data
   * @returns API Request
   */
  getUserInfo(data) {
    // Req Url
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.MY_ACCOUNT.USER_INFO}`;

    // Default headers
    const headers = appAPIHeaders.DEFAULT_RAW_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.GET,
      url,
      headers,
      data,
    });
  }

  /**
   * Store user  information.
   * @param {Object} data Onboard user data
   * @returns API Request
   */
  saveUserInfo(data) {
    // Req Url
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.MY_ACCOUNT.USER_INFO}`;

    // Default headers
    const headers = appAPIHeaders.DEFAULT_RAW_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.POST,
      url,
      headers,
      data,
    });
  }
}
