/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { createContext, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Storage from '@utils/Storage';
import { appRoute, appStorage } from '@utils/Constant';
import { withRouter } from '@utils/Router/withRouter';
import { useAppConfig } from '@context/AppConfig';
import AuthService from '@service/Auth';
import { assignAuthTo, StatusCodes } from '@utils/ApiCodex';
import { keysToCamel } from '@utils/Validation';

import { SignInProvider } from '@context/SignIn';
import { LoginInProvider } from '@context/Login';
import { ForgotPasswordProvider } from '@context/ForgotPassword';
import { ResetPasswordProvider } from '@context/ResetPassword';
import { PartnerProvider } from '@context/Partner';
import { MyAccountProvider } from '@context/MyAccount';

import { useLoading } from '@context/Loading';
import { useAuth } from '@context/AuthProvider';
import { useErrorHandler } from '@context/ErrorHandler';
import { MyVoucherProvider } from '@context/MyVoucher';

// A context to load all app configuration from server
const ApiContext = createContext();

// The top level component that will wrap our app's core features
export const ApiProviderPre = withRouter(({ children }) => {
  // Config
  const { env } = useAppConfig();
  const { setAuthCode, setUserLogin } = useAuth();
  const { showLoader } = useLoading();

  const { setErrorStatusCode } = useErrorHandler();
  // const history = useHistory();

  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  const processReLogin = () => {
    console.log('Returning user to login');
    Storage.remove(appStorage.AUTH_TOKEN);
    Storage.remove(appStorage.AUTH_REFRESH_TOKEN);
    window.location.href = appRoute.LOGIN;
    // return Promise.reject(new Error('Unauthorised'));
    setErrorStatusCode(StatusCodes.UNAUTHORIZED);
  };
  // Pre Request
  // TODO: Add header to headers.
  axios.interceptors.request.use((req) => {
    // console.log(`${req.method} ${req.url}`);
    const checkAuth = assignAuthTo(env).find((header) => `${req.url}`.includes(header.URL));
    if (checkAuth && checkAuth.IS_VALID) {
      req.headers[checkAuth.HEADER_KEY] = checkAuth.HEADER_VALUE;
    }

    // Important: request interceptors **must** return the request.
    return req;
  });

  // Post Request
  // TODO: Handle error if we received specific
  axios.interceptors.response.use(
    (res) =>
      // console.log(res.data.json);
      // Important: response interceptors **must** return the response.
      res,
    (error) => {
      const originalRequest = error.config;
      if (error.response.status === StatusCodes.SERVICE_UNAVAILABLE) {
        Storage.clear();
        window.location.href = '/maintenance';
      } else {
        if (
          originalRequest.url.includes('refreshToken') ||
          originalRequest.url.includes('logout')
        ) {
          return Promise.reject({
            status: StatusCodes.FORBIDDEN,
            data: { message: 'Please Login to continue!' },
          });
        }
        if (error.response.status === StatusCodes.UNAUTHORIZED && !originalRequest._retry) {
          if (isRefreshing) {
            return new Promise((resolve, reject) => {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                originalRequest.headers.Authorization = `bearer ${token}`;
                return axios(originalRequest);
              })
              .catch((err) => Promise.reject(err));
          }

          showLoader();
          const authService = new AuthService(env);
          const refreshToken = Storage.get(appStorage.AUTH_REFRESH_TOKEN);
          originalRequest._retry = true;
          isRefreshing = true;

          return new Promise((resolve, reject) => {
            authService
              .getRefreshToken(refreshToken)
              .then((res) => {
                if (res && res.status >= StatusCodes.OK && res.status <= StatusCodes.MULTI_STATUS) {
                  const { data } = res;
                  setAuthCode(keysToCamel(data));
                  Storage.set(appStorage.AUTH_TOKEN, data.access_token);
                  // Check if refresh token is available
                  if (data.refresh_token) {
                    Storage.set(appStorage.AUTH_REFRESH_TOKEN, data.refresh_token);
                    setUserLogin(true);
                  }

                  axios.defaults.headers.common.Authorization = `bearer ${data.refresh_token}`;
                  originalRequest.headers.Authorization = `bearer ${data.access_token}`;

                  processQueue(null, data.access_token);

                  resolve(axios(originalRequest));
                }
                // processReLogin();
              })
              .catch((err) => {
                processReLogin();
                processQueue(err, null);
                reject(err);
              })
              .then(() => {
                isRefreshing = false;
              });
          });

          //   showLoader();
          //   originalRequest._retry = true;
          //   const authService = new AuthService(env);

          //   const refreshToken = Storage.get(appStorage.AUTH_REFRESH_TOKEN);

          //   return authService
          //     .getRefreshToken(refreshToken)
          //     .then((res) => {
          //       if (res && res.status >= StatusCodes.OK && res.status <= StatusCodes.MULTI_STATUS) {
          //         const { data } = res;

          //         setAuthCode(keysToCamel(data));
          //         Storage.set(appStorage.AUTH_TOKEN, data.access_token);

          //         // Check if refresh token is available
          //         if (data.refresh_token) {
          //           Storage.set(appStorage.AUTH_REFRESH_TOKEN, data.refresh_token);
          //           setUserLogin(true);
          //         }
          //         axios.defaults.headers.common.Authorization = `bearer ${data.refresh_token}`;
          //         return axios(originalRequest);
          //       }
          //       console.log('Returning user to login');
          //       Storage.remove(appStorage.AUTH_TOKEN);
          //       Storage.remove(appStorage.AUTH_REFRESH_TOKEN);
          //       // window.location.href = '/';
          //       // return Promise.reject(new Error('Unauthorised'));
          //       setErrorStatusCode(StatusCodes.UNAUTHORIZED);
          //       history.push(appRoute.LOGIN);
          //     })
          //     .catch((err) => {
          //       setErrorStatusCode(StatusCodes.NOT_ACCEPTABLE);
          //       Storage.remove(appStorage.AUTH_TOKEN);
          //       Storage.remove(appStorage.AUTH_REFRESH_TOKEN);
          //       console.log(err);
          //       // throw new Error(err);
          //     });
        }
      }

      // return Error object with Promise
      return Promise.reject(error);
    },
  );

  // Render the children as normal
  // TODO: Implemente loading until authentication is verified.
  const renderContent = () => children;

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(() => ({}), []);

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <ApiContext.Provider value={contextPayload}>
      {/* App Sign In Provider */}
      <SignInProvider>
        {/* App Login In Provider */}
        <LoginInProvider>
          {/* App Forgot Password In Provider */}
          <ForgotPasswordProvider>
            {/* App Reset Password In Provider */}
            <ResetPasswordProvider>
              {/* App Partner Provider */}
              <PartnerProvider>
                {/* My Account */}
                <MyAccountProvider>
                  {/* My Voucher */}
                  <MyVoucherProvider>{renderContent()}</MyVoucherProvider>
                </MyAccountProvider>
              </PartnerProvider>
            </ResetPasswordProvider>
          </ForgotPasswordProvider>
        </LoginInProvider>
      </SignInProvider>
    </ApiContext.Provider>
  );
});

ApiProviderPre.propTypes = {
  children: PropTypes.node,
};

ApiProviderPre.defaultProps = {
  children: null,
};

export const ApiProvider = withRouter(ApiProviderPre);
export default ApiProvider;

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useApi = () => useContext(ApiContext);
