import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import Button from '@core/Button';
import { animationStyle, appIcons, appModalBackground, buttonTypes } from '@utils/Constant';
// import Card from '@core/Card';
import { themeColor } from '@theme/common/color';

import './style.scss';

function MobileModal({ isOpen, children, title, onClose, backDropColor, className }) {
  const modalEl = useRef(null);
  const location = useLocation();
  const close = () => {
    const modal = modalEl.current;
    modal.className = `hidden ${animationStyle.ANIMATED} ${animationStyle.FADE_OUT}`;
    onClose();
  };

  const onOpen = () => {
    const modal = modalEl.current;
    modal.className = `${backDropColor} ${className}`;
  };

  useEffect(() => {
    if (isOpen) {
      onOpen();
    } else {
      close();
    }
  }, [isOpen]);

  useEffect(() => {
    // const unlisten = history.listen(() => {
    close();
    // });

    // return () => {
    //   unlisten();
    // };
  }, [location]);

  return (
    <div ref={modalEl} className="mobile-modal" key={`mobile_${title}`}>
      <div className="app-ed-header w-full bg-ed-white flex">
        <div className="flex-center flex-1">
          <span className="text-24 text-ed-bold text-ed-gray">{title}</span>
        </div>
        <div className="mobile-modal-close">
          <Button
            className="mobile-modal-close-btn"
            icon
            iconComponent={appIcons.CLOSE_X}
            type={buttonTypes.DEFAULT}
            onClick={close}
            pathFill={themeColor.edWhite}
          />
        </div>
      </div>
      <div className="app-ed-content overflow-y-auto overflow-x-hidden bg-ed-dk-gray h-90 relative px-5">
        {children}
      </div>
      {/* <div className="modal-card">
        <Card>
          {title ? (
            <div className="modal-header relative flex-center">
              <div className="modal-title w-full text-center">
                <span className="text-24 text-ed-bold text-ed-gray">{title}</span>
              </div>
              <div className="modal-close flex-center">
                <Button
                  className="modal-close-btn"
                  icon
                  iconComponent={appIcons.CLOSE_X}
                  type={buttonTypes.FAB}
                  onClick={close}
                  pathFill={themeColor.edWhite}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="modal-close flex-center">
                <Button
                  className="modal-close-btn"
                  icon
                  iconComponent={appIcons.CLOSE_X}
                  type={buttonTypes.FAB}
                  onClick={close}
                  pathFill={themeColor.edWhite}
                />
              </div>
            </>
          )}
          <div className="modal-content flex-col-center">{children}</div>
        </Card>
      </div> */}
    </div>
  );
}

MobileModal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  backDropColor: PropTypes.string,
  className: PropTypes.string,
};

MobileModal.defaultProps = {
  onClose: () => {},
  title: '',
  isOpen: false,
  backDropColor: appModalBackground.DEFAULT,
  className:
    'fixed w-screen h-screen inset-0 z-50 overflow-hidden flex justify-center items-center',
};

export default MobileModal;
