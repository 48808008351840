/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import Modal from '@core/Modal';
// import PartnerViewCard from '@part/Card/PartnerViewCard';
import Button from '@core/Button';
import { appModalBackground, buttonLooks } from '@utils/Constant';
import SelectedPartnerCard from '@part/Card/SelectedPartnerCard';
// import { isObject } from '@utils/Validation';

import './style.scss';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';
import MobileModal from '@core/Modal/MobileModal';
import { sanitizedDOM } from '@utils/Validation';

function PartnerDetailConfirm({ open, partner, closePartnerConfirm, isMobile }) {
  // setConfirmPartner

  const [isOpen, setIsOpen] = useState(open);
  const { translate } = useLanguage();

  useEffect(() => {
    setIsOpen(open);
  }, [open, partner]);

  const confirmContent = () => (
    <div className={isMobile ? '' : 'h-full'}>
      <div className="modal-container h-full">
        {!isMobile && (
          <div className="modal-content-title">
            <h2 className="text-24 text-ed-gray text-ed-bold w-full text-left">
              {translate(LANGUAGE.partnerSlection)}
            </h2>
          </div>
        )}
        <div className="modal-body flex-center h-full">
          <div className="partner-detail-confirm grid grid-cols-2 gap-x-10 h-full">
            <div className="col-span-2 lg:col-span-1 partner-logo flex-center py-10 sm:py-0 relative">
              {/* <PartnerViewCard {...{ imgStyle: { width: '269px' } }} /> */}
              {/* <PartnerViewCard {...{ name: partner.partnerName, categoryName: partner.categoryName, logo: partner.partnerLogo }} /> */}
              <SelectedPartnerCard
                {...{
                  name: partner.partnerName,
                  categoryName: partner.categoryName,
                  logo: partner.partnerLogo,
                  isSelected: false,
                  backgroundClass: isMobile ? '' : 'shade-modal',
                }}
              />
            </div>
            <div
              className="col-span-2 lg:col-span-1 flex flex-col justify-between"
              style={{ zIndex: 1 }}
            >
              <div className="partner-description">
                <div
                  className={`text-14 ${
                    isMobile ? 'text-ed-white' : 'text-ed-gray'
                  } text-ed-regular`}
                  dangerouslySetInnerHTML={{ __html: sanitizedDOM(partner.confirmText) }}
                />
              </div>
              <div className="w-full flex-center py-5">
                <Button
                  label={translate(LANGUAGE.okDoIt)}
                  look={buttonLooks.PILL}
                  onClick={() => closePartnerConfirm(true)}
                  onClickLoading
                  icon
                  className="relative bg-ed-blue text-ed-bold text-white px-8 py-2 transition duration-300 ease-in-out flex-center rounded-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const getDeviceModal = () => (
    <Modal
      {...{
        isOpen,
        backDropColor: appModalBackground.DARK,
        className: 'partner-detail-confirm-modal flex justify-center items-center w-full h-full',
        onClose: closePartnerConfirm,
      }}
    >
      {' '}
      {confirmContent()}
    </Modal>
  );

  const getMobileModal = () => (
    <MobileModal
      {...{
        isOpen,
        // backDropColor: appModalBackground.DARK,
        title: translate(LANGUAGE.partnerSlection),
        className: 'partner-detail-confirm-mobile-modal w-screen ',
        onClose: closePartnerConfirm,
      }}
    >
      {confirmContent()}
    </MobileModal>
  );

  return <>{isMobile ? getMobileModal() : getDeviceModal()}</>;
}

PartnerDetailConfirm.propTypes = {
  open: PropTypes.bool,
  partner: PropTypes.shape({
    partnerName: PropTypes.string,
    categoryName: PropTypes.string,
    partnerLogo: PropTypes.string,
    partnerId: PropTypes.string,
    confirmText: PropTypes.string,
  }),
  closePartnerConfirm: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

PartnerDetailConfirm.defaultProps = {
  open: false,
  partner: {
    partnerName: '',
    categoryName: '',
    partnerLogo: '',
    partnerId: '',
    confirmText: '',
  },
};

export default PartnerDetailConfirm;
