/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getIcon } from '@utils/SVG';
import { buttonLooks } from '@utils/Constant';

function FabButton({
  className,
  look,
  pathFill,
  loading,
  disabled,
  iconComponent,
  svgProps,
  onClick,
  onClickLoading,
}) {
  const [isLoading, setIsLoading] = useState(loading);

  useEffect(() => {
    if (loading !== isLoading) setIsLoading(loading);

    return () => {
      setIsLoading(false);
    };
  }, [loading]);

  let btnClassName =
    className ||
    'flex bg-blue-500 font-bold text-white px-4 py-4 transition duration-300 ease-in-out hover:bg-blue-600 mr-6 flex-center';

  if (look) {
    switch (look) {
      case buttonLooks.ROUNDED:
        btnClassName += ' rounded-lg';
        break;
      case buttonLooks.CIRCLE:
        btnClassName += ' rounded-full';
        break;

      default:
        break;
    }
  }

  const handleClick = () => {
    onClick();
    if (onClickLoading) setIsLoading(!isLoading);
  };

  const checkIcon = () => getIcon({ iconComponent, pathFill, svgProps });

  return (
    <button
      type="button"
      className={btnClassName}
      onClick={handleClick}
      disabled={disabled || isLoading}
    >
      {isLoading && <div className="default-btn-loader" />}
      <div className={isLoading ? 'opacity-10' : ''}>{checkIcon()}</div>
    </button>
  );
}

FabButton.propTypes = {
  className: PropTypes.string,
  look: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClickLoading: PropTypes.bool,
  pathFill: PropTypes.string,
  iconComponent: PropTypes.string,
  svgProps: PropTypes.object,
  onClick: PropTypes.func,
};

FabButton.defaultProps = {
  className: '',
  look: '',
  loading: false,
  disabled: false,
  onClickLoading: false,
  pathFill: '',
  iconComponent: null,
  svgProps: {},
  onClick: () => {},
};
export default FabButton;
