// App API Urls Routes
export const appAPIUri = {
  STATIC: {
    LANGUAGE: '/assets/i18n/',
    CONTENT: 'api/voucher/static-content',
  },
  AUDIT: 'api/audit',
  AUTH: {
    LOGIN: 'api/login',
    VERIFY_LOGIN: 'api/verify',
    FORGOT_PASSWORD: 'api/forgot-password',
    RESET_PASSWORD: (id) => `api/reset-password/${id}`,
    ACCESS_TOKEN: 'api/access-token',
    REFRESH_TOKEN: 'api/refresh-token',
    LOGOUT: 'api/logout',
  },
  ONBOARDING: {
    USERUID: 'api/deeplink',
    ONBOARD: 'api/on_board_user',
    USER_INFO: (id) => `api/voucher/user-onboard/${id}`,
    SAVE_USER_INFO: 'api/voucher/private/user-info',
    VERIFY_EMAIL: (id) => `api/voucher/personal/verify-email?assoc=${id}`,
  },
  PARTNER: {
    USER_PARTNERS: 'api/voucher/user-partners',
    USER_SELECTION: 'api/voucher/private-selection',
    FAQ: (id) => `api/voucher/user-partners-faq/${id}`,
  },
  MY_ACCOUNT: {
    USER_INFO: 'api/voucher/private/user-info',
    USER_PERSONAL_INFO: 'api/voucher/personal/user-info',
    USER_PASSWORD: 'api/voucher/set-password',
  },
  MY_VOUCHER: {
    VOUCHER: 'api/voucher',
  },
  DASHBOARD_SLIDES: 'api/voucher/dashboard',
};

// App API Headers
export const appAPIHeaders = {
  DEFAULT_JSON: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  DEFAULT_RAW_JSON: {
    'Content-Type': 'application/json',
  },
};

// App Url Params
export const appUrlQueryParams = {
  AUTH_CALLBACK: {
    CODE: 'code',
    SESSION_STATE: 'session_state',
  },
  ONBOARDING_CALLBACK: {
    URL: 'url',
    ASSOC: 'assoc',
    E_MAIL: 'email',
  },
  FORGOT_PASSWORD_CALLBACK: {
    URL: 'url',
  },
  RESET_PASSWORD_CALLBACK: {
    URL: 'url',
  },
};

/**
 * Perform Navigation
 * @param {string} url Path to follow
 * @returns check and will navigate accourding
 */
export function navigateTo(url) {
  try {
    if (url.includes('http')) {
      return (window.location.href = url);
    }
    return null;
  } catch (error) {
    // TODO: Routing check
    throw new Error(error);
  }
}

export const createQueryParams = (data = {}) => {
  const keyValue = Object.entries(data);

  const onlyValid = keyValue.filter((element) => element[1]);

  const keyValueObj = Object.fromEntries(onlyValid);

  const query = new URLSearchParams(keyValueObj);

  return query;
};

export const getURLQueryParams = (url) => {
  const splitURL = (url.split('?') || []).pop();
  const query = new URLSearchParams(`?${splitURL}`);
  return Object.fromEntries(query.entries());
};

/* Copy the text inside the text field */
export const copyToClipboard = (value) => {
  navigator.clipboard.writeText(value);
};

/**
 * Mark/store the script as fully loaded in a global variable.
 * @param src URL of the script
 */
function markScriptFullyLoaded(src) {
  try {
    let scriptLoadMap = window.scriptLoadMap || [];
    const index = scriptLoadMap.findIndex((script) => script && script.src === src);
    if (index > -1) {
      scriptLoadMap[index].loaded = true;
      window.scriptLoadMap = scriptLoadMap;
    } else {
      scriptLoadMap = [{ src, loaded: true }];
      window.scriptLoadMap = scriptLoadMap;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

/**
 * Returns true if the script has been added to the page
 * @param src URL of the script
 */
function isScriptAdded(src) {
  return Boolean(document.querySelector(`script[src="${src}"]`));
}

/**
 * Returns true if the script has been fully loaded
 * @param src URL of the script
 */
function isScriptFullyLoaded(src) {
  const index = (window.scriptLoadMap || []).findIndex((script) => script && script.src === src);
  return index > -1 && window.scriptLoadMap[index] && window.scriptLoadMap[index].loaded;
}

/**
 * Load a script.
 * @param src URL of the script
 * @param appendOnElement Append on head / body / element
 * @param onLoadCallback Callback function when the script is fully loaded
 * @param onLoadErrorCallback Callback function when the script fails to load
 * @param retryCount How many times retry laoding the script? (Not implimented here. Logic goes into js.onerror function)
 */
export function loadScript(
  { src, appendOnElement, id },
  onLoadCallback = () => {},
  onLoadErrorCallback = () => {},
) {
  if (!src) return;
  // Check if the script is already loaded
  if (isScriptAdded(src)) {
    // If script already loaded successfully, trigger the callback function
    if (isScriptFullyLoaded(src)) onLoadCallback();

    // eslint-disable-next-line no-console
    console.warn('Script already loaded. Skipping: ', src);
    return;
  }

  // Loading the script...
  const js = document.createElement('script');
  js.setAttribute('async', '');
  js.src = src;
  js.async = true;
  js.id = id;

  js.onload = () => {
    markScriptFullyLoaded(src);

    // Optional callback on script load
    if (onLoadCallback) onLoadCallback();
  };

  js.onerror = () => {
    // Remove the script node (to be able to try again later)
    const js2 = document.querySelector(`script[src="${src}"]`);
    js2.parentNode.removeChild(js2);

    // Optional callback on script load failure
    if (onLoadErrorCallback) onLoadErrorCallback();
  };
  if (appendOnElement === 'head') {
    document.head.appendChild(js);
  } else if (appendOnElement === 'body') {
    document.body.appendChild(js);
  } else {
    document.getElementById(appendOnElement).appendChild(js);
  }
}

export default {
  createQueryParams,
  navigateTo,
  appAPIUri,
  getURLQueryParams,
  copyToClipboard,
  loadScript,

  markScriptFullyLoaded,
  isScriptAdded,
  isScriptFullyLoaded,
};
