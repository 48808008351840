/* eslint-disable no-control-regex */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
// App Routes
export const appRoute = {
  APP: '/',
  LANDING: '/onboarding',
  HOME: '/home',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot',
  RESET_PASSWORD: '/password_reset',
  AUTH_CALLBACK: '/callback',
  NOT_FOUND: '/404',
  ALL: '*',

  MY_ACCOUNT: '/my-account',
  MY_ACCOUNT_PERSONAL_INFO: '/my-account/personal',
  MY_ACCOUNT_PASSWORD_RESET: '/my-account/password-reset',
  MY_ACCOUNT_NOTIFICATION_SETTINGS: '/my-account/notification',
  MY_ACCOUNT_LOGOUT: '/my-account/logout',
  MY_ACCOUNT_INFO: '/my-account/info',

  MY_VOUCHER: '/my-voucher',
  MY_VOUCHER_DETAILS: (voucherId) =>
    voucherId ? `/my-voucher/details/${voucherId}` : '/my-voucher/details/:voucherId',

  PARTNER: '/partner',
  PARTNER_DETAIL: (partnerId) =>
    partnerId ? `/partner/details/${partnerId}` : '/partner/details/:partnerId',

  TEST: '/test-auth',

  STATIC_PAGE: (pageRoute) => (pageRoute ? `/${pageRoute}` : '/:pageRoute'),
  VERIFY_EMAIL: '/email_confirm',
  FAQ: '/faq',

  INFO: '/info',

  // Static Pages
  DATA_PROTECTION: '/data-protection',
  MAINTENANCE: '/maintenance',
};

export const appStaticContentRoutes = {
  VOUCEHR_INFO: '/voucher-info',
};

export const appHistoryStates = {
  PUSH: 'PUSH',
  REPLACE: 'REPLACE',
  POP: 'POP',
};

export const appIcons = {
  BAN: 'Ban',
  BURGERMENU: 'BurgerMenu',
  CHECKMARK: 'CheckMark',
  CIRCLE: 'Circle',
  CIRCLE_EXCLAMATION: 'CircleExclamation',
  CIRCLE_TICK: 'CircleTick',
  CIRCLE_X: 'CircleX',
  X: 'X',
  XRed: 'XRed',
  EYE: 'Eye',
  EYE_OFF: 'EyeOff',
  HOME: 'Home',
  HOME_ACTIVE: 'HomeActive',
  MY_ACCOUNT: 'MyAccount',
  MY_ACCOUNT_ACTIVE: 'MyAccountActive',
  PARTNER: 'Partner',
  PARTNER_ACTIVE: 'PartnerActive',
  VOUCHER: 'Voucher',
  VOUCHER_ACTIVE: 'VoucherActive',
  NO_VOUCHER_AVAILABLE: 'NoVoucherAvailable',
  RIGHT_ARROW: 'RightArrow',

  FILTER: 'Filter',
  APPLY_FILTER: 'Apply-Filter',
  SORT: 'Sort',
  SORT_DEC: 'Sort-Dec',
  SORT_ACTIVE: 'Sort-Active',
  SORT_DEC_ACTIVE: 'Sort-Dec-Active',
  SEARCH: 'Search',
  SELECTED: 'Selected',
  PLUS: 'Plus',
  MINUS: 'Minus',

  BLOCK: 'Block',
  BLOCK_ACTIVE: 'Block-Active',
  LIST: 'List',
  LIST_ACTIVE: 'List-Active',
  MENU_LIST: 'Menu',
  MENU_LIST_ACTIVE: 'Menu-Active',
  CLOSE_X: 'Close-X',
  INFO: 'Info',
  INFO_DARK: 'Info-Dark',

  DATABASE: 'Database',

  APPLE: 'apple',
  PLAYSTORE: 'playstore',

  COMMENT_Q: 'comment-q',
  EDERN_RED: 'edern-red',

  BALANCER: 'Balancer',
};

export const appIconsPath = {
  HOME: '/assets/images/icons/home.svg',
  HOME_ACTIVE: '/assets/images/icons/home-active.svg',

  MY_ACCOUNT: '/assets/images/icons/myaccount.svg',
  MY_ACCOUNT_ACTIVE: '/assets/images/icons/myaccount-active.svg',

  PARTNER: '/assets/images/icons/partner.svg',
  PARTNER_ACTIVE: '/assets/images/icons/partner-active.svg',

  VOUCHER: '/assets/images/icons/voucher.svg',
  VOUCHER_ACTIVE: '/assets/images/icons/voucher-active.svg',
  NO_VOUCHER_AVAILABLE: '/assets/images/icons/novoucher.svg',

  FILTER: '/assets/images/icons/filter.svg',
  SEARCH: '/assets/images/icons/search.svg',
  SELECTED: '/assets/images/icons/checkblue.svg',

  PLUS: '/assets/images/icons/plus.svg',
  MINUS: '/assets/images/icons/minus.svg',

  BLOCK: '/assets/images/icons/block.svg',
  BLOCK_ACTIVE: '/assets/images/icons/block-active.svg',
  LIST: '/assets/images/icons/list.svg',
  LIST_ACTIVE: '/assets/images/icons/list-active.svg',
  MENU_LIST: '/assets/images/icons/menu-active.svg',
  MENU_LIST_ACTIVE: '/assets/images/icons/menu-list-active.svg',
  CLOSE_X: '/assets/images/icons/close.svg',
  APPLY_FILTER: '/assets/images/icons/apply-filter.png',
  SORT: '/assets/images/icons/sort.svg',
  SORT_ACTIVE: '/assets/images/icons/sort-active.svg',
  INFO: '/assets/images/icons/info.png',
  INFO_DARK: '/assets/images/icons/bubble_info@2x.png',

  COMMENT_Q: '/assets/images/icons/CommentQ.svg',
  EDERN_RED: '/assets/images/icons/EdernRed.svg',

  DATABASE: '/assets/images/icons/database.svg',

  BALANCER: '/assets/images/icons/balancer.svg',

  APPLE: '/assets/images/icons/apple.svg',
  PLAYSTORE: '/assets/images/icons/playstore.svg',
};

export const appHeaderNavConfig = [
  {
    title: 'Home',
    to: appRoute.HOME,
    active: false,
    icon: appIcons.HOME,
    iconActive: appIcons.HOME_ACTIVE,
  },
  {
    title: 'Gutscheine',
    to: appRoute.MY_VOUCHER,
    active: false,
    icon: appIcons.VOUCHER,
    iconActive: appIcons.VOUCHER_ACTIVE,
  },
  {
    title: 'Partner',
    to: appRoute.PARTNER,
    active: false,
    icon: appIcons.PARTNER,
    iconActive: appIcons.PARTNER_ACTIVE,
  },
  {
    title: 'Mein Konto',
    to: appRoute.MY_ACCOUNT,
    active: false,
    icon: appIcons.MY_ACCOUNT,
    iconActive: appIcons.MY_ACCOUNT_ACTIVE,
  },
];

export const appMobileHeaderNavConfig = [
  {
    title: 'Partner',
    to: appRoute.PARTNER,
    active: false,
    icon: appIcons.PARTNER,
    iconActive: appIcons.PARTNER_ACTIVE,
  },
  {
    title: 'Gutscheine',
    to: appRoute.MY_VOUCHER,
    active: false,
    icon: appIcons.VOUCHER,
    iconActive: appIcons.VOUCHER_ACTIVE,
  },
  {
    title: 'Home',
    to: appRoute.HOME,
    active: false,
    icon: appIcons.HOME,
    iconActive: appIcons.HOME_ACTIVE,
  },
  {
    title: 'Mein Konto',
    to: appRoute.MY_ACCOUNT_INFO,
    active: false,
    icon: appIcons.MY_ACCOUNT,
    iconActive: appIcons.MY_ACCOUNT_ACTIVE,
  },
  {
    title: 'Menu',
    to: appRoute.INFO,
    active: false,
    icon: appIcons.LIST,
    iconActive: appIcons.MENU_LIST_ACTIVE,
  },
];
// App Currency
export const appCurrency = '€';

// App Input Classes
export const inputViewSizes = {
  DEFAULT: 'input-ed', // 44px,
  FIXED: 'input-ed-fixed',
  SM: 'input-ed-sm', // 41px
};
export const inputValidation = {
  VALID: 'valid',
  INVALID: 'invalid',

  MIN_ERROR: 'min',
  MAX_ERROR: 'max',

  MIN_MAX_ERROR: 'minMax',

  UPPER_CASE_ERROR: 'uppercase',
  LOWER_CASE_ERROR: 'lowecase',

  NUMBER_ERROR: 'number',
  SPECIAL_CASE_ERROR: 'specific',
};

// App Button Types
export const buttonLooks = {
  ROUNDED: 'rounded',
  PILL: 'pill',
  CIRCLE: 'circle',
};

export const buttonTypes = {
  DEFAULT: 'default',
  OUTLINE: 'outline',
  FAB: 'fab',
};
// App Switch Size
export const switchToggleSize = {
  DEFAULT: 'switch-s',
  SM: 'switch-s',
  MD: 'switch-m',
  LG: 'switch-l',
};

// App Radius Classes
export const borderRadius = {
  // Apply radius
  DEFAULT: 'border-ed-radius',
  LARGE: 'border-ed-radius-lg',

  PILL: 'rounded-full',

  // Remove radius from specific posotion
  REMOVE_BOTTOM: 'border-ed-bottom-none',
};

// App Background Classes
export const backgroundColor = {
  WHITE: 'bg-ed-white',
  LIGHT_COBALD: 'bg-ed-lt-cobald',
};

// App Animation Styles Classes
export const animationStyle = {
  ANIMATED: 'animate-animated',

  FADE_IN: 'animate-fadeIn',
  FADE_IN_DOWN: 'animate-fadeInDown',
  FADE_IN_DOWN_BIG: 'animate-fadeInDownBig',

  FADE_IN_LEFT: 'animate-fadeInLeft',
  FADE_IN_LEFT_BIG: 'animate-fadeInLeftBig',

  FADE_IN_RIGHT: 'animate-fadeInRight',
  FADE_IN_RIGHT_BIG: 'animate-fadeInRightBig',

  FADE_IN_UP: 'animate-fadeInUp',
  FADE_IN_UP_BIG: 'animate-fadeInUpBig',

  FADE_IN_TOP_LEFT: 'animate-fadeInTopLeft',
  FADE_IN_TOP_RIGHT: 'animate-fadeInTopRight',

  FADE_IN_BOTTOM_LEFT: 'animate-fadeInBottomLeft',
  FADE_IN_BOTTOM_RIGHT: 'animate-fadeInBottomRight',

  FADE_OUT: 'animate-fadeOut',
  FADE_OUT_DOWN: 'animate-fadeOutDown',
  FADE_OUT_DOWN_BIG: 'animate-fadeOutDownBig',

  FADE_OUT_LEFT: 'animate-fadeOutLeft',
  FADE_OUT_LEFT_BIG: 'animate-fadeOutLeftBig',

  FADE_OUT_RIGHT: 'animate-fadeOutRight',
  FADE_OUT_RIGHT_BIG: 'animate-fadeOutRightBig',

  FADE_OUT_UP: 'animate-fadeOutUp',
  FADE_OUT_UP_BIG: 'animate-fadeOutUpBig',

  FADE_OUT_TOP_LEFT: 'animate-fadeOutTopLeft',
  FADE_OUT_TOP_RIGHT: 'animate-fadeOutTopRight',

  FADE_OUT_BOTTOM_LEFADE_IN_BOTTOM_LEFT: 'animate-fadeOutBottomLeft',
  FADE_OUT_BOTTOM_RIGHT: 'animate-fadeOutBottomRight',

  SLIDE_IN_DOWN: 'animate-slideInDown',
};

export const animationDelay = {
  DELAY_1S: 'animate-delay-1s',
};

// App Constant
export const appDefaultImages = {
  ONBOARD_PAGE: '/assets/images/onboard/onboard-main-view.png',
  ONBOARD_PAGE_2X: '/assets/images/onboard/image2.png',

  HOME_PAGE: '/assets/images/home/TPOnline_Kachel1.png',
  HOME_PAGE_2X: '/assets/images/home/TPOnline_Kachel1@2x.png',

  ABOUT_YOU: '/assets/images/home/about_you.svg',

  FOOTER_LOGO: '/assets/images/footer/logo-gray.svg',
  MAINTENANCE_LOGO: '/assets/images/maintenance/maintenance.jpg',
};

// Landing page views
export const defaultLandingViews = Object.freeze({
  ONBOARD_START: 1,
  SIGN_IN: 2,
  ONBOARD_WELCOME: 3,
});

// Forgot Password page views
export const defaultForgotPasswordViews = Object.freeze({
  FORGOT_PASSWORD: 1,
  FORGOT_PASSWORD_WELCOME: 2,
});

// Reset Password page views
export const defaultResetPasswordViews = Object.freeze({
  RESET_PASSWORD: 1,
  RESET_PASSWORD_WELCOME: 2,
  RESET_PASSWORD_FAILED: 3,
});

// Feedback Password page views
export const feedbackContentType = Object.freeze({
  ONBOARD_WELCOME: 1,
  FORGOT_PASSWORD_WELCOME: 2,
  RESET_PASSWORD_WELCOME: 3,
  RESET_PASSWORD_FAILED: 4,
});

// My Account page views
export const defaultMyAccountView = {
  PERSONAL_INFO: appRoute.MY_ACCOUNT_PERSONAL_INFO,
  PASSWORD_RESET: appRoute.MY_ACCOUNT_PASSWORD_RESET,
  NOTIFICATION_SETTINGS: appRoute.MY_ACCOUNT_NOTIFICATION_SETTINGS,
  LOGOUT: appRoute.MY_ACCOUNT_LOGOUT,
};

export const defaultMyAccountNavList = [
  { title: 'personal_data', view: 'PERSONAL_INFO', to: appRoute.MY_ACCOUNT_PERSONAL_INFO },
  { title: 'change_password', view: 'PASSWORD_RESET', to: appRoute.MY_ACCOUNT_PASSWORD_RESET },
  {
    title: 'my_notfications',
    view: 'NOTIFICATION_SETTINGS',
    to: appRoute.MY_ACCOUNT_NOTIFICATION_SETTINGS,
  },
  { title: 'logout', view: 'LOGOUT', to: appRoute.MY_ACCOUNT_LOGOUT },
];

// App Sort Type
export const appSortTypes = Object.freeze({
  NONE: 0,
  ASC: 1,
  DESC: 2,
});

// App List View Type
export const appListViewTypes = Object.freeze({
  BLOCK_VIEW: 1,
  LIST_VIEW: 2,
});

// Filteration
export const defaultPartnerFilter = {
  isOpen: false,
  view: appListViewTypes.BLOCK_VIEW,
  categories: [],
  searchText: '',
  sort: appSortTypes.NONE,
};

export const defaultVoucherFilterStatus = Object.freeze({
  NONE: false,
  APPLIED: true,
  USED_VOUCHER: 1,
  UN_USER_VOUCHER: 2,
});

export const defaultVoucherFilter = {
  isOpen: false,
  view: appListViewTypes.BLOCK_VIEW,
  // status: defaultVoucherFilterStatus.NONE,
  status: {
    usedVoucher: defaultVoucherFilterStatus.NONE,
    unUsedVoucher: defaultVoucherFilterStatus.NONE,
  },
  searchText: '',
};

export const voucherStatus = {
  COMPLETE: 'Complete',
  INDELIVERY: 'InDelivery',
};

export const voucherType = {
  CODE: 'Ecode',
  CODE_WITH_SECRET: 'EcodeSecret',
  PDF: 'Pdf',
};

export const defaultFilterationTypes = Object.freeze({
  PARTNER_FILTER: 1,
});

// App Regex
export const appRegex = {
  validateEmail: new RegExp(
    /^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/,
  ),
  validateString: /^[A-Za-z]+$/,
  // Password
  validateUpperCase: /[A-Z]/,
  validateLowerCase: /[a-z]/,
  validateNumbers: /\d/,
  validateSpecial: /[!@#$%&()]/,
};

// App modal
export const appModal = {
  DEFAUT_TIMEOUT: false,
};

// App alert types
export const appAlert = {
  DEFAUT_TIMEOUT: 4000,
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
};

// App alert types
export const appDefaultAlertMessage = {
  SUCCESS: 'success',
  ERROR: 'error',
  ERROR_NOT_FOUND: 'Not able to found your last request',
  ERROR_UNAUTH: 'Please login to continue!',
  INFO: 'info',
  WARNING: 'warning',
};

// Drupal response validator
export const appResponseStatus = {
  SUCCESS: {
    KEY: 'settings.success',
    VALUE: '1',
  },
  MESSAGE: {
    KEY: 'settings.message',
  },
  ERROR: {
    KEY: 'settings.success',
    VALUE: '0',
  },
};

export const appDefaultErrorCode = {
  CODE: 406, // NOT ACCEPTED
  NOT_FOUND: 404, // NOT ACCEPTED
};

// App alert types
export const appToast = {
  DEFAUT_TIMEOUT: 6000,
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
};

// App Storage keys
export const appStorage = {
  // Authentication
  AUTH_CODE: 'code',
  AUTH_CODE_VERIFIER: 'code-verifirer',
  AUTH_SESSION_STATE: 'session-state',
  AUTH_TOKEN: 'access-token',
  AUTH_REFRESH_TOKEN: 'refresh-token',
  FIRST_LOGIN: 'first-login',
  EMAIL: 'email',

  // Language
  i18n: 'i18n',
  DEFAULT_LANGUAGE: 'de',
};

// App Modal
export const appModalBackground = {
  DEFAULT: 'modal',
  DARK: 'modal-dark',
};

// App Static content type

export const appStaticContent = {
  HTML: 'html',
  LINK: 'link',
  TEXT: 'text',
  FOOTER_LINK: 'footer_link',
};

export const appLogs = {
  LAST_LOGIN: 'last_login_log',
  EMAIL_CHANGE: 'email_change_log',
  CHANGE_PASSWORD: 'change_password_log',
  FORGOT_PASSWORD: 'forgot_password',
  RESET_PASSWORD: 'reset_password',
  EMAIL_CONFIRM: 'reset_email_confirmation',
};

export const appAudit = {
  LOGIN: 'Login - Source: Web',
  PARTENR_CHANGE: 'Partner change',
  FORGOT_PASSWORD: 'Reset password request',
  RESET_PASSWORD_CHANGE: 'Reset Passowrd Request',
  RESET_PASSWORD: 'Reset password completed',
  RESET_EMAIL_CONFIRMATION: 'Change email confirmation',
  EMAIL_CHANGE: 'Change Email Request',
  ONBOARDING_CONFIRMATION: 'Onboarding Link confirmed',
  REGISTRATION_COMPLETED: 'Registration completed',
};

export const dashboardSliderBtnType = {
  PARTENR: 'partner',
  LINK: 'link',
};

export const appDynamicScripts = {
  DATA_PROTECTION_COOKIE:
    'https://consent.cookiebot.com/d9508ba7-0072-4c96-b7df-09345f4ebee6/cd.js',
};
