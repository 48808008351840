/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import Button from '@core/Button';
import { animationStyle, appIcons, buttonLooks, buttonTypes } from '@utils/Constant';
import AnimateLoad from '@part/AnimateLoad';
import PropTypes from 'prop-types';

import './style.scss';
import { sanitizedDOM } from '@utils/Validation';

function Accordion({ data }) {
  const [accordionData, setAccordionData] = useState(data);

  const openAccordion = (index) => {
    const updateData = accordionData.map((acc, i) => ({
      ...acc,
      active: i === index && !acc.active,
    }));
    setAccordionData(updateData);
  };

  return (
    <section className="accordion">
      {accordionData.map((acc, i) => (
        <article className="border-dotted border-b-4" key={`acc_list_${acc.title}`}>
          <div className="">
            <header
              className="flex justify-between items-center py-1 cursor-pointer select-none"
              onClick={() => openAccordion(i)}
            >
              <span className="text-ed-gray text-ed-bold text-18">{acc.title}</span>
              <div className="flex items-center justify-center pl-2">
                <Button
                  type={buttonTypes.FAB}
                  className="accordion-btn flex text-ed-gray flex-center"
                  icon
                  look={buttonLooks.CIRCLE}
                  iconComponent={acc.active ? appIcons.MINUS : appIcons.PLUS}
                />
              </div>
            </header>
            {acc.active && (
              <AnimateLoad style={animationStyle.FADE_IN}>
                <div className="py-1">
                  <div
                    className="text-14 text-ed-regular text-ed-gray"
                    dangerouslySetInnerHTML={{ __html: sanitizedDOM(acc.body) }}
                  />
                </div>
              </AnimateLoad>
            )}
          </div>
        </article>
      ))}
    </section>
  );
}

Accordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

export default Accordion;
