/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
// import Card from '@core/Card';
import Image from '@core/Image';
import Button from '@core/Button';
import { appIcons } from '@utils/Constant';
import PropTypes from 'prop-types';
// import { usePartner } from '@context/Partner';

function SelectedPartnerCard({ name, categoryName, logo, isSelected, onClick, backgroundClass }) {
  // name, logo
  // const { selectedPartner } = usePartner();

  return (
    <div {...{ onClick }}>
      <div className={backgroundClass} style={{ position: 'relative', zIndex: 0 }}>
        <div className="relative box-ed-shadow cursor-pointer h-fit-content card border-ed-radius bg-ed-white">
          {isSelected && (
            <Button
              className="rounded-full h-16 w-16 flex items-center justify-center absolute right-0 -top-6"
              type="fab"
              icon
              iconComponent={appIcons.SELECTED}
              pathFill="#fff"
              svgProps={{ width: 53, height: 53 }}
            />
          )}

          <div className="selected-partner-card w-50 px-2 py-4 flex-col-center">
            {logo ? (
              <Image
                {...{
                  className: 'mx-auto py-5',
                  src: logo,
                  alt: name,
                  // style: imgStyle,
                }}
              />
            ) : (
              <></>
            )}
            <div className="text-center">
              <h1 className="text-18 text-ed-bold text-ed-gray">{name}</h1>
              <p className="text-18 text-ed-light text-ed-gray">{categoryName}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SelectedPartnerCard.propTypes = {
  imgStyle: PropTypes.shape({}),
  name: PropTypes.string,
  categoryName: PropTypes.string,
  backgroundClass: PropTypes.string,
  logo: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

SelectedPartnerCard.defaultProps = {
  imgStyle: {},
  name: '',
  categoryName: '',
  backgroundClass: '',
  logo: '',
  isSelected: false,
  onClick: () => {},
};
export default SelectedPartnerCard;
