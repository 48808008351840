import React from 'react';
import PropTypes from 'prop-types';
import { getIcon } from '@utils/SVG';
import { withAnimate } from '@part/AnimateLoad';
// import { animationStyle } from '@utils/Constant';
import './style.scss';
import { inputViewSizes } from '@utils/Constant';

function Input({
  icon,
  iconComponent,

  disabled,
  max,
  maxLength,
  min,
  minLength,
  name,
  // pattern,
  placeholder,
  readOnly,
  required,
  viewSize,
  type,
  value,
  onChange,
  onBlur,
  isInvalid,
  onIconClick,
  validation = 'border border-red-500',
  error,
}) {
  // const [isFoucs, setIsFoucs] = useState(false);

  const checkIcon = () => {
    const displayIcon = icon && getIcon({ iconComponent });

    // if (icon && iconComponent === 'Eye') displayIcon = <SVG.Eye isOpen />;
    // if (icon && iconComponent === 'EyeOff') displayIcon = <SVG.Eye isOpen={false} />;

    // const className = isFoucs ? `${animationStyle.ANIMATED} ${animationStyle.FADE_OUT} hidden` : "z-10 h-full leading-snug font-normal text-center text-ed-lt-gray absolute bg-transparent text-base flex-center right-0 pr-3"
    const className =
      'z-10 h-full leading-snug font-normal text-center text-ed-lt-gray text-ed-light absolute bg-transparent text-base flex-center right-0 pr-3 focus:outline-none';

    return (
      <button type="button" className={className} onClick={onIconClick}>
        {displayIcon}
      </button>
    );
  };

  let inputClass = `${viewSize} relative text-ed-gray text-18 text-ed-light shadow-none outline-none hover:border-ed-dark w-full`;
  // if (validation) inputClass += ` ${validation}`;
  if (isInvalid) inputClass += `${validation}`;

  return (
    <>
      <div className="relative flex w-full bg-transprent shadow-none outline-none flex-wrap items-stretch mb-3">
        <input
          // className={inputClass}
          className={`${inputClass} ${isInvalid ? "invalid" : ''}`}
          autoComplete="nope"
          {...{
            disabled,
            max,
            maxLength,
            min,
            minLength,
            name,
            // pattern,
            placeholder,
            readOnly,
            required,
            type,
            value,
            onChange,
            onBlur,

            // onFocus,
          }}
        />
        {checkIcon()}
      </div>
      {isInvalid && error && <div className="px-2 text-red-500 text-xs italic">{error}</div>}
    </>
  );
}

Input.propTypes = {
  // Config Props
  icon: PropTypes.bool,
  iconComponent: PropTypes.string,
  // Input Props
  disabled: PropTypes.bool,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  minLength: PropTypes.number,
  name: PropTypes.string,
  // pattern: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  viewSize: PropTypes.string,
  value: PropTypes.string,
  validation: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onIconClick: PropTypes.func,
  // Form validation props
  isInvalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  error: PropTypes.string,
};

Input.defaultProps = {
  icon: false,
  iconComponent: '',

  disabled: false,
  max: null,
  maxLength: null,
  min: null,
  minLength: null,
  name: '',
  // pattern: '',
  placeholder: '',
  readOnly: false,
  required: false,
  type: 'text',
  viewSize: inputViewSizes.DEFAULT,
  value: '',
  validation: '',
  onChange: () => {},
  onBlur: () => {},
  isInvalid: false,
  onIconClick: () => {},
  error: '',
};

export default withAnimate(Input);