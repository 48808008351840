// App authenctication page.
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoading } from '@context/Loading';
import Storage from '@utils/Storage';
import { appRoute, appStorage } from '@utils/Constant';
import { useAuth } from '@context/AuthProvider';
import { appUrlQueryParams } from '@utils/Url';
import { withRouter } from '@utils/Router/withRouter';

function CallBackPage() {
  const location = useLocation();

  const { showLoader } = useLoading();
  const { onCallbackLogin } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    showLoader();
    if (location.search) {
      const args = new URLSearchParams(location.search);
      const code = args.get(appUrlQueryParams.AUTH_CALLBACK.CODE);
      const sessionState = args.get(appUrlQueryParams.AUTH_CALLBACK.SESSION_STATE);

      const codeVerifier = Storage.get(appStorage.AUTH_CODE_VERIFIER);

      // Check if the url has code query param
      if (code) {
        // Store code on local storage
        Storage.set(appStorage.AUTH_CODE, code);
        Storage.set(appStorage.AUTH_SESSION_STATE, sessionState);

        // Getting user auth token and process login
        onCallbackLogin(code, codeVerifier)
          .then((goTo) => {
            // Clear up url and take me home.
            if (args.has(appUrlQueryParams.AUTH_CALLBACK.CODE)) {
              args.delete(appUrlQueryParams.AUTH_CALLBACK.CODE);
              args.delete(appUrlQueryParams.AUTH_CALLBACK.SESSION_STATE);
              navigate(goTo);
            }
          })
          .catch(() => {
            //
            args.delete(appUrlQueryParams.AUTH_CALLBACK.CODE);
            args.delete(appUrlQueryParams.AUTH_CALLBACK.SESSION_STATE);
            navigate(appRoute.LOGIN);
          });
      }
    }
  }, []);

  return (
    <div className="bg-ed-bubbles flex-above-center">
      <div />
    </div>
  );
}

export default withRouter(CallBackPage);
