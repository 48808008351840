import { createContext, useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { inputValidation } from '@utils/Constant';
import { validateEmail, validatePassword } from '@utils/Validation';
import { useAuth } from '@context/AuthProvider';

// A context to load all app configuration from server
const LoginInContext = createContext();

// The top level component that will wrap our app's core features
export const LoginInProvider = ({ children }) => {
  // Context
  const { onLoginClick } = useAuth();

  // State
  // Sign in form state.
  const defaultForm = {
    email: '',
    validEmail: false,

    password: '',
    viewPassword: false,
    validPassword: false,

    errorMessage: '',
    submit: false,
  };
  const [logInForm, setLogInForm] = useState(defaultForm);

  // Effect
  useEffect(() => {
    // Reset form on load
    setLogInForm(defaultForm);
  }, []);

  const onChange = (e) => {
    if (e.target.name === 'email') {
      // validateEmail(logInForm.email)
      setLogInForm({
        ...logInForm,
        [e.target.name]: e.target.value,
        validEmail: validateEmail(e.target.value),
        errorMessage: '',
      });
    } else if (e.target.name === 'password') {
      setLogInForm({
        ...logInForm,
        [e.target.name]: e.target.value,
        validPassword: validatePassword(e.target.value, e.target.name, logInForm.password),
      });
    } else {
      setLogInForm({
        ...logInForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onSubmitClick = () => {
    setLogInForm({ ...logInForm, submit: true });
    if (
      logInForm.validEmail === inputValidation.VALID &&
      logInForm.validPassword.valid === inputValidation.VALID &&
      logInForm.validConfirmPassword.valid === inputValidation.VALID
    ) {
      setLogInForm({
        ...logInForm,
        submit: true,
        errorMessage: '',
      });
      // setLandingView(defaultLandingViews.ONBOARD_WELCOME);
    } else {
      setLogInForm({
        ...logInForm,
        submit: true,
        errorMessage: 'Please check your input',
        validEmail: validateEmail(logInForm.email),
        validPassword: validatePassword(logInForm.password, 'password', logInForm.password),
      });
    }
  };

  // Render the children as normal
  // TODO: Implemente loading if required.
  const renderContent = () => children;

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(
    () => ({
      logInForm,
      setLogInForm,

      onChange,
      onSubmitClick,
      onLoginClick,
    }),
    [logInForm, setLogInForm, onChange, onSubmitClick, onLoginClick],
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <LoginInContext.Provider value={contextPayload}>{renderContent()}</LoginInContext.Provider>
  );
};

LoginInProvider.propTypes = {
  children: PropTypes.node,
};

LoginInProvider.defaultProps = {
  children: null,
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useLogin = () => useContext(LoginInContext);
