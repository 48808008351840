import { createContext, useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { decodeBase64, validatePassword } from '@utils/Validation';
// import { useAuth } from '@context/AuthProvider';
import { useAlert } from '@context/Alert';
import { useLoading } from '@context/Loading';
import OnboardService from '@service/Onboard';
import { useAppConfig } from '@context/AppConfig';
import { responseMessage, StatusCodes, validateResponse } from '@utils/ApiCodex'; // , StatusCodes, validateResponse
import {
  appAlert,
  appAudit,
  appStorage,
  // appDefaultErrorCode,
  // appDefaultErrorCode,
  defaultResetPasswordViews,
  inputValidation,
} from '@utils/Constant';
import AuthService from '@service/Auth';
import Storage from '@utils/Storage';
// import { useErrorHandler } from '@context/ErrorHandler';

// A context to load all app configuration from server
const ResetPasswordContext = createContext();

// The top level component that will wrap our app's core features
export const ResetPasswordProvider = ({ children }) => {
  // Context
  // const { onResetPasswordClick } = useAuth();

  // State
  const defaultForm = {
    email: '',
    password: '',
    viewPassword: false,
    validPassword: false,

    confirmPassword: '',
    viewConfirmPassword: false,
    validConfirmPassword: false,
    errorMessage: '',
    submit: false,
  };
  const [resetPasswordForm, setResetPasswordForm] = useState(defaultForm);
  // User UID Details
  const [resetPasswordUserDetail, setResetPasswordUserDetail] = useState({});

  const [resetView, setResetView] = useState(defaultResetPasswordViews.RESET_PASSWORD);

  const { env } = useAppConfig();
  const { showAlert } = useAlert();
  const { showLoader, hideLoader } = useLoading();
  // const { setErrorStatusCode } = useErrorHandler();

  // Effect
  useEffect(() => {
    // Reset form on load
    // setResetPasswordForm(defaultForm);
  }, []);

  const onChange = (e) => {
    if (e.target.name === 'password' || e.target.name === 'confirmPassword') {
      setResetPasswordForm({
        ...resetPasswordForm,
        [e.target.name]: e.target.value,
        [e.target.name === 'password' ? 'validPassword' : 'validConfirmPassword']: validatePassword(
          e.target.value,
          e.target.name,
          resetPasswordForm.password,
        ),
        ...(resetPasswordForm.confirmPassword && e.target.name === 'password'
          ? {
              validConfirmPassword: validatePassword(
                resetPasswordForm.confirmPassword,
                'confirmPassword',
                e.target.value,
              ),
            }
          : {}),
      });
    } else {
      setResetPasswordForm({
        ...resetPasswordForm,
        [e.target.name]: e.target.value,
        errorMessage: '',
      });
    }
  };

  const onSubmitClick = (assoc) => {
    setResetPasswordForm({ ...resetPasswordForm, submit: true });
    showLoader();
    if (
      resetPasswordForm.validPassword.valid === inputValidation.VALID &&
      resetPasswordForm.validConfirmPassword.valid === inputValidation.VALID
    ) {
      const onboardService = new OnboardService(env);
      onboardService.saveUserAudit({
        user_email: Storage.get(appStorage.email),
        type: appAudit.RESET_PASSWORD_CHANGE,
      });
      setResetPasswordForm({
        ...resetPasswordForm,
        submit: true,
        errorMessage: '',
      });
      const resetPasswordData = {
        // user_id: resetPasswordUserDetail.userId,
        password: resetPasswordForm.password,
      };

      const authService = new AuthService(env);
      authService
        .processResetPassword(resetPasswordData, assoc)
        .then((result) => {
          hideLoader();
          onboardService.saveUserAudit({
            user_email: resetPasswordForm.email,
            type: appAudit.RESET_PASSWORD,
          });
          // setResetView(defaultResetPasswordViews.RESET_PASSWORD_WELCOME);
          // showAlert({ type: appAlert.SUCCESS, message: responseMessage(result) || 'Success!' });
          if (
            (result.status === StatusCodes.OK && validateResponse(result)) ||
            result?.status === StatusCodes.NO_CONTENT
          ) {
            setResetView(defaultResetPasswordViews.RESET_PASSWORD_WELCOME);
            showAlert({ type: appAlert.SUCCESS, message: responseMessage(result) || 'Success!' });
          } else {
            setResetView(defaultResetPasswordViews.RESET_PASSWORD_FAILED);
            showAlert({
              type: appAlert.ERROR,
              message: responseMessage(result) || 'Something went wrong!',
            });
          }
        })
        .catch((err) => {
          console.log(err.status || err.response.status);
          hideLoader();
          // setErrorStatusCode(err.status || err.response.status || appDefaultErrorCode.CODE);
          setResetView(defaultResetPasswordViews.RESET_PASSWORD_FAILED);
          // throw new Error(err);
        });
    } else {
      setResetPasswordForm({
        ...resetPasswordForm,
        submit: true,
        errorMessage: 'Please check your input',
        validPassword: validatePassword(
          resetPasswordForm.password,
          'password',
          resetPasswordForm.password,
        ),
        validConfirmPassword: validatePassword(
          resetPasswordForm.confirmPassword,
          'confirmPassword',
          resetPasswordForm.password,
        ),
      });
      hideLoader();
    }
  };

  // // On landing with url from email
  const onLoadUser = (email) => {
    const decodeEmail = decodeBase64(email);
    setResetPasswordForm({ ...resetPasswordForm, email: decodeEmail });
    // const onboardService = new OnboardService(env);

    // showLoader();
    // onboardService
    //   .getUserUid(url)
    //   .then((result) => {
    //     if (result.status === StatusCodes.OK) {
    //       setResetPasswordUserDetail(keysToCamel(result.data));
    //     }
    //     hideLoader();
    //   })
    //   .catch((err) => {
    //     throw new Error(err);
    //   });
  };

  // Render the children as normal
  // TODO: Implemente loading if required.
  const renderContent = () => children;

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(
    () => ({
      resetPasswordForm,
      setResetPasswordForm,

      resetPasswordUserDetail,
      setResetPasswordUserDetail,

      resetView,
      setResetView,

      onChange,

      onLoadUser,
      onSubmitClick,
    }),
    [
      resetPasswordForm,
      setResetPasswordForm,

      resetPasswordUserDetail,
      setResetPasswordUserDetail,

      resetView,
      setResetView,

      onChange,

      onLoadUser,
      onSubmitClick,
    ],
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <ResetPasswordContext.Provider value={contextPayload}>
      {renderContent()}
    </ResetPasswordContext.Provider>
  );
};

ResetPasswordProvider.propTypes = {
  children: PropTypes.node,
};

ResetPasswordProvider.defaultProps = {
  children: null,
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useResetPassword = () => useContext(ResetPasswordContext);
