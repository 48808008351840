import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import Input from '@core/Input';
// import Button from '@core/Button';
import { inputViewSizes } from '@utils/Constant';
import { withAnimate } from '@part/AnimateLoad';
import { useMyAccount } from '@context/MyAccount';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';

function PersonalData() {
  const {
    personalInfoForm,
    personalFormValidationSchema,
    checkNav,
    onChangePersonal,
    onSubmitPersnonalInfo,
  } = useMyAccount();
  const { translate } = useLanguage();

  useEffect(() => {
    checkNav();
  }, []);

  return (
    <Formik
      initialValues={personalInfoForm}
      validationSchema={personalFormValidationSchema}
      enableReinitialize
      onSubmit={onSubmitPersnonalInfo}
    >
      {({
        values: { salutation, firstname, lastname, employeeEmail },
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        errors,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="personal-data">
            <div className="grid grid-cols-12">
              <div className="col-span-12 md:col-span-8">
                <div className="grid grid-cols-12">
                  <div className="col-span-12 pb-7">
                    <div className="form-input w-5/12 sm:w-3/12">
                      <span className="text-18 text-ed-gray text-ed-regular mb-2">
                        {translate(LANGUAGE.salutation)}
                      </span>
                      {/* <Input
                        validation="my-2"
                        viewSize={inputViewSizes.SM}
                        name="salutation"
                        value={personalInfoForm.salutation}
                        onChange={onChangePersonal}
                        placeholder={translate(LANGUAGE.mr)}
                      /> */}
                      <select
                        className="relative bg-ed-white text-ed-gray shadow-none outline-none hover:border-ed-dark w-full focus:w-11/12 my-2"
                        name="salutation"
                        // value={personalInfoForm.salutation}
                        value={salutation}
                        onChange={onChangePersonal}
                      >
                        <option className="pl-1" value={translate(LANGUAGE.mr)}>
                          {translate(LANGUAGE.mr)}
                        </option>
                        <option className="pl-1" value={translate(LANGUAGE.mrs)}>
                          {translate(LANGUAGE.mrs)}
                        </option>
                        <option className="pl-1" value={translate(LANGUAGE.mrMrs)}>
                          {translate(LANGUAGE.mrMrs)}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6 pb-7">
                    <div className="form-input">
                      <span className="text-18 text-ed-gray text-ed-regular mb-2">
                        {translate(LANGUAGE.firstname)}
                      </span>
                      <Input
                        type="text"
                        viewSize={inputViewSizes.SM}
                        name="firstname"
                        value={firstname}
                        placeholder={translate(LANGUAGE.firstname)}
                        onChange={(e) => handleChange(e)}
                        onBlur={handleBlur}
                        isInvalid={touched.firstname && errors.firstname}
                        error={errors.firstname ? errors.firstname : ''}
                      />
                    </div>
                  </div>
                  <div className="col-span-6 pb-7 pl-2 md:pl-6">
                    <div className="form-input">
                      <span className="text-18 text-ed-gray text-ed-regular mb-2">
                        {translate(LANGUAGE.lastname)}
                      </span>
                      <Input
                        viewSize={inputViewSizes.SM}
                        name="lastname"
                        value={lastname}
                        placeholder={translate(LANGUAGE.lastname)}
                        onChange={(e) => handleChange(e)}
                        onBlur={handleBlur}
                        isInvalid={touched.lastname && errors.lastname}
                        error={errors.lastname ? errors.lastname : ''}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 pb-7">
                    <div className="form-input">
                      <span className="text-18 text-ed-gray text-ed-regular mb-2">
                        {translate(LANGUAGE.email)}
                      </span>
                      <Input
                        viewSize={inputViewSizes.SM}
                        name="employeeEmail"
                        value={employeeEmail}
                        placeholder=""
                        type="text"
                        onChange={(e) => handleChange(e)}
                        onBlur={handleBlur}
                        isInvalid={touched.employeeEmail && errors.employeeEmail}
                        error={errors.employeeEmail ? errors.employeeEmail : ''}
                      />
                    </div>
                    <div className="form-hint">
                      <span className="text-14 text-ed-gray">
                        {translate(LANGUAGE.personalDataMsg)}
                      </span>
                    </div>
                  </div>
                  <div className="col-span-12 pb-7 flex-center-end my-4">
                    {/* <Button
                      className="relative bg-ed-red text-ed-regular text-white text-18 px-16 py-3 flex-center "
                      label={translate(LANGUAGE.saveChanges)}
                      look={buttonLooks.PILL}
                      // onClick={onSubmitPersnonalInfo}
                      type="submit"
                    /> */}
                    <button
                      className="relative bg-ed-red text-ed-regular text-white text-18 px-16 py-3 flex-center rounded-full"
                      type="submit"
                    >
                      {translate(LANGUAGE.saveChanges)}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default withAnimate(PersonalData);
