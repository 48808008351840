import React from 'react';
import Card from '@core/Card';
import Image from '@core/Image';
import { borderRadius } from '@utils/Constant';
import PropTypes from 'prop-types';

import './style.scss';

function PartnerListViewCard({ name, categoryName, logo, imgStyle }) {
  return (
    <Card bdRadius={borderRadius.PILL} bgShadow>
      <div className="partner-list-card flex items-center justify-between">
        <div className="text-left">
          <h1 className="text-14 text-ed-gray text-ed-bold">{name}</h1>
          <p className="text-14 text-ed-gray text-ed-light">{categoryName}</p>
        </div>
        <div className="">
          {logo && (
            <Image
              {...{
                className: 'object-cover relative h-6',
                src: logo,
                alt: 'Test',
                style: imgStyle,
              }}
            />
          )}
        </div>
      </div>
    </Card>
  );
}

PartnerListViewCard.propTypes = {
  imgStyle: PropTypes.shape({}),
  name: PropTypes.string,
  categoryName: PropTypes.string,
  logo: PropTypes.string,
};

PartnerListViewCard.defaultProps = {
  imgStyle: {},
  name: '',
  categoryName: '',
  logo: '',
};
export default PartnerListViewCard;
