/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Voucher from '@core/Voucher';
import './style.scss';
import { voucherStatus, voucherType, switchToggleSize } from '@utils/Constant';
import Image from '@core/Image';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';
import { convertCurrency, getMonthShortName } from '@utils/Validation';
import { copyToClipboard } from '@utils/Url';
import Switch from '@core/Switch';
import { useLayout } from '@context/Layout';

function PartnerVoucherMain({
  voucher,
  isAvailableVoucher,
  isMainVoucher,
  onClick,
  showDate,
  onChange,
}) {
  const { isSM } = useLayout();
  const { translate } = useLanguage();

  const [isCodeCopied, setIsCodeCopied] = useState(false);

  const defaultHideCopy = 5000;

  const getVouchersDate = () => new Date(voucher.allocationDate).getDate() || '';
  const getVouchersMonth = () =>
    getMonthShortName(new Date(voucher.allocationDate).getMonth()) || '';

  const copyECode = (code) => {
    if (voucher && voucher?.type === voucherType.CODE) {
      copyToClipboard(code);
      setIsCodeCopied(true);
    }

    setTimeout(() => {
      window.open(voucher.affUrl, '_blank');
    }, 350);
  };

  const downloadAsPdf = () => {
    if (voucher && voucher.pdfContent && voucher.pdfContent.url) {
      const pop = window.open(voucher.pdfContent.url, '_blank');
      if (
        !pop ||
        pop.closed ||
        pop.closed === 'undefined' ||
        pop === 'undefined' ||
        parseInt(pop.innerWidth, 10) === 0 ||
        pop.document.documentElement.clientWidth !== 150 ||
        pop.document.documentElement.clientHeight !== 150
      ) {
        // console.log('Pop ups are blocked');
        // Create a new link to open up pdf
        const linkPdf = document.createElement('a');
        linkPdf.target = '_blank';
        linkPdf.href = voucher.pdfContent.url;
        document.body.appendChild(linkPdf);
        linkPdf.click();
        document.body.removeChild(linkPdf);
      }

      const link = document.createElement('a');
      link.href = voucher.affUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsCodeCopied(false);
    }, defaultHideCopy);
  }, [isCodeCopied]);

  if (!voucher) {
    return <></>;
  }

  return (
    <>
      <Voucher
        key={`partner-main-voucher-${voucher.voucherId}`}
        style={{ zIndex: voucher.status === voucherStatus.COMPLETE ? 0 : 1 }}
      >
        <div className="partner-main-voucher h-full cursor-pointer">
          <div className="border-dotted border-l-2 border-r-2 h-full border-gray-300 relative">
            <div className="voucher-content h-full w-full">
              <div className="voucher-header w-full flex">
                <div className="w-4/12 flex-start-center">
                  {showDate && (
                    <span className="text-14 text-ed-regular text-ed-gray">
                      <strong>{getVouchersDate()}</strong> {getVouchersMonth()}
                    </span>
                  )}
                </div>
                <div className="voucher-switch w-8/12 flex items-center justify-end">
                  <div className="mr-2">
                    <Switch
                      {...{
                        switchSize: isSM ? switchToggleSize.SM : switchToggleSize.LG,
                        onChange: () =>
                          onChange({ refId: voucher.refId, status: !voucher.redeemed }),
                        checked: voucher.redeemed,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`voucher-detail ${
                  isMainVoucher ? 'flex-col-start-center pt-2 -mt-4 ' : 'flex-col-center -mt-2'
                } h-full relative`}
                onClick={() => onClick(voucher.voucherId)}
              >
                {/* "voucher-detail flex-col-center h-full py-5 relative" */}
                <div className="pb-3 lg:pb-5">
                  <div className="text-center w-100">
                    {(isAvailableVoucher || voucher.status === voucherStatus.COMPLETE) &&
                    !isCodeCopied ? (
                      <p
                        className={`text-50 text-ed-gray text-ed-bold ${
                          isMainVoucher ? '-m-3 md:m-0' : ''
                        }`}
                      >
                        {convertCurrency(parseInt(parseInt(voucher?.value, 10) / 100, 10))}
                      </p>
                    ) : (
                      <></>
                    )}
                    {/* {
                    voucher.status === voucherStatus.COMPLETE && <p className="text-35 text-ed-gray text-ed-bold">{convertCurrency(voucher.value)}</p>
                  } */}
                    {voucher.status === voucherStatus.INDELIVERY && (
                      <p className="text-16 text-ed-red text-ed-bold">
                        {translate(LANGUAGE.voucherWillBeAvailSoon)}
                      </p>
                    )}
                    {isCodeCopied && (
                      <>
                        <p className="text-16 text-ed-blue text-ed-bold">
                          {translate(LANGUAGE.copiedSuccessful)}
                        </p>
                        <span className="text-14 text-ed-blue text-ed-regular">
                          {translate(LANGUAGE.codeWasSavedInClipboard)}
                        </span>
                      </>
                    )}
                    {isMainVoucher && voucher.status === voucherStatus.COMPLETE && !isCodeCopied ? (
                      <p className="text-14 text-ed-gray text-ed-regular uppercase w-full pt-2 sm:pt-1">
                        {voucher?.eCodeContent?.code}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="flex-center py-1">
                    <Image className="max-h-8" alt="Company Logo" src={voucher.logo} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Voucher>
      {isMainVoucher && (
        <div
          className={
            voucher.status === voucherStatus.INDELIVERY
              ? 'voucher-action flex-col-center w-full'
              : 'voucher-action flex-col-center w-full -mt-5 sm:-mt-8'
          }
          style={{ zIndex: voucher.status === voucherStatus.COMPLETE ? 2 : 2 }}
        >
          {voucher.type === voucherType.PDF ? (
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                downloadAsPdf();
              }}
              className={`${
                voucher.status === voucherStatus.COMPLETE
                  ? 'relative bg-ed-blue px-8 md:px-16 py-1 md:py-2 flex-col-center flex rounded-full'
                  : 'relative px-8 md:px-16 py-1 flex-col-center flex rounded-full border-ed rounded-pill'
              }`}
              rel="noreferrer"
              target="_blank"
              disabled={voucher.status === voucherStatus.INDELIVERY}
              style={{ zIndex: 2 }}
            >
              <span
                className={`text-ed-regular  ${
                  voucher.status === voucherStatus.COMPLETE ? 'text-ed-white ' : 'text-ed-lt-gray'
                } text-18`}
              >
                {translate(LANGUAGE.downloadAsPdf)}
              </span>
              <span
                className={`text-ed-light  ${
                  voucher.status === voucherStatus.COMPLETE ? 'text-ed-white ' : 'text-ed-lt-gray'
                } text-14`}
              >
                {translate(LANGUAGE.toThePartnerWebsite)}
              </span>
            </button>
          ) : (
            <button
              className={`${
                voucher.status === voucherStatus.COMPLETE
                  ? 'relative bg-ed-blue px-8 md:px-16 py-1 md:py-2 flex-col-center flex rounded-full'
                  : 'relative px-8 md:px-16 py-1 flex-col-center flex rounded-full border-ed rounded-pill'
              }`}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                copyECode(voucher?.eCodeContent?.code);
              }}
              style={{ zIndex: 2 }}
              disabled={isCodeCopied || voucher.status === voucherStatus.INDELIVERY}
            >
              <span
                className={`text-ed-regular  ${
                  voucher.status === voucherStatus.COMPLETE ? 'text-ed-white ' : 'text-ed-lt-gray'
                } text-18`}
              >
                {voucher?.eCodeContent?.code.includes('PIN')
                  ? translate(LANGUAGE.toThePartnerWebsite)
                  : translate(LANGUAGE.copyECode)}
              </span>
              <span
                className={`text-ed-light  ${
                  voucher.status === voucherStatus.COMPLETE ? 'text-ed-white ' : 'text-ed-lt-gray'
                } text-12`}
              >
                {voucher?.eCodeContent?.code.includes('PIN')
                  ? ''
                  : translate(LANGUAGE.toThePartnerWebsite)}
              </span>
            </button>
          )}
          <span className="text-14 text-ed-gray text-ed-regular w-72 text-center pt-2 px-5 md:px-0">
            {voucher.type === voucherType.CODE || voucher.type === voucherType.CODE_WITH_SECRET
              ? translate(LANGUAGE.byClickingWebsiteOpenBgText)
              : translate(LANGUAGE.byClickingPdfDownload)}
          </span>
        </div>
      )}
    </>
  );
}

PartnerVoucherMain.propTypes = {
  onChange: PropTypes.func,
  voucher: PropTypes.shape({
    affUrl: PropTypes.string,
    voucherId: PropTypes.string,
    allocationDate: PropTypes.string,
    logo: PropTypes.string,
    loadAmmount: PropTypes.number,
    value: PropTypes.number,
    status: PropTypes.string,
    refId: PropTypes.string,
    redeemed: PropTypes.bool,
    eCodeContent: PropTypes.shape({
      code: PropTypes.string,
    }),
    pdfContent: PropTypes.shape({
      url: PropTypes.string,
    }),
    type: PropTypes.string,
  }),
  isAvailableVoucher: PropTypes.bool,
  isMainVoucher: PropTypes.bool,
  onClick: PropTypes.func,
  showDate: PropTypes.bool,
};

PartnerVoucherMain.defaultProps = {
  onChange: () => {},
  voucher: {
    affUrl: '',
    voucherId: '',
    allocationDate: '',
    logo: '',
    loadAmmount: 0,
    value: 0,
    status: '',
    refId: '',
    redeemed: false,
    eCodeContent: {
      code: '',
    },
    type: '',
    pdfContent: {
      url: '',
    },
  },
  isAvailableVoucher: true,
  isMainVoucher: false,
  showDate: false,
  onClick: () => {},
};
export default PartnerVoucherMain;
