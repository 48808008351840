import React from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, Navigate } from 'react-router-dom';

import PrivateRoute from '@config/AppRoutes/PrivateRoute';
import { withRouter } from '@utils/Router/withRouter';

import DummyComponent from '@page/Test';
import HomePage from '@page/Home';
import PageNotFound from '@page/404';
import LandingPage from '@page/Landing';
import LoginPage from '@page/Login';
import ForgotPasswordPage from '@page/ForgotPassword';
import ResetPasswordPage from '@page/ResetPassword';
import CallBackPage from '@page/CallBack';
import MyAccount from '@page/MyAccount';
import MyAccountInfo from '@page/MyAccount/Info';
import Partner from '@page/Partner';
import PartnerDetail from '@page/Partner/PartnerDetail';
import MyVoucherPage from '@page/MyVoucher';
import VoucherDetail from '@page/MyVoucher/VoucherDetail';
import StaticPages from '@page/StaticPage';
import InfoPage from '@page/Info';

import { withLayout } from '@part/Layout';
import { withAlert } from '@core/Alert';
import { appRoute } from '@utils/Constant';
import { withToast } from '@core/Toast';
import { useAuth } from '@context/AuthProvider';
import VerifyEmail from '@page/VerifyEmail';
import FaqPage from '@page/Faq';

function AppRoutes({ location }) {
  const { isUserLoggedIn } = useAuth();

  return (
    <Routes>
      <Route path={appRoute.TEST} element={<DummyComponent />} />
      {/* <Route exact path={appRoute.TEST} element={<PrivateRoute />}>
        <Route exact path={appRoute.TEST} element={<DummyComponent />} />
      </Route> */}
      {/* App Home Page */}
      {/* <PrivateRoute path={appRoute.HOME} element={<HomePage />} /> */}
      <Route exact path={appRoute.HOME} element={<PrivateRoute />}>
        <Route exact path={appRoute.HOME} element={<HomePage />} />
      </Route>
      {/* Authe Pages */}
      <Route path={appRoute.LOGIN} element={<LoginPage />} />
      <Route path={appRoute.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
      <Route path={appRoute.RESET_PASSWORD} element={<ResetPasswordPage />} />
      <Route path={appRoute.VERIFY_EMAIL} element={<VerifyEmail />} />

      <Route path={appRoute.AUTH_CALLBACK} element={<CallBackPage />} />

      {/* Try to lan with SpaceX Lander */}
      <Route path={appRoute.LANDING} element={<LandingPage />} />

      {/* Partner Page */}
      {/* <PrivateRoute exact path={appRoute.PARTNER} element={<Partner />} /> */}
      <Route exact path={appRoute.PARTNER} element={<PrivateRoute />}>
        <Route exact path={appRoute.PARTNER} element={<Partner />} />
      </Route>
      {/* <PrivateRoute path={appRoute.PARTNER_DETAIL()} element={<PartnerDetail />} /> */}
      <Route exact path={appRoute.PARTNER_DETAIL()} element={<PrivateRoute />}>
        <Route exact path={appRoute.PARTNER_DETAIL()} element={<PartnerDetail />} />
      </Route>

      {/* Personalised Page */}
      {/* <PrivateRoute exact path={appRoute.MY_ACCOUNT_PERSONAL_INFO} element={<MyAccount />} /> */}
      <Route exact path={appRoute.MY_ACCOUNT_PERSONAL_INFO} element={<PrivateRoute />}>
        <Route exact path={appRoute.MY_ACCOUNT_PERSONAL_INFO} element={<MyAccount />} />
      </Route>

      {/* <PrivateRoute exact path={appRoute.MY_ACCOUNT_PASSWORD_RESET} element={<MyAccount />} /> */}
      <Route exact path={appRoute.MY_ACCOUNT_PASSWORD_RESET} element={<PrivateRoute />}>
        <Route exact path={appRoute.MY_ACCOUNT_PASSWORD_RESET} element={<MyAccount />} />
      </Route>

      {/* <PrivateRoute exact path={appRoute.MY_ACCOUNT_NOTIFICATION_SETTINGS} element={<MyAccount />} /> */}
      <Route exact path={appRoute.MY_ACCOUNT_NOTIFICATION_SETTINGS} element={<PrivateRoute />}>
        <Route exact path={appRoute.MY_ACCOUNT_NOTIFICATION_SETTINGS} element={<MyAccount />} />
      </Route>

      {/* <PrivateRoute exact path={appRoute.MY_ACCOUNT_LOGOUT} element={<MyAccount />} /> */}
      <Route exact path={appRoute.MY_ACCOUNT_LOGOUT} element={<PrivateRoute />}>
        <Route exact path={appRoute.MY_ACCOUNT_LOGOUT} element={<MyAccount />} />
      </Route>

      {/* <PrivateRoute exact path={appRoute.MY_ACCOUNT_INFO} element={<MyAccountInfo />} /> */}
      <Route exact path={appRoute.MY_ACCOUNT_INFO} element={<PrivateRoute />}>
        <Route exact path={appRoute.MY_ACCOUNT_INFO} element={<MyAccountInfo />} />
      </Route>

      {/* My Voucher */}
      {/* <PrivateRoute exact path={appRoute.MY_VOUCHER} element={<MyVoucherPage />} /> */}
      <Route exact path={appRoute.MY_VOUCHER} element={<PrivateRoute />}>
        <Route exact path={appRoute.MY_VOUCHER} element={<MyVoucherPage />} />
      </Route>
      {/* <PrivateRoute path={appRoute.MY_VOUCHER_DETAILS()} element={<VoucherDetail />} /> */}
      <Route exact path={appRoute.MY_VOUCHER_DETAILS()} element={<PrivateRoute />}>
        <Route exact path={appRoute.MY_VOUCHER_DETAILS()} element={<VoucherDetail />} />
      </Route>

      {/* App Pages */}
      {/* <PrivateRoute path={appRoute.INFO} element={<InfoPage />} /> */}
      <Route exact path={appRoute.INFO} element={<PrivateRoute />}>
        <Route exact path={appRoute.INFO} element={<InfoPage />} />
      </Route>

      {/* App redirect page */}
      {/* <Navigate path={appRoute.MY_ACCOUNT} to={{ pathname: appRoute.MY_ACCOUNT_PERSONAL_INFO }} /> */}
      <Route
        path={appRoute.MY_ACCOUNT}
        element={<Navigate to={{ pathname: appRoute.MY_ACCOUNT_PERSONAL_INFO }} />}
      />

      {/* FAQ */}
      <Route path={appRoute.FAQ} element={<FaqPage />} />
      {/* Public Paths */}
      <Route path={appRoute.NOT_FOUND} element={<PageNotFound />} />

      {/* Static Content Routes */}
      <Route path={appRoute.STATIC_PAGE()} element={<StaticPages />} />

      {/* <Navigate
        path={appRoute.APP}
        to={{
          pathname: isUserLoggedIn ? appRoute.HOME : appRoute.LOGIN,
          state: { from: location },
        }}
      /> */}
      <Route
        path={appRoute.APP}
        element={
          <Navigate
            to={{
              pathname: isUserLoggedIn ? appRoute.HOME : appRoute.LOGIN,
              state: { from: location },
            }}
          />
        }
      />

      {/* <Navigate
        path={appRoute.ALL}
        to={{ pathname: appRoute.NOT_FOUND, state: { from: location } }}
      /> */}
      <Route
        path={appRoute.ALL}
        element={
          <Navigate to={{ pathname: appRoute.NOT_FOUND, state: { from: location } }} replace />
        }
      />
    </Routes>
  );
}

AppRoutes.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

export default withRouter(withAlert(withToast(withLayout(AppRoutes))));
