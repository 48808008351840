import { createContext, useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import MyVoucherService from '@service/MyVoucher';
import DashboardService from '@service/Dashboard';

import { useAppConfig } from '@context/AppConfig';
import { StatusCodes, validateResponse } from '@utils/ApiCodex';
import { useLoading } from '@context/Loading';
import { useErrorHandler } from '@context/ErrorHandler';
import {
  appAlert,
  appDefaultErrorCode,
  appRoute,
  defaultVoucherFilter,
  defaultVoucherFilterStatus,
} from '@utils/Constant';
import { useAuth } from '@context/AuthProvider';
import { keysToCamel, onlyUnique } from '@utils/Validation';
import VoucherFilteration from '@part/Filteration/VoucherFilteration';
import { useAlert } from '@context/Alert';
import { LANGUAGE } from '@utils/Constant/language';
import { useLanguage } from '@context/Language';

// A context to load all app configuration from server
const MyVoucherContext = createContext();

// The top level component that will wrap our app's core features
export function MyVoucherProvider({ children }) {
  const { env } = useAppConfig();
  const { isUserLoggedIn } = useAuth();
  const { showLoader, hideLoader } = useLoading();
  const { setErrorStatusCode } = useErrorHandler();
  const { showAlert } = useAlert();
  const { translate } = useLanguage();

  const [filter, setFilter] = useState(defaultVoucherFilter);
  const [appliedFilter, setAppliedFilter] = useState(defaultVoucherFilter);

  const [voucherList, setVoucherList] = useState([]);
  const [voucherViewList, setVoucherViewList] = useState([]);
  const [myVoucher, setMyVoucher] = useState(null);
  const [dashboardSlides, setDashboardSlides] = useState([]);

  const [myVoucherDetail, setMyVoucherDetail] = useState({});

  const navigate = useNavigate();

  const navigateToDetails = (id) => navigate(appRoute.MY_VOUCHER_DETAILS(id));

  const getDashboardSlides = () => {
    const dashboardService = new DashboardService(env);
    dashboardService.getDashboardSlides().then((results) => {
      // console.log(results);
      if (results.status === StatusCodes.OK && validateResponse(results)) {
        setDashboardSlides(keysToCamel(results.data.data) || []);
      }
    });
  };

  const getVocherList = () => {
    showLoader();
    const myVoucherService = new MyVoucherService(env);
    myVoucherService
      .getVouchers()
      .then((result) => {
        if (result.status === StatusCodes.OK) {
          const data = keysToCamel(result.data);
          setVoucherList(data);
          // data.splice();
          const sortedData = data.sort(
            (a, b) => new Date(b.allocationDate) - new Date(a.allocationDate),
          );
          const years = data
            .map((v) => new Date(v.allocationDate).getFullYear())
            .filter(onlyUnique);
          const splitYearWise = years.map((year, yi) => ({
            year,
            vouchers: sortedData
              .filter(
                (a, vi) => new Date(a.allocationDate).getFullYear() === year && yi >= 0 && vi > 0,
              ) // Skip first voucher
              .sort((a, b) => new Date(b.allocationDate) - new Date(a.allocationDate)),
          }));
          setMyVoucher({ ...data.find((v) => v.voucherId) } || null);
          hideLoader();
          // console.log({ splitYearWise, sortedData, years });
          setVoucherViewList(
            splitYearWise.length > 0
              ? splitYearWise
              : [{ year: new Date().getFullYear(), vouchers: [] }],
          );
        }
      })
      .catch(() => {
        hideLoader();
        // console.log(err);
        // showAlert({ type: appAlert.ERROR, message: err.response?.data || 'Invalid request' });
        setErrorStatusCode(appDefaultErrorCode.CODE);
      });
  };

  const onRedeemChange = ({ refId, status }) => {
    const myVoucherService = new MyVoucherService(env);
    myVoucherService
      .saveVouchers({ ref_id: refId, status })
      .then((result) => {
        if (result.status >= StatusCodes.OK && result.status <= StatusCodes.MULTI_STATUS) {
          getVocherList();
          showAlert({ type: appAlert.SUCCESS, message: translate(LANGUAGE.updatedSuccess) });
        }
      })
      .catch(() => {
        hideLoader();
        setErrorStatusCode(appDefaultErrorCode.CODE);
      });
  };

  useEffect(() => {
    if (isUserLoggedIn) {
      getVocherList();
      getDashboardSlides();
    }
    return () => {};
  }, [isUserLoggedIn]);

  const getVoucherDetails = (voucherId) => {
    const voucher = voucherList.find((v) => v.voucherId === voucherId);
    if (voucher) {
      setMyVoucherDetail(voucher);
    }
  };

  const openFilter = () => setFilter({ ...filter, isOpen: true });
  const closeFilter = () => setFilter({ ...filter, isOpen: false });
  const changeVoucherView = (applyView) => setFilter({ ...filter, view: applyView });
  const changeVoucherStatus = (applyView) => {
    let { usedVoucher } = filter.status;
    let { unUsedVoucher } = filter.status;
    if (defaultVoucherFilterStatus.USED_VOUCHER === applyView) {
      usedVoucher = !filter.status.usedVoucher;
    } else if (defaultVoucherFilterStatus.UN_USER_VOUCHER === applyView) {
      unUsedVoucher = !filter.status.unUsedVoucher;
    }

    // if (filter.status !== applyView) {
    //   status = applyView;
    // } else {
    //   status = defaultVoucherFilterStatus.NONE;
    // }
    setFilter({ ...filter, status: { usedVoucher, unUsedVoucher } });
    // setFilter({ ...filter, status: applyView })
  };

  const searchVoucher = (value) => {
    setFilter({ ...filter, searchText: value });
    const list = voucherList;

    const sortedData = list.sort((a, b) => new Date(b.allocationDate) - new Date(a.allocationDate));
    const years = list.map((v) => new Date(v.allocationDate).getFullYear()).filter(onlyUnique);

    if (value) {
      const splitYearWise = years.map((year) => ({
        year,
        vouchers: sortedData
          .filter((a) => new Date(a.allocationDate).getFullYear() === year)
          .sort((a, b) => new Date(b.allocationDate) - new Date(a.allocationDate))
          // eslint-disable-next-line no-nested-ternary
          .filter((a) =>
            value
              ? `${a.partnerName}`.toLocaleLowerCase().includes(`${value}`.toLocaleLowerCase())
              : a,
          ),
      }));
      setVoucherViewList(splitYearWise);
    }
  };

  const filterVoucher = (value) => {
    setFilter({ ...value, isOpen: false });
    setAppliedFilter({ ...value, isOpen: false });
    // let list = sortAsc(partnerList, 'i');
    const list = voucherList;

    const sortedData = list.sort((a, b) => new Date(b.allocationDate) - new Date(a.allocationDate));
    const years = list.map((v) => new Date(v.allocationDate).getFullYear()).filter(onlyUnique);

    if (value.status.usedVoucher === defaultVoucherFilterStatus.APPLIED) {
      const splitYearWise = years.map((year, yi) => ({
        year,
        vouchers: sortedData
          .filter((a, vi) => new Date(a.allocationDate).getFullYear() === year && yi >= 0 && vi > 0)
          .sort((a, b) => new Date(b.allocationDate) - new Date(a.allocationDate))
          .filter((a) => a.redeemed),
      }));
      setVoucherViewList(splitYearWise);
    } else if (value.status.unUsedVoucher === defaultVoucherFilterStatus.APPLIED) {
      const splitYearWise = years.map((year, yi) => ({
        year,
        vouchers: sortedData
          .filter((a, vi) => new Date(a.allocationDate).getFullYear() === year && yi >= 0 && vi > 0)
          .sort((a, b) => new Date(b.allocationDate) - new Date(a.allocationDate))
          .filter((a) => !a.redeemed),
      }));
      setVoucherViewList(splitYearWise);
    } else {
      const splitYearWise = years.map((year, yi) => ({
        year,
        vouchers: sortedData
          .filter((a, vi) => new Date(a.allocationDate).getFullYear() === year && yi >= 0 && vi > 0)
          .sort((a, b) => new Date(b.allocationDate) - new Date(a.allocationDate)),
      }));
      setVoucherViewList(splitYearWise);
    }
  };

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(
    () => ({
      voucherList,
      setVoucherList,
      voucherViewList,
      setVoucherViewList,
      filter,
      openFilter,
      closeFilter,
      changeVoucherView,
      changeVoucherStatus,
      filterVoucher,
      appliedFilter,
      setAppliedFilter,
      myVoucher,
      onRedeemChange,
      searchVoucher,
      getVoucherDetails,
      myVoucherDetail,
      navigateToDetails,
      dashboardSlides,
    }),
    [
      voucherList,
      setVoucherList,
      voucherViewList,
      setVoucherViewList,
      filter,
      openFilter,
      closeFilter,
      changeVoucherView,
      changeVoucherStatus,
      filterVoucher,
      appliedFilter,
      setAppliedFilter,
      myVoucher,
      onRedeemChange,
      searchVoucher,
      getVoucherDetails,
      myVoucherDetail,
      navigateToDetails,
      dashboardSlides,
    ],
  );

  // Render the children as normal
  // TODO: Implemente loading if required.
  const renderContent = () => children;

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <MyVoucherContext.Provider value={contextPayload}>
      {filter.isOpen && (
        <div className="partner-filter-modal absolute w-full h-full inset-0 z-50  overflow-x-hidden">
          <VoucherFilteration
            {...{
              isOpen: filter.isOpen,
              filter,
              closeFilter,
              changeVoucherView,
              changeVoucherStatus,
              filterVoucher,
            }}
          />
        </div>
      )}
      {renderContent()}
    </MyVoucherContext.Provider>
  );
}

MyVoucherProvider.propTypes = {
  children: PropTypes.node,
};

MyVoucherProvider.defaultProps = {
  children: null,
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useMyVoucher = () => useContext(MyVoucherContext);
