/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@core/Modal';
import Button from '@core/Button';
import {
  appIcons,
  appListViewTypes,
  appSortTypes,
  buttonLooks,
  buttonTypes,
} from '@utils/Constant';
import Checkbox from '@core/Checkbox';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';

function PartnerFilteration({
  isOpen,
  filter,
  closeFilter,
  changePartnerView,
  selectCategory,
  filterPartners,
  sortFilter,
}) {
  const { translate } = useLanguage();

  const getCategories = () =>
    filter.categories.map((category, index) => (
      <div className="col-span-1 fil-list" key={`cat_filter_${category.name}`}>
        <div className="fil-list-item">
          <div className="grid grid-cols-12">
            <div className="col-span-2 flex-center">
              <Checkbox checked={category.isActive} onChange={() => selectCategory(index)} />
            </div>
            <div className="col-span-9 pl-2 flex-start-center">
              <span className="text-18 text-ed-gray text-ed-regular">{category.name}</span>
            </div>
          </div>
        </div>
      </div>
    ));

  const confirmFilter = () => filterPartners(filter);

  const getSortTypeClass = () => {
    let className = 'text-ed-gray border-ed';
    if (filter.sort === appSortTypes.ASC || filter.sort === appSortTypes.DESC) {
      className = 'bg-ed-blue text-white';
    }
    return className;
  };
  const getSortIcon = () => {
    if (filter.sort === appSortTypes.ASC) {
      return appIcons.SORT_ACTIVE;
    }
    if (filter.sort === appSortTypes.DESC) {
      return appIcons.SORT_DEC_ACTIVE;
    }
    return appIcons.SORT;
  };

  return (
    <Modal
      {...{
        isOpen,
        title: 'Filter',
        onClose: closeFilter,
        className: 'partner-filter-modal py-20 2xl:flex justify-center items-center h-auto',
      }}
    >
      <div className="filter-content flex-center">
        <section className="view-selector">
          <span className="text-18 text-ed-bold text-ed-gray w-full text-left">Ansicht</span>
          <div className="btn-selection">
            <div className="grid grid-cols-1 xs:grid-cols-2 gap-y-4 xs:gap-y-0 w-full">
              <div className="col-span-1">
                <Button
                  label={translate(LANGUAGE.blockView)}
                  className={`border-2 ${
                    filter.view === appListViewTypes.BLOCK_VIEW
                      ? 'border-ed-blue'
                      : 'border-ed-dark'
                  }  text-ed-regular text-ed-gray px-4 py-3 transition duration-300 ease-in-out flex-center flex rounded-full`}
                  type={buttonTypes.OUTLINE}
                  icon
                  iconComponent={
                    filter.view === appListViewTypes.BLOCK_VIEW
                      ? appIcons.BLOCK_ACTIVE
                      : appIcons.BLOCK
                  }
                  look={buttonLooks.PILL}
                  disabled={filter.view === appListViewTypes.BLOCK_VIEW}
                  onClick={() => changePartnerView(appListViewTypes.BLOCK_VIEW)}
                />
              </div>
              <div className="col-span-1">
                <Button
                  label={translate(LANGUAGE.listView)}
                  className={`border-2 ${
                    filter.view === appListViewTypes.LIST_VIEW ? 'border-ed-blue' : 'border-ed-dark'
                  }  text-ed-regular text-ed-gray px-4 py-3 transition duration-300 ease-in-out flex-center flex rounded-full`}
                  type={buttonTypes.OUTLINE}
                  icon
                  iconComponent={
                    filter.view === appListViewTypes.LIST_VIEW
                      ? appIcons.LIST_ACTIVE
                      : appIcons.LIST
                  }
                  look={buttonLooks.PILL}
                  disabled={filter.view === appListViewTypes.LIST_VIEW}
                  onClick={() => changePartnerView(appListViewTypes.LIST_VIEW)}
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="filter-content flex-center">
        <section className="sort-selector">
          <span className="text-18 text-ed-bold text-ed-gray w-full text-left">Sortieren nach</span>
          <div className="list-sort flex item-center justify-between">
            <div className="apply-sort w-full flex-center">
              <Button
                label={translate(
                  filter.sort === appSortTypes.ASC || filter.sort === appSortTypes.NONE
                    ? LANGUAGE.sortAZ
                    : LANGUAGE.sortZA,
                )}
                className={`border-2 text-ed-regular ${getSortTypeClass()} px-4 py-3 transition duration-300 ease-in-out flex-center flex rounded-full`}
                type={buttonTypes.OUTLINE}
                icon
                iconComponent={getSortIcon()}
                look={buttonLooks.PILL}
                svgProps={{ width: 22, height: 22 }}
                onClick={() => sortFilter()}
              />
            </div>
          </div>
        </section>
      </div>

      <div className="filter-content flex-center">
        <section className="categories-selector">
          <span className="text-18 text-ed-bold text-ed-gray w-full text-left">
            {translate(LANGUAGE.category)}
          </span>
          <div className="list-selection flex item-center justify-between">
            <div className="grid grid-cols-1 xs:grid-cols-2 w-full">{getCategories()}</div>
          </div>
          <div className="apply-filter w-full flex-center">
            <Button
              className="relative bg-ed-blue text-ed-regular text-ed-white px-4 py-3 transition duration-300 ease-in-out flex-center flex rounded-full"
              label={translate(LANGUAGE.applyFilter)}
              type={buttonTypes.DEFAULT}
              icon
              iconComponent={appIcons.APPLY_FILTER}
              look={buttonLooks.PILL}
              svgProps={{ width: 22, height: 22 }}
              onClick={() => confirmFilter()}
            />
          </div>
        </section>
      </div>
    </Modal>
  );
}

PartnerFilteration.propTypes = {
  isOpen: PropTypes.bool,
  filter: PropTypes.any,
  closeFilter: PropTypes.func,
  changePartnerView: PropTypes.func,
  selectCategory: PropTypes.func,
  filterPartners: PropTypes.func,
  sortFilter: PropTypes.func,
};

PartnerFilteration.defaultProps = {
  isOpen: false,
  filter: {},
  closeFilter: () => {},
  changePartnerView: () => {},
  selectCategory: () => {},
  filterPartners: () => {},
  sortFilter: () => {},
};

export default PartnerFilteration;
