/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import Input from '@core/Input';
// import Button from '@core/Button';
import {
  backgroundColor,
  borderRadius,
  // buttonLooks,
  inputValidation,
  inputViewSizes,
} from '@utils/Constant';
import { withAnimate } from '@part/AnimateLoad';
import SVG from '@utils/SVG';
import Card from '@core/Card';
import { useMyAccount } from '@context/MyAccount';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';
import { validatePasswordWithKeys } from '@utils/Validation';

function PasswordReset() {
  const { passwordForm, checkNav, onSubmitPassword } = useMyAccount();
  const { translate } = useLanguage();

  const [showPassword, setShowPassword] = useState({
    password: false,
    oldPassword: false,
    confirmPassword: false,
  });

  // const viewValidatePassword = (type) => {
  //   if (passwordForm.password || passwordForm.confirmPassword) {
  //     const isValid = passwordForm.validPassword;
  //     if (isValid && isValid[type]) {
  //       return <SVG.CircleTick {...{ width: 12, height: 12 }} />;
  //     }
  //     return <SVG.XRed {...{ width: 12, height: 12 }} />;
  //   }
  //   return false;
  // };

  // const viewValidatePassword = (type) => {
  //   if (passwordForm.password || passwordForm.confirmPassword) {
  //     const isValid = passwordForm.validPassword;
  //     if (isValid && isValid[type]) {
  //       return <SVG.CircleTick {...{ width: 12, height: 12 }} />;
  //     }
  //     return <SVG.XRed {...{ width: 12, height: 12 }} />;
  //   }
  //   return false;
  // };

  // const visibleFormField = (field) => {
  //   onChangePassword({
  //     target: {
  //       name: field,
  //       value: !passwordForm[field],
  //     },
  //   });
  // };

  useEffect(() => {
    checkNav();
  }, []);

  return (
    <Formik
      initialValues={passwordForm}
      // validationSchema={passwordValidationSchema}
      enableReinitialize
      validate={(v) => validatePasswordWithKeys(v, 'password')}
      onSubmit={onSubmitPassword}
    >
      {({
        values: { oldPassword, password, confirmPassword },
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        errors,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="personal-data">
            <div className="grid grid-cols-11 md:grid-cols-12">
              <div className="col-span-12 sm:col-span-8 pb-2">
                <div className="form-input">
                  <span className="text-18 text-ed-gray text-ed-regular mb-2">
                    {translate(LANGUAGE.oldPassword)}
                  </span>
                  <div className="mt-1">
                    <Input
                      type={showPassword.oldPassword ? 'text' : 'password'}
                      viewSize={inputViewSizes.SM}
                      name="oldPassword"
                      value={oldPassword}
                      placeholder={translate(LANGUAGE.oldPassword)}
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                      isInvalid={touched.oldPassword && errors.oldPassword}
                      error={errors.oldPassword ? errors.oldPassword : ''}
                      icon
                      iconComponent={showPassword.oldPassword ? 'EyeOff' : 'Eye'}
                      onIconClick={() =>
                        setShowPassword({ ...showPassword, oldPassword: !showPassword.oldPassword })
                      }
                    />
                    {/* <Input
                      type={passwordForm.viewOldPassword ? 'text' : 'password'}
                      viewSize={inputViewSizes.SM}
                      icon
                      iconComponent={passwordForm.viewOldPassword ? 'EyeOff' : 'Eye'}
                      validation={
                        passwordForm.validOldPassword && passwordForm.validOldPassword.valid
                          ? passwordForm.validOldPassword
                          : ''
                      }
                      onIconClick={() => visibleFormField('viewOldPassword')}
                      name="oldPassword"
                      value={passwordForm.oldPassword}
                      placeholder={translate(LANGUAGE.oldPassword)}
                      onChange={onChangePassword}
                    /> */}
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-8 pb-2">
                <div className="form-input">
                  <span className="text-18 text-ed-gray text-ed-regular mb-2">
                    {translate(LANGUAGE.newPassword)}
                  </span>
                  <div className="mt-1">
                    <Input
                      type={showPassword.password ? 'text' : 'password'}
                      viewSize={inputViewSizes.SM}
                      name="password"
                      value={password}
                      placeholder={translate(LANGUAGE.password)}
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                      isInvalid={touched.password && errors.password}
                      error={errors.password ? errors.password : ''}
                      icon
                      iconComponent={showPassword.password ? 'EyeOff' : 'Eye'}
                      onIconClick={() =>
                        setShowPassword({ ...showPassword, password: !showPassword.password })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-8 pb-2">
                <div className="form-input">
                  <span className="text-18 text-ed-gray text-ed-regular mb-2">
                    {translate(LANGUAGE.confirmPassword)}
                  </span>
                  <div className="mt-1">
                    <Input
                      type={showPassword.confirmPassword ? 'text' : 'password'}
                      viewSize={inputViewSizes.SM}
                      name="confirmPassword"
                      value={confirmPassword}
                      placeholder={translate(LANGUAGE.confirmPassword)}
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                      isInvalid={touched.confirmPassword && errors.confirmPassword}
                      error={errors.confirmPassword ? errors.confirmPassword : ''}
                      icon
                      iconComponent={showPassword.confirmPassword ? 'EyeOff' : 'Eye'}
                      onIconClick={() =>
                        setShowPassword({
                          ...showPassword,
                          confirmPassword: !showPassword.confirmPassword,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-8 pb-3 py-3">
                <Card bgColor={backgroundColor.LIGHT_COBALD} bdRadius={borderRadius.LARGE} bgShadow>
                  <div className="reset-password-ed-verification-list">
                    <h6 className="text-12 text-ed-gray text-ed-bold">
                      {translate(LANGUAGE.yourPasswordMust)}
                    </h6>
                    <div className="grid grid-cols-12 ">
                      {/*  */}
                      {touched.password &&
                      errors &&
                      errors.otherErrors &&
                      errors.otherErrors[inputValidation.MIN_MAX_ERROR] ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.XRed {...{ width: 12, height: 12 }} />
                        </div>
                      ) : password ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.CircleTick {...{ width: 12, height: 12 }} />
                        </div>
                      ) : (
                        ''
                      )}
                      {/* {touched.password &&
                      errors.otherErrors &&
                      errors.otherErrors[inputValidation.MIN_MAX_ERROR] ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.CircleTick {...{ width: 12, height: 12 }} />
                        </div>
                      ) : touched.password &&
                        errors.otherErrors &&
                        !errors.otherErrors[inputValidation.MIN_MAX_ERROR] ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.XRed {...{ width: 12, height: 12 }} />
                        </div>
                      ) : (
                        ''
                      )} */}

                      <div className="col-span-11 flex-start-center">
                        <span className="text-12 text-ed-gray text-ed-regular">
                          {translate(LANGUAGE.eightTo_20Chars)}
                        </span>
                      </div>

                      {/*  */}
                      {/* {touched.password &&
                      errors.otherErrors &&
                      errors.otherErrors[inputValidation.UPPER_CASE_ERROR] ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.CircleTick {...{ width: 12, height: 12 }} />
                        </div>
                      ) : touched.password &&
                        errors.otherErrors &&
                        !errors.otherErrors[inputValidation.UPPER_CASE_ERROR] ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.XRed {...{ width: 12, height: 12 }} />
                        </div>
                      ) : (
                        ''
                      )} */}
                      {touched.password &&
                      errors &&
                      errors.otherErrors &&
                      errors.otherErrors[inputValidation.UPPER_CASE_ERROR] ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.XRed {...{ width: 12, height: 12 }} />
                        </div>
                      ) : password ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.CircleTick {...{ width: 12, height: 12 }} />
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="col-span-11 flex-start-center">
                        <span className="text-12 text-ed-gray text-ed-regular">
                          {translate(LANGUAGE.atleastOneUpperCaseLetter)}
                        </span>
                      </div>

                      {/*  */}
                      {/* {touched.password &&
                      errors.otherErrors &&
                      errors.otherErrors[inputValidation.LOWER_CASE_ERROR] ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.CircleTick {...{ width: 12, height: 12 }} />
                        </div>
                      ) : touched.password &&
                        errors.otherErrors &&
                        !errors.otherErrors[inputValidation.LOWER_CASE_ERROR] ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.XRed {...{ width: 12, height: 12 }} />
                        </div>
                      ) : (
                        ''
                      )} */}
                      {touched.password &&
                      errors &&
                      errors.otherErrors &&
                      errors.otherErrors[inputValidation.LOWER_CASE_ERROR] ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.XRed {...{ width: 12, height: 12 }} />
                        </div>
                      ) : password ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.CircleTick {...{ width: 12, height: 12 }} />
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="col-span-11 flex-start-center">
                        <span className="text-12 text-ed-gray text-ed-regular">
                          {translate(LANGUAGE.atleastOneLowerCaseLetter)}
                        </span>
                      </div>
                      {/*  */}
                      {/* {touched.password &&
                      errors.otherErrors &&
                      errors.otherErrors[inputValidation.NUMBER_ERROR] ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.CircleTick {...{ width: 12, height: 12 }} />
                        </div>
                      ) : touched.password &&
                        errors.otherErrors &&
                        !errors.otherErrors[inputValidation.NUMBER_ERROR] ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.XRed {...{ width: 12, height: 12 }} />
                        </div>
                      ) : (
                        ''
                      )} */}
                      {touched.password &&
                      errors &&
                      errors.otherErrors &&
                      errors.otherErrors[inputValidation.NUMBER_ERROR] ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.XRed {...{ width: 12, height: 12 }} />
                        </div>
                      ) : password ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.CircleTick {...{ width: 12, height: 12 }} />
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="col-span-11 flex-start-center">
                        <span className="text-12 text-ed-gray text-ed-regular">
                          {translate(LANGUAGE.atleasrOneNum)}
                        </span>
                      </div>

                      {/*  */}
                      {/* {touched.password &&
                      errors.otherErrors &&
                      errors.otherErrors[inputValidation.SPECIAL_CASE_ERROR] ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.CircleTick {...{ width: 12, height: 12 }} />
                        </div>
                      ) : touched.password &&
                        errors.otherErrors &&
                        !errors.otherErrors[inputValidation.SPECIAL_CASE_ERROR] ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.XRed {...{ width: 12, height: 12 }} />
                        </div>
                      ) : (
                        ''
                      )} */}
                      {touched.password &&
                      errors &&
                      errors.otherErrors &&
                      errors.otherErrors[inputValidation.SPECIAL_CASE_ERROR] ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.XRed {...{ width: 12, height: 12 }} />
                        </div>
                      ) : password ? (
                        <div className="col-span-1 col-start-1 flex-center">
                          <SVG.CircleTick {...{ width: 12, height: 12 }} />
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="col-span-11 flex-start-center">
                        <span className="text-12 text-ed-gray text-ed-regular">
                          {translate(LANGUAGE.atleastOneSpecialChar)}
                        </span>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-span-12 flex items-center justify-center sm:justify-start my-4">
                <button
                  className="relative bg-ed-red text-ed-regular text-white text-18 px-14 py-3 flex-center rounded-full"
                  type="submit"
                >
                  {translate(LANGUAGE.changePassword)}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default withAnimate(PasswordReset);
