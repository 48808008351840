import React, { useState } from 'react';

import { withAnimate } from '@part/AnimateLoad';
import { useLayout } from '@context/Layout';
import { Link } from 'react-router-dom';
// import { buttonLooks } from '@utils/Constant';
// import Button from '@core/Button';
import { getIcon } from '@utils/SVG';
// import { themeColor } from '@theme/common/color';

function AppNavigation() {
  const { navList, isSM } = useLayout();

  const [hoverMenu, setHoverMenu] = useState(null);

  const mouseOver = (i) => {
    setHoverMenu(i);
  };
  const mouseOut = () => {
    setHoverMenu(null);
  };

  const renderNavList = () =>
    navList.map((nav, index) => (
      <div className="app-ed-nav-menu px-2 md:pl-2" key={`app_nav_menu_${nav.title}`}>
        <Link
          to={nav.to}
          className="app-ed-nav-link text-18 text-ed-gray text-ed-regular flex rounded-full"
          onMouseEnter={() => mouseOver(index)}
          onMouseLeave={() => mouseOut()}
        >
          <div
            className={`app-ed-nav-icon ${
              nav.active ? 'bg-ed-red active' : 'bg-ed-white '
            } text-ed-gray flex-center`}
          >
            {getIcon({
              iconComponent:
                nav.active || (hoverMenu > -1 && hoverMenu === index) ? nav.iconActive : nav.icon,
              svgProps: { width: isSM ? 20 : 18, height: isSM ? 20 : 18 },
            })}
          </div>
          {/* svgProps={{ width: isSM ? 30 : 18, height: isSM ? 30 : 18 }}
            iconComponent={nav.active ? nav.iconActive : nav.icon} */}
          <span
            className={`app-ed-nav-label ${
              nav.active ? 'text-ed-red active' : 'text-ed-gray'
            } items-center justify-center`}
          >
            {nav.title}
          </span>
        </Link>
      </div>
    ));

  return (
    <div className="app-ed-nav-list w-full bg-ed-white flex-center">
      <nav className="flex item-center justify-between">{renderNavList()}</nav>
    </div>
  );
}

export default withAnimate(AppNavigation);
