// Default app landing page.
import React from 'react';
import { feedbackContentType } from '@utils/Constant';

import Feedback from '@part/Feedback';

function OnboardWelcome() {
  return <Feedback type={feedbackContentType.ONBOARD_WELCOME} />;
}

export default OnboardWelcome;
