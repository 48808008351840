import { useAlert } from '@context/Alert';
import { useAuth } from '@context/AuthProvider';
import { useLanguage } from '@context/Language';
import { appAlert } from '@utils/Constant';
import { LANGUAGE } from '@utils/Constant/language';
import React, { useEffect } from 'react';

function Logout() {
  const { onLogout } = useAuth();
  const { showAlert } = useAlert();
  const { translate } = useLanguage();

  useEffect(() => {
    onLogout()
      .then(() => {
        showAlert({ type: appAlert.SUCCESS, message: translate(LANGUAGE.logoutSuccessMsg) });
      })
      .catch(() => {
        showAlert({ type: appAlert.ERROR, message: translate(LANGUAGE.logoutErrorMsg) });
      });
  }, []);
  // return <div className="w-full">Logout</div>;
  return <></>;
}

export default Logout;
