/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';

import { withAnimate } from '@part/AnimateLoad';

import './style.scss';
import SelectedPartnerCard from '@part/Card/SelectedPartnerCard';
import Button from '@core/Button';
import { appRoute, buttonLooks, buttonTypes } from '@utils/Constant';
import Accordion from '@part/Accordion';
import { usePartner } from '@context/Partner';
import { useParams, useNavigate } from 'react-router-dom';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';
import { sanitizedDOM } from '@utils/Validation';

function PartnerDetailPage() {
  const {
    openPartnerConfirm,
    partnerList,
    selectedPartner,
    selectedPartnerId,
    getPartnerFaq,
    viewPartnerFaq,
  } = usePartner();

  const [viewPartner, setViewPartner] = useState({});

  const { partnerId } = useParams();

  const navigate = useNavigate();

  const { translate } = useLanguage();

  const setPartner = () => {
    const searchPartner = partnerList.find((partner) => partner.partnerId === partnerId);
    if (searchPartner)
      return setViewPartner({
        ...searchPartner,
        isSelected: parseInt(selectedPartnerId, 10) === parseInt(searchPartner.partnerId, 10),
      });
    return setViewPartner({ ...selectedPartner, isSelected: true });
  };

  useEffect(() => {
    setPartner();
    // if (partnerId) getPartnerFaq(partnerId);
  }, []);

  useEffect(() => {
    setPartner();
    if (partnerId) getPartnerFaq(partnerId);
  }, [partnerId, partnerList, selectedPartner, selectedPartnerId]);

  const navigateToPartner = () => {
    navigate(appRoute.PARTNER);
  };

  const confirmPartner = () => {
    openPartnerConfirm(viewPartner);
  };

  const getFaq = () => {
    const searchPartnerFaq = viewPartnerFaq.find((faq) => faq.partnerId === partnerId);
    if (searchPartnerFaq) {
      return (
        <Accordion
          {...{
            data: searchPartnerFaq.faqList.map((faq) => ({
              title: faq.title,
              body: faq.answer,
              active: false,
            })),
          }}
        />
      );
    }
    return <></>;
  };

  return (
    <div className="flex-center bg-ed-cold-white bg-ed-partner-detail-circle">
      <div className="app-ed-page ">
        <div className="app-ed-page-content w-full">
          <div className="page-title w-full text-left">
            <span className="text-24 text-ed-bold text-ed-gray">
              {translate(LANGUAGE.partnerOverview)}
            </span>
          </div>
          <div className="grid sm:grid-cols-2 gap-x-10">
            <div className="col-span-1 selected-partner">
              {viewPartner && (
                <SelectedPartnerCard
                  {...{
                    name: viewPartner.partnerName,
                    categoryName: viewPartner.categoryName,
                    logo: viewPartner.partnerLogo,
                    isSelected: viewPartner.isSelected,
                    backgroundClass: 'shade-lg',
                  }}
                />
              )}
            </div>
            <div className="col-span-1 available-details">
              <div
                className="text-18 text-ed-light text-ed-gray"
                dangerouslySetInnerHTML={{ __html: sanitizedDOM(viewPartner.description) }}
              />
              <div className="selection-btn">
                {viewPartner.isSelected ? (
                  <div className="select-btn flex-center">
                    <Button
                      type={buttonTypes.OUTLINE}
                      className="flex-center text-18 md:text-14 text-ed-regular md:w-60 w-full"
                      label={translate(LANGUAGE.partnerAlreadySelected)}
                      look={buttonLooks.PILL}
                      disabled
                    />
                  </div>
                ) : (
                  <div className="select-new-btn flex-center">
                    <Button
                      type={buttonTypes.DEFAULT}
                      className="flex-center text-18 md:text-14 text-ed-regular text-ed-white bg-ed-blue md:w-60 w-full"
                      label={translate(LANGUAGE.selectAsNewPrtnr)}
                      look={buttonLooks.PILL}
                      onClick={() => confirmPartner()}
                    />
                  </div>
                )}
                <div className="overview-btn flex-center">
                  <Button
                    type={buttonTypes.OUTLINE}
                    className="flex-center text-18 md:text-14 text-ed-regular md:w-60 w-full"
                    label={translate(LANGUAGE.goToPartnerOverView)}
                    look={buttonLooks.PILL}
                    onClick={() => navigateToPartner()}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex-center mt-10">
            <div className="partner-faq">
              <span className="faq-title text-24 text-ed-bold text-ed-gray flex-center w-full">
                {translate(LANGUAGE.mostCommonQuestion)}
              </span>
              <div className="faq-list">{getFaq()}</div>
            </div>
          </div>
          {/* <div className="faq-btn w-full flex-center ">
            <Button
              type={buttonTypes.DEFAULT}
              className="flex-center text-18 text-ed-regular text-ed-white bg-ed-blue"
              label="Load more FAQ"
              look={buttonLooks.PILL}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default withAnimate(PartnerDetailPage);
