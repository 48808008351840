import { createContext, useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  appAlert,
  appAudit,
  // appRoute,
  defaultForgotPasswordViews,
  inputValidation,
} from '@utils/Constant';
import { validateEmail, validatePassword } from '@utils/Validation';
import { useAuth } from '@context/AuthProvider';
import { useAlert } from '@context/Alert';
import { useLoading } from '@context/Loading';
import OnboardService from '@service/Onboard';
import { useAppConfig } from '@context/AppConfig';
import { responseMessage, StatusCodes, validateResponse } from '@utils/ApiCodex';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';
import { useLocation } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';

// A context to load all app configuration from server
const ForgotPasswordContext = createContext();

// The top level component that will wrap our app's core features
export function ForgotPasswordProvider({ children }) {
  // Context
  const { env } = useAppConfig();
  const { onForgotPasswordClick } = useAuth();
  const [forgotPasswordView, setForgotPasswordView] = useState(
    defaultForgotPasswordViews.FORGOT_PASSWORD,
  );

  // const history = useHistory();

  // Navigate to login on successful reset of password
  // const navigateToLogin = () => navigate(appRoute.LOGIN);

  // State
  const defaultForm = {
    email: '',
    validEmail: false,
    errorMessage: '',
    submit: false,
  };
  const [forgotPasswordForm, setForgotPasswordForm] = useState(defaultForm);
  // User UID Details
  // const [forgotUserDetails, setForgotUserDetails] = useState({});

  const { showAlert } = useAlert();
  const { showLoader, hideLoader } = useLoading();
  const { translate } = useLanguage();
  const location = useLocation();
  // Effect
  useEffect(() => {
    // Reset form on load
    setForgotPasswordView(defaultForgotPasswordViews.FORGOT_PASSWORD);
    setForgotPasswordForm(defaultForm);
  }, [location.pathname]);

  const onChange = (e) => {
    if (e.target.name === 'email') {
      // validateEmail(forgotPasswordForm.email)
      setForgotPasswordForm({
        ...forgotPasswordForm,
        [e.target.name]: e.target.value,
        validEmail: validateEmail(e.target.value),
        errorMessage: '',
      });
    }
  };

  const onSubmitClick = () => {
    setForgotPasswordForm({ ...forgotPasswordForm, submit: true });
    if (forgotPasswordForm.validEmail === inputValidation.VALID) {
      showLoader();
      setForgotPasswordForm({
        ...forgotPasswordForm,
        submit: true,
        errorMessage: '',
      });
      onForgotPasswordClick(forgotPasswordForm.email)
        .then((result) => {
          setForgotPasswordView(defaultForgotPasswordViews.ONBOARD_WELCOME);
          hideLoader();
          if (
            (result && result.data && validateResponse(result)) ||
            result?.status === StatusCodes.NO_CONTENT
          ) {
            const onboardService = new OnboardService(env);
            onboardService.saveUserAudit({
              user_email: forgotPasswordForm.email,
              type: appAudit.FORGOT_PASSWORD,
            });
            showAlert({
              type: appAlert.SUCCESS,
              message: responseMessage(result) || translate(LANGUAGE.processToContinueMsg),
            });
            // navigateToLogin();
            setForgotPasswordView(defaultForgotPasswordViews.FORGOT_PASSWORD_WELCOME);
          } else {
            // showAlert({
            //   type: appAlert.ERROR,
            //   message: responseMessage(result) || 'Something went wrong!',
            // });
            setForgotPasswordView(defaultForgotPasswordViews.FORGOT_PASSWORD_WELCOME);
          }
        })
        .catch((err) => {
          window.console.log(err);
          hideLoader();
          // showAlert({ type: appAlert.ERROR, message: 'Something went wrong!' });
          setForgotPasswordView(defaultForgotPasswordViews.FORGOT_PASSWORD_WELCOME);
        });
    } else {
      setForgotPasswordForm({
        ...forgotPasswordForm,
        submit: true,
        errorMessage: 'Please check your input',
        validEmail: validateEmail(forgotPasswordForm.email),
        validPassword: validatePassword(
          forgotPasswordForm.password,
          'password',
          forgotPasswordForm.password,
        ),
      });
      showAlert({ type: appAlert.ERROR, message: translate(LANGUAGE.checkYouInputMsg) });
    }
  };

  // // On landing with url from email
  const onLoadUser = (url) => {
    const onboardService = new OnboardService(env);

    showLoader();
    onboardService
      .getUserUid(url)
      .then((result) => {
        if (result.status === StatusCodes.OK) {
          // setForgotUserDetails(keysToCamel(result.data));
          // console.log(keysToCamel(result.data));
        }
        hideLoader();
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  // Render the children as normal
  // TODO: Implemente loading if required.
  const renderContent = () => children;

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(
    () => ({
      forgotPasswordForm,
      setForgotPasswordForm,
      forgotPasswordView,
      setForgotPasswordView,

      onChange,

      onLoadUser,
      onSubmitClick,
    }),
    [
      forgotPasswordForm,
      setForgotPasswordForm,
      forgotPasswordView,
      setForgotPasswordView,
      onChange,

      onLoadUser,
      onSubmitClick,
    ],
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <ForgotPasswordContext.Provider value={contextPayload}>
      {renderContent()}
    </ForgotPasswordContext.Provider>
  );
}

ForgotPasswordProvider.propTypes = {
  children: PropTypes.node,
};

ForgotPasswordProvider.defaultProps = {
  children: null,
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useForgotPassword = () => useContext(ForgotPasswordContext);
