import React from 'react';

function BurgerMenu() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
      <path
        d="M0.875 11.0024H17.125"
        stroke="#6D7787"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.875 6.00244H17.125"
        stroke="#6D7787"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.875 1.00244H17.125"
        stroke="#6D7787"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BurgerMenu;
