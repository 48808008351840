import React from 'react';
import PropTypes from 'prop-types';

function RightArrow({ pathFill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="inline w-6 stroke-current text-white stroke-2"
      viewBox="0 0 24 24"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="5" y1="12" x2="19" y2="12" stroke={pathFill} />
      <polyline points="12 5 19 12 12 19" stroke={pathFill} />
    </svg>
  );
}

RightArrow.propTypes = {
  pathFill: PropTypes.string,
};

RightArrow.defaultProps = {
  pathFill: '#fff',
};

export default RightArrow;
