// Default app landing page.
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import './style.scss';

import SignInForm from '@page/Landing/SignInForm';
import { useSignIn } from '@context/SignIn';
import { defaultLandingViews } from '@utils/Constant';
import { appUrlQueryParams } from '@utils/Url';

import OnboardNow from './OnboardNow';
import OnboardWelcome from './OnboardWelcome';

function LandingPage() {
  // Load view on base of set lander
  const { landingView, onLoadBoardingUser } = useSignIn();
  const location = useLocation();

  useEffect(() => {
    const args = new URLSearchParams(location.search);
    // const url = args.get(appUrlQueryParams.ONBOARDING_CALLBACK.URL);
    const assoc = args.get(appUrlQueryParams.ONBOARDING_CALLBACK.ASSOC);
    const email = args.get(appUrlQueryParams.ONBOARDING_CALLBACK.E_MAIL);
    if (assoc && email) {
      onLoadBoardingUser({ assoc, email });
    }
  }, []);

  // useEffect(() => {
  //   if (onBoardUserDetails) {
  //     console.log(onBoardUserDetails, history);
  //   }
  // }, [onBoardUserDetails])

  let renderContent = <></>;
  // Switch between landing view as per demand
  switch (landingView) {
    case defaultLandingViews.ONBOARD_START:
      renderContent = <OnboardNow />;
      break;

    case defaultLandingViews.SIGN_IN:
      renderContent = <SignInForm />;
      break;

    case defaultLandingViews.ONBOARD_WELCOME:
      renderContent = <OnboardWelcome />;
      break;

    default:
      renderContent = <OnboardNow />;
      break;
  }

  return (
    <div className="app-ed-page bg-ed-bubbles bg-ed-cold-white flex-above-center">
      {renderContent}
    </div>
  );
}

export default LandingPage;
