// Default app landing page.
import React from 'react';

import Button from '@core/Button';
import Card from '@core/Card';
import Input from '@core/Input';

import './style.scss';
import AnimateLoad from '@part/AnimateLoad';
import {
  animationStyle,
  backgroundColor,
  borderRadius,
  buttonLooks,
  inputValidation,
} from '@utils/Constant';
import SVG from '@utils/SVG';
import Checkbox from '@core/Checkbox';
import { useSignIn } from '@context/SignIn';
import { Link } from 'react-router-dom';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';

function SignInForm() {
  const { translate, getStaticPageRoute } = useLanguage();

  const { signInForm, onSubmitClick, onChange } = useSignIn();

  const visibleFormField = (field) => {
    onChange({
      target: {
        name: field,
        value: !signInForm[field],
      },
    });
  };

  const viewValidatePassword = (type) => {
    const isValid = signInForm.validPassword;
    if (isValid && isValid[type]) {
      return <SVG.CircleTick {...{ width: 12, height: 12 }} />;
    }
    return <SVG.XRed {...{ width: 12, height: 12 }} />;
  };

  return (
    <div className="sign-in-ed h-full py-0 sm:py-10">
      <AnimateLoad style={animationStyle.FADE_IN}>
        <div className="hidden sm:block">
          <Card bgColor={backgroundColor.WHITE} bdRadius={borderRadius.DEFAULT} bgShadow>
            {signInForm.errorMessage && (
              <div
                className={`py-5 px-1 bg-ed-red text-ed-white relative flex-center -mb-10 ${borderRadius.DEFAULT} ${borderRadius.REMOVE_BOTTOM}`}
              >
                <div className="absolute left-0 px-4">
                  <SVG.CircleExclamation />
                </div>
                <div className="w-full flex-1 flex-center">
                  <span className="text-18 text-ed-white text-ed-regular">
                    {translate(LANGUAGE.pleaseCheckYourInput)}
                  </span>
                </div>
              </div>
            )}
            <div className="sign-in-ed-card h-fit-content">
              <h1 className="text-ed-gray text-24 text-ed-bold w-full text-center">
                {translate(LANGUAGE.completeToSignIn)}
              </h1>
              <div className="sign-in-ed-card-content w-full sm:py-5">
                <div className="form-input">
                  <Input
                    type="email"
                    placeholder={translate(LANGUAGE.email)}
                    name="email"
                    value={signInForm.email}
                    validation={signInForm.validEmail ? signInForm.validEmail : ''}
                    onChange={onChange}
                    disabled
                  />
                </div>
                <div className="form-input">
                  <Input
                    type={signInForm.viewPassword ? 'text' : 'password'}
                    icon
                    iconComponent={signInForm.viewPassword ? 'EyeOff' : 'Eye'}
                    name="password"
                    validation={signInForm.validPassword ? signInForm.validPassword.valid : ''}
                    value={signInForm.password}
                    placeholder={translate(LANGUAGE.password)}
                    onChange={onChange}
                    onIconClick={() => visibleFormField('viewPassword')}
                  />
                </div>
                <div className="form-input">
                  <Input
                    type={signInForm.viewConfirmPassword ? 'text' : 'password'}
                    icon
                    iconComponent={signInForm.viewConfirmPassword ? 'EyeOff' : 'Eye'}
                    name="confirmPassword"
                    validation={
                      signInForm.validConfirmPassword ? signInForm.validConfirmPassword.valid : ''
                    }
                    value={signInForm.confirmPassword}
                    placeholder={translate(LANGUAGE.confirmPassword)}
                    onChange={onChange}
                    onIconClick={() => visibleFormField('viewConfirmPassword')}
                  />
                </div>

                <Card bgColor={backgroundColor.LIGHT_COBALD} bdRadius={borderRadius.LARGE}>
                  <div className="sign-in-ed-verification-list">
                    <h6
                      className={`text-12 text-ed-gray text-ed-bold ${
                        signInForm.password ? 'pl-2' : ''
                      }`}
                    >
                      {translate(LANGUAGE.yourPasswordMust)}
                    </h6>
                    <div className="grid grid-cols-12 ">
                      {signInForm.password && (
                        <div className="col-span-1 flex-center">
                          {viewValidatePassword(inputValidation.MIN_MAX_ERROR)}
                        </div>
                      )}
                      <div className="col-span-11 flex-start-center">
                        <span className="text-12 text-ed-gray text-ed-light">
                          {translate(LANGUAGE.eightTo_20Chars)}
                        </span>
                      </div>
                      {signInForm.password && (
                        <div className="col-span-1 flex-center">
                          {viewValidatePassword(inputValidation.UPPER_CASE_ERROR)}
                        </div>
                      )}
                      <div className="col-span-11 flex-start-center">
                        <span className="text-12 text-ed-gray text-ed-light">
                          {translate(LANGUAGE.atleastOneUpperCaseLetter)}
                        </span>
                      </div>
                      {signInForm.password && (
                        <div className="col-span-1 flex-center">
                          {viewValidatePassword(inputValidation.LOWER_CASE_ERROR)}
                        </div>
                      )}
                      <div className="col-span-11 flex-start-center">
                        <span className="text-12 text-ed-gray text-ed-light">
                          {translate(LANGUAGE.atleastOneLowerCaseLetter)}
                        </span>
                      </div>
                      {signInForm.password && (
                        <div className="col-span-1 flex-center">
                          {viewValidatePassword(inputValidation.NUMBER_ERROR)}
                        </div>
                      )}
                      <div className="col-span-11 flex-start-center">
                        <span className="text-12 text-ed-gray text-ed-light">
                          {translate(LANGUAGE.atleasrOneNum)}
                        </span>
                      </div>
                      {signInForm.password && (
                        <div className="col-span-1 flex-center">
                          {viewValidatePassword(inputValidation.SPECIAL_CASE_ERROR)}
                        </div>
                      )}
                      <div className="col-span-11 flex-start-center">
                        <span className="text-12 text-ed-gray text-ed-light">
                          {translate(LANGUAGE.atleastOneSpecialChar)}
                        </span>
                      </div>
                    </div>
                  </div>
                </Card>
                <div className="sign-in-terms-condition my-3">
                  <div className="grid grid-cols-12 ">
                    <div className="col-span-1 flex-center">
                      <Checkbox
                        required
                        {...{
                          onChange,
                          name: 'termsCondition',
                          checked: signInForm.termsCondition,
                          isInvalid: signInForm.submit && !signInForm.termsCondition,
                        }}
                      />
                    </div>
                    <div className="col-span-11 flex-start-center pl-2">
                      <span className="text-14 text-ed-gray text-ed-regular">
                        {translate(LANGUAGE.agree)}{' '}
                        <Link to={getStaticPageRoute(LANGUAGE.termsConditionsStaticPage)}>
                          <strong>
                            <u>{translate(LANGUAGE.termsConditions)}</u>
                          </strong>{' '}
                        </Link>
                        {translate(LANGUAGE.mandatory)}
                      </span>
                    </div>
                    {signInForm.submit && !signInForm.termsCondition && (
                      <span className="col-span-12 pl-1 text-12 text-ed-red text-ed-regular">
                        {translate(LANGUAGE.acceptTermsAndConditions)}
                      </span>
                    )}
                    <div className="col-span-1 flex-center">
                      <Checkbox
                        required
                        {...{
                          onChange,
                          name: 'dataProtection',
                          checked: signInForm.dataProtection,
                          isInvalid: signInForm.submit && !signInForm.dataProtection,
                        }}
                      />
                    </div>
                    <div className="col-span-11 flex-start-center pl-2">
                      <span className="text-14 text-ed-gray text-ed-regular pr-1">
                        {translate(LANGUAGE.agree)}{' '}
                        <Link to={getStaticPageRoute(LANGUAGE.dataProtectionStaticPage)}>
                          <strong>
                            <u>{translate(LANGUAGE.dataProtectionStatement)}</u>
                          </strong>{' '}
                        </Link>
                        {translate(LANGUAGE.mandatory)}
                      </span>
                    </div>
                    {signInForm.submit && !signInForm.dataProtection && (
                      <span className="col-span-12 pl-1 flex-start-center text-12 text-ed-red text-ed-regular">
                        {translate(LANGUAGE.pleaseAgreeDataProtection)}
                      </span>
                    )}
                    <div className="col-span-1 flex-center">
                      <Checkbox
                        {...{
                          onChange,
                          name: 'termsPersonalAds',
                          checked: signInForm.termsPersonalAds,
                        }}
                      />
                    </div>
                    <div className="col-span-11 flex-start-center pl-2">
                      <span className="text-14 text-ed-gray text-ed-regular">
                        {translate(LANGUAGE.agree)}{' '}
                        <Link to={getStaticPageRoute(LANGUAGE.personalAddsStaticPage)}>
                          <strong>
                            <u>{translate(LANGUAGE.personalizedAdvertizement)}</u>
                          </strong>
                        </Link>
                      </span>
                    </div>
                    <div className="col-span-1 flex-center">
                      <Checkbox
                        {...{
                          onChange,
                          name: 'subscribNewsLetter',
                          checked: signInForm.subscribNewsLetter,
                        }}
                      />
                    </div>
                    <div className="col-span-11 flex-start-center pl-2">
                      <span className="text-14 text-ed-gray text-ed-regular">
                        {translate(LANGUAGE.subscribeNewsLetter)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="sign-in-submit flex-center">
                  <Button
                    className="relative bg-ed-red font-bold text-white text-24 px-10 py-3 mr-6 flex-center "
                    label={translate(LANGUAGE.signUp)}
                    look={buttonLooks.PILL}
                    onClick={onSubmitClick}
                  />
                </div>
                <div className="sign-in-submit flex-center pt-5">
                  <span className="text-18 text-ed-gray text-ed-regular">
                    {translate(LANGUAGE.needHelpWith)}{' '}
                    <Link to={getStaticPageRoute(LANGUAGE.helpWithSignupStaticPage)}>
                      <strong>
                        <u>{translate(LANGUAGE.helpWithSignupQ)}</u>
                      </strong>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="block sm:hidden">
          {signInForm.errorMessage && (
            <div
              className={`py-5 px-1 bg-ed-red text-ed-white relative flex-center -mb-10 ${borderRadius.DEFAULT} ${borderRadius.REMOVE_BOTTOM}`}
            >
              <div className="absolute left-0 px-4">
                <SVG.CircleExclamation />
              </div>
              <div className="w-full flex-1 flex-center">
                <span className="text-18 text-ed-white text-ed-regular">
                  {translate(LANGUAGE.pleaseCheckYourInput)}
                </span>
              </div>
            </div>
          )}
          <div className="sign-in-ed-card h-fit-content">
            <h1 className="text-ed-gray text-24 text-ed-bold w-full text-center">
              {translate(LANGUAGE.completeToSignIn)}
            </h1>
            <div className="sign-in-ed-card-content w-full sm:py-5">
              <div className="form-input">
                <Input
                  type="email"
                  placeholder={translate(LANGUAGE.email)}
                  name="email"
                  value={signInForm.email}
                  validation={signInForm.validEmail ? signInForm.validEmail : ''}
                  onChange={onChange}
                />
              </div>
              <div className="form-input">
                <Input
                  type={signInForm.viewPassword ? 'text' : 'password'}
                  icon
                  iconComponent={signInForm.viewPassword ? 'EyeOff' : 'Eye'}
                  name="password"
                  validation={signInForm.validPassword ? signInForm.validPassword.valid : ''}
                  value={signInForm.password}
                  placeholder={translate(LANGUAGE.password)}
                  onChange={onChange}
                  onIconClick={() => visibleFormField('viewPassword')}
                />
              </div>
              <div className="form-input">
                <Input
                  type={signInForm.viewConfirmPassword ? 'text' : 'password'}
                  icon
                  iconComponent={signInForm.viewConfirmPassword ? 'EyeOff' : 'Eye'}
                  name="confirmPassword"
                  validation={
                    signInForm.validConfirmPassword ? signInForm.validConfirmPassword.valid : ''
                  }
                  value={signInForm.confirmPassword}
                  placeholder={translate(LANGUAGE.confirmPassword)}
                  onChange={onChange}
                  onIconClick={() => visibleFormField('viewConfirmPassword')}
                />
              </div>

              <Card bgColor={backgroundColor.LIGHT_COBALD} bdRadius={borderRadius.LARGE}>
                <div className="sign-in-ed-verification-list">
                  <h6
                    className={`text-12 text-ed-gray text-ed-bold ${
                      signInForm.password ? 'pl-2' : ''
                    }`}
                  >
                    {translate(LANGUAGE.yourPasswordMust)}
                  </h6>
                  <div className="grid grid-cols-12 ">
                    {signInForm.password && (
                      <div className="col-span-1 flex-center">
                        {viewValidatePassword(inputValidation.MIN_MAX_ERROR)}
                      </div>
                    )}
                    <div className="col-span-11 flex-start-center">
                      <span className="text-12 text-ed-gray text-ed-light">
                        {translate(LANGUAGE.eightTo_20Chars)}
                      </span>
                    </div>
                    {signInForm.password && (
                      <div className="col-span-1 flex-center">
                        {viewValidatePassword(inputValidation.UPPER_CASE_ERROR)}
                      </div>
                    )}
                    <div className="col-span-11 flex-start-center">
                      <span className="text-12 text-ed-gray text-ed-light">
                        {translate(LANGUAGE.atleastOneUpperCaseLetter)}
                      </span>
                    </div>
                    {signInForm.password && (
                      <div className="col-span-1 flex-center">
                        {viewValidatePassword(inputValidation.LOWER_CASE_ERROR)}
                      </div>
                    )}
                    <div className="col-span-11 flex-start-center">
                      <span className="text-12 text-ed-gray text-ed-light">
                        {translate(LANGUAGE.atleastOneLowerCaseLetter)}
                      </span>
                    </div>
                    {signInForm.password && (
                      <div className="col-span-1 flex-center">
                        {viewValidatePassword(inputValidation.NUMBER_ERROR)}
                      </div>
                    )}
                    <div className="col-span-11 flex-start-center">
                      <span className="text-12 text-ed-gray text-ed-light">
                        {translate(LANGUAGE.atleasrOneNum)}
                      </span>
                    </div>
                    {signInForm.password && (
                      <div className="col-span-1 flex-center">
                        {viewValidatePassword(inputValidation.SPECIAL_CASE_ERROR)}
                      </div>
                    )}
                    <div className="col-span-11 flex-start-center">
                      <span className="text-12 text-ed-gray text-ed-light">
                        {translate(LANGUAGE.atleastOneSpecialChar)}
                      </span>
                    </div>
                  </div>
                </div>
              </Card>
              <div className="sign-in-terms-condition my-3">
                <div className="grid grid-cols-12 ">
                  <div className="col-span-1 flex-center">
                    <Checkbox
                      required
                      {...{
                        onChange,
                        name: 'termsCondition',
                        checked: signInForm.termsCondition,
                        isInvalid: signInForm.submit && !signInForm.termsCondition,
                      }}
                    />
                  </div>
                  <div className="col-span-11 flex-start-center pl-2">
                    <span className="text-14 text-ed-gray text-ed-regular">
                      {translate(LANGUAGE.agree)}{' '}
                      <Link to={getStaticPageRoute(LANGUAGE.termsConditionsStaticPage)}>
                        <strong>
                          <u>{translate(LANGUAGE.termsConditions)}</u>
                        </strong>{' '}
                      </Link>
                      {translate(LANGUAGE.mandatory)}
                    </span>
                  </div>
                  {signInForm.submit && !signInForm.termsCondition && (
                    <span className="col-span-12 pl-1 text-12 text-ed-red text-ed-regular">
                      {translate(LANGUAGE.acceptTermsAndConditions)}
                    </span>
                  )}
                  <div className="col-span-1 flex-center">
                    <Checkbox
                      required
                      {...{
                        onChange,
                        name: 'dataProtection',
                        checked: signInForm.dataProtection,
                        isInvalid: signInForm.submit && !signInForm.dataProtection,
                      }}
                    />
                  </div>
                  <div className="col-span-11 flex-start-center pl-2">
                    <span className="text-14 text-ed-gray text-ed-regular pr-1">
                      {translate(LANGUAGE.agree)}{' '}
                      <Link to={getStaticPageRoute(LANGUAGE.dataProtectionStaticPage)}>
                        <strong>
                          <u>{translate(LANGUAGE.dataProtectionStatement)}</u>
                        </strong>{' '}
                      </Link>
                      {translate(LANGUAGE.mandatory)}
                    </span>
                  </div>
                  {signInForm.submit && !signInForm.dataProtection && (
                    <span className="col-span-12 pl-1 flex-start-center text-12 text-ed-red text-ed-regular">
                      {translate(LANGUAGE.pleaseAgreeDataProtection)}
                    </span>
                  )}
                  <div className="col-span-1 flex-center">
                    <Checkbox
                      {...{
                        onChange,
                        name: 'termsPersonalAds',
                        checked: signInForm.termsPersonalAds,
                      }}
                    />
                  </div>
                  <div className="col-span-11 flex-start-center pl-2">
                    <span className="text-14 text-ed-gray text-ed-regular">
                      {translate(LANGUAGE.agree)}{' '}
                      <Link to={getStaticPageRoute(LANGUAGE.personalAddsStaticPage)}>
                        <strong>
                          <u>{translate(LANGUAGE.personalizedAdvertizement)}</u>
                        </strong>
                      </Link>
                    </span>
                  </div>
                  <div className="col-span-1 flex-center">
                    <Checkbox
                      {...{
                        onChange,
                        name: 'subscribNewsLetter',
                        checked: signInForm.subscribNewsLetter,
                      }}
                    />
                  </div>
                  <div className="col-span-11 flex-start-center pl-2">
                    <span className="text-14 text-ed-gray text-ed-regular">
                      {translate(LANGUAGE.subscribeNewsLetter)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="sign-in-submit flex-center">
                <Button
                  className="relative bg-ed-red font-bold text-white text-24 px-10 py-3 mr-6 flex-center "
                  label={translate(LANGUAGE.signUp)}
                  look={buttonLooks.PILL}
                  onClick={onSubmitClick}
                />
              </div>
              <div className="sign-in-submit flex-center pt-5">
                <span className="text-18 text-ed-gray text-ed-regular">
                  {translate(LANGUAGE.needHelpWith)}{' '}
                  <Link to={getStaticPageRoute(LANGUAGE.helpWithSignupStaticPage)}>
                    <strong>
                      <u>{translate(LANGUAGE.helpWithSignupQ)}</u>
                    </strong>
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </AnimateLoad>
    </div>
  );
}

export default SignInForm;
