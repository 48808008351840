/**
 * All Context will be merged to child, to clear up app.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { ErrorHandler } from '@context/ErrorHandler';
import { AppConfigProvider } from '@context/AppConfig';
import { AuthProvider } from '@context/AuthProvider';
import { ApiProvider } from '@context/Api';
import { LoadingProvider } from '@context/Loading';
import { AlertProvider } from '@context/Alert';
import { ToastProvider } from '@context/Toast';
import { LanguageProvider } from '@context/Language';
import { LayoutProvider } from '@context/Layout';

function ContextManager({ children }) {
  return (
    <>
      {/* Global App Loader */}
      <LoadingProvider>
        {/* Global Alert Provider */}
        <AlertProvider>
          {/* Global Alert Provider */}
          <ToastProvider>
            {/* Global Error Handel */}
            <ErrorHandler>
              {/* Global App Condifguration */}
              <AppConfigProvider>
                {/* Global App Language Provider */}
                <LanguageProvider>
                  {/* Global App Authenticator */}
                  <AuthProvider>
                    {/* App Layout Configuration */}
                    <LayoutProvider>
                      {/* Global App Api Configuration */}
                      <ApiProvider>
                        {/* App Pages Providers */}
                        {children}
                      </ApiProvider>
                    </LayoutProvider>
                  </AuthProvider>
                </LanguageProvider>
              </AppConfigProvider>
            </ErrorHandler>
          </ToastProvider>
        </AlertProvider>
      </LoadingProvider>
    </>
  );
}

ContextManager.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContextManager;
