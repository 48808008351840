/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import { useLayout } from '@context/Layout';
import { useLanguage } from '@context/Language';
import { navigateTo } from '@utils/Url';
import { LANGUAGE } from '@utils/Constant/language';
import { getIcon } from '@utils/SVG';
import { appIcons } from '@utils/Constant';

import AppHeader, { AppCtaButton } from './Header';
import AppFooter from './Footer';

function AppLayout({ children }) {
  const { isSM } = useLayout();

  const { getLinkUrl } = useLanguage();

  const navigateToPlayStore = () => navigateTo(getLinkUrl(LANGUAGE.appPlayStoreLink));
  const navigateToAppStore = () => navigateTo(getLinkUrl(LANGUAGE.appAppStoreLink));

  return (
    <div className="app-ed-layout h-screen w-screen overflow-y-auto  overflow-x-hidden relative">
      <div className="circle" />
      <AppHeader />
      <main className="app-ed-content overflow-y-auto overflow-x-hidden relative z">
        {isSM && (
          <div className="app-ed-cta-mobile-page flex-row-center">
            <button
              type="button"
              className="card flex-center bg-ed-white p-2"
              onClick={() => navigateToPlayStore()}
            >
              {getIcon({ iconComponent: appIcons.PLAYSTORE, svgProps: { width: 30, height: 30 } })}
            </button>
            <button
              type="button"
              className="card flex-center bg-ed-white p-2"
              onClick={() => navigateToAppStore()}
            >
              {getIcon({ iconComponent: appIcons.APPLE, svgProps: { width: 30, height: 30 } })}
            </button>
          </div>
        )}
        {children}
        <div className="content-false" />
        <AppCtaButton />
      </main>
      <AppFooter />
    </div>
  );
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

// HOC
export const withLayout = (Component) => (props) => (
  <AppLayout>
    {/* All props are passed through to the Component being wrapped */}
    <Component {...props} />
  </AppLayout>
);

export default AppLayout;
