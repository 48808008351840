import { useLanguage } from '@context/Language';
import { useMyAccount } from '@context/MyAccount';
import Switch from '@core/Switch';
import { withAnimate } from '@part/AnimateLoad';
import { switchToggleSize } from '@utils/Constant';
import { LANGUAGE } from '@utils/Constant/language';
import React from 'react';

function Notificationsetting() {
  const { pushNotificationForm, onChangePushNotification } = useMyAccount();
  const { translate } = useLanguage();
  return (
    <div className="space-y-4">
      <div className="block border-dotted border-b-4">
        <div className="grid grid-cols-12">
          <div className="col-span-10">
            <h3 className="text-ed-bold text-18 text-ed-gray">{translate(LANGUAGE.newsLetter)}</h3>
            <p className="text-ed-light text-14 text-ed-gray pb-4">
              {translate(LANGUAGE.newsLetterMsg)}
            </p>
          </div>
          <div className="col-span-2 flex-center-end">
            <Switch
              name="newsLetterOpted"
              onChange={onChangePushNotification}
              checked={pushNotificationForm.newsLetterOpted}
              switchSize={switchToggleSize.LG}
            />
          </div>
        </div>
      </div>
      <div className="block border-dotted border-b-4">
        <div className="grid grid-cols-12">
          <div className="col-span-10">
            <h3 className="text-ed-bold text-18 text-ed-gray">
              {translate(LANGUAGE.personalizedAds)}
            </h3>
            <p className="text-ed-light text-14 text-ed-gray pb-4">
              {translate(LANGUAGE.personalizedAdsMsg)}
            </p>
          </div>
          <div className="col-span-2 flex-center-end">
            <Switch
              name="advOpted"
              onChange={onChangePushNotification}
              checked={pushNotificationForm.advOpted}
              switchSize={switchToggleSize.LG}
            />
          </div>
        </div>
      </div>
      <div className="block border-dotted border-b-4">
        <div className="grid grid-cols-12">
          <div className="col-span-10">
            <h3 className="text-ed-bold text-18 text-ed-gray">
              {translate(LANGUAGE.pushNotifications)}
            </h3>
            <p className="text-ed-light text-14 text-ed-gray pb-4">
              {translate(LANGUAGE.pushNotificationsMsg)}
            </p>
          </div>
          <div className="col-span-2 flex-center-end">
            <Switch
              name="pushNotificationOpted"
              onChange={onChangePushNotification}
              checked={pushNotificationForm.pushNotificationOpted}
              switchSize={switchToggleSize.LG}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAnimate(Notificationsetting);
