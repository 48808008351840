import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import AnimateLoad from '@part/AnimateLoad';
import { animationStyle } from '@utils/Constant';

function Loading({ isLoading }) {
  if (!isLoading) return <></>;

  return (
    <div className="fixed z-50 h-screen w-screen flex-center bg-ed-cold-white bg-opacity-75">
      <AnimateLoad style={animationStyle.FADE_IN}>
        <div className="fading-circle bg-ed-red rounded-full flex-center opacity-90">
          <svg
            className="fading-circle bg-ed-red rounded-full flex-center"
            width="100px"
            height="100px"
          >
            <g>
              <circle cx="77.767" cy="71.307" fill="#ffffff" r="6">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  calcMode="spline"
                  values="0 50 50;360 50 50"
                  times="0;1"
                  keySplines="0.75 0 0.75 1"
                  repeatCount="indefinite"
                  dur="1.5s"
                  begin="0s"
                />
              </circle>
              <circle cx="71.307" cy="77.767" fill="#ffffff" r="6">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  calcMode="spline"
                  values="0 50 50;360 50 50"
                  times="0;1"
                  keySplines="0.75 0 0.75 1"
                  repeatCount="indefinite"
                  dur="1.5s"
                  begin="-0.062s"
                />
              </circle>
              <circle cx="63.394" cy="82.336" fill="#ffffff" r="6">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  calcMode="spline"
                  values="0 50 50;360 50 50"
                  times="0;1"
                  keySplines="0.75 0 0.75 1"
                  repeatCount="indefinite"
                  dur="1.5s"
                  begin="-0.125s"
                />
              </circle>
              <circle cx="54.568" cy="84.701" fill="#ffffff" r="6">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  calcMode="spline"
                  values="0 50 50;360 50 50"
                  times="0;1"
                  keySplines="0.75 0 0.75 1"
                  repeatCount="indefinite"
                  dur="1.5s"
                  begin="-0.187s"
                />
              </circle>
              <circle cx="45.432" cy="84.701" fill="#ffffff" r="6">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  calcMode="spline"
                  values="0 50 50;360 50 50"
                  times="0;1"
                  keySplines="0.75 0 0.75 1"
                  repeatCount="indefinite"
                  dur="1.5s"
                  begin="-0.25s"
                />
              </circle>
              <circle cx="36.606" cy="82.336" fill="#ffffff" r="6">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  calcMode="spline"
                  values="0 50 50;360 50 50"
                  times="0;1"
                  keySplines="0.75 0 0.75 1"
                  repeatCount="indefinite"
                  dur="1.5s"
                  begin="-0.312s"
                />
              </circle>
              <circle cx="28.693" cy="77.767" fill="#ffffff" r="6">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  calcMode="spline"
                  values="0 50 50;360 50 50"
                  times="0;1"
                  keySplines="0.75 0 0.75 1"
                  repeatCount="indefinite"
                  dur="1.5s"
                  begin="-0.375s"
                />
              </circle>
              <circle cx="22.233" cy="71.307" fill="#ffffff" r="6">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  calcMode="spline"
                  values="0 50 50;360 50 50"
                  times="0;1"
                  keySplines="0.75 0 0.75 1"
                  repeatCount="indefinite"
                  dur="1.5s"
                  begin="-0.437s"
                />
              </circle>
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="spline"
                values="0 50 50;0 50 50"
                times="0;1"
                keySplines="0.75 0 0.75 1"
                repeatCount="indefinite"
                dur="1.5s"
              />
            </g>
          </svg>
        </div>
      </AnimateLoad>
    </div>
  );
}

Loading.propTypes = {
  isLoading: PropTypes.bool,
};

Loading.defaultProps = {
  isLoading: false,
};

export default Loading;
