// Default app landing page.
import React from 'react';

import Button from '@core/Button';
import Input from '@core/Input';

import './style.scss';
import AnimateLoad from '@part/AnimateLoad';
import { animationStyle, backgroundColor, borderRadius, buttonLooks } from '@utils/Constant';
import { useForgotPassword } from '@context/ForgotPassword';
import Card from '@core/Card';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';

function ForgotPasswordForm() {
  const { forgotPasswordForm, onChange, onSubmitClick } = useForgotPassword();
  const { translate } = useLanguage();

  return (
    <div className="forgot-password-ed my-24">
      <AnimateLoad style={animationStyle.FADE_IN}>
        <div className="hidden sm:block">
          <Card bgColor={backgroundColor.WHITE} bdRadius={borderRadius.DEFAULT} bgShadow>
            <div className="forgot-password-ed-card h-fit-content flex-center flex-col">
              <div className="forgot-password-ed-card-content w-full py-2">
                <h1 className="text-ed-gray text-24 text-ed-bold w-full text-center">
                  {translate(LANGUAGE.requestNewPassword)}
                </h1>
                <h2 className="text-18 text-ed-gray text-ed-light w-full text-center px-10">
                  {translate(LANGUAGE.enterEmailAddressUsedToLogin)}
                </h2>
              </div>
              <div className="forgot-password-ed-card-content w-full pt-5">
                <div className="form-input">
                  <Input
                    type="email"
                    placeholder={translate(LANGUAGE.email)}
                    name="email"
                    value={forgotPasswordForm.email}
                    validation={forgotPasswordForm.validEmail ? forgotPasswordForm.validEmail : ''}
                    onChange={onChange}
                  />
                </div>
                <div className="forgot-submit flex-center py-3">
                  <Button
                    className="relative bg-ed-red font-bold text-white text-24 px-16 py-3 flex-center "
                    label="Absenden"
                    look={buttonLooks.PILL}
                    onClick={onSubmitClick}
                  />
                </div>
                <div className="forgot-submit flex-center pt-2">
                  <span className="text-14 text-center text-ed-gray text-ed-regular">
                    Nach Prüfung Ihres E-Mail-Postfach bitte zusätzlich den{' '}
                    <strong>
                      <u>SPAM-Ordner</u>
                    </strong>{' '}
                    prüfen! In Ausnahmefällen kann es bis zu 24 Stunden dauern, bis die
                    Bestätigungsmail in Ihrem Postfach eingeht
                  </span>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="block sm:hidden">
          <div className="forgot-password-ed-card h-fit-content flex-center flex-col">
            <div className="forgot-password-ed-card-content w-full py-2">
              <h1 className="text-ed-gray text-24 text-ed-bold w-full text-center">
                {translate(LANGUAGE.requestNewPassword)}
              </h1>
              <h2 className="text-18 text-ed-gray text-ed-light w-full text-center">
                {translate(LANGUAGE.enterEmailAddressUsedToLogin)}
              </h2>
            </div>
            <div className="forgot-password-ed-card-content w-full pt-5">
              <div className="form-input">
                <Input
                  type="email"
                  placeholder={translate(LANGUAGE.email)}
                  name="email"
                  value={forgotPasswordForm.email}
                  validation={forgotPasswordForm.validEmail ? forgotPasswordForm.validEmail : ''}
                  onChange={onChange}
                />
              </div>
              <div className="forgot-submit flex-center py-3">
                <Button
                  className="relative bg-ed-red font-bold text-white text-24 px-14 py-2 flex-center "
                  label="Absenden"
                  look={buttonLooks.PILL}
                  onClick={onSubmitClick}
                />
              </div>
              <div className="forgot-submit flex-center pt-2">
                <span className="text-14 text-center text-ed-gray text-ed-regular">
                  Nach Prüfung Ihres E-Mail-Postfach bitte zusätzlich den{' '}
                  <strong>
                    <u>SPAM-Ordner</u>
                  </strong>{' '}
                  prüfen! In Ausnahmefällen kann es bis zu 24 Stunden dauern, bis die
                  Bestätigungsmail in Ihrem Postfach eingeht
                </span>
              </div>
            </div>
          </div>
        </div>
      </AnimateLoad>
    </div>
  );
}

export default ForgotPasswordForm;
