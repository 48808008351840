// Storage Manager

class Storage {
  get(key) {
    if (this.isLocalStorageSupported) {
      try {
        return JSON.parse((localStorage || window.localStorage).getItem(key));
      } catch (error) {
        return false;
      }
    }
    return null;
  }

  set(key, value) {
    if (this.isLocalStorageSupported) {
      (localStorage || window.localStorage).setItem(key, JSON.stringify(value));
      return true;
    }
    return false;
  }

  remove(key) {
    if (this.isLocalStorageSupported) {
      (localStorage || window.localStorage).removeItem(key);
      return true;
    }
    return false;
  }

  clear() {
    if (this.isLocalStorageSupported) {
      (localStorage || window.localStorage).clear();
      return true;
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  get isLocalStorageSupported() {
    return !!(localStorage || window.localStorage);
  }
}

export default new Storage();
