/* eslint-disable no-console */
import { createContext, useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import { useLoading } from '@context/Loading';
import { HTTP_REQUEST_METHODS } from '@utils/ApiCodex';

// A context to load all app configuration from server
const AppConfigContext = createContext();

// The top level component that will wrap our app's core features
export const AppConfigProvider = ({ children }) => {
  const [env, setEnv] = useState(process.env || {});
  const [isEnvLoading, setEnvLoading] = useState(true); // Set loading true if as it landed

  const { showLoader, hideLoader } = useLoading();

  const [footerLinks, setFooterLinks] = useState([]);

  // const loadRecaptcha = (key) => {
  //   const loadScriptByURL = (id, url, callback) => {
  //     const isScriptExist = document.getElementById(id);

  //     if (!isScriptExist) {
  //       // eslint-disable-next-line prefer-const
  //       let script = document.createElement('script');
  //       script.type = 'text/javascript';
  //       script.src = url;
  //       script.id = id;
  //       script.onload = () => {
  //         if (callback) callback();
  //       };
  //       document.body.appendChild(script);
  //     }

  //     if (isScriptExist && callback) callback();
  //   };

  //   // load the script by passing the URL
  //   loadScriptByURL(
  //     'recaptcha-key',
  //     `https://www.google.com/recaptcha/api.js?render=${key || env.REACT_APP_RECAPTCHA_SITE_KEY}`,
  //     () => {
  //       console.log('Script loaded!');
  //     },
  //   );
  // };

  // TODO: Implement app configuration loading from server.
  // Make sure this calls before anything is loaded
  // when the app loads. If we didn't do that, then the api
  // would be "trapped" into error.
  useEffect(() => {
    showLoader();
    const loadAppConfig = async () => {
      try {
        setEnvLoading(true);
        const url = `${env.REACT_APP_SERVER}app-config`;
        const appEnvConfig = await axios({ url, method: HTTP_REQUEST_METHODS.GET });
        setEnv({ ...env, ...appEnvConfig.data });
        setEnvLoading(false);
        hideLoader();
      } catch (error) {
        console.log(error);
      }
    };
    loadAppConfig();
    //     // Listen for changes to the current location.
    //     const unlisten = history.listen(() => setEnv(undefined));
    //     // cleanup the listener on unmount
    //     return unlisten;
  }, []);

  // Disabled reCAPTCHA for loading unwanted fonts
  // https://rm3.dom.de/issues/41608
  // No response to disable fonts loading configuration
  // https://github.com/google/recaptcha/issues/377
  // useEffect(() => {
  //   if (env && !isEnvLoading) {
  //     loadRecaptcha(env.REACT_APP_RECAPTCHA_SITE_KEY);
  //   }
  // }, [env, isEnvLoading]);

  // Render the children as normal
  // TODO: Implemente loading until authentication is verified.
  const renderContent = () => (isEnvLoading ? <>Loading...</> : children);

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(
    () => ({ env, isEnvLoading, footerLinks, setFooterLinks }),
    [env, isEnvLoading, footerLinks, setFooterLinks],
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <AppConfigContext.Provider value={contextPayload}>{renderContent()}</AppConfigContext.Provider>
  );
};

AppConfigProvider.propTypes = {
  children: PropTypes.node,
};

AppConfigProvider.defaultProps = {
  children: null,
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useAppConfig = () => useContext(AppConfigContext);
