import React from 'react';
import Card from '@core/Card';
import Image from '@core/Image';
import PropTypes from 'prop-types';

import './style.scss';

function PartnerViewCard({ name, categoryName, logo, imgStyle }) {
  return (
    <Card bgShadow>
      <div className="partner-block-card w-50 flex-col-center h-full card-content">
        <Image
          {...{
            className: 'mx-auto py-2 sm:py-3',
            src: logo,
            alt: name,
            style: imgStyle,
          }}
        />
        <div className="text-center">
          <h1 className="text-14 text-ed-bold text-ed-gray py-2 leading-none">{name}</h1>
          <p className="text-14 text-ed-light text-ed-gray leading-none">{categoryName}</p>
        </div>
      </div>
    </Card>
  );
}

PartnerViewCard.propTypes = {
  imgStyle: PropTypes.shape({}),
  name: PropTypes.string,
  categoryName: PropTypes.string,
  logo: PropTypes.string,
};

PartnerViewCard.defaultProps = {
  imgStyle: {},
  name: '',
  categoryName: '',
  logo: '',
};

export default PartnerViewCard;
