// Default app landing page.
import React from 'react';

import Button from '@core/Button';
// import Input from '@core/Input';

import './style.scss';
import AnimateLoad from '@part/AnimateLoad';
import { animationStyle, appDefaultImages, appRoute, buttonLooks } from '@utils/Constant';
import { useLogin } from '@context/Login';
import { Link } from 'react-router-dom';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';

function LogInForm() {
  const { onLoginClick } = useLogin();
  const { translate, getStaticPageRoute } = useLanguage();

  return (
    <div className="log-in-ed">
      <AnimateLoad style={animationStyle.FADE_IN}>
        <div className="log-in-ed-content h-fit-content">
          <div className="log-in-ed-img-container py-5 -ml-1">
            <img src={appDefaultImages.ONBOARD_PAGE_2X} alt="Onboard" />
          </div>
          <h1 className="text-ed-gray text-24 text-ed-bold w-full text-center">
            {translate(LANGUAGE.login)}
          </h1>
          <h2 className="text-18 text-ed-gray text-ed-light w-full text-center">
            {translate(LANGUAGE.pleaseLogInToContinue)}
          </h2>
          <div className="log-in-ed-form-content w-full p-5">
            {/* <div className="form-input">
              <Input
                type="email"
                placeholder="Email"
                name="email"
                value={logInForm.email}
                validation={logInForm.validEmail ? logInForm.validEmail : ''}
                onChange={onChange}
              />
            </div>
            <div className="form-input">
              <Input
                type={logInForm.viewPassword ? 'text' : 'password'}
                icon
                iconComponent={logInForm.viewPassword ? 'EyeOff' : 'Eye'}
                name="password"
                validation={logInForm.validPassword ? logInForm.validPassword.valid : ''}
                value={logInForm.password}
                placeholder="Password"
                onChange={onChange}
                onIconClick={() => visibleFormField('viewPassword')}
              />
            </div> */}
            <div className="log-in-submit flex-center py-3">
              <Button
                className="relative bg-ed-red font-bold text-white text-24 px-16 py-3 flex-center "
                label={translate(LANGUAGE.login)}
                look={buttonLooks.PILL}
                onClick={onLoginClick}
              />
            </div>
            <div className="log-in-submit flex-center pt-2">
              <span className="text-18 text-ed-gray text-ed-regular">
                {/* {translate(LANGUAGE.forgot)}{' '} */}
                <Link to={appRoute.FORGOT_PASSWORD}>
                  <strong>
                    <u>{translate(LANGUAGE.passwordQ)}</u>
                  </strong>
                </Link>
              </span>
            </div>
            <div className="log-in-submit flex-center pt-3">
              <span className="text-18 text-ed-gray text-ed-light">
                {translate(LANGUAGE.needHelpWith)}{' '}
                <Link
                  to={getStaticPageRoute(LANGUAGE.helpWithLoginStaticPage)}
                  className="text-18 text-ed-gray text-ed-regular"
                >
                  <strong>
                    <u>{translate(LANGUAGE.helpWithLoginQ)}</u>
                  </strong>
                </Link>
              </span>
            </div>
          </div>
        </div>
      </AnimateLoad>
    </div>
  );
}

export default LogInForm;
