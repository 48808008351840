import { useSignIn } from '@context/SignIn';
import { appRoute } from '@utils/Constant';
import { appUrlQueryParams } from '@utils/Url';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function VerifyEmail() {
  const { confirmEmail } = useSignIn();

  // https://voucher-portal-web.d.dom.de/email_confirm?assoc=676dfc53f53a4484b0046ece302e554714acfab7b04342d6bb5e0b455b44e6c5&email=bmFzaXJ1ZGRpbi5zYWl5ZWRAaGlkZGVuYnJhaW5zLmlu
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const args = new URLSearchParams(location.search);
    // const url = args.get(appUrlQueryParams.ONBOARDING_CALLBACK.URL);
    const assoc = args.get(appUrlQueryParams.ONBOARDING_CALLBACK.ASSOC);
    const email = args.get(appUrlQueryParams.ONBOARDING_CALLBACK.E_MAIL);
    if (assoc) {
      confirmEmail(assoc, email);
    } else {
      navigate(appRoute.LANDING);
    }
  }, []);

  // return <div>Loading ....</div>;
  return (
    <div className="bg-ed-bubbles-2 flex-center bg-ed-cold-white">
      <div className="app-ed-page ">
        <div className="app-ed-page-content w-full py-16">Loading...</div>
      </div>
    </div>
  );
}
