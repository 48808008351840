import { createContext, useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  useLocation,
  // useHistory
} from 'react-router-dom';

import {
  appAlert,
  appAudit,
  appDefaultErrorCode,
  appLogs,
  appStorage,
  defaultMyAccountNavList,
  defaultMyAccountView,
  inputValidation,
} from '@utils/Constant';
import {
  keysToCamel,
  validateBooleanToString,
  validateEmail,
  validatePassword,
  validateStringLength,
  validateStringToBoolean,
} from '@utils/Validation';
import MyAccountService from '@service/MyAccount';
import { useAppConfig } from '@context/AppConfig';
import { useAuth } from '@context/AuthProvider';
import { useLoading } from '@context/Loading';
import { useAlert } from '@context/Alert';
import { useErrorHandler } from '@context/ErrorHandler';
import { StatusCodes } from '@utils/ApiCodex';
import { LANGUAGE } from '@utils/Constant/language';
import { useLanguage } from '@context/Language';
import Storage from '@utils/Storage';
import OnboardService from '@service/Onboard';

// A context to load all app configuration from server
const MyAccountContext = createContext();

// The top level component that will wrap our app's core features
export function MyAccountProvider({ children }) {
  const [myAccount, setMyAccount] = useState({});
  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const [isPasswordChange, setIsPasswordChange] = useState(false);

  const [myAccountNav, setMyAccountNav] = useState(defaultMyAccountNavList);
  const [myAccountView, setMyAccountView] = useState(defaultMyAccountView.PERSONAL_INFO);
  const { env } = useAppConfig();
  const { isUserLoggedIn, isInitialLogin, setIsInitialLogin } = useAuth();
  const { showLoader, hideLoader } = useLoading();
  const { showAlert } = useAlert();
  const { setErrorStatusCode } = useErrorHandler();
  const { translate } = useLanguage();
  const Location = useLocation();

  // const history = useHistory();

  // Sign in form state.
  const defaultPersonalForm = {
    salutation: '',
    firstname: '',
    lastname: '',
    employeeEmail: '',
  };
  const personalFormValidationSchema = Yup.object().shape({
    salutation: Yup.string().required('Pflichtfeld'),
    // firstname: Yup.string()
    //   .required('Pflichtfeld')
    //   .matches(/^[a-zA-Z(Ö)(Ä)(Ü)(ü)(ö)(ä)(ß)\s\x2D]{1,20}$/, 'Der Vorname ist ungültig'),
    // lastname: Yup.string()
    //   .required('Pflichtfeld')
    //   .matches(/^[a-zA-Z(Ö)(Ä)(Ü)(ü)(ö)(ä)(ß)\s\x2D]{1,20}$/, 'Der Nachname ist ungültig'),
    // employeeEmail: Yup.string().required('Pflichtfeld').email('Die E-Mail ist ungültig'),
    firstname: Yup.string()
      .required('Pflichtfeld')
      .matches(/^[a-zA-ZÄäÖöÜüß0-9 '-]{1,20}$/, 'Der Vorname ist ungültig'),
    lastname: Yup.string()
      .required('Pflichtfeld')
      .matches(/^[a-zA-ZÄäÖöÜüß0-9 '-]{1,20}$/, 'Der Nachname ist ungültig'),
    employeeEmail: Yup.string()
      .required('Pflichtfeld')
      .matches(/^([a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3})$/, 'Die E-Mail ist ungültig'),
  });
  const [personalInfoForm, setPersonalInfoForm] = useState(defaultPersonalForm);
  const [userPersonalInfo, setUserPersonalInfo] = useState({});
  const [userInfoFromAPI, setUserInfoFromAPI] = useState({});

  const defaultPasswordForm = {
    oldPassword: '',
    password: '',
    confirmPassword: '',
  };

  const passwordValidationSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Pflichtfeld'),
    password: Yup.string()
      .required('Pflichtfeld')
      .matches(
        /^(?=.*[A-ZÄÖÜ])(?=.*[a-zäöüß])(?=.*\d)(?=.*[!@#$%&()])[A-Za-zÄÖÜäöüß\d!@#$%&()]{8,20}$/,
        // /^(?=.*[0-9])(?=.*[!"$%&#§ÄÜÖßäöü])[a-zA-Z0-9|!"$%&#§ÄÜÖßäöü]{12,20}$/,
        'Das eingegebene Passwort entspricht keinem oder mehreren der folgenden Kriterien',
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwort stimmt nicht überein')
      .required('Pflichtfeld'),
  });
  // Regex for password validation
  // A length of 8 to 20 characters
  // At least one capital letter
  // At least one lowercase letter
  // At least one number
  // At least one of these special characters: !@#$%&()

  const [passwordForm, setPasswordForm] = useState(defaultPasswordForm);

  const defaultPushNotificationForm = {
    pushNotificationOpted: false,
    advOpted: false,
    newsLetterOpted: false,
  };
  const [pushNotificationForm, setPushNotificationForm] = useState(defaultPushNotificationForm);

  const checkNav = (location) => {
    const loc = location || Location;
    if (loc && loc.pathname) {
      const activateRoute = defaultMyAccountNavList.find((nav) => nav.to === loc.pathname);
      if (activateRoute) {
        setMyAccountView(defaultMyAccountView[activateRoute.view]);
        // Reset form to its initial values
        if (activateRoute.view === 'PERSONAL_INFO' && userPersonalInfo) {
          setPersonalInfoForm({ ...defaultPersonalForm, ...personalInfoForm, ...userPersonalInfo });
        }
        // Reset form to its initial values
        if (activateRoute.view === 'PASSWORD_RESET') {
          setPasswordForm({ ...passwordForm, ...defaultPasswordForm });
        }
      }
    }
  };

  // Render the children as normal
  // TODO: Implemente loading if required.
  const renderContent = () => children;

  const onChangePersonal = ({ target }) => {
    if (target && target.name) {
      switch (target.name) {
        case 'employeeEmail':
          // console.log({ ...personalInfoForm, employeeEmail: target.value, validEmail: validateEmail(target.value), });
          setPersonalInfoForm({
            ...personalInfoForm,
            employeeEmail: target.value,
            validEmail: validateEmail(target.value),
          });
          break;
        case 'salutation':
          setPersonalInfoForm({
            ...personalInfoForm,
            salutation: target.value,
            validSalutation: validateStringLength(target.value, 1, 20)
              ? inputValidation.VALID
              : inputValidation.INVALID, // target.value ? inputValidation.VALID : inputValidation.INVALID,
          });
          break;
        case 'firstname':
          setPersonalInfoForm({
            ...personalInfoForm,
            firstname: target.value,
            validFirstname: validateStringLength(target.value, 1, 20)
              ? inputValidation.VALID
              : inputValidation.INVALID, // target.value ? inputValidation.VALID : inputValidation.INVALID,
          });
          break;
        case 'lastname':
          setPersonalInfoForm({
            ...personalInfoForm,
            lastname: target.value,
            validLastname: validateStringLength(target.value, 1, 20)
              ? inputValidation.VALID
              : inputValidation.INVALID, // target.value ? inputValidation.VALID : inputValidation.INVALID,
          });
          break;
        default:
          setPersonalInfoForm({ ...personalInfoForm, [target.name]: target.value });
          break;
      }
    }
  };

  const onChangePassword = ({ target }) => {
    if (target.name === 'password' || target.name === 'confirmPassword') {
      setPasswordForm({
        ...passwordForm,
        [target.name]: target.value,
        [target.name === 'password' ? 'validPassword' : 'validConfirmPassword']: validatePassword(
          target.value,
          target.name,
          passwordForm.password,
        ),
        ...(passwordForm.confirmPassword && target.name === 'password'
          ? {
              validConfirmPassword: validatePassword(
                passwordForm.confirmPassword,
                'confirmPassword',
                target.value,
              ),
            }
          : {}),
      });
    } else {
      setPasswordForm({
        ...passwordForm,
        [target.name]: target.value,
        validOldPassword: validateStringLength(target.value, 8, 20)
          ? inputValidation.VALID
          : inputValidation.INVALID,
        errorMessage: '',
      });
    }
  };

  const validateAllPersonalInput = ({ employeeEmail, salutation, firstname, lastname }) => {
    const infoForm = {
      employeeEmail,
      validEmail: validateEmail(employeeEmail),
      salutation,
      validSalutation: salutation ? inputValidation.VALID : inputValidation.INVALID,
      firstname,
      validFirstname: firstname ? inputValidation.VALID : inputValidation.INVALID,
      lastname,
      validLastname: lastname ? inputValidation.VALID : inputValidation.INVALID,
    };
    setPersonalInfoForm({ ...personalInfoForm, ...infoForm });
  };

  const getUserInfo = () => {
    showLoader();
    const myAccountService = new MyAccountService(env);
    myAccountService
      .getUserInfo()
      .then((result) => {
        // console.log(result.data);
        const data = {
          ...keysToCamel(result.data),
          employeeEmail: result.data.personalEmail || result.data.employeeEmail,
        };
        // console.log({ data });
        // history.listen(checkNav);
        if (Storage.get(appStorage.FIRST_LOGIN)) {
          const onboardService = new OnboardService(env);
          onboardService.saveUserAudit({
            user_email: result.data.personalEmail || result.data.employeeEmail,
            type: appAudit.LOGIN,
          });
          Storage.set(appStorage.EMAIL, result.data.personalEmail || result.data.employeeEmail);
          Storage.remove(appStorage.FIRST_LOGIN);
        }
        setUserPersonalInfo(data);
        setUserInfoFromAPI(result.data); // Store User info for logging.

        setPushNotificationForm({
          ...personalInfoForm,
          pushNotificationOpted: validateStringToBoolean(data.pushNotificationOpted),
          advOpted: validateStringToBoolean(data.advOpted),
          newsLetterOpted: validateStringToBoolean(data.newsLetterOpted),
        });
        // setPersonalInfoForm({ ...defaultPersonalForm, ...data });
        validateAllPersonalInput(data);

        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        // showAlert({ type: appAlert.ERROR, message: err.response?.data || 'Invalid request' });
        setErrorStatusCode(err?.response?.status || err?.status || appDefaultErrorCode.CODE);
      });
  };

  // Save Audit Logs.
  const setUserLog = (logType) => {
    const myAccountService = new MyAccountService(env);
    // console.log({logType});
    if (
      appLogs.LAST_LOGIN === logType ||
      appLogs.EMAIL_CHANGE === logType ||
      appLogs.CHANGE_PASSWORD === logType
    ) {
      const data = { ...userInfoFromAPI, [logType]: Date.now() };
      myAccountService
        .saveUserInfo(data)
        .then(() => {
          // console.log(result.data);
          setIsEmailChanged(false);
          setIsPasswordChange(false);
          setIsInitialLogin(false);
        })
        .catch(() => {
          hideLoader();
          // console.log(err);
        });
    }
  };

  const onSubmitPersnonalInfo = (e) => {
    // if (e) {
    //   e.preventDefault();
    // }
    // console.log('Personal Info Submitted!', personalInfoForm, e);
    showLoader();
    setPersonalInfoForm({ ...personalInfoForm, submit: true });
    // console.log({ ...personalInfoForm, submit: true });
    if (
      personalInfoForm.validEmail === inputValidation.VALID &&
      personalInfoForm.validSalutation === inputValidation.VALID &&
      personalInfoForm.validFirstname === inputValidation.VALID &&
      personalInfoForm.validLastname === inputValidation.VALID
    ) {
      // console.log('personalFOrm====', personalInfoForm, e);
      const myAccountService = new MyAccountService(env);
      myAccountService
        .saveUserInfo({
          client_id: userPersonalInfo.clientId,
          personalEmail: e.employeeEmail,
          firstname: e.firstname,
          lastname: e.lastname,
          salutation: personalInfoForm.salutation,
        })
        .then((result) => {
          if (personalInfoForm.employeeEmail !== Storage.get(appStorage.EMAIL)) {
            Storage.set(appStorage.EMAIL, personalInfoForm.employeeEmail);
            const onboardService = new OnboardService(env);
            onboardService.saveUserAudit({
              user_email: personalInfoForm.employeeEmail,
              type: appAudit.EMAIL_CHANGE,
            });
          }
          // console.log('setPersonalInfoForm : ', result.data);
          hideLoader();
          // Check if entered email is different from existing personal email
          if (personalInfoForm.employeeEmail !== userPersonalInfo.personalEmail) {
            setUserInfoFromAPI(false);
            setIsEmailChanged(true);
          }

          if (result.status >= StatusCodes.OK && result.status <= StatusCodes.MULTI_STATUS) {
            getUserInfo();
            showAlert({
              type: appAlert.SUCCESS,
              message: translate(LANGUAGE.profileUpdatedSuccessfully),
            });
          }
        })
        .catch((err) => {
          hideLoader();
          // showAlert({ type: appAlert.ERROR, message: err.response?.data || 'Invalid request' });
          setErrorStatusCode(err.status || err.response.status || appDefaultErrorCode.CODE);
        });
    } else {
      hideLoader();
      showAlert({ type: appAlert.ERROR, message: translate(LANGUAGE.pleaseCheckYourInput) });
    }
  };

  const onSubmitPassword = (values) => {
    console.log('values---------', values);
    showLoader();
    setPasswordForm({ ...values, submit: true });
    try {
      const myAccountService = new MyAccountService(env);
      myAccountService
        .setUserPassword({
          confirmation: values.password,
          currentpassword: values.oldPassword,
          newpassword: values.confirmPassword,
        })
        .then((result) => {
          // console.log(result.data);
          hideLoader();
          if (result.status >= StatusCodes.OK && result.status <= StatusCodes.MULTI_STATUS) {
            showAlert({
              type: appAlert.SUCCESS,
              message: translate(LANGUAGE.passwordChangedSuccessfully),
            });
            setPasswordForm(defaultPasswordForm);
          }
          setIsPasswordChange(true);
        })
        .catch((err) => {
          hideLoader();
          // showAlert({ type: appAlert.ERROR, message: err.response?.data || 'Invalid request' });
          setErrorStatusCode(err?.response?.status || err?.status || appDefaultErrorCode.CODE);
        });
    } catch (error) {
      hideLoader();
      showAlert({ type: appAlert.ERROR, message: translate(LANGUAGE.checkYouInputMsg) });
    }
    // console.log({ ...passwordForm, submit: true });
    // if (
    //   passwordForm.validPassword.valid === inputValidation.VALID &&
    //   passwordForm.validConfirmPassword.valid === inputValidation.VALID
    // ) {
    // } else {
    //   hideLoader();
    //   showAlert({ type: appAlert.ERROR, message: translate(LANGUAGE.checkYouInputMsg) });
    // }
  };

  const onSubmitPushNotification = (data) => {
    showLoader();
    // console.log({ ...personalInfoForm, submit: true });
    if (data) {
      const myAccountService = new MyAccountService(env);
      myAccountService
        .saveUserInfo({ personalEmail: userPersonalInfo.employeeEmail, ...data })
        .then((result) => {
          // console.log(result.data);
          hideLoader();
          if (result.status >= StatusCodes.OK && result.status <= StatusCodes.MULTI_STATUS) {
            getUserInfo();
            showAlert({
              type: appAlert.SUCCESS,
              message: translate(LANGUAGE.profileUpdatedSuccessfully),
            });
          }
        })
        .catch((err) => {
          hideLoader();
          // showAlert({ type: appAlert.ERROR, message: err.response?.data || 'Invalid request' });
          setErrorStatusCode(err.status || err.response.status || appDefaultErrorCode.CODE);
        });
    } else {
      hideLoader();
      showAlert({ type: appAlert.ERROR, message: translate(LANGUAGE.pleaseCheckYourInput) });
    }
  };

  const onChangePushNotification = ({ target }) => {
    if (target && target.name) {
      switch (target.name) {
        case 'pushNotificationOpted':
          setPushNotificationForm({
            ...pushNotificationForm,
            pushNotificationOpted: !pushNotificationForm.pushNotificationOpted,
          });
          onSubmitPushNotification({
            push_notification_opted: validateBooleanToString(
              !pushNotificationForm.pushNotificationOpted,
            ),
          });
          break;
        case 'advOpted':
          setPushNotificationForm({
            ...pushNotificationForm,
            advOpted: !pushNotificationForm.advOpted,
          });
          onSubmitPushNotification({
            adv_opted: validateBooleanToString(!pushNotificationForm.advOpted),
          });
          break;
        case 'newsLetterOpted':
          setPushNotificationForm({
            ...pushNotificationForm,
            newsLetterOpted: !pushNotificationForm.newsLetterOpted,
          });
          onSubmitPushNotification({
            news_letter_opted: validateBooleanToString(!pushNotificationForm.newsLetterOpted),
          });
          break;
        default:
          break;
      }
    }
  };

  // Effect
  useEffect(() => {
    if (isUserLoggedIn) getUserInfo();
    checkNav();
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (
      isUserLoggedIn &&
      isInitialLogin &&
      userInfoFromAPI &&
      (userInfoFromAPI.employeeEmail || userInfoFromAPI.username || userInfoFromAPI.personalEmail)
    ) {
      setUserLog(appLogs.LAST_LOGIN);
    }
  }, [isUserLoggedIn, isInitialLogin, userInfoFromAPI]);

  useEffect(() => {
    if (isEmailChanged && userInfoFromAPI) {
      setUserLog(appLogs.EMAIL_CHANGE);
    }
  }, [isEmailChanged, userInfoFromAPI]);

  useEffect(() => {
    if (isPasswordChange && userInfoFromAPI) {
      setUserLog(appLogs.CHANGE_PASSWORD);
    }
  }, [isPasswordChange, userInfoFromAPI]);

  // useEffect(() => {
  //   const unlisten = history.listen(checkNav);
  //   return () => {
  //     unlisten();
  //   };
  // }, []);

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(
    () => ({
      myAccount,
      setMyAccount,
      myAccountNav,
      setMyAccountNav,
      myAccountView,
      setMyAccountView,
      personalInfoForm,
      setPersonalInfoForm,
      personalFormValidationSchema,
      checkNav,
      onChangePersonal,
      userPersonalInfo,
      onSubmitPersnonalInfo,
      passwordForm,
      setPasswordForm,
      passwordValidationSchema,
      onChangePassword,
      onSubmitPassword,
      onSubmitPushNotification,
      pushNotificationForm,
      onChangePushNotification,
    }),
    [
      myAccount,
      setMyAccount,
      myAccountNav,
      setMyAccountNav,
      myAccountView,
      setMyAccountView,
      personalInfoForm,
      setPersonalInfoForm,
      personalFormValidationSchema,
      checkNav,
      onChangePersonal,
      userPersonalInfo,
      onSubmitPersnonalInfo,
      passwordForm,
      setPasswordForm,
      passwordValidationSchema,
      onChangePassword,
      onSubmitPassword,
      onSubmitPushNotification,
      pushNotificationForm,
      onChangePushNotification,
    ],
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <MyAccountContext.Provider value={contextPayload}>{renderContent()}</MyAccountContext.Provider>
  );
}

MyAccountProvider.propTypes = {
  children: PropTypes.node,
};

MyAccountProvider.defaultProps = {
  children: null,
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useMyAccount = () => useContext(MyAccountContext);
