import React from 'react';
import PropTypes from 'prop-types';

function X({ pathFill, width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`h-${height} w-${width} circle-tick`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M6 18L18 6M6 6l12 12"
        stroke={pathFill}
      />
    </svg>
  );
}

X.propTypes = {
  pathFill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

X.defaultProps = {
  pathFill: '#fff',
  width: 3,
  height: 3,
};

export default X;
