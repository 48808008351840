// eslint-disable-next-line import/prefer-default-export
export const LANGUAGE = {
  aboutEdenred: 'about_edenred',
  aboutUsStaticPage: 'about_us_static_page',
  acceptTermsAndConditions: 'accept_terms_and_conditions',
  accountQ: 'account_q',
  adaptSelection: 'adapt_selection',
  afterCheckingEmailInbox: 'after_checking_email_inbox',
  agree: 'agree',
  alreadyHaveAn: 'already_have_an',
  applyFilter: 'apply_filter',
  areYouSureWantToLogout: 'are_you_sure_want_to_logout',
  asYouPrfdVchr: 'as_you_prfd_vchr',
  assignPassword: 'assign_password',
  atleasrOneNum: 'atleasr_one_num',
  atleastOneLowerCaseLetter: 'atleast_one_lower_case_letter',
  atleastOneSpecialChar: 'atleast_one_special_char',
  atleastOneUpperCaseLetter: 'atleast_one_upper_case_letter',
  blockView: 'block_view',
  byClickingPdfDownload: 'by_clicking_pdf_download',
  byClickingWebsiteOpenBgText: 'by_clicking_website_open_bg_text',
  cancelS: 'cancel_s',
  categories: 'categories',
  category: 'category',
  changePassword: 'change_password',
  changePasswordH: 'change_password_h',
  clearFilter: 'clear_filter',
  codeWasSavedInClipboard: 'code_was_saved_in_clipboard',
  company: 'company',
  completeToSignIn: 'complete_to_sign_in',
  completeToSignUp: 'complete_to_sign_up',
  completeYourOnboardingNow: 'complete_your_onboarding_now',
  confirmPassword: 'confirm_password',
  consentTo: 'consent_to',
  contentTemplateExample: 'content_template_example',
  continue: 'continue',
  copiedSuccessful: 'copied_successful',
  copyECode: 'copy_e_code',
  dataProtection: 'data_protection',
  dataProtectionStatement: 'data_protection_statement',
  dataProtectionStaticPage: 'data_protection_static_page',
  downloadAsPdf: 'download_as_pdf',
  eCode: 'e_code',
  eightTo_20Chars: 'eight_to_20_chars',
  email: 'email',
  emailC: 'email_c',
  emailHasBeenSent: 'email_has_been_sent',
  emailMsgAfterPassword: 'email_msg_after_password',
  emailMsgAfterRegistration: 'email_msg_after_registration',
  enterEmailAddressUsedToLogin: 'enter_email_address_used_to_login',
  exceptionalCasesCanTakeTwentyFourHours: 'exceptional_cases_can_take_twenty_four_hours',
  expiryDate: 'expiry_date',
  filterByCategory: 'filter_by_category',
  firstname: 'first_name',
  forgot: 'forgot',
  format: 'format',
  goToPartnerOverView: 'go_to_partner_over_view',
  goToPartnerWebsite: 'go_to_partner_website',
  greetingsTraveller: 'greetings_traveller',
  hello: 'hello',
  helpContact: 'help_contact',
  helpContactLink: 'help_contact_link',
  helpWithLoginQ: 'help_with_login_q',
  helpWithLoginStaticPage: 'help_with_login_static_page',
  helpWithSignupStaticPage: 'help_with_signup_static_page',
  helpWithSignupQ: 'help_with_signup_q',
  id: 'id',
  imprint: 'imprint',
  imprintStaticPage: 'imprint_static_page',
  info: 'info',
  infoActionVoucherStaticPage: 'info_action_voucher_static_page',
  invalidRequest: 'invalid_request',
  issueId: 'issue_id',
  issueMonth: 'issue_month',
  lastname: 'last_name',
  listView: 'list_view',
  loadMoreFaq: 'load_more_faq',
  loading: 'loading',
  login: 'login',
  loginHaveFun: 'login_have_fun',
  loginQ: 'login_q',
  logout: 'logout',
  mandatory: 'mandatory',
  mr: 'mr',
  mrMrs: 'mr_mrs',
  mrs: 'mrs',
  myAccount: 'my_account',
  myLatestVouchers: 'my_latest_vouchers',
  mostCommonQuestion: 'most_common_question',
  myNotfications: 'my_notfications',
  myVoucher: 'my_voucher',
  needHelpWith: 'need_help_with',
  networkErrorMsg: 'network_error_msg',
  networkErrorTitle: 'network_error_title',
  newPassword: 'new_password',
  newPasswordCnfrmPswrdMatchErrorMsg: 'new_password_cnfrm_pswrd_match_error_msg',
  newsLetter: 'news_letter',
  newsLetterMsg: 'news_letter_msg',
  no: 'no',
  noDataLabel: 'no_data_label',
  ok: 'ok',
  okDoIt: 'ok_do_it',
  oldPassword: 'old_password',
  onboardNow: 'onboard_now',
  partnerAlreadySelected: 'partner_already_selected',
  partnerDetails: 'partner_details',
  partnerForNextVoucher: 'partner_for_next_voucher',
  partnerOverview: 'partner_overview',
  partnerSlection: 'partner_slection',
  partnerChangedSuccessfully: 'partner_changed_successfully',
  password: 'password',
  passwordChangedSuccessfully: 'password_changed_successfully',
  passwordResetFailed: 'password_reset_failed',
  passwordCnfrmPswrdMatchErrorMsg: 'password_cnfrm_pswrd_match_error_msg',
  passwordErrorMsg: 'password_error_msg',
  passwordQ: 'password_q',
  personalAddsStaticPage: 'personal_adds_static_page',
  personalData: 'personal_data',
  personalDataMsg: 'personal_data_msg',
  personalizedAds: 'personalized_ads',
  personalizedAdsMsg: 'personalized_ads_msg',
  personalizedAdvertizement: 'personalized_advertizement',
  pleaseAgreeDataProtection: 'please_agree_data_protection',
  pleaseCheckYourInput: 'please_check_your_input',
  pleaseEnter: 'please_enter',
  pleaseEnterCnfrmPassword: 'please_enter_cnfrm_password',
  pleaseEnterEmail: 'please_enter_email',
  pleaseEnterFirstName: 'please_enter_first_name',
  pleaseEnterLastName: 'please_enter_last_name',
  pleaseEnterNewPassword: 'please_enter_new_password',
  pleaseEnterOldPassword: 'please_enter_old_password',
  pleaseEnterPassword: 'please_enter_password',
  pleaseEnterValid: 'please_enter_valid',
  pleaseEnterValidEmail: 'please_enter_valid_email',
  pleaseKeepInMindMsg: 'please_keep_in_mind_msg',
  pleaseLogInToContinue: 'please_log_in_to_continue',
  profileUpdatedSuccessfully: 'profile_updated_successfully',
  pushNotifications: 'push_notifications',
  pushNotificationsMsg: 'push_notifications_msg',
  registrationPlaceholderPassword: 'registration_placeholder_password',
  requestNewPassword: 'request_new_password',
  salutation: 'salutation',
  sampleStaticContentTemplate: 'sample_static_content_template',
  saveChanges: 'save_changes',
  search: 'search',
  seeAll: 'see_all',
  selectAsNewPrtnr: 'select_as_new_prtnr',
  showUnUsedVoucher: 'show_un_used_voucher',
  showUsedVoucher: 'show_used_voucher',
  signIn: 'sign_in',
  signInQ: 'sign_in_q',
  signUp: 'sign_up',
  signUpQ: 'sign_up_q',
  someThingWentWrong: 'some_thing_went_wrong',
  sortAZ: 'sort_a_z',
  sortZA: 'sort_z_a',
  spamFolder: 'spam_folder',
  submit: 'submit',
  subscribeNewsLetter: 'subscribe_news_letter',
  termsConditions: 'terms_conditions',
  termsConditionsStaticPage: 'terms_conditions_static_page',
  tempNoVoucherH: 'temp_no_voucher_h',
  toThePartnerWebsite: 'to_the_partner_website',

  inFutureVoucher: 'in_future_voucher',
  updatedSuccess: 'updated_success',
  value: 'value',
  voucherDetails: 'voucher_details',
  voucherNextMonth: 'voucher_next_month',
  voucherWillBeAvailSoon: 'voucher_will_be_avail_soon',
  willBeNowDefltVoucher: 'will_be_now_deflt_voucher',
  yes: 'yes',
  youHaveSelected: 'you_have_selected',
  yourPasswordMust: 'your_password_must',

  appPlayStoreLink: 'app_playstore_link',
  appAppStoreLink: 'app_appstore_link',

  logoutSuccessMsg: 'logout_success_msg',
  logoutErrorMsg: 'logout_error_msg',
  processLoginSuccessMsg: 'process_login_success_msg',
  smtWentWrongMsg: 'smt_went_wrong_msg',
  processToContinueMsg: 'process_to_continue_msg',
  checkYouInputMsg: 'check_you_input_msg',
  langNotFoundErrorMsg: 'lang_not_found_error_msg',
  invalidReqMsg: 'invalid_req_msg',
  monthNames: 'month_names',
};

export const LANGUAGE_TYPE = {
  HTML: 'html',
  TEXT: 'text',
  LINK: 'link',
};
