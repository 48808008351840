/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

import Voucher from '@core/Voucher';
import './style.scss';

import Image from '@core/Image';
import { convertCurrency, getMonthShortName } from '@utils/Validation';
import { appRoute, switchToggleSize } from '@utils/Constant';
import { useNavigate } from 'react-router-dom';
import Switch from '@core/Switch';

function PartnerListVoucher({ voucher, onChange }) {
  const navigate = useNavigate();

  if (!voucher) {
    return <> </>;
  }

  const getVouchersDate = () => new Date(voucher.allocationDate).getDate() || '';
  const getVouchersMonth = () =>
    getMonthShortName(new Date(voucher.allocationDate).getMonth()) || '';
  const navigateToVoucherDetails = (id) => navigate(appRoute.MY_VOUCHER_DETAILS(id));

  const getVooucher = () => (
    <Voucher>
      <div
        className="partner-list-voucher w-full flex"
        onClick={() => navigateToVoucherDetails(voucher.voucherId)}
      >
        <div className="w-4/12 flex-center">
          <p className="text-28 text-gray-500 font-bold">
            {' '}
            {convertCurrency(parseInt(parseInt(voucher?.value, 10) / 100, 10))}
          </p>
        </div>
        <div className="w-6/12 flex-center border-dashed border-r-2 border-gray-300 py-6">
          <Image className="" alt="Company Logo" src={voucher.logo} />
        </div>
        <div
          className="w-3/12 flex-center"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Switch
            {...{
              switchSize: switchToggleSize.SM,
              onChange: () => onChange({ refId: voucher.refId, status: !voucher.redeemed }),
              checked: voucher.redeemed,
            }}
          />
        </div>
      </div>
    </Voucher>
  );

  return (
    <div className="w-full part-voucher-list">
      <div className="voucher-list-item">
        <div className="voucher-date dotted-vertical-line-with-text mr-2 sm:mr-5 sm:pr-1 z-0">
          <div className="date-label">
            <span className="text-14 text-ed-bold text-ed-gray">
              <strong>{getVouchersDate()}</strong>{' '}
            </span>
            <span className="text-14 text-ed-regular text-ed-gray">{getVouchersMonth()}</span>
          </div>
        </div>
        <div className="voucher-list-item">{getVooucher()}</div>
      </div>
    </div>
  );
}

PartnerListVoucher.propTypes = {
  onChange: PropTypes.func,
  voucher: PropTypes.shape({
    voucherId: PropTypes.string,
    allocationDate: PropTypes.string,
    logo: PropTypes.string,
    loadAmmount: PropTypes.number,
    value: PropTypes.number,
    status: PropTypes.string,
    refId: PropTypes.string,
    redeemed: PropTypes.bool,
    eCodeContent: PropTypes.shape({
      code: PropTypes.string,
    }),
  }),
};

PartnerListVoucher.defaultProps = {
  onChange: () => {},
  voucher: {
    voucherId: '',
    allocationDate: '',
    logo: '',
    loadAmmount: 0,
    value: 0,
    status: '',
    refId: '',
    redeemed: false,
    eCodeContent: {
      code: '',
    },
  },
};

export default PartnerListVoucher;
