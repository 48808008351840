/* eslint-disable consistent-return */
/* eslint-disable no-console */
import { createContext, useMemo, useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from '@utils/Router/withRouter';
import { appToast } from '@utils/Constant';

// A context to load all app configuration from server
const ToastContext = createContext();

// The top level component that will wrap our app's core features
export function ToastProviderPre({ children }) {
  const [toasts, setToasts] = useState([]);

  // Render the children as normal
  const renderContent = () => children;

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(
        () => setToasts((extToasts) => extToasts.slice(1)),
        appToast.DEFAUT_TIMEOUT,
      );
      return () => clearTimeout(timer);
    }
  }, [toasts]);

  const addToast = useCallback(
    (toast) => {
      setToasts((extToasts) => [
        ...extToasts,
        { type: toast.type || appToast.INFO, message: toast.message },
      ]);
    },
    [setToasts],
  );

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(
    () => ({ addToast, toasts, setToasts }),
    [addToast, toasts, setToasts],
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <ToastContext.Provider value={contextPayload}>{renderContent()}</ToastContext.Provider>;
}

ToastProviderPre.propTypes = {
  children: PropTypes.node,
};

ToastProviderPre.defaultProps = {
  children: null,
};

export const ToastProvider = withRouter(ToastProviderPre);
export default ToastProvider;

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useToast = () => useContext(ToastContext);
