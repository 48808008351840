// Default app landing page.
import React from 'react';
import LogInForm from './LoginForm';

import './style.scss';

function LoginPage() {
  return (
    <div className="app-ed-page bg-ed-cold-white flex-above-center pb-20">
      <LogInForm />
    </div>
  );
}

export default LoginPage;
