import React from 'react';
import { useNavigate } from 'react-router-dom';
// import PropTypes from 'prop-types'
import Button from '@core/Button';
import { appIcons, buttonLooks, buttonTypes, appRoute } from '@utils/Constant';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';

function Info() {
  const { translate } = useLanguage();
  const navigate = useNavigate();

  // const navigateToLink = (location) => navigate(getLinkUrl(location));
  // const navigateToUrl = (location) => navigate(getStaticPageRoute(location));

  return (
    <div className="bg-ed-bubbles-4 flex-center bg-ed-cold-white">
      <div className="app-ed-xs-page ">
        <div className="app-ed-page-content w-full">
          <div className="py-5">
            <h2 className="text-24 text-ed-gray text-ed-bold w-full text-center">
              {translate(LANGUAGE.myAccount)}
            </h2>
          </div>
          <div className="grid grid-cols-1 gap-4  w-full">
            <div className="col-span-1">
              <Button
                label={translate(LANGUAGE.personalData)}
                className="border-2 border-ed-dark text-ed-regular text-center text-ed-gray px-4 py-3 transition duration-300 ease-in-out flex-center flex rounded-full w-full"
                type={buttonTypes.OUTLINE}
                icon
                iconComponent={appIcons.COMMENT_Q}
                textCenter
                look={buttonLooks.PILL}
                onClick={() => navigate(appRoute.MY_ACCOUNT_PERSONAL_INFO)}
              />
            </div>
            <div className="col-span-1">
              <Button
                label={translate(LANGUAGE.changePassword)}
                className="border-2 border-ed-dark text-ed-regular text-center text-ed-gray px-4 py-3 transition duration-300 ease-in-out flex-center flex rounded-full w-full"
                type={buttonTypes.OUTLINE}
                icon
                iconComponent={appIcons.DATABASE}
                textCenter
                look={buttonLooks.PILL}
                onClick={() => navigate(appRoute.MY_ACCOUNT_PASSWORD_RESET)}
              />
            </div>
            <div className="col-span-1">
              <Button
                label={translate(LANGUAGE.myNotfications)}
                className="border-2 border-ed-dark text-ed-regular text-center text-ed-gray px-4 py-3 transition duration-300 ease-in-out flex-center flex rounded-full w-full"
                type={buttonTypes.OUTLINE}
                icon
                iconComponent={appIcons.BALANCER}
                textCenter
                look={buttonLooks.PILL}
                onClick={() => navigate(appRoute.MY_ACCOUNT_NOTIFICATION_SETTINGS)}
              />
            </div>
            <div className="col-span-1">
              <Button
                label={translate(LANGUAGE.logout)}
                className="border-2 border-ed-dark text-ed-regular text-ed-gray px-4 py-3 transition duration-300 ease-in-out flex-center flex rounded-full w-full"
                type={buttonTypes.OUTLINE}
                icon
                iconComponent={appIcons.EDERN_RED}
                textCenter
                look={buttonLooks.PILL}
                onClick={() => navigate(appRoute.MY_ACCOUNT_LOGOUT)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Info.propTypes = {

// }

export default Info;
