/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { backgroundColor, borderRadius } from '@utils/Constant';

function Card({ bgColor, bgShadow, children, bdRadius, onClick }) {
  let className = `relative ${bgShadow ? 'box-ed-shadow' : ''} cursor-pointer h-fit-content card`;

  // Changable radius
  if (bdRadius) {
    className += ` ${bdRadius}`;
  } else {
    className += ` ${borderRadius.DEFAULT}`;
  }

  // Changable color
  if (bgColor) {
    className += ` ${bgColor}`;
  } else {
    className += ` ${backgroundColor.WHITE}`;
  }
  return (
    <div className={className} onClick={onClick}>
      {/* hover:shadow-xl hover:scale-105 duration-500 transform transition  */}
      {children}
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.node,
  bgColor: PropTypes.string,
  bgShadow: PropTypes.bool.isRequired,
  bdRadius: PropTypes.string,
  onClick: PropTypes.func,
};

Card.defaultProps = {
  children: null,
  bgColor: '',
  // bgShadow: false,
  bdRadius: '',
  onClick: () => {},
};
export default Card;
