// Default app landing page.
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useResetPassword } from '@context/ResetPassword';
import { appUrlQueryParams } from '@utils/Url';

import './style.scss';
import { defaultResetPasswordViews, feedbackContentType } from '@utils/Constant';
import Feedback from '@part/Feedback';
import ResetPasswordForm from './ResetPasswordForm';

function ResetPasswordPage() {
  const { resetView, onLoadUser } = useResetPassword();
  const location = useLocation();

  const args = new URLSearchParams(location.search);
  const assoc = args.get(appUrlQueryParams.ONBOARDING_CALLBACK.ASSOC);
  const email = args.get(appUrlQueryParams.ONBOARDING_CALLBACK.E_MAIL);

  useEffect(() => {
    // const url = args.get(appUrlQueryParams.RESET_PASSWORD_CALLBACK.URL);
    if (email) {
      onLoadUser(email);
    }
  }, []);

  const renderContent = () => {
    let defaultContent = <></>;
    // Switch between landing view as per demand
    switch (resetView) {
      case defaultResetPasswordViews.RESET_PASSWORD:
        defaultContent = <ResetPasswordForm {...{ assoc }} />;
        break;

      case defaultResetPasswordViews.RESET_PASSWORD_WELCOME:
        defaultContent = <Feedback type={feedbackContentType.RESET_PASSWORD_WELCOME} />;
        break;

      case defaultResetPasswordViews.RESET_PASSWORD_FAILED:
        defaultContent = <Feedback type={feedbackContentType.RESET_PASSWORD_FAILED} />;
        break;

      default:
        defaultContent = <ResetPasswordForm {...{ assoc }} />;
        break;
    }
    return defaultContent;
  };

  return <div className="bg-ed-bubbles flex-center h-full">{renderContent()}</div>;
}

export default ResetPasswordPage;
