/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { switchToggleSize } from '@utils/Constant';

import './style.css';

function Switch({ checked, name, onChange, switchSize }) {
  // const [value, setValue] = useState(checked);
  const switchToggle = useRef(null);

  function switchTheme() {
    if (switchToggle && switchToggle.current) {
      if (checked) {
        switchToggle.current.classList.remove('border-gray-200', '-translate-x-2');
        switchToggle.current.classList.add('border-blue-500', 'translate-x-full');
      } else {
        switchToggle.current.classList.add('border-gray-200', '-translate-x-2');
        switchToggle.current.classList.remove('border-blue-500', 'translate-x-full');
      }
    }
  }

  useEffect(() => {
    switchTheme();
  }, []);

  useEffect(() => {
    // setValue(checked);
    switchTheme();
  }, [checked]);

  const toggleTheme = () => {
    // setValue(!value);
    // switchTheme();
    onChange({ target: { name, value: !checked } });
  };

  const onIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#0E8AFD"
        d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
      />
    </svg>
  );

  const offIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#6D7787"
        d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
      />
    </svg>
  );

  return (
    <button
      type="button"
      className={`${switchSize} rounded-full flex items-center transition duration-300 focus:outline-none shadow ${
        checked ? 'bg-blue-500' : 'bg-gray-200'
      }`}
      onClick={() => toggleTheme()}
    >
      <div
        id="switch-toggle"
        className={`relative rounded-full transition duration-500 transform border-2 p-1 text-white bg-white ${
          checked ? 'border-blue-500 translate-x-full' : 'border-gray-200 translate-x-0'
        }`}
        ref={switchToggle}
      >
        {checked ? onIcon : offIcon}
      </div>
    </button>
  );
}

Switch.propTypes = {
  // Switch Props
  checked: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  switchSize: PropTypes.string,
};

Switch.defaultProps = {
  checked: false,
  name: '',
  onChange: () => {},
  switchSize: switchToggleSize.DEFAULT,
};

export default Switch;
