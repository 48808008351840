import React, { useEffect } from 'react';

// import { useLanguage } from '@context/Language';
// import { LANGUAGE } from '@utils/Constant/language';

function FaqPage() {
  // const { translate } = useLanguage();
  useEffect(() => {
    const stFaq = (i) => {
      i.detail.init({
        kbId: 278,
        locale: 'de',
        cookieOptin: false,
        buildName: 'edenred-myvouchers',
        filters: { thematics: [], tags: [] },
      });
    };
    window.addEventListener('STFAQLoaded', stFaq);
    const script = document.createElement('script');

    script.src = 'https://assets.app.smart-tribune.com/edenred/FAQ/faq.main.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      window.removeEventListener('STFAQLoaded', stFaq);
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="flex-center-start">
      <div className="app-ed-page ">
        <div className="app-ed-page-content pt-0 w-full">
          {/* <div className="pb-8">
            <h2 className="text-24 text-ed-gray text-ed-bold">{translate(LANGUAGE.loadMoreFaq)}</h2>
          </div> */}
          {/* <div className="grid grid-cols-12">
            <div className="col-span-5 lg:col-span-4 text-left">{navList()}</div>
            <div className="col-span-7 lg:col-span-8 md:-ml-5">{renderContent()}</div>
          </div> */}
          <div id="st-faq" style={{ zIndex: 0 }}>
            {' '}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqPage;
