import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StatusCodes } from '@utils/ApiCodex';

// TODO: Move status to constant file if required.
const imgStatus = {
  LOADING: StatusCodes.CONTINUE, // Initlia State
  LOADED: StatusCodes.OK, // Successfully loaded image from url
  ERROR: StatusCodes.NOT_FOUND, // Error while loading image / not found
};

// TODO: Change url with proper image.
const errorImageURL = '/logo192.png';

function Image({ className, src, alt, srcSet, style }) {
  const [imageStatus, setImageStatus] = useState(imgStatus.LOADING);
  const [srcUrl, setSrcUrl] = useState(src);

  useEffect(() => {
    setSrcUrl(src);
  }, [src]);

  const handleImageLoaded = () => {
    setImageStatus(imgStatus.LOADED);
  };

  const handleImageErrored = () => {
    setImageStatus(imgStatus.ERROR);
    setSrcUrl(errorImageURL);
  };

  return (
    <div className="app-image">
      {srcUrl && (
        <img
          {...{
            className,
            src: srcUrl,
            alt,
            srcSet,
            style,
            onLoad: handleImageLoaded,
            onError: handleImageErrored,
          }}
        />
      )}
      {imageStatus && imageStatus === imgStatus.ERROR && (
        <div className="absolute">
          <span className="text-red">Error...</span>
        </div>
      )}
    </div>
  );
}

Image.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  srcSet: PropTypes.string,
  style: PropTypes.shape({}),
};

Image.defaultProps = {
  className: '',
  src: '',
  alt: '',
  srcSet: '',
  style: {},
};

export default Image;
