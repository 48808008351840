// Default app landing page.
import React from 'react';
// import { useLocation } from 'react-router-dom';

import { useForgotPassword } from '@context/ForgotPassword';
// import { appUrlQueryParams } from '@utils/Url';

import './style.scss';
import { defaultForgotPasswordViews, feedbackContentType } from '@utils/Constant';
import Feedback from '@part/Feedback';
import ForgotPasswordForm from './ForgotPasswordForm';

function ForgotPasswordPage() {
  const { forgotPasswordView } = useForgotPassword();
  // const location = useLocation();

  // useEffect(() => {
  //   const args = new URLSearchParams(location.search);
  //   const url = args.get(appUrlQueryParams.FORGOT_PASSWORD_CALLBACK.URL);
  //   if (url) {
  //     onLoadUser(url);
  //   }
  // }, []);

  const renderContent = () => {
    let defaultContent = <></>;
    // Switch between landing view as per demand
    switch (forgotPasswordView) {
      case defaultForgotPasswordViews.FORGOT_PASSWORD:
        defaultContent = <ForgotPasswordForm />;
        break;

      case defaultForgotPasswordViews.FORGOT_PASSWORD_WELCOME:
        defaultContent = <Feedback type={feedbackContentType.FORGOT_PASSWORD_WELCOME} />;
        break;

      default:
        defaultContent = <ForgotPasswordForm />;
        break;
    }
    return defaultContent;
  };

  return <div className="bg-ed-bubbles bg-ed-cold-white flex-center">{renderContent()}</div>;
}

export default ForgotPasswordPage;
