import React from 'react';
import { useNavigate } from 'react-router-dom';

import { LANGUAGE } from '@utils/Constant/language';
import { appRoute, buttonLooks, buttonTypes } from '@utils/Constant';
import Image from '@core/Image';
import ImageSlider from '@part/ImageSlider';

import './style.scss';
import { useMyAccount } from '@context/MyAccount';
import { useLanguage } from '@context/Language';
import Button from '@core/Button';
import { useMyVoucher } from '@context/MyVoucher';
import PartnerVoucherMain from '@part/Voucher/PartnerVoucherMain';
import PartnerListVoucher from '@part/Voucher/PartnerListVoucher';
import { usePartner } from '@context/Partner';
import PartnerViewCard from '@part/Card/PartnerViewCard';
import { getMonthShortName } from '@utils/Validation';

function HomePage() {
  const { userPersonalInfo } = useMyAccount();
  const { translate } = useLanguage();

  const { voucherViewList, onRedeemChange, dashboardSlides, myVoucher } = useMyVoucher();
  const { viewPartnerList, selectedPartner } = usePartner();

  const navigate = useNavigate();

  const navigateToPartnerDetails = (id) => navigate(appRoute.PARTNER_DETAIL(id));
  const navigateToVoucherDetails = (id) => navigate(appRoute.MY_VOUCHER_DETAILS(id));

  const navigateToVoucher = () => navigate(appRoute.MY_VOUCHER);
  const navigateToPartner = () => navigate(appRoute.PARTNER);

  // const defaultVoucherList = 5;
  const defaultPartnerList = 12;

  const onChange = (e) => {
    onRedeemChange(e);
  };

  const getVouchersDate = (voucher) => new Date(voucher.allocationDate).getDate() || '';
  const getVouchersMonth = (voucher) =>
    getMonthShortName(new Date(voucher.allocationDate).getMonth()) || '';

  return (
    <div className="flex-center bg-ed-cold-white bg-ed-bubbles-3">
      <div className="app-ed-page ">
        <div className="app-ed-page-content w-full py-16">
          <div className="sm:pb-8">
            <div className="w-full flex-start-between">
              <section className="partner-overview text-left">
                <h2 className="text-24 text-ed-gray text-ed-bold capitalize whitespace-nowrap">
                  {translate(LANGUAGE.hello)} {userPersonalInfo?.firstname}{' '}
                  {userPersonalInfo?.lastname}
                </h2>
                <h3 className="text-14 text-ed-gray text-ed-light">{userPersonalInfo?.client}</h3>
              </section>
            </div>
            <div className="relative">
              <div className="w-screen xs:w-full mt-10 -mx-5 xs:-mx-0">
                {/* <Image src={appDefaultImages.HOME_PAGE_2X} alt="Home page slider" /> */}
                {dashboardSlides && <ImageSlider {...{ data: dashboardSlides }} />}
              </div>
              {selectedPartner && (
                <div className="top box-ed-shadow cursor-pointer h-fit-content card border-ed-radius bg-ed-white info-card">
                  <div className="w-50 px-10 xs:px-2 py-4 sm:py-8 flex-col-center relative">
                    <div className="text-left">
                      <h2 className="text-24 text-ed-gray text-ed-bold">
                        {translate(LANGUAGE.voucherNextMonth)}
                      </h2>
                      <p className="text-14 text-ed-gray">
                        {' '}
                        {translate(LANGUAGE.format)}{' '}
                        <span className="text-ed-gray text-ed-bold">
                          {' '}
                          {/* {translate(LANGUAGE.eCode)}{' '} */}
                          {selectedPartner.partnerType}
                        </span>{' '}
                      </p>
                      <div className="py-4 xs:py-6 default-partner flex-center">
                        <Image src={selectedPartner.partnerLogo} alt="Partner" />
                      </div>
                    </div>
                    <Button
                      type={buttonTypes.DEFAULT}
                      className="flex-center text-18 text-ed-regular text-ed-white bg-ed-blue px-4 py-2 mx-auto absolute -bottom-6 xs:-bottom-4 left-0 right-0 w-60"
                      label={translate(LANGUAGE.adaptSelection)}
                      look={buttonLooks.PILL}
                      onClick={navigateToPartner}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="pt-60">
              <div className="grid grid-cols-1 lgMinus:grid-cols-2 gap-x-6 lg:gap-x-24 text-xl">
                <div className="text-left pt-6">
                  <div className="flex-start-between">
                    <h2 className="text-24 text-ed-gray text-ed-bold py-4">
                      {translate(LANGUAGE.myLatestVouchers)}
                    </h2>
                    <button
                      type="button"
                      onClick={navigateToVoucher}
                      className="text-ed-gray border-ed rounded-full px-4 py-2 my-auto"
                    >
                      {translate(LANGUAGE.seeAll)}
                    </button>
                  </div>
                  <div className="my-6">
                    {myVoucher && (
                      <div className="grid grid-cols-12">
                        {/* <div className="mb-5 expand-full-width"> */}
                        <div className="col-span-12 mb-2  sm:block main-voucher">
                          <div className="voucher-list-item">
                            <div className="voucher-date">
                              <span className="text-14 text-ed-regular text-ed-gray">
                                <strong>{getVouchersDate(myVoucher)}</strong>{' '}
                                {getVouchersMonth(myVoucher)}
                              </span>
                            </div>
                            <div className="list-voucher-item w-full">
                              <PartnerVoucherMain
                                {...{
                                  voucher: myVoucher,
                                  onChange,
                                  isAvailableVoucher: false,
                                  isMainVoucher: true,
                                  onClick: navigateToVoucherDetails,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 mb-2 block sm:hidden xs:hidden">
                          <PartnerListVoucher
                            {...{ voucher: myVoucher, onChange, onClick: navigateToVoucherDetails }}
                          />
                        </div>
                      </div>
                    )}
                    <div className="-mt-2.5">
                      {voucherViewList.map(({ year, vouchers }) => (
                        <div key={`voucher_list_${year}`}>
                          <div className="list-voucher grid grid-flow-row">
                            {vouchers.map((voucher, i) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <div key={`partner-${voucher.voucherId}_${i}`}>
                                {/* {i === 0 ? (
                                  <div className="year-selection w-3/12 text-center">
                                    <span className="text-14 text-ed-bold text-ed-gray">{year}</span>
                                  </div>
                                ) : (
                                  <></>
                                )} */}
                                <PartnerListVoucher
                                  {...{ voucher, onChange, onClick: navigateToVoucherDetails }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="text-left pt-6">
                  <div className="flex-start-between px-0 xl:px-5">
                    <h2 className="text-24 text-ed-gray text-ed-bold py-4">
                      {translate(LANGUAGE.partnerOverview)}
                    </h2>
                    <button
                      type="button"
                      onClick={navigateToPartner}
                      className="text-ed-gray border-ed rounded-full px-4 py-2 my-auto"
                    >
                      {translate(LANGUAGE.seeAll)}
                    </button>
                  </div>
                  <div className="grid home-partner-list my-8">
                    {viewPartnerList.slice(0, defaultPartnerList).map((partner) => (
                      <button
                        className="col-span-1 px-0"
                        key={`partner_block_${partner.partnerName}`}
                        type="button"
                        onClick={() => navigateToPartnerDetails(partner.partnerId)}
                      >
                        <PartnerViewCard
                          {...{
                            name: partner.partnerName,
                            categoryName: partner.categoryName,
                            logo: partner.partnerLogo,
                            imgStyle: { maxHeight: '64px' },
                          }}
                        />
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
