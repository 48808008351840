/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { buttonTypes } from '@utils/Constant';

import DefaultButton from './DefaultButton';
import OutlineButton from './OutlineButton';
import FabButton from './FabButton';

import './style.scss';

function Button({
  className,
  label,
  type,
  icon,
  look,
  pathFill,
  iconComponent,
  svgProps,
  loading,
  onClickLoading,
  onClick,
  disabled,
  textCenter,
}) {
  let render = <></>;
  switch (type) {
    case buttonTypes.DEFAULT:
      render = (
        <DefaultButton
          {...{
            className,
            label,
            icon,
            look,
            pathFill,
            iconComponent,
            svgProps,
            loading,
            onClickLoading,
            onClick,
            disabled,
            textCenter,
          }}
        />
      );
      break;
    case buttonTypes.OUTLINE:
      render = (
        <OutlineButton
          {...{
            className,
            label,
            icon,
            look,
            pathFill,
            iconComponent,
            svgProps,
            loading,
            onClickLoading,
            onClick,
            disabled,
            textCenter,
          }}
        />
      );
      break;
    case buttonTypes.FAB:
      render = (
        <FabButton
          {...{
            className,
            label,
            icon,
            look,
            pathFill,
            iconComponent,
            svgProps,
            loading,
            onClickLoading,
            onClick,
            disabled,
            textCenter,
          }}
        />
      );
      break;
    default:
      render = (
        <DefaultButton
          {...{
            className,
            label,
            icon,
            look,
            pathFill,
            iconComponent,
            svgProps,
            loading,
            onClickLoading,
            onClick,
            disabled,
          }}
        />
      );
      break;
  }

  return render;
}

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  look: PropTypes.string,
  onClickLoading: PropTypes.bool,
  loading: PropTypes.bool,
  pathFill: PropTypes.string,
  icon: PropTypes.bool,
  iconComponent: PropTypes.string,
  svgProps: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  textCenter: PropTypes.bool,
};

Button.defaultProps = {
  className: '',
  type: buttonTypes.DEFAULT,
  label: '',
  look: null,
  onClickLoading: false,
  loading: false,
  pathFill: null,
  icon: false,
  iconComponent: null,
  svgProps: { width: null, height: null },
  onClick: () => {},
  disabled: false,
  textCenter: false,
};
export default Button;

// < div className = "flex" >
//     <div className="flex flex-col">
//         <div className="flex justify-center">
//             <a
//                 href="/"
//                 className="bg-blue-500 font-bold text-white px-4 py-3 transition duration-300 ease-in-out hover:bg-blue-600 mr-6"
//             >
//                 Click here
//             </a>
//             <a
//                 href="/"
//                 className="bg-blue-500 rounded-lg font-bold text-white text-center px-4 py-3 transition duration-300 ease-in-out hover:bg-blue-600 mr-6"
//             >
//                 Click here
//             </a>
//             <a
//                 href="/"
//                 className="bg-blue-500 rounded-full font-bold text-white px-4 py-3 transition duration-300 ease-in-out hover:bg-blue-600 mr-6"
//             >
//                 Click here
//             </a>
//         </div>
//         <div className="mt-16 flex justify-center">
//             <a
//                 href="/"
//                 className="border-2 border-blue-500 font-bold text-blue-500 px-4 py-3 transition duration-300 ease-in-out hover:bg-blue-500 hover:text-white mr-6"
//             >
//                 Click here
//             </a>
//             <a
//                 href="/"
//                 className="border-2 border-blue-500 rounded-lg font-bold text-blue-500 px-4 py-3 transition duration-300 ease-in-out hover:bg-blue-500 hover:text-white mr-6"
//             >
//                 Click here
//             </a>
//             <a
//                 href="/"
//                 className="border-2 border-blue-500 rounded-full font-bold text-blue-500 px-4 py-3 transition duration-300 ease-in-out hover:bg-blue-500 hover:text-white mr-6"
//             >
//                 Click here
//             </a>
//         </div>
//         <div className="mt-16 flex justify-center">
//             <a
//                 href="/"
//                 className="flex bg-blue-500 font-bold text-white px-4 py-3 transition duration-300 ease-in-out hover:bg-blue-600 mr-6"
//             >
//                 Click here
//                 <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     className="inline ml-2 w-6 stroke-current text-white stroke-2"
//                     viewBox="0 0 24 24"
//                     fill="none"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                 >
//                     <line x1="5" y1="12" x2="19" y2="12" />
//                     <polyline points="12 5 19 12 12 19" />
//                 </svg>
//             </a>
//             <a
//                 href="/"
//                 className="flex bg-blue-500 rounded-lg font-bold text-white text-center px-4 py-3 transition duration-300 ease-in-out hover:bg-blue-600 mr-6"
//             >
//                 Click here
//                 <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     className="inline ml-2 w-6 stroke-current text-white stroke-2"
//                     viewBox="0 0 24 24"
//                     fill="none"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                 >
//                     <line x1="5" y1="12" x2="19" y2="12" />
//                     <polyline points="12 5 19 12 12 19" />
//                 </svg>
//             </a>
//             <a
//                 href="/"
//                 className="flex bg-blue-500 rounded-full font-bold text-white px-4 py-3 transition duration-300 ease-in-out hover:bg-blue-600 mr-6"
//             >
//                 Click here
//                 <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     className="inline ml-2 w-6 stroke-current text-white stroke-2"
//                     viewBox="0 0 24 24"
//                     fill="none"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                 >
//                     <line x1="5" y1="12" x2="19" y2="12" />
//                     <polyline points="12 5 19 12 12 19" />
//                 </svg>
//             </a>
//         </div>
//         <div className="mt-16 flex justify-center">
//             <a
//                 href="/"
//                 className="flex bg-blue-500 font-bold text-white px-4 py-4 transition duration-300 ease-in-out hover:bg-blue-600 mr-6"
//             >
//                 <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     className="inline w-6 stroke-current text-white stroke-2"
//                     viewBox="0 0 24 24"
//                     fill="none"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                 >
//                     <line x1="5" y1="12" x2="19" y2="12" />
//                     <polyline points="12 5 19 12 12 19" />
//                 </svg>
//             </a>
//             <a
//                 href="/"
//                 className="flex bg-blue-500 rounded-lg font-bold text-white text-center px-4 py-3 transition duration-300 ease-in-out hover:bg-blue-600 mr-6"
//             >
//                 <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     className="inline w-6 stroke-current text-white stroke-2"
//                     viewBox="0 0 24 24"
//                     fill="none"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                 >
//                     <line x1="5" y1="12" x2="19" y2="12" />
//                     <polyline points="12 5 19 12 12 19" />
//                 </svg>
//             </a>
//             <a
//                 href="/"
//                 className="flex bg-blue-500 rounded-full font-bold text-white px-4 py-4 transition duration-300 ease-in-out hover:bg-blue-600 mr-6"
//             >
//                 <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     className="inline w-6 stroke-current text-white stroke-2"
//                     viewBox="0 0 24 24"
//                     fill="none"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                 >
//                     <line x1="5" y1="12" x2="19" y2="12" />
//                     <polyline points="12 5 19 12 12 19" />
//                 </svg>
//             </a>
//         </div>
//     </div>
//   </ >
