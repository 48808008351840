// Default app landing page.
import React from 'react';
import { useNavigate } from 'react-router-dom';

import AnimateLoad from '@part/AnimateLoad';
import {
  animationStyle,
  appDefaultImages,
  appRoute,
  buttonLooks,
  feedbackContentType,
} from '@utils/Constant';
import PropTypes from 'prop-types';

import './style.scss';
import Button from '@core/Button';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';

function Feedback({ type }) {
  const navigate = useNavigate();

  const { translate } = useLanguage();

  const renderContent = () => {
    let defaultContent = <> </>;

    switch (type) {
      case feedbackContentType.ONBOARD_WELCOME:
        defaultContent = (
          <>
            <h3 className="text-ed-gray text-ed-bold text-24 pb-1">
              {translate(LANGUAGE.emailHasBeenSent)}
            </h3>
            <h4 className="text-ed-gray text-ed-light text-18">
              {translate(LANGUAGE.emailMsgAfterRegistration)}
            </h4>
            {/* <h4 className="text-ed-gray text-ed-light text-18">
              If you have access to it, please click the link in order to complete your registration
            </h4> */}
          </>
        );
        break;
      case feedbackContentType.FORGOT_PASSWORD_WELCOME:
        defaultContent = (
          <>
            <h3 className="text-ed-gray text-ed-bold text-24 pb-1">
              {translate(LANGUAGE.emailHasBeenSent)}
            </h3>
            <h4 className="text-ed-gray text-ed-light text-18">
              {translate(LANGUAGE.emailMsgAfterPassword)}
            </h4>
          </>
        );
        break;
      case feedbackContentType.RESET_PASSWORD_WELCOME:
        defaultContent = (
          <>
            <h3 className="text-ed-gray text-ed-bold text-24 pb-1">
              {translate(LANGUAGE.passwordChangedSuccessfully)}
            </h3>
            {/* <h3 className="text-ed-gray text-ed-bold text-24 pb-1">erfolgreich geandert!</h3> */}
            {/* <h4 className="text-ed-gray text-ed-light text-18">
              Klicken Sie auf Login, um fortzufahren
            </h4> */}
            <div className="flex-center py-5">
              <Button
                className="relative bg-ed-red font-bold text-white text-24 px-16 py-3 flex-center "
                label="Absenden"
                look={buttonLooks.PILL}
                onClick={() => navigate(appRoute.LOGIN)}
              />
            </div>
          </>
        );
        break;
      case feedbackContentType.RESET_PASSWORD_FAILED:
        defaultContent = (
          <>
            <h3 className="text-ed-gray text-ed-bold text-24 pb-1">
              {translate(LANGUAGE.passwordResetFailed)}
            </h3>
            {/* <h3 className="text-ed-gray text-ed-bold text-24 pb-1">erfolgreich geandert!</h3> */}
            {/* <h4 className="text-ed-gray text-ed-light text-18">
              Klicken Sie auf Login, um fortzufahren
            </h4> */}
            <div className="flex-center py-5">
              <Button
                className="relative bg-ed-red font-bold text-white text-24 px-16 py-3 flex-center "
                label="Absenden"
                look={buttonLooks.PILL}
                onClick={() => navigate(appRoute.LOGIN)}
              />
            </div>
          </>
        );
        break;
      default:
        break;
    }
    return defaultContent;
  };

  return (
    <div className="bg-ed-bubbles overflow-hidden flex-above-center">
      <AnimateLoad style={animationStyle.FADE_IN}>
        <div className="feedback-view max-w-screen-sm pt-2">
          <div className="feedback-container">
            <div className="feedback-img-container pb-5">
              <img src={appDefaultImages.ONBOARD_PAGE_2X} alt="Onboard" />
            </div>
            <div className="text-center w-full my-5">{renderContent()}</div>
          </div>
        </div>
      </AnimateLoad>
    </div>
  );
}

Feedback.propTypes = {
  type: PropTypes.number.isRequired,
};

export default Feedback;
