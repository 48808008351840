import React from 'react';
import PropTypes from 'prop-types';
import { themeColor } from '@theme/common/color';

function XRed({ pathFill, width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      width={width}
      height={height}
      style={{ backgroundColor: themeColor.edRed, borderRadius: '50%', padding: '1px' }}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M6 18L18 6M6 6l12 12"
        stroke={pathFill}
      />
    </svg>
  );
}

XRed.propTypes = {
  pathFill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

XRed.defaultProps = {
  pathFill: themeColor.edWhite,
  width: 16,
  height: 16,
};

export default XRed;
