/* eslint-disable no-sequences */
/* eslint-disable no-console */
import { createContext, useMemo, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from '@utils/Router/withRouter';
import axios from 'axios';

import Loading from '@core/Loading';

// A context to load all app configuration from server
const LoadingContext = createContext();

// The top level component that will wrap our app's core features
export function LoadingProviderPre({ children }) {
  const [isLoading, setIsLoading] = useState(false);

  const [counter, setCounter] = useState(0);

  const interceptors = useMemo(() => {
    const inc = () => setCounter((c) => c + 1);
    const dec = () => setCounter((c) => c - 1);

    return {
      request: (config) => (inc(), config),
      response: (response) => (dec(), response),
      error: (error) => (dec(), Promise.reject(error)),
    };
  }, []); // create the interceptors

  useEffect(() => {
    // add request interceptors
    const reqInterceptor = axios.interceptors.request.use(interceptors.request, interceptors.error);
    // add response interceptors
    const resInterceptor = axios.interceptors.response.use(
      interceptors.response,
      interceptors.error,
    );
    return () => {
      // remove all intercepts when done
      axios.interceptors.request.eject(reqInterceptor);
      axios.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);

  // Render the children as normal
  const renderContent = () => children;

  const showLoader = () => {
    // setIsLoading(true);
  };
  const hideLoader = () => {
    // setIsLoading(false);
  };

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(
    () => ({ isLoading, setIsLoading, showLoader, hideLoader }),
    [isLoading, setIsLoading, showLoader, hideLoader],
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <LoadingContext.Provider value={contextPayload}>
      <Loading {...{ isLoading: counter > 0 }} />
      {renderContent()}
    </LoadingContext.Provider>
  );
}

LoadingProviderPre.propTypes = {
  children: PropTypes.node,
};

LoadingProviderPre.defaultProps = {
  children: null,
};

export const LoadingProvider = withRouter(LoadingProviderPre);
export default LoadingProvider;

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useLoading = () => useContext(LoadingContext);
