import { createContext, useState, useEffect, useMemo, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import { appHeaderNavConfig, appMobileHeaderNavConfig } from '@utils/Constant';
import { useLocation } from 'react-router-dom';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

// A context to load all app configuration from server
const LayoutContext = createContext();

// The top level component that will wrap our app's core features
export function LayoutProvider({ children }) {
  const [navList, setNavList] = useState(appHeaderNavConfig);
  const [mobileNavList, setMoblileNavList] = useState(appMobileHeaderNavConfig);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  // const history = useNavigate();
  const Location = useLocation();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [isXS, setIsXS] = useState(windowDimensions.width < 576);
  const [isSM, setIsSM] = useState(windowDimensions.width <= 768);
  const [isXXL, setIsXXL] = useState(windowDimensions.width >= 1920);
  const [isMobile, setIsMobile] = useState(windowDimensions.width < 640);

  // const checkNav = () => {
  //   if (location && location.pathname) {
  //     const updateNav = appHeaderNavConfig.map(nav => ({ ...nav, active: `${nav.to}`.split('/').filter(t => !!t).shift() === `${location.pathname}`.split('/').filter(t => !!t).shift() }));
  //     console.log({ updateNav });
  //     if (updateNav) setNavList(updateNav);
  //   }
  // }

  const toggleMobileMenu = () => {
    console.log('clicked', showMobileMenu);
    setShowMobileMenu(!showMobileMenu);
  };

  const checkNav = useCallback(
    (location) => {
      const loc = location || Location;
      if (loc && loc.pathname) {
        const updateNav = appHeaderNavConfig.map((nav) => ({
          ...nav,
          active:
            `${nav.to}`
              .split('/')
              .filter((t) => !!t)
              .shift() ===
            `${loc.pathname}`
              .split('/')
              .filter((t) => !!t)
              .shift(),
        }));
        if (updateNav) setNavList([...updateNav]);
        // Mobile
        const updateMobileNav = appMobileHeaderNavConfig.map((nav) => ({
          ...nav,
          active:
            `${nav.to}`
              .split('/')
              .filter((t) => !!t)
              .shift() ===
            `${loc.pathname}`
              .split('/')
              .filter((t) => !!t)
              .shift(),
        }));
        if (updateMobileNav) setMoblileNavList([...updateMobileNav]);
      }
    },
    [setNavList, setMoblileNavList],
  );

  // Effect
  useEffect(() => {
    // setMoblileNavList(appMobileHeaderNavConfig);
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', handleResize);

    // const unlisten = history.listen(checkNav);
    checkNav(Location);
    return () => {
      // unlisten();
      window.removeEventListener('resize', handleResize);
    };
  }, [Location]);

  useEffect(() => {
    setIsXS(windowDimensions.width < 576);
    setIsSM(windowDimensions.width <= 768);
    setIsMobile(windowDimensions.width < 576);
    setIsXXL(windowDimensions.width >= 1920);
  }, [windowDimensions]);

  // const location = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [Location.pathname]);

  // Render the children as normal
  // TODO: Implemente loading if required.
  const renderContent = () => children;

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(
    () => ({
      navList,
      mobileNavList,
      setNavList,
      isSM,
      isMobile,
      isXXL,
      isXS,
      windowDimensions,
      toggleMobileMenu,
      showMobileMenu,
    }),
    [
      navList,
      mobileNavList,
      setNavList,
      isSM,
      isMobile,
      isXXL,
      windowDimensions,
      toggleMobileMenu,
      showMobileMenu,
    ],
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <LayoutContext.Provider value={contextPayload}>{renderContent()}</LayoutContext.Provider>;
}

LayoutProvider.propTypes = {
  children: PropTypes.node,
};

LayoutProvider.defaultProps = {
  children: null,
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useLayout = () => useContext(LayoutContext);
