import React, { useEffect } from 'react';
import { useLanguage } from '@context/Language';
// import Button from '@core/Button';
// import Input from '@core/Input';
import { withAnimate } from '@part/AnimateLoad';
// import {
//   appIcons,
//   appListViewTypes,
//   buttonLooks,
//   buttonTypes,
//   inputViewSizes,
// } from '@utils/Constant';
import { LANGUAGE } from '@utils/Constant/language';
import PartnerVoucher from '@part/Voucher/PartnerVoucher';
// import PartnerListVoucher from '@part/Voucher/PartnerListVoucher';

import './style.scss';
import { useMyVoucher } from '@context/MyVoucher';
import { useParams } from 'react-router-dom';
import { convertCurrency, getMonthFullName } from '@utils/Validation';

function VoucherDetailPage() {
  const { translate } = useLanguage();
  const { voucherId } = useParams();
  const { voucherList, myVoucherDetail } = useMyVoucher();
  const { getVoucherDetails, onRedeemChange } = useMyVoucher();

  const onChange = (e) => {
    onRedeemChange(e);
  };

  useEffect(() => {
    if (voucherId) {
      getVoucherDetails(voucherId);
    }
  }, [voucherId]);

  useEffect(() => {
    if (voucherId) {
      getVoucherDetails(voucherId);
    }
  }, [voucherList]);

  //   const onSearch = (e) => searchVoucher(e.target.value);

  //   const blockView = () =>
  //     voucherViewList.map(({ year, vouchers }) => (
  //       <div key={`voucher_list_${year}`}>
  //         <div className="year-selection dotted-line-with-text">
  //           <span className="text-14 text-ed-bold text-ed-gray">{year}</span>
  //         </div>
  //         <div className="available-voucher text-center grid grid-cols-2">
  //           {vouchers.map((voucher, i) => (
  //             // eslint-disable-next-line react/no-array-index-key
  //             <div key={`partner-${voucher.voucherId}_${i}`}>
  //               <PartnerVoucher {...{ voucher, onChange, isAvailableVoucher: true }} />
  //             </div>
  //           ))}
  //         </div>
  //       </div>
  //     ));

  //   const listView = () =>
  //     voucherViewList.map(({ year, vouchers }) => (
  //       <div key={`voucher_list_${year}`}>
  //         <div className="year-selection w-3/12 text-center">
  //           <span className="text-14 text-ed-bold text-ed-gray">{year}</span>
  //         </div>
  //         <div className="available-list-voucher grid grid-flow-row">
  //           {vouchers.map((voucher, i) => (
  //             // eslint-disable-next-line react/no-array-index-key
  //             <div key={`partner-${voucher.voucherId}_${i}`}>
  //               <PartnerListVoucher {...{ voucher, onChange }} />
  //             </div>
  //           ))}
  //         </div>
  //       </div>
  //     ));

  //   const getView = () =>
  //     appliedFilter.view === appListViewTypes.LIST_VIEW ? listView() : blockView();

  const translateMonths = `${translate(LANGUAGE.monthNames)}`.split(',');
  const getIssuedMonth = () =>
    `${getMonthFullName(
      new Date(myVoucherDetail.allocationDate).getMonth(),
      translateMonths,
    )}, ${new Date(myVoucherDetail.allocationDate).getFullYear()}`;

  const getExpDate = () =>
    `${new Date(myVoucherDetail.expiryDate).getDate()}.${
      new Date(myVoucherDetail.expiryDate).getMonth() + 1
    }.${new Date(myVoucherDetail.expiryDate).getFullYear()}`;

  return (
    <div className="flex-center bg-ed-cold-white">
      <div className="app-ed-page ">
        <div className="app-ed-page-content w-full py-16">
          <div className="sm:pb-8">
            <div className="grid grid-cols-12 gap-y-4">
              <div className="col-span-12 text-left">
                <h2 className="text-24 text-ed-gray text-ed-bold">
                  {translate(LANGUAGE.voucherDetails)}
                </h2>
              </div>
            </div>
            <div className="flex-center">
              <div className="pt-6 detailed-voucher">
                <PartnerVoucher
                  {...{
                    showDate: true,
                    onChange,
                    isAvailableVoucher: false,
                    voucher: myVoucherDetail,
                    isMainVoucher: true,
                  }}
                />
              </div>
              {/* <div className="col-span-6 text-start">{getView()}</div> */}
            </div>

            <div className="voucher-info mt-24 pt-10 w-full flex-center">
              <div className="information">
                <div className="flex-start-between">
                  <span className="text-18 sm:text-24 text-ed-regular text-ed-gray">
                    {translate(LANGUAGE.company)}
                  </span>
                  <span className="text-18 sm:text-24 text-ed-bold text-ed-gray">
                    {myVoucherDetail.partnerName}
                  </span>
                </div>
                <div className="flex-start-between">
                  <span className="text-18 sm:text-24 text-ed-regular text-ed-gray">
                    {translate(LANGUAGE.category)}
                  </span>
                  <span className="text-18 sm:text-24 text-ed-bold text-ed-gray">
                    {myVoucherDetail.categoryName}
                  </span>
                </div>
                <div className="flex-start-between">
                  <span className="text-18 sm:text-24 text-ed-regular text-ed-gray">
                    {translate(LANGUAGE.issueMonth)}
                  </span>
                  <span className="text-18 sm:text-24 text-ed-bold text-ed-gray">
                    {getIssuedMonth()}
                  </span>
                </div>
                <div className="flex-start-between">
                  <span className="text-18 sm:text-24 text-ed-regular text-ed-gray">
                    {translate(LANGUAGE.issueId)}
                  </span>
                  <span className="text-18 sm:text-24 text-ed-bold text-ed-gray">
                    ID-{myVoucherDetail.orderId}
                  </span>
                </div>
                <div className="flex-start-between">
                  <span className="text-18 sm:text-24 text-ed-regular text-ed-gray">
                    {translate(LANGUAGE.value)}
                  </span>
                  <span className="text-18 sm:text-24 text-ed-bold text-ed-gray">
                    {convertCurrency(parseInt(parseInt(myVoucherDetail?.value, 10) / 100, 10))}
                  </span>
                </div>
                <div className="flex-start-between">
                  <span className="text-18 sm:text-24 text-ed-regular text-ed-gray">
                    {translate(LANGUAGE.expiryDate)}
                  </span>
                  <span className="text-18 sm:text-24 text-ed-bold text-ed-gray">
                    {getExpDate()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAnimate(VoucherDetailPage);
