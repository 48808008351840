/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// import PropTypes from 'prop-types'
import { useLayout } from '@context/Layout';
import { withAnimate } from '@part/AnimateLoad';
import { appIcons, appRoute, buttonTypes } from '@utils/Constant';
import { useAuth } from '@context/AuthProvider';
import Button from '@core/Button';

import { getIcon } from '@utils/SVG';
import { useLanguage } from '@context/Language';
import { navigateTo } from '@utils/Url';
import { LANGUAGE } from '@utils/Constant/language';

import Navigation from './Navigation';

function AppHeader() {
  const { windowDimensions, toggleMobileMenu, isMobile } = useLayout();
  const { isUserLoggedIn } = useAuth();

  const [headerLogo, setHeaderLogo] = useState('/assets/images/header/EdenredLogo.svg');

  useEffect(() => {
    if (windowDimensions.width < 576) {
      setHeaderLogo('/assets/images/header/EdenredLogo.svg');
      // console.log('1');
    } else if (isUserLoggedIn) {
      setHeaderLogo('/assets/images/header/EdenredFullLogoMeinGutschein.svg');
      // console.log('2');
    } 
    // else {
    //   setHeaderLogo('/assets/images/header/EdenredFullLogoMeinGutschein.svg');
    //   console.log('3');
    // }
  }, [windowDimensions.width]);

  return (
    <>
      <div className="app-ed-header w-[100vw] bg-ed-white flex-center [border-bottom-style:dashed] border-grey border-b-[3px]">
        <div
          className={`app-ed-header-content 2xl:w-[1923px] xl:w-[1116px] lg:w-[995px] md:w-[995px] w-full px-[30px] sm:py-0 relative ${
            isUserLoggedIn && 'flex-space-between'
          }`}
        >
          {/* <div className=""> */}

          {/* </div> */}
          {isUserLoggedIn ? (
            <>
              <Link
                to={isUserLoggedIn ? appRoute.HOME : appRoute.LOGIN}
                className="app-ed-header-logo"
              >
                {headerLogo && <img src={headerLogo} alt="Edenred Logo" />}
                {/* <img src="/assets/images/header/EdenredLogoMeinGutschein.svg" className="px-[20px]" alt="Edenred Logo" /> */}
              </Link>
              {isMobile ? (
                <div
                  role="button"
                  className="app-ed-header-nav"
                  aria-hidden
                  onClick={toggleMobileMenu}
                >
                  <Button
                    className="bg-transprent"
                    icon
                    iconComponent={appIcons.BURGERMENU}
                    type={buttonTypes.FAB}
                  />
                </div>
              ) : (
                <nav className="app-ed-header-nav">
                  <Navigation />
                </nav>
              )}
            </>
          ) : (
            <Link
              to={isUserLoggedIn ? appRoute.HOME : appRoute.LOGIN}
              className={`app-ed-header-logo ${isMobile ? 'flex-center' : 'flex-space-between'}`}
              // className="app-ed-header-logo flex-center"
            >
              {/* {headerLogo && <img src={headerLogo} alt="Edenred Logo" />} */}
              {isMobile ? (
                <img
                  src="/assets/images/header/EdenredFullLogoMeinGutschein.svg"
                  alt="Edenred Logo"
                />
              ) : (
                <>
                  <img src="/assets/images/header/EdenredLogo.svg" alt="Edenred Logo" />
                  <img
                    src="/assets/images/header/EdenredLogoMeinGutschein.svg"
                    alt="Edenred Logo"
                  />
                </>
              )}
            </Link>
          )}
        </div>
      </div>
    </>
  );
}

// AppHeader.propTypes = {
//   children: PropTypes.node.isRequired,
// };

// import PropTypes from "prop-types";
// import Navigation from './Navigation';
// // import { Back } from "./Back";
// // import { EdenredLogo } from "./EdenredLogo";
// // import { EdenredLogoMeinGutschein } from "./EdenredLogoMeinGutschein";
// // import { Icon } from "./Icon";

// // import { NavLink } from "./NavLink";

// export function AppHeader({
//   style="private-large",
//   className,
//   // navLinkIcon = <div className="!absolute !w-[20px] !h-[20px] !top-[10px] !left-[10px]" />,
//   // navLinkIconClassName,
//   // override = <div className="!absolute !w-[20px] !h-[20px] !top-[10px] !left-[10px]" />,
//   // navLinkIconClassNameOverride,
//   // navLinkIcon1 = <div className="!absolute !w-[20px] !h-[20px] !top-[10px] !left-[10px]" />,
//   // navLinkIconWrapperClassName,
//   // navLinkIcon2 = <div className="!absolute !w-[20px] !h-[20px] !top-[10px] !left-[10px]" />,
//   // navLinkIconWrapperClassNameOverride,
//   // navLinkIcon3 = <div className="!absolute !w-[20px] !h-[20px] !top-[10px] !left-[10px]" />,
//   // navLinkDivClassName,
// }) {
//   return (
//     <div
//       className={`[border-bottom-style:dashed] border-grey flex items-center gap-[10px] h-[123px] border-b-[3px] justify-center bg-white relative ${
//         style === "private-middle" ? "w-[995px]" : style === "private-small" ? "w-[378px]" : "w-[1923px]"
//       } ${["back", "public"].includes(style) ? "flex-col" : ""} ${
//         style === "back" ? "pl-0 pr-[30px] py-0" : "px-[30px] py-0"
//       } ${className}`}
//     >
//       <div
//         className={`flex items-center justify-between relative ${["back", "public"].includes(style) ? "w-full" : ""} ${
//           ["back", "public"].includes(style) ? "self-stretch" : ""
//         } ${["private-large", "private-middle", "private-small"].includes(style) ? "grow" : ""} ${
//           ["back", "public"].includes(style) ? "flex-[0_0_auto]" : "flex-1"
//         }`}
//       >
//         {style === "private-large" && (
//           <>
//             <div className="w-[765.88px] flex items-center gap-[30px] relative">
//               <img src="/assets/images/header/EdenredLogo.svg" className="!relative !w-[107.03px] !h-[65.62px]" alt='' />
//               {style === "private-large" && <div className="!relative !w-[159.73px] !h-[17.45px]" />}

//               {style === "private-middle" && (
//                 <div className="!relative !w-[159.73px] !h-[17.45px]" />
//               )}
//             </div>
//             <div
//               className={`inline-flex items-center flex-[0_0_auto] justify-center relative ${
//                 style === "private-middle" ? "gap-[10px]" : "gap-[40px]"
//               } ${style === "private-middle" ? "ml-[-23.88px]" : ""}`}
//             >
//               {/* <Navigation /> */}
//             </div>
//           </>
//         )}

//         {/* {style === "back" && <Back />} */}

//         {/* {["back", "private-small", "public"].includes(style) && ( */}
//           <img src="/assets/images/header/EdenredLogoMeinGutschein.svg" alt="" className="!h-[17px] bg-[url(clip-path-group.png)] !relative !bg-[100%_100%] !left-[unset] !w-[160px] !top-[unset]" />
//         {/* )} */}

//         {style === "public" && <img src="/assets/images/header/EdenredLogoMeinGutschein.svg" alt="" className="!relative !w-[159.73px] !h-[17.45px]" />}

//         {/* {style === "private-small" && (
//           <div className="inline-flex items-center justify-center gap-[10px] relative flex-[0_0_auto]">
//             A Tag */}
//             {/* <a
//               href="https://www.edenred.de/unternehmen/ueber-uns/unternehmensprofil.html"
//               target="_blank"
//               rel="noreferrer"
//               className="!flex-[0_0_auto]"
//               hasDiv={false}
//               icon={navLinkIcon3}
//               iconClassName={navLinkDivClassName}
//               status="inactive"
//             /> */}
//           {/* </div>
//         )} */}
//       </div>
//       <div className="w-[20px] left-[-10px] top-[110px] h-[20px] rounded-[10px] bg-ed-gray absolute z-10" />
//       <div
//         className={`w-[20px] top-[110px] h-[20px] rounded-[10px] bg-ed-gray absolute z-10 ${
//           style === "private-middle" ? "left-[982px]" : style === "private-small" ? "left-[365px]" : "left-[1910px]"
//         }`}
//       />
//     </div>
//   );
// }

// AppHeader.propTypes = {
//   // eslint-disable-next-line react/require-default-props
//   style: PropTypes.oneOf(["back", "private-small", "public", "private-large", "private-middle"]),
// };

export function AppCtaButton() {
  const { getLinkUrl } = useLanguage();

  const { isSM } = useLayout();

  const navigateToPlayStore = () => navigateTo(getLinkUrl(LANGUAGE.appPlayStoreLink));
  const navigateToAppStore = () => navigateTo(getLinkUrl(LANGUAGE.appAppStoreLink));

  if (isSM) {
    return <></>;
  }

  return (
    <div className="app-ed-cta-page flex-col-center">
      <button type="button" className="card bg-ed-white p-2" onClick={() => navigateToPlayStore()}>
        {getIcon({ iconComponent: appIcons.PLAYSTORE, svgProps: { width: 30, height: 30 } })}
      </button>
      <button type="button" className="card bg-ed-white p-2" onClick={() => navigateToAppStore()}>
        {getIcon({ iconComponent: appIcons.APPLE, svgProps: { width: 30, height: 30 } })}
      </button>
    </div>
  );
}

export default withAnimate(AppHeader);
