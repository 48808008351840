/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@core/Modal';
import Button from '@core/Button';
import {
  appIcons,
  appListViewTypes,
  buttonLooks,
  buttonTypes,
  defaultVoucherFilterStatus,
} from '@utils/Constant';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';

function VoucherFilteration({
  isOpen,
  filter,
  closeFilter,
  changeVoucherView,
  changeVoucherStatus,
  filterVoucher,
}) {
  const { translate } = useLanguage();

  const confirmFilter = () => filterVoucher(filter);

  return (
    <Modal
      {...{
        isOpen,
        title: 'Filter',
        onClose: closeFilter,
        className: 'voucher-filter-modal py-20 w-full h-full flex-center',
      }}
    >
      <div className="filter-content flex-center">
        <section className="view-selector">
          <span className="text-18 text-ed-bold text-ed-gray w-full text-left">Ansicht</span>
          <div className="btn-selection">
            <div className="grid grid-cols-1 xs:grid-cols-2 w-full">
              <div className="col-span-1">
                <Button
                  label={translate(LANGUAGE.blockView)}
                  className="border-2 border-ed-dark text-ed-regular text-ed-gray px-4 py-3 transition duration-300 ease-in-out flex-center flex rounded-full"
                  type={buttonTypes.OUTLINE}
                  icon
                  iconComponent={appIcons.BLOCK}
                  look={buttonLooks.PILL}
                  disabled={filter.view === appListViewTypes.BLOCK_VIEW}
                  onClick={() => changeVoucherView(appListViewTypes.BLOCK_VIEW)}
                />
              </div>
              <div className="col-span-1">
                <Button
                  label={translate(LANGUAGE.listView)}
                  className="border-2 border-ed-dark text-ed-regular text-ed-gray px-4 py-3 transition duration-300 ease-in-out flex-center flex rounded-full"
                  type={buttonTypes.OUTLINE}
                  icon
                  iconComponent={appIcons.LIST}
                  look={buttonLooks.PILL}
                  disabled={filter.view === appListViewTypes.LIST_VIEW}
                  onClick={() => changeVoucherView(appListViewTypes.LIST_VIEW)}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="filter-content flex-center">
        <section className="voucher-selector">
          <span className="text-18 text-ed-bold text-ed-gray w-full text-left">
            Gutscheinstatus
          </span>
          <div className="list-selection flex-center">
            <div className="grid grid-cols-1 gap-y-3 w-full">
              <div className="col-span-1">
                <Button
                  label={translate(LANGUAGE.showUsedVoucher)}
                  className="border-2 w-full border-ed-dark text-ed-regular text-center text-ed-gray px-2 sm:px-4 py-3 transition duration-300 ease-in-out flex-center flex rounded-full"
                  type={buttonTypes.OUTLINE}
                  icon
                  iconComponent={
                    filter.status.usedVoucher === defaultVoucherFilterStatus.APPLIED
                      ? appIcons.CIRCLE_TICK
                      : appIcons.CIRCLE
                  }
                  svgProps={{ width: 22, height: 22 }}
                  look={buttonLooks.PILL}
                  // disabled={filter.status.usedVoucher === defaultVoucherFilterStatus.USED_VOUCHER}
                  onClick={() => changeVoucherStatus(defaultVoucherFilterStatus.USED_VOUCHER)}
                />
              </div>
              <div className="col-span-1 w-full flex-center">
                <Button
                  label={translate(LANGUAGE.showUnUsedVoucher)}
                  className="border-2 w-full border-ed-dark text-ed-regular text-center text-ed-gray px-2 sm:px-4 py-3 transition duration-300 ease-in-out flex-center flex rounded-full"
                  type={buttonTypes.OUTLINE}
                  icon
                  iconComponent={
                    filter.status.unUsedVoucher === defaultVoucherFilterStatus.APPLIED
                      ? appIcons.CIRCLE_TICK
                      : appIcons.CIRCLE
                  }
                  svgProps={{ width: 22, height: 22 }}
                  look={buttonLooks.PILL}
                  // disabled={filter.status.unUsedVoucher === defaultVoucherFilterStatus.UN_USER_VOUCHER}
                  onClick={() => changeVoucherStatus(defaultVoucherFilterStatus.UN_USER_VOUCHER)}
                />
              </div>
            </div>
          </div>
          <div className="apply-filter w-full flex-center">
            <Button
              className="relative bg-ed-blue text-ed-regular text-ed-white px-4 py-3 transition duration-300 ease-in-out flex-center flex rounded-full"
              label={translate(LANGUAGE.applyFilter)}
              type={buttonTypes.DEFAULT}
              icon
              iconComponent={appIcons.APPLY_FILTER}
              look={buttonLooks.PILL}
              svgProps={{ width: 22, height: 22 }}
              onClick={() => confirmFilter()}
            />
          </div>
        </section>
      </div>
    </Modal>
  );
}

VoucherFilteration.propTypes = {
  isOpen: PropTypes.bool,
  filter: PropTypes.any,
  closeFilter: PropTypes.func,
  changeVoucherView: PropTypes.func,
  changeVoucherStatus: PropTypes.func,
  selectCategory: PropTypes.func,
  filterVoucher: PropTypes.func,
  sortFilter: PropTypes.func,
};

VoucherFilteration.defaultProps = {
  isOpen: false,
  filter: {},
  closeFilter: () => {},
  changeVoucherView: () => {},
  changeVoucherStatus: () => {},
  selectCategory: () => {},
  filterVoucher: () => {},
  sortFilter: () => {},
};

export default VoucherFilteration;
