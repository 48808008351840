import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@core/Button';
import { animationStyle, appIcons, appModalBackground, buttonTypes } from '@utils/Constant';
import Card from '@core/Card';
import { themeColor } from '@theme/common/color';

import './style.scss';

function Modal({ isOpen, children, title, onClose, backDropColor, className }) {
  const modalEl = useRef(null);

  const close = () => {
    const modal = modalEl.current;
    modal.className = `hidden ${animationStyle.ANIMATED} ${animationStyle.FADE_OUT}`;
    onClose();
  };

  const onOpen = () => {
    const modal = modalEl.current;
    modal.className = `${backDropColor} ${className}`;
  };

  useEffect(() => {
    if (isOpen) {
      onOpen();
    } else {
      close();
    }
  }, [isOpen]);

  return (
    <div ref={modalEl} className="">
      <div className="modal-card">
        <Card>
          {title ? (
            <div className="modal-header relative flex-center">
              <div className="modal-title w-full text-center">
                <span className="text-24 text-ed-bold text-ed-gray">{title}</span>
              </div>
              <div className="modal-close flex-center">
                <Button
                  className="modal-close-btn"
                  icon
                  iconComponent={appIcons.CLOSE_X}
                  type={buttonTypes.FAB}
                  onClick={close}
                  pathFill={themeColor.edWhite}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="modal-close flex-center">
                <Button
                  className="modal-close-btn"
                  icon
                  iconComponent={appIcons.CLOSE_X}
                  type={buttonTypes.FAB}
                  onClick={close}
                  pathFill={themeColor.edWhite}
                />
              </div>
            </>
          )}
          <div className="modal-content flex-col-center">{children}</div>
        </Card>
      </div>
    </div>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  backDropColor: PropTypes.string,
  className: PropTypes.string,
};

Modal.defaultProps = {
  onClose: () => {},
  title: '',
  isOpen: false,
  backDropColor: appModalBackground.DEFAULT,
  className:
    'fixed w-screen h-screen inset-0 z-50 overflow-hidden flex justify-center items-center',
};

export default Modal;
