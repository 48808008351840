import React from 'react';
import PropTypes from 'prop-types';

function CircleTick({ width, height }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
      <circle cx={8} cy={8} r={8} fill="#0d8aff" />
      <path
        d="M4.144 8.049l2.542 2.888 5.17-5.873"
        fill="none"
        stroke="#fff"
        strokeLinejoin="round"
        strokeWidth="1.4px"
      />
    </svg>
  );
}

CircleTick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

CircleTick.defaultProps = {
  width: 16,
  height: 16,
};

export default CircleTick;
