// App theme colors
export const themeColor = {
  edRed: '#f72717',
  edLtCobald: '#b0efff',
  edLtMint: '#98f6db',
  edLtOlive: '#dcf691',
  edLtViolet: '#c8c3ff',
  edWhite: '#ffffff',
  edColdWhite: '#f1f7ff',
  edGray: '#6d7787',
  edLtGray: '#d5d8dd',
  edDkGray: '#707070',
  edblue: '#3a8bf7',
};

export default themeColor;
