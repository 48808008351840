/* eslint-disable no-unused-expressions */
/* eslint-disable no-bitwise */
import DOMPurify from 'dompurify';
// App Validations
import { appCurrency, appRegex, inputValidation } from '@utils/Constant';

// String Length Validations
export function validateStringLength(string, min = 1, max = 64) {
  return `${string}`.length >= min && `${string}`.length <= max;
  // return (
  //   appRegex.validateString.test(string) && `${string}`.length >= min && `${string}`.length <= max
  // );
}

// UpperCase Validations
export function validateUpperCase(string) {
  return appRegex.validateUpperCase.test(string);
}

// LowerCase Validations
export function validateLowerCase(string) {
  return appRegex.validateLowerCase.test(string);
}

// Number Validations
export function validateNumber(string) {
  return appRegex.validateNumbers.test(string);
}

// Special Validations
export function validateSpecial(string) {
  return appRegex.validateSpecial.test(string);
}

// Email Validation
export function validateEmail(email) {
  if (appRegex.validateEmail.test(email) && validateStringLength(email, 3, 64)) {
    return inputValidation.VALID;
  }
  return inputValidation.INVALID;
}

// Password Validation
export function validatePassword(string, controlName, password) {
  // Confirm password validation
  if (controlName === 'confirmPassword') {
    return {
      valid:
        password && string && password === string ? inputValidation.VALID : inputValidation.INVALID,
    };
  }

  const hasLength = validateStringLength(string, 8, 20);
  const hasUpperCase = validateUpperCase(string);
  const hasLowerCase = validateLowerCase(string);
  const hasNumber = validateNumber(string);
  const hasSpecial = validateSpecial(string);

  return {
    valid:
      hasLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecial
        ? inputValidation.VALID
        : inputValidation.INVALID,
    [inputValidation.MIN_MAX_ERROR]: hasLength,
    [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
    [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
    [inputValidation.NUMBER_ERROR]: hasNumber,
    [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial,
  };
}

// Password Validation
export function validatePasswordWithKeys(values, firstPasswordKey) {
  // string, controlName, password

  const errors = Object.keys(values).map((controlName) => {
    const string = values[controlName];
    if (!string) {
      return {
        [controlName]: 'Pflichtfeld',
        // otherErrors: {
        //   [inputValidation.MIN_MAX_ERROR]: false,
        //   [inputValidation.UPPER_CASE_ERROR]: false,
        //   [inputValidation.LOWER_CASE_ERROR]: false,
        //   [inputValidation.NUMBER_ERROR]: false,
        //   [inputValidation.SPECIAL_CASE_ERROR]: false
        //   //  [inputValidation.MATCH]: values[firstPasswordKey] === values[confirmPasswordKey]
        // }
      };
    }

    const hasLength = !validateStringLength(values[firstPasswordKey], 8, 20);
    const hasUpperCase = !validateUpperCase(values[firstPasswordKey]);
    const hasLowerCase = !validateLowerCase(values[firstPasswordKey]);
    const hasNumber = !validateNumber(values[firstPasswordKey]);
    const hasSpecial = !validateSpecial(values[firstPasswordKey]);

    if (hasLength || hasUpperCase || hasLowerCase || hasNumber || hasSpecial) {
      return {
        otherErrors: {
          [inputValidation.MIN_MAX_ERROR]: hasLength,
          [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
          [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
          [inputValidation.NUMBER_ERROR]: hasNumber,
          [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial,
          //  [inputValidation.MATCH]: values[firstPasswordKey] === values[confirmPasswordKey]
        },
      };
    }

    /* if (
      (values[firstPasswordKey] || values[confirmPasswordKey]) &&
      values[firstPasswordKey] !== values[confirmPasswordKey]
    ) {
      return { [confirmPasswordKey]: 'match_error' };
    } */

    return {};
  });
  return Object.assign(...errors);
}

// Object Validations
export function validateObject(obj) {
  // because Object.keys(new Date()).length === 0;
  // we have to do some additional check
  return (
    obj && // 👈 null and undefined check
    Object.keys(obj).length === 0 &&
    obj.constructor === Object
  );
}

// Array Validations
export function validateArray(array) {
  return typeof array !== 'undefined' && array != null && array.length != null && array.length > 0;
}

export const toCamel = (str) =>
  str.replace(/([-_][a-z])/gi, ($1) => $1.toUpperCase().replace('-', '').replace('_', ''));

export const isObject = (obj) =>
  obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';

export const keysToCamel = (obj) => {
  if (isObject(obj)) {
    const n = {};

    Object.keys(obj).forEach((k) => {
      n[toCamel(k)] = keysToCamel(obj[k]);
    });

    return n;
  }
  if (Array.isArray(obj)) {
    return obj.map((i) => keysToCamel(i));
  }

  return obj;
};

export const validateBooleanToString = (value) => (value ? '1' : '0');
export const validateStringToBoolean = (value) => value && value === '1';

export const decodeBase64 = (s) => {
  if (!s) return s;
  const e = {};
  let i;
  let b = 0;
  let c;
  let x;
  let l = 0;
  let a;
  let r = '';
  const w = String.fromCharCode;
  const L = s.length;
  const A = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  for (i = 0; i < 64; i += 1) {
    e[A.charAt(i)] = i;
  }
  for (x = 0; x < L; x += 1) {
    c = e[s.charAt(x)];
    b = (b << 6) + c;
    l += 6;
    // eslint-disable-next-line no-unused-expressions
    while (l >= 8) {
      ((a = (b >>> (l -= 8)) & 0xff) || x < L - 2) && (r += w(a));
    }
  }
  return r;
};

export const onlyUnique = (value, index, self) => self.indexOf(value) === index;

export const sortAsc = (array, key) =>
  array.sort((a, b) => {
    if (`${a[key]}`.toLowerCase() < `${b[key]}`.toLowerCase()) {
      return -1;
    }
    if (`${a[key]}`.toLowerCase() > `${b[key]}`.toLowerCase()) {
      return 1;
    }
    return 0;
  });

export const sortDes = (array, key) =>
  array.sort((a, b) => {
    if (`${a[key]}`.toLowerCase() < `${b[key]}`.toLowerCase()) {
      return 1;
    }
    if (`${a[key]}`.toLowerCase() > `${b[key]}`.toLowerCase()) {
      return -1;
    }
    return 0;
  });

export const convertCurrency = (price) => `${price}${appCurrency}`;

export const getMonthFullName = (dateMonth, translatedMonths = []) => {
  const defaultMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = translatedMonths && translatedMonths.length > 0 ? translatedMonths : defaultMonths;
  if (month[dateMonth]) return month[dateMonth];
  return defaultMonths[dateMonth];
};
export const getMonthShortName = (dateMonth) => {
  const month = [
    'Jan',
    'Feb',
    'Mär',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ];
  return month[dateMonth];
};

export const sanitizedDOM = (htmlContent) => (htmlContent ? DOMPurify.sanitize(htmlContent) : '');

// document.write("The current month is " + monthNames[d.getMonth()]);

export default {
  validateUpperCase,
  validateLowerCase,
  validateNumber,
  validateEmail,
  validatePassword,
  validatePasswordWithKeys,
  validateObject,
  validateArray,

  keysToCamel,
  validateBooleanToString,
  validateStringToBoolean,
  decodeBase64,
  onlyUnique,

  sortAsc,
  sortDes,

  getMonthFullName,
  getMonthShortName,
};
