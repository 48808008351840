/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getIcon } from '@utils/SVG';
import { buttonLooks } from '@utils/Constant';

function OutlineButton({
  className,
  label,
  icon,
  look,
  pathFill,
  loading,
  disabled,
  iconComponent,
  onClick,
  onClickLoading,
  svgProps,
  textCenter,
}) {
  const [isLoading, setIsLoading] = useState(loading);

  useEffect(() => {
    if (loading !== isLoading) setIsLoading(loading);

    return () => {
      setIsLoading(false);
    };
  }, [loading]);

  let btnClassName =
    className ||
    'border-2 border-blue-500 font-bold text-blue-500 px-4 py-3 transition duration-300 ease-in-out hover:bg-blue-500 hover:text-white mr-6 flex-center';
  if (icon) {
    btnClassName += ' flex';
  }

  if (look) {
    switch (look) {
      case buttonLooks.ROUNDED:
        btnClassName += ' rounded-lg';
        break;
      case buttonLooks.PILL:
        btnClassName += ' rounded-full';
        break;

      default:
        break;
    }
  }

  const handleClick = () => {
    onClick();
    if (onClickLoading) setIsLoading(!isLoading);
  };

  const checkIcon = () => getIcon({ iconComponent, pathFill, svgProps });

  // const checkIcon = () => {
  //   if (iconComponent === 'CheckMark') return <SVG.CheckMark {...{ pathFill }} />;

  //   if (icon) return <SVG.RightArrow {...{ pathFill }} />;

  //   return <></>;
  // };

  return (
    <button
      type="button"
      className={btnClassName}
      onClick={handleClick}
      disabled={disabled || isLoading}
    >
      {isLoading && <div className="default-btn-loader" />}
      {icon ? (
        <div className={isLoading ? 'opacity-10' : 'w-full flex items-center justify-between'}>
          <span className={`pr-2 ${textCenter ? 'w-full text-center' : ''}`}>{label}</span>
          {checkIcon()}
        </div>
      ) : (
        label
      )}
    </button>
  );
}

OutlineButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClickLoading: PropTypes.bool,
  look: PropTypes.string,
  pathFill: PropTypes.string,
  iconComponent: PropTypes.string,
  svgProps: PropTypes.object,
  textCenter: PropTypes.bool,
  onClick: PropTypes.func,
};

OutlineButton.defaultProps = {
  className: '',
  label: '',
  icon: false,
  loading: false,
  disabled: false,
  onClickLoading: false,
  look: 'null',
  pathFill: '',
  iconComponent: null,
  svgProps: {},
  textCenter: false,
  onClick: () => {},
};

export default OutlineButton;
