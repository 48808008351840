import { HTTP_REQUEST_METHODS } from '@utils/ApiCodex';
import { appStorage } from '@utils/Constant';
import { appAPIHeaders, appAPIUri } from '@utils/Url';
import axios from 'axios';

export default class AuthService {
  env = {};

  /**
   * Set env from app loading with server.
   * @param {JSON} env APP Envs
   */
  constructor(env) {
    this.env = env;
  }

  /**
   * Initiate login process with server
   * @returns API Request
   */
  getLogin() {
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.AUTH.LOGIN}`;
    return axios({ url, method: HTTP_REQUEST_METHODS.GET });
  }

  /**
   * Initiate direct login (Not usable in pkce)
   * @param {string} username Input
   * @param {string} password Input
   * @returns API Request
   */
  processLogin(username, password) {
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.AUTH.LOGIN}`;
    return axios({ url, method: HTTP_REQUEST_METHODS.POST, data: { username, password } });
  }

  /**
   * Verify login and get tokens
   * @param {string} code From redirected query params
   * @param {string} codeVerifier Stored from login api response
   * @returns API Request
   */
  getAccessToken(code, codeVerifier = Storage.get(appStorage.AUTH_CODE_VERIFIER)) {
    // Req Url
    let url = `${this.env.REACT_APP_SERVER}${appAPIUri.AUTH.ACCESS_TOKEN}`;
    // Attatch code and verifier
    if (code && codeVerifier) url += `?code=${code}&codeVerifier=${codeVerifier}`;
    // Default headers
    const headers = appAPIHeaders.DEFAULT_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.GET,
      url,
      headers,
    });
  }

  /**
   * Regenerate access tokens
   * @param {string} refreshToken Stored refresh token
   * @returns API Request
   */
  getRefreshToken(refreshToken) {
    // Req Url
    let url = `${this.env.REACT_APP_SERVER}${appAPIUri.AUTH.REFRESH_TOKEN}`;
    // Attatch code and verifier
    if (refreshToken) url += `?refreshToken=${refreshToken}`;
    // Default headers
    const headers = appAPIHeaders.DEFAULT_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.GET,
      url,
      headers,
    });
  }

  /**
   * Logout user
   * @param {string} refreshToken Stored refresh token
   * @returns API Request
   */
  getLogout(refreshToken, accessToken) {
    // Req Url
    let url = `${this.env.REACT_APP_SERVER}${appAPIUri.AUTH.LOGOUT}`;
    // Attatch code and verifier
    if (refreshToken) url += `?refreshToken=${refreshToken}`;
    if (accessToken) url += `&accessToken=${accessToken}`;
    // Default headers
    const headers = appAPIHeaders.DEFAULT_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.GET,
      url,
      headers,
    });
  }

  /**
   * Send request to generate new link
   * @returns API Request
   */
  processForgotPassword(email) {
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.AUTH.FORGOT_PASSWORD}`;
    const headers = appAPIHeaders.DEFAULT_RAW_JSON;

    return axios({
      url,
      method: HTTP_REQUEST_METHODS.POST,
      headers,
      data: { username: email },
    });
  }

  /**
   * Initiate login process with server
   * @returns API Request
   */
  processResetPassword(data, associationId) {
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.AUTH.RESET_PASSWORD(associationId)}`;
    const headers = appAPIHeaders.DEFAULT_RAW_JSON;

    return axios({
      url,
      method: HTTP_REQUEST_METHODS.POST,
      headers,
      data, //  { user_id: userId, password }
    });
  }

  /**
   * Verify reCAPTCHA token
   * @returns API Request
   */
  verifyRecaptchaToken(token) {
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.AUTH.VERIFY_LOGIN}`;
    const headers = appAPIHeaders.DEFAULT_RAW_JSON;

    return axios({ url, method: HTTP_REQUEST_METHODS.POST, headers, data: { token } });
  }
}
