/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
// import { Route, Navigate } from 'react-router-dom';
import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '@context/AuthProvider';
import { appRoute } from '@utils/Constant';

// function PrivateRoute({ element: Component, path, funcs, ...rest }) {
//   const { isUserLoggedIn } = useAuth();

//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         isUserLoggedIn ? (
//           <Component {...props} {...funcs} />
//         ) : (
//           <Navigate to={{ pathname: appRoute.LOGIN }} />
//         )
//       }
//     />
//   );
// }

function PrivateRoute() {
  const { isUserLoggedIn } = useAuth();

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return isUserLoggedIn ? <Outlet /> : <Navigate to={{ pathname: appRoute.LOGIN }} />;
}

export default PrivateRoute;
