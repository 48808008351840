import { createContext, useState, useEffect, useMemo, useContext } from 'react';
// import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { useHistory } from 'react-router-dom';

import { useAlert } from '@context/Alert';
import { appStorage, appRoute, appAlert } from '@utils/Constant';
import Storage from '@utils/Storage';
import { useLoading } from '@context/Loading';
// import { navigateTo } from '@utils/Url';
import { keysToCamel } from '@utils/Validation';

import AuthService from '@service/Auth';
import { useAppConfig } from '@context/AppConfig';
import { StatusCodes } from '@utils/ApiCodex';
import { navigateTo } from '@utils/Url';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';

// A context will be the way that we allow components lower down
// the tree to trigger the authentication state of user.
const AuthContext = createContext();

// The top level component that will wrap our app's core features
export function AuthProvider({ children }) {
  // State
  // Manage User authentication throught app
  const [isUserLoggedIn, setUserLogin] = useState(!!Storage.get(appStorage.AUTH_TOKEN));
  // Store authenctication creds
  const [authCode, setAuthCode] = useState({});
  // Processing login flag

  const [isLogInLoading, setIsLogInLoading] = useState(false);
  const [isInitialLogin, setIsInitialLogin] = useState(false);

  // const history = useHistory();

  // API services
  const { showAlert } = useAlert();
  const { showLoader, hideLoader } = useLoading();
  const { translate } = useLanguage();

  // Hooks
  // Default Hook
  useEffect(() => {
    setUserLogin(!!Storage.get(appStorage.AUTH_TOKEN));
    setAuthCode({});
    // if (!Storage.get(appStorage.AUTH_TOKEN)) {
    //   history.push(appRoute.LOGIN);
    // }
  }, []);

  // Config
  const { env } = useAppConfig();

  // Functions
  // Handle Loing click
  const onLoginClick = () => {
    setIsLogInLoading(true);
    showLoader();
    // Services
    const authService = new AuthService(env);
    // Verify Recaptcha
    // window.grecaptcha.ready(() => {
    //   window.grecaptcha
    //     .execute(env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' })
    //     .then((token) => {
    //       authService
    //         .verifyRecaptchaToken(token)
    //         .then((verifyResult) => {
    //           if (verifyResult.status === StatusCodes.OK) {
    authService
      .getLogin()
      .then((result) => {
        if (result && result.data && result.data.success) {
          Storage.set(appStorage.AUTH_CODE_VERIFIER, result.data.codeVerifier);
          showAlert({
            type: appAlert.SUCCESS,
            message: translate(LANGUAGE.processLoginSuccessMsg),
          });
          navigateTo(result.data.url);
        }
      })
      .catch((err) => {
        window.console.log(err);
        hideLoader();

        showAlert({
          type: appAlert.ERROR,
          message: translate(LANGUAGE.smtWentWrongMsg),
        });
      });
    //           } else {
    //             hideLoader();
    //             showAlert({ type: appAlert.ERROR, message: translate(LANGUAGE.smtWentWrongMsg) });
    //           }
    //         })
    //         .catch(() => {
    //           hideLoader();
    //           showAlert({ type: appAlert.ERROR, message: translate(LANGUAGE.smtWentWrongMsg) });
    //           // console.log(err);
    //         });
    //     });
    // });
  };

  const performLogin = ({ data }) =>
    new Promise((resolve, reject) => {
      try {
        // Store response into provider state
        setAuthCode(keysToCamel(data));
        Storage.set(appStorage.AUTH_TOKEN, data.access_token);

        // Check if refresh token is available
        if (data.refresh_token) {
          Storage.set(appStorage.AUTH_REFRESH_TOKEN, data.refresh_token);
          setIsInitialLogin(true);
          setUserLogin(true);
        }
        setTimeout(() => {
          resolve(true);
        }, 250);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        reject(error);
      }
    });

  // Handle callback after successfull login
  const onCallbackLogin = (code, codeVerifier) =>
    new Promise((resolve, reject) => {
      showLoader();
      // Services
      const authService = new AuthService(env);

      authService
        .getAccessToken(code, codeVerifier)
        .then((res) => {
          if (res && res.status >= StatusCodes.OK && res.status <= StatusCodes.MULTI_STATUS) {
            Storage.set(appStorage.FIRST_LOGIN, true);
            performLogin(res).then(() => {
              resolve(appRoute.HOME);
            });
          }
        })
        .catch((err) => {
          hideLoader();
          // eslint-disable-next-line no-console
          console.log(err);
          reject(appRoute.LOGIN);
        });
    });

  // Handle callback after successfull login
  const onRefreshToken = () =>
    new Promise((resolve, reject) => {
      showLoader();
      const refreshToken = Storage.get(appStorage.AUTH_REFRESH_TOKEN);
      // Services
      const authService = new AuthService(env);

      authService
        .getRefreshToken(refreshToken)
        .then((res) => {
          if (res && res.status >= StatusCodes.OK && res.status <= StatusCodes.MULTI_STATUS) {
            const { data } = res;
            // Store response into provider state
            setAuthCode(keysToCamel(data));
            Storage.set(appStorage.AUTH_TOKEN, data.access_token);
            // Check if refresh token is available
            if (data.refresh_token) {
              Storage.set(appStorage.AUTH_REFRESH_TOKEN, data.refresh_token);
              setUserLogin(true);
            }
            hideLoader();
            resolve(data.access_token);
          }
        })
        .catch((err) => {
          hideLoader();
          // eslint-disable-next-line no-console
          console.log(err);
          reject(err);
        });
    });

  // Handle logout
  const onLogout = () =>
    new Promise((resolve, reject) => {
      showLoader();
      const refreshToken = Storage.get(appStorage.AUTH_REFRESH_TOKEN);
      const accessToken = Storage.get(appStorage.AUTH_TOKEN);
      // Services
      const authService = new AuthService(env);

      authService
        .getLogout(refreshToken, accessToken)
        .then((res) => {
          if (res.status >= StatusCodes.OK && res.status <= StatusCodes.MULTI_STATUS) {
            // Store response into provider state
            setUserLogin(false);
            setAuthCode({});
            Storage.remove(appStorage.AUTH_TOKEN);
            Storage.remove(appStorage.AUTH_REFRESH_TOKEN);

            Storage.remove(appStorage.AUTH_CODE_VERIFIER);
            Storage.remove(appStorage.AUTH_CODE);
            hideLoader();
            resolve(true);
          } else {
            throw new Error({ error: 'Something went wrong!' });
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          setUserLogin(false);
          setAuthCode({});
          Storage.remove(appStorage.AUTH_TOKEN);
          Storage.remove(appStorage.AUTH_REFRESH_TOKEN);

          Storage.remove(appStorage.AUTH_CODE_VERIFIER);
          Storage.remove(appStorage.AUTH_CODE);
          hideLoader();
          reject(err);
        });
    });

  // Handle Loing click
  const onForgotPasswordClick = (email) => {
    setIsLogInLoading(true);
    showLoader();
    // Services
    const authService = new AuthService(env);

    return authService.processForgotPassword(email);
  };

  // Render the children as normal
  const renderContent = () => children;

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(
    () => ({
      isUserLoggedIn,
      setUserLogin,
      authCode,
      setAuthCode,
      onLoginClick,
      performLogin,
      isLogInLoading,
      setIsLogInLoading,
      isInitialLogin,
      setIsInitialLogin,
      onCallbackLogin,
      onRefreshToken,
      onLogout,
      onForgotPasswordClick,
    }),
    [
      isUserLoggedIn,
      setUserLogin,
      authCode,
      setAuthCode,
      onLoginClick,
      performLogin,
      isLogInLoading,
      setIsLogInLoading,
      isInitialLogin,
      setIsInitialLogin,
      onCallbackLogin,
      onRefreshToken,
      onLogout,
      onForgotPasswordClick,
    ],
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <AuthContext.Provider value={contextPayload}>{renderContent()}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};

AuthProvider.defaultProps = {
  children: null,
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useAuth = () => useContext(AuthContext);
