/* eslint-disable-file */
import { createContext, useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { useAppConfig } from '@context/AppConfig';

import {
  appAlert,
  appAudit,
  appDefaultErrorCode,
  appRoute,
  defaultLandingViews,
  inputValidation,
} from '@utils/Constant';
import {
  decodeBase64,
  validateBooleanToString,
  validateEmail,
  validatePassword,
} from '@utils/Validation';

import OnboardService from '@service/Onboard';
// import { StatusCodes } from '@utils/ApiCodex';
import { useLoading } from '@context/Loading';
import { useAlert } from '@context/Alert';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';
import { StatusCodes } from '@utils/ApiCodex';
import { useAuth } from '@context/AuthProvider';
import { useErrorHandler } from '@context/ErrorHandler';
// import { useErrorHandler } from '@context/ErrorHandler';
// import { getURLQueryParams } from '@utils/Url';

// A context to load all app configuration from server
const SignInContext = createContext();

// The top level component that will wrap our app's core features
export function SignInProvider({ children }) {
  // State
  // Sign in form state.
  const defaultForm = {
    email: '',
    validEmail: false,

    password: '',
    viewPassword: false,
    validPassword: false,

    confirmPassword: '',
    viewConfirmPassword: false,
    validConfirmPassword: false,

    termsCondition: false,
    termsPersonalAds: false,
    subscribNewsLetter: false,
    dataProtection: false,

    touched: false,
    errorMessage: '',
    submit: false,
  };
  const [signInForm, setSignInForm] = useState(defaultForm);
  // View switch between onboarding welcome & sign in
  const [landingView, setLandingView] = useState(defaultLandingViews.ONBOARD_START);
  // User UID Details
  const [onBoardUserDetails, setOnBoardUserDetails] = useState({});

  const { showLoader, hideLoader } = useLoading();
  const { showAlert } = useAlert();
  const { translate } = useLanguage();
  const { performLogin } = useAuth();
  const navigate = useNavigate();
  const { setErrorStatusCode } = useErrorHandler();

  // Effect
  // Default
  useEffect(() => {
    // Reset form on load
    setSignInForm(defaultForm);
  }, []);

  // Config Hook
  const { env } = useAppConfig();
  // Services
  const onboardService = new OnboardService(env);

  // On form value change assign and validate input
  const onChange = (e) => {
    if (e.target.name === 'email') {
      // validateEmail(signInForm.email)
      setSignInForm({
        ...signInForm,
        [e.target.name]: e.target.value,
        validEmail: validateEmail(e.target.value),
        errorMessage: '',
        touched: true,
      });
    } else if (e.target.name === 'password' || e.target.name === 'confirmPassword') {
      setSignInForm({
        ...signInForm,
        [e.target.name]: e.target.value,
        [e.target.name === 'password' ? 'validPassword' : 'validConfirmPassword']: validatePassword(
          e.target.value,
          e.target.name,
          signInForm.password,
        ),
        ...(signInForm.confirmPassword && e.target.name === 'password'
          ? {
              validConfirmPassword: validatePassword(
                signInForm.confirmPassword,
                'confirmPassword',
                e.target.value,
              ),
            }
          : {}),
        touched: true,
      });
    } else {
      setSignInForm({
        ...signInForm,
        [e.target.name]: e.target.value,
        errorMessage: '',
        touched: true,
      });
    }
  };

  // Submited form validate and process with onboarding user.
  const onSubmitClick = () => {
    showLoader();
    setSignInForm({ ...signInForm, submit: true });
    if (
      signInForm.validEmail === inputValidation.VALID &&
      signInForm.validPassword.valid === inputValidation.VALID &&
      signInForm.validConfirmPassword.valid === inputValidation.VALID &&
      signInForm.termsCondition &&
      signInForm.dataProtection
    ) {
      setSignInForm({
        ...signInForm,
        submit: true,
        errorMessage: '',
      });
      const onboardingUserData = {
        UID: onBoardUserDetails.UID,
        employeeEmail: onBoardUserDetails.email,
        personalEmail: signInForm.email,
        password: signInForm.password,
        firstname: onBoardUserDetails.firstname,
        lastname: onBoardUserDetails.lastname,
        salutation: onBoardUserDetails.salutation,
        adv_opted: validateBooleanToString(signInForm.termsPersonalAds),
        news_letter_opted: validateBooleanToString(signInForm.subscribNewsLetter),
        push_notification_opted: '1',
        terms_opted: validateBooleanToString(signInForm.termsCondition),
        data_protection_opted: validateBooleanToString(signInForm.dataProtection),
        // terms_conditions: validateBooleanToString(signInForm.termsCondition),
      };
      // Previous durpal Implementation
      // onboardService
      //   .processUserOnboard(onboardingUserData)
      //   .then((result) => {
      //     hideLoader();
      //     if (result.status === StatusCodes.OK && validateResponse(result)) {
      //       setLandingView(defaultLandingViews.ONBOARD_WELCOME);
      //       showAlert({ type: appAlert.SUCCESS, message: responseMessage(result) || 'Success!' });
      //     } else {
      //       showAlert({
      //         type: appAlert.ERROR,
      //         message: responseMessage(result) || 'Something went wrong!',
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     hideLoader();
      //     setErrorStatusCode(err.status || err.response.status || appDefaultErrorCode.CODE);
      //     // throw new Error(err);
      //   });
      const { assoc } = onBoardUserDetails.queryParams;
      if (!assoc) {
        showAlert({ type: appAlert.ERROR, message: translate(LANGUAGE.invalidReqMsg) });
        hideLoader();
      } else {
        onboardService
          .saveOnboardUserPersonalInfo(assoc, onboardingUserData)
          .then((res) => {
            hideLoader();
            onboardService.saveUserAudit({
              user_email: onboardingUserData.personalEmail,
              type: appAudit.REGISTRATION_COMPLETED,
            });
            showAlert({ type: appAlert.SUCCESS, message: 'Success!' });
            // If same email as onboarding then perform login
            if (onBoardUserDetails.email === signInForm.email) {
              performLogin(res).then(() => {
                onboardService.saveUserAudit({
                  user_email: onboardingUserData.personalEmail,
                  type: appAudit.LOGIN,
                });
                navigate(appRoute.HOME);
              });
            } else {
              setLandingView(defaultLandingViews.ONBOARD_WELCOME);
            }
          })
          .catch((err) => {
            hideLoader();
            showAlert({
              type: appAlert.ERROR,
              message: err.response?.data || translate(LANGUAGE.invalidReqMsg),
            });
          });
      }
    } else {
      setSignInForm({
        ...signInForm,
        submit: true,
        errorMessage: 'Please check your input',
        validEmail: validateEmail(signInForm.email),
        validPassword: validatePassword(signInForm.password, 'password', signInForm.password),
        validConfirmPassword: validatePassword(
          signInForm.confirmPassword,
          'confirmPassword',
          signInForm.password,
        ),
      });
      hideLoader();
    }
  };

  // On landing with url from email
  // const onLoadBoardingUser = (_url) => {
  //   showLoader();
  //   onboardService
  //     .getUserUid(_url)
  //     .then((result) => {
  //       if (result.status === StatusCodes.OK) {
  //         const {
  //           data: { email, url },
  //         } = result;
  //         const decodeEmail = decodeBase64(email);
  //         const queryParams = getURLQueryParams(url);
  //         setOnBoardUserDetails({ email: decodeEmail, url, queryParams });
  //         setSignInForm({
  //           ...signInForm,
  //           email: decodeEmail,
  //           validEmail: validateEmail(decodeEmail),
  //         });
  //         // Additional fetch user personal information
  //         onboardService
  //           .getOnboardUserPersonalInfo(queryParams.assoc)
  //           .then((personalInformation) => {
  //             setOnBoardUserDetails({
  //               email: decodeEmail,
  //               url,
  //               queryParams,
  //               ...personalInformation.data,
  //             });
  //           })
  //           .catch((err) => {
  //             if ((err.status || err.response.status || appDefaultErrorCode.CODE) === 400) {
  //               // setErrorStatusCode(appDefaultErrorCode.NOT_FOUND);
  //               showAlert({
  //                 type: appAlert.ERROR,
  //                 message: err.response?.data || 'Invalid request',
  //               });
  //             } else {
  //               // setErrorStatusCode(err.status || err.response.status || appDefaultErrorCode.CODE);
  //               showAlert({
  //                 type: appAlert.ERROR,
  //                 message: err.response?.data || 'Invalid request',
  //               });
  //             }
  //           });
  //       }
  //       hideLoader();
  //     })
  //     .catch((err) => {
  //       if ((err.status || err.response.status || appDefaultErrorCode.CODE) === 400) {
  //         setErrorStatusCode(appDefaultErrorCode.NOT_FOUND);
  //       } else {
  //         setErrorStatusCode(err.status || err.response.status || appDefaultErrorCode.CODE);
  //       }
  //       // throw new Error(err);
  //     });
  // };

  const onLoadBoardingUser = ({ email, assoc }) => {
    showLoader();
    // onboardService
    //   .getUserUid(_url)
    //   .then((result) => {
    // if (result.status === StatusCodes.OK) {
    // const {
    //   data: { email, url },
    // } = result;
    const decodeEmail = decodeBase64(email);
    // const queryParams = getURLQueryParams(url);
    setOnBoardUserDetails({ email: decodeEmail });
    setSignInForm({
      ...signInForm,
      email: decodeEmail,
      validEmail: validateEmail(decodeEmail),
    });
    // Additional fetch user personal information
    onboardService
      .getOnboardUserPersonalInfo(assoc)
      .then((personalInformation) => {
        onboardService.saveUserAudit({
          user_email: decodeEmail,
          type: appAudit.ONBOARDING_CONFIRMATION,
        });
        setOnBoardUserDetails({
          // email: decodeEmail,
          // url,
          queryParams: { assoc },
          ...personalInformation.data,
          email: personalInformation.data.employeeEmail,
        });
        setSignInForm({
          ...signInForm,
          email: personalInformation.data.employeeEmail,
          validEmail: validateEmail(personalInformation.data.employeeEmail),
        });
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        // setErrorStatusCode(err?.response?.status || err?.status || appDefaultErrorCode.NOT_FOUND);
        setErrorStatusCode(appDefaultErrorCode.NOT_FOUND);
      });
  };

  const confirmEmail = (id, email) => {
    showLoader();
    onboardService
      .getVerifyEmail(id)
      .then((result) => {
        hideLoader();
        if (result.status >= StatusCodes.OK && result.status <= StatusCodes.MULTI_STATUS) {
          onboardService.saveUserAudit({
            user_email: decodeBase64(email),
            type: appAudit.RESET_EMAIL_CONFIRMATION,
          });
          showAlert({ type: appAlert.SUCCESS, message: translate(LANGUAGE.updatedSuccess) });
          navigate(appRoute.LOGIN);
        } else {
          showAlert({ type: appAlert.ERROR, message: translate(LANGUAGE.invalidRequest) });
          navigate(appRoute.LANDING);
        }
      })
      .catch((err) => {
        hideLoader();
        navigate(appRoute.NOT_FOUND);
        console.log(err);
      });
  };

  // Render the children as normal
  // TODO: Implemente loading if required.
  const renderContent = () => children;

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(
    () => ({
      signInForm,
      setSignInForm,

      landingView,
      setLandingView,
      onChange,
      onSubmitClick,

      onBoardUserDetails,
      onLoadBoardingUser,
      confirmEmail,
    }),
    [
      signInForm,
      setSignInForm,
      landingView,
      setLandingView,
      onChange,
      onSubmitClick,

      onBoardUserDetails,
      onLoadBoardingUser,
      confirmEmail,
    ],
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <SignInContext.Provider value={contextPayload}>{renderContent()}</SignInContext.Provider>;
}

SignInProvider.propTypes = {
  children: PropTypes.node,
};

SignInProvider.defaultProps = {
  children: null,
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useSignIn = () => useContext(SignInContext);
