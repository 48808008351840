// Default app landing page.
import React from 'react';
import PropTypes from 'prop-types';

import Button from '@core/Button';
import Card from '@core/Card';
import Input from '@core/Input';

import './style.scss';
import AnimateLoad from '@part/AnimateLoad';
import {
  animationStyle,
  backgroundColor,
  borderRadius,
  buttonLooks,
  inputValidation,
} from '@utils/Constant';
import SVG from '@utils/SVG';
import { useResetPassword } from '@context/ResetPassword';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';

function ResetPassowrdForm({ assoc }) {
  const { resetPasswordForm, onSubmitClick, onChange } = useResetPassword();
  const { translate } = useLanguage();
  const visibleFormField = (field) => {
    onChange({
      target: {
        name: field,
        value: !resetPasswordForm[field],
      },
    });
  };

  const viewValidatePassword = (type) => {
    const isValid = resetPasswordForm.validPassword;
    if (isValid && isValid[type]) {
      return <SVG.CircleTick />;
    }
    return <SVG.CircleX />;
  };

  return (
    <div className="reset-password-ed my-24">
      <AnimateLoad style={animationStyle.FADE_IN}>
        <Card bgColor={backgroundColor.WHITE} bdRadius={borderRadius.DEFAULT} bgShadow>
          {resetPasswordForm.errorMessage && (
            <div
              className={`px-1 py-5 bg-ed-red text-ed-white relative flex-center ${borderRadius.DEFAULT} ${borderRadius.REMOVE_BOTTOM}`}
            >
              <div className="absolute left-0 px-4">
                <SVG.CircleExclamation />
              </div>
              <div className="w-full flex-1 flex-center">
                <span className="text-18 text-ed-white text-ed-regular">
                  Please check your input
                </span>
              </div>
            </div>
          )}
          <div className="reset-password-ed-card h-fit-content flex-center flex-col">
            <div className="reset-password-ed-card-content w-full py-2">
              <h1 className="text-ed-gray text-24 text-ed-bold w-full text-center leading-none">
                Neue Passwort vergeben
              </h1>
              <h2 className="text-ed-gray text-18 text-ed-regular w-full text-center leading-none">
                Geben Sie ein neues Passwort ein
              </h2>
            </div>
            <div className="reset-password-ed-card-content w-full pt-5">
              <div className="form-input">
                <Input
                  type={resetPasswordForm.viewPassword ? 'text' : 'password'}
                  icon
                  iconComponent={resetPasswordForm.viewPassword ? 'EyeOff' : 'Eye'}
                  name="password"
                  validation={
                    resetPasswordForm.validPassword ? resetPasswordForm.validPassword.valid : ''
                  }
                  value={resetPasswordForm.password}
                  placeholder={translate(LANGUAGE.password)}
                  onChange={onChange}
                  onIconClick={() => visibleFormField('viewPassword')}
                />
              </div>
              <div className="form-input">
                <Input
                  type={resetPasswordForm.viewConfirmPassword ? 'text' : 'password'}
                  icon
                  iconComponent={resetPasswordForm.viewConfirmPassword ? 'EyeOff' : 'Eye'}
                  name="confirmPassword"
                  validation={
                    resetPasswordForm.validConfirmPassword
                      ? resetPasswordForm.validConfirmPassword.valid
                      : ''
                  }
                  value={resetPasswordForm.confirmPassword}
                  placeholder={translate(LANGUAGE.confirmPassword)}
                  onChange={onChange}
                  onIconClick={() => visibleFormField('viewConfirmPassword')}
                />
              </div>
            </div>
            <div className="reset-password-ed-card-foot w-full pt-2">
              <Card bgColor={backgroundColor.LIGHT_COBALD} bdRadius={borderRadius.LARGE}>
                <div className="reset-password-ed-verification-list">
                  <h6 className="text-12 text-ed-gray text-ed-bold">
                    {translate(LANGUAGE.yourPasswordMust)}
                  </h6>
                  <div className="grid grid-cols-12 ">
                    {resetPasswordForm.password && (
                      <div className="col-span-1 flex-center">
                        {viewValidatePassword(inputValidation.MIN_MAX_ERROR)}
                      </div>
                    )}
                    <div className="col-span-11 flex-start-center">
                      <span className="text-12 text-ed-gray text-ed-regular">
                        {translate(LANGUAGE.eightTo_20Chars)}
                      </span>
                    </div>
                    {resetPasswordForm.password && (
                      <div className="col-span-1 flex-center">
                        {viewValidatePassword(inputValidation.UPPER_CASE_ERROR)}
                      </div>
                    )}
                    <div className="col-span-11 flex-start-center">
                      <span className="text-12 text-ed-gray text-ed-regular">
                        {translate(LANGUAGE.atleastOneUpperCaseLetter)}
                      </span>
                    </div>
                    {resetPasswordForm.password && (
                      <div className="col-span-1 flex-center">
                        {viewValidatePassword(inputValidation.LOWER_CASE_ERROR)}
                      </div>
                    )}
                    <div className="col-span-11 flex-start-center">
                      <span className="text-12 text-ed-gray text-ed-regular">
                        {translate(LANGUAGE.atleastOneLowerCaseLetter)}
                      </span>
                    </div>
                    {resetPasswordForm.password && (
                      <div className="col-span-1 flex-center">
                        {viewValidatePassword(inputValidation.NUMBER_ERROR)}
                      </div>
                    )}
                    <div className="col-span-11 flex-start-center">
                      <span className="text-12 text-ed-gray text-ed-regular">
                        {translate(LANGUAGE.atleasrOneNum)}
                      </span>
                    </div>
                    {resetPasswordForm.password && (
                      <div className="col-span-1 flex-center">
                        {viewValidatePassword(inputValidation.SPECIAL_CASE_ERROR)}
                      </div>
                    )}
                    <div className="col-span-11 flex-start-center">
                      <span className="text-12 text-ed-gray text-ed-regular">
                        {translate(LANGUAGE.atleastOneSpecialChar)}
                      </span>
                    </div>
                  </div>
                </div>
              </Card>

              <div className="reset-password-submit flex-center py-5">
                <Button
                  className="relative bg-ed-red texted-regular text-white text-24 px-12 py-2 flex-center "
                  label={translate(LANGUAGE.changePassword)}
                  look={buttonLooks.PILL}
                  onClick={() => onSubmitClick(assoc)}
                />
              </div>
            </div>
          </div>
        </Card>
      </AnimateLoad>
    </div>
  );
}

ResetPassowrdForm.propTypes = {
  assoc: PropTypes.string.isRequired,
};

export default ResetPassowrdForm;
