// Default app landing page.
import React from 'react';
import Button from '@core/Button';
import AnimateLoad from '@part/AnimateLoad';
import {
  animationDelay,
  animationStyle,
  appDefaultImages,
  appRoute,
  buttonLooks,
  defaultLandingViews,
} from '@utils/Constant';

import './style.scss';
import { useSignIn } from '@context/SignIn';
import { Link } from 'react-router-dom';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';

function OnboardNow() {
  const { setLandingView } = useSignIn();

  const { translate } = useLanguage();

  return (
    <div className="flex-above-center pb-20">
      <AnimateLoad style={animationStyle.FADE_IN} delay={animationDelay.DELAY_1S}>
        <div className="onboard-view max-w-screen-sm pt-2">
          <div className="onboard-container">
            <div className="onboard-img-container py-5 flex-center -ml-1">
              <img src={appDefaultImages.ONBOARD_PAGE_2X} alt="Onboard" />
            </div>
            <div className="text-center w-full">
              <h3 className="text-ed-gray text-ed-bold text-24 pb-1">
                {translate(LANGUAGE.greetingsTraveller)}
              </h3>
              <h4 className="text-ed-gray text-ed-light text-18">
                {translate(LANGUAGE.completeYourOnboardingNow)}
              </h4>
            </div>
            <div className="flex-center w-full my-6">
              <Button
                className="relative bg-ed-red text-ed-regular text-white text-24 px-14 py-3 flex-center"
                label={translate(LANGUAGE.onboardNow)}
                look={buttonLooks.PILL}
                onClick={() => setLandingView(defaultLandingViews.SIGN_IN)}
              />
            </div>
            <div className="text-center">
              <h4 className="text-ed-gray text-ed-regular text-18">
                {translate(LANGUAGE.alreadyHaveAn)}
                <Link to={appRoute.LOGIN}>
                  <strong className="pl-1">
                    <u>{translate(LANGUAGE.accountQ)}</u>
                  </strong>
                </Link>
              </h4>
            </div>
          </div>
        </div>
      </AnimateLoad>
    </div>
  );
}

export default OnboardNow;
