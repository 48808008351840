import React from 'react';
import PropTypes from 'prop-types';

function CheckMark({ pathFill, width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={pathFill}
        d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
      />
    </svg>
  );
}

CheckMark.propTypes = {
  pathFill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

CheckMark.defaultProps = {
  pathFill: '#6D7787',
  width: null,
  height: null,
};

export default CheckMark;
