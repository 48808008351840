import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import SVG from '@utils/SVG';
import { withAnimate } from '@part/AnimateLoad';
import SVG from '@utils/SVG';
import { themeColor } from '@theme/common/color';
// import { animationStyle } from '@utils/Constant';

function Checkbox({ checked, disabled, name, readOnly, required, onChange, isInvalid }) {
  const [isChecked, setIsChecked] = useState(checked);

  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (checked !== isChecked) setIsChecked(checked);
  }, [checked]);

  const onClick = () => {
    // const value = isChecked;
    // setIsChecked(!isChecked);
    onChange({ target: { name, value: !isChecked } });
    setTouched(true);
  };

  return (
    <div className="relative flex w-full bg-transparent shadow-none outline-none">
      <button type="button" className="w-7 h-7 flex-center rounded-full" onClick={onClick}>
        <input
          type="checkbox"
          className="opacity-0 absolute"
          checked={isChecked}
          onChange={() => {}}
          {...{
            disabled,
            name,
            readOnly,
            required,
          }}
        />
        {isChecked ? (
          <SVG.CircleTick />
        ) : (
          <SVG.CircleRound
            {...{
              pathFill:
                (touched && required && !isChecked) || isInvalid
                  ? themeColor.edRed
                  : themeColor.edDkGray,
            }}
          />
        )}
      </button>
    </div>
  );
}

Checkbox.propTypes = {
  // Checkbox Props
  checked: PropTypes.bool,
  disabled: PropTypes.bool,

  name: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  name: '',
  readOnly: false,
  required: false,
  isInvalid: false,
  onChange: () => {},
};

export default withAnimate(Checkbox);
