/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { withAnimate } from '@part/AnimateLoad';
import { appDefaultImages } from '@utils/Constant';
// import { Link } from 'react-router-dom';
import { useAppConfig } from '@context/AppConfig';
import { useLayout } from '@context/Layout';
import { Link } from 'react-router-dom';
import { getIcon } from '@utils/SVG';
import { useAuth } from '@context/AuthProvider';

// import PropTypes from 'prop-types'

function AppFooter() {
  const { footerLinks } = useAppConfig();
  const { mobileNavList, isMobile, windowDimensions, showMobileMenu } = useLayout();
  const { isUserLoggedIn } = useAuth();

  const [footer2DLinks, setFooter2DLinks] = useState([]);

  const navigateTo = (url) => (window.location.href = url);

  useEffect(() => {
    let arrIndex = 0;
    const footer2dLinks = [];
    footerLinks.forEach((elLink) => {
      footer2dLinks[arrIndex] = !footer2dLinks[arrIndex] ? [] : footer2dLinks[arrIndex];
      footer2dLinks[arrIndex].push(elLink);
      if (footer2dLinks[arrIndex].length === 2) {
        arrIndex += 1;
      }
    });
    setFooter2DLinks(footer2dLinks);
  }, [footerLinks]);

  const renderNavList = () =>
    mobileNavList.map((nav) => (
      <div
        className="app-ed-nav-menu [border-bottom-style:dashed] border-edenredgrey flex items-center gap-[10px] h-[123px] border-t-[3px]  px-2 md:pl-4"
        key={`app_nav_menu_${nav.title}`}
      >
        <Link
          to={nav.to}
          className="app-ed-nav-link text-18 text-ed-gray text-ed-regular flex rounded-full"
        >
          <div
            className={`app-ed-nav-icon ${
              nav.active ? 'bg-ed-red active' : 'bg-ed-white '
            } text-ed-gray flex-center`}
          >
            {getIcon({
              iconComponent: nav.active ? nav.iconActive : nav.icon,
              svgProps: { width: 25, height: 25 },
            })}
          </div>
          {/* svgProps={{ width: isSM ? 30 : 18, height: isSM ? 30 : 18 }}
            iconComponent={nav.active ? nav.iconActive : nav.icon} */}
          <span
            className={`app-ed-nav-label ${
              nav.active ? 'text-ed-red active' : 'text-ed-gray'
            } items-center justify-center`}
          >
            {nav.title}
          </span>
        </Link>
      </div>
    ));
  return (
    <div
      className={`app-ed-footer ${windowDimensions.width} showMobileMenu=${showMobileMenu} ${windowDimensions.width < 576 ? (showMobileMenu ? '' : 'hidden') : ''} bg-ed-white ${
        isMobile && !isUserLoggedIn ? 'flex flex-col py-4' : 'sm:flex items-center justify-center'
      }`}
    >
      {/* Desktop Footer */}
      <div className="hidden sm:flex">
        <div className="app-ed-footer-container w-full">
          <div className="footer-nav flex-space-around px-12 md:px-0 xs:flex-rows">
            {footerLinks.map((link, i) => (
              <button
                key={`footer_link_${link.title}_${i + 1}`}
                className="text-14 md:text-16 text-ed-regular text-ed-dk-gray"
                // to={}
                onClick={() =>
                  navigateTo(link.content.replace('https://{{url}}', window.location.origin))
                }
                type="button"
              >
                {link.title}
              </button>
            ))}
          </div>
          <div className="app-ed-footer-logo flex-center my-3">
            <img src={appDefaultImages.FOOTER_LOGO} alt="Edenred Logo" />
          </div>
        </div>
      </div>
      {/* Mobile Footer */}
      {isUserLoggedIn && showMobileMenu ? (
        <div className="flex sm:hidden">
          <div className="app-ed-nav-list w-full bg-ed-white flex-center">
            <nav className="w-full flex item-center justify-between mobile-footer">
              {renderNavList()}
            </nav>
          </div>
        </div>
      ) : isMobile ? (
        footer2DLinks.map((links) => (
          <div className="footer-nav">
            {links.map((link, i) => (
              <>
                <button
                  key={`footer_link_${link.title}_${i + 1}`}
                  className="text-14 md:text-16 text-ed-regular text-ed-dk-gray"
                  onClick={() =>
                    navigateTo(link.content.replace('https://{{url}}', window.location.origin))
                  }
                  type="button"
                >
                  {link.title}
                </button>
                {i === 0 && isMobile && <span>|</span>}
              </>
            ))}
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
}

// AppFooter.propTypes = {
//   children: PropTypes.node.isRequired,
// };

export default withAnimate(AppFooter);
