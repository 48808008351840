import React from 'react';
import './style.scss';
import PropTypes from 'prop-types';

function Voucher({ children, style }) {
  return (
    <div className="voucher my-2 relative" {...{ style }}>
      {children}
    </div>
  );
}

Voucher.propTypes = {
  children: PropTypes.node,
  style: PropTypes.shape({
    zIndex: PropTypes.number,
  }),
};

Voucher.defaultProps = {
  children: null,
  style: {},
};
export default Voucher;
