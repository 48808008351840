import { createContext, useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
// import PageNotFound from '@page/404';
import PageDidNotUnderstood from '@page/500';
import { appAlert, appDefaultAlertMessage, appDefaultErrorCode } from '@utils/Constant';
import { StatusCodes } from '@utils/ApiCodex';
import { useAlert } from '@context/Alert';

// A context will be the way that we allow components lower down
// the tree to trigger the display of an error page
const ErrorStatusContext = createContext();

// The top level component that will wrap our app's core features
export function ErrorHandler({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [errorStatusCode, setErrorStatusCode] = useState();

  const { showAlert } = useAlert();

  // Make sure to "remove" this status code whenever the user
  // navigates to a new URL. If we didn't do that, then the user
  // would be "trapped" into error pages forever
  useEffect(() => {
    // Listen for changes to the current location.
    // const unlisten = history.listen(() => setErrorStatusCode(undefined));
    setErrorStatusCode(undefined);
    // cleanup the listener on unmount
    // return unlisten;
  }, [location]);

  useEffect(() => {
    // Check if specific error occured
    // Resource not found
    if (errorStatusCode === StatusCodes.NOT_FOUND) {
      showAlert({ type: appAlert.ERROR, message: appDefaultAlertMessage.ERROR_NOT_FOUND });
      navigate('/404');
    }

    // Unauthorised Access
    if (errorStatusCode === StatusCodes.UNAUTHORIZED) {
      showAlert({ type: appAlert.ERROR, message: appDefaultAlertMessage.ERROR_NOT_FOUND });
    }
  }, [errorStatusCode]);

  // This is what the component will render. If it has an
  // errorStatusCode that matches an API error, it will only render
  // an error page. If there is no error status, then it will render
  // the children as normal
  const renderContent = () => (
    <>
      {errorStatusCode === appDefaultErrorCode.CODE && <PageDidNotUnderstood />}
      {children}
    </>
  );
  //  const renderContent = () => {
  //   // if (errorStatusCode === 404) {
  //   //   return <PageNotFound />;
  //   // }

  //   // ... more HTTP codes handled here
  //   // if (errorStatusCode === appDefaultErrorCode.CODE) {
  //   //   return <PageDidNotUnderstood />;
  //   // }

  //   // return children;
  //   return (
  //     <>
  //     {errorStatusCode === appDefaultErrorCode.CODE && <PageDidNotUnderstood />}
  //     {children}
  //     </>
  //   )
  // };

  // We wrap it in a useMemo for performance reasons. More here:
  // https://kentcdodds.com/blog/how-to-optimize-your-context-value/
  const contextPayload = useMemo(() => ({ setErrorStatusCode }), [setErrorStatusCode]);

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <ErrorStatusContext.Provider value={contextPayload}>
      {renderContent()}
    </ErrorStatusContext.Provider>
  );
}

ErrorHandler.propTypes = {
  children: PropTypes.node,
};

ErrorHandler.defaultProps = {
  children: null,
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useErrorHandler = () => useContext(ErrorStatusContext);
