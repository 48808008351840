import React from 'react';
import { useLanguage } from '@context/Language';
import Button from '@core/Button';
import Input from '@core/Input';
import { withAnimate } from '@part/AnimateLoad';
import {
  appIcons,
  appListViewTypes,
  buttonLooks,
  buttonTypes,
  inputViewSizes,
} from '@utils/Constant';
import { LANGUAGE } from '@utils/Constant/language';
import PartnerVoucher from '@part/Voucher/PartnerVoucher';
import PartnerListVoucher from '@part/Voucher/PartnerListVoucher';

import './style.scss';
import { useMyVoucher } from '@context/MyVoucher';
import { getIcon } from '@utils/SVG';

function MyVoucherPage() {
  const { translate } = useLanguage();

  const {
    myVoucher,
    voucherViewList,
    appliedFilter,
    onRedeemChange,
    openFilter,
    filter,
    searchVoucher,
    navigateToDetails,
  } = useMyVoucher();

  const onChange = (e) => {
    onRedeemChange(e);
  };

  const onSearch = (e) => searchVoucher(e.target.value);

  const getNoVoucher = () => (
    <div className="col-span-2 w-full flex-col-center py-6">
      {getIcon({
        iconComponent: appIcons.NO_VOUCHER_AVAILABLE,
        svgProps: { width: 63, height: 63 },
      })}
      <p className="text-24 text-ed-gray text-ed-regular" style={{ zIndex: 1 }}>
        {translate(LANGUAGE.tempNoVoucherH)}
      </p>
      <p className="text-18 text-ed-gray text-ed-light" style={{ zIndex: 1 }}>
        {translate(LANGUAGE.inFutureVoucher)}
      </p>
    </div>
  );

  const blockView = () =>
    voucherViewList.map(({ year, vouchers }) => (
      <div key={`voucher_list_${year}`}>
        <div className="year-selection dotted-line-with-text">
          <span className="text-14 text-ed-bold text-ed-gray">{year}</span>
        </div>
        <div className="available-voucher text-center grid grid-cols-2">
          {vouchers.length > 0
            ? vouchers.map((voucher, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`partner-${voucher.voucherId}_${i}`}>
                  <PartnerVoucher
                    {...{
                      voucher,
                      onChange,
                      isAvailableVoucher: true,
                      onClick: navigateToDetails,
                      showDate: true,
                    }}
                  />
                </div>
              ))
            : getNoVoucher()}
        </div>
      </div>
    ));

  const listView = () =>
    voucherViewList.map(({ year, vouchers }) => (
      <div key={`voucher_list_${year}`}>
        <div className="year-selection w-3/12 text-center">
          <span className="text-14 text-ed-bold text-ed-gray">{year}</span>
        </div>
        <div className="available-list-voucher grid grid-flow-row">
          {vouchers.length > 0
            ? vouchers.map((voucher, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`partner-${voucher.voucherId}_${i}`}>
                  <PartnerListVoucher {...{ voucher, onChange }} />
                </div>
              ))
            : getNoVoucher()}
        </div>
      </div>
    ));

  const getView = () =>
    appliedFilter.view === appListViewTypes.LIST_VIEW ? listView() : blockView();

  return (
    <div className="flex-center bg-ed-cold-white">
      <div className="app-ed-page bg-ed-voucher">
        <div className="app-ed-page-content w-full py-16">
          <div className="sm:pb-8">
            <div className="grid grid-cols-12 gap-y-4">
              <div className="col-span-12 sm:col-span-6 text-left">
                <h2 className="text-24 text-ed-gray text-ed-bold" style={{ zIndex: 1 }}>
                  {translate(LANGUAGE.myVoucher)}
                </h2>
              </div>
              <div className="col-span-12 sm:col-span-6">
                <div className="w-full flex items-start justify-between">
                  <div className="w-9/12 md:w-10/12">
                    <div className="form-input">
                      <Input
                        type="text"
                        icon
                        iconComponent={appIcons.SEARCH}
                        viewSize={inputViewSizes.FIXED}
                        value={filter.searchText}
                        name="search"
                        placeholder={translate(LANGUAGE.search)}
                        onChange={onSearch}
                      />
                    </div>
                  </div>
                  <div className="w-2/12 flex-center-end">
                    <Button
                      type={buttonTypes.FAB}
                      className="flex bg-ed-white text-ed-gray h-12 w-12 md:px-3 md:py-3  flex-center rounded-full"
                      icon
                      look={buttonLooks.CIRCLE}
                      iconComponent={appIcons.FILTER}
                      svgProps={{ width: 20, height: 20 }}
                      onClick={() => openFilter()}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-y-4">
              <div className="col-span-12 sm:col-span-6 text-left pt-6 selected-voucher">
                {myVoucher && (
                  <PartnerVoucher
                    {...{
                      isAvailableVoucher: false,
                      showDate: true,
                      onChange,
                      voucher: myVoucher,
                      isMainVoucher: true,
                      onClick: navigateToDetails,
                    }}
                  />
                )}
              </div>
              <div className="col-span-12 sm:col-span-6 text-start voucher-list">{getView()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAnimate(MyVoucherPage);
