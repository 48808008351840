import { HTTP_REQUEST_METHODS } from '@utils/ApiCodex';
import { appAPIUri } from '@utils/Url';
import axios from 'axios';

export default class LanguageService {
  env = {};

  /**
   * Set env from app loading with server.
   * @param {JSON} env APP Envs
   */
  constructor(env) {
    this.env = env;
  }

  /**
   * Initiate login process with server
   * @returns API Request
   */
  // eslint-disable-next-line class-methods-use-this
  getLanguageDictionary(lanCode) {
    const url = `${window?.location?.origin}${appAPIUri.STATIC.LANGUAGE}${lanCode}.json`;
    return axios({ url, method: HTTP_REQUEST_METHODS.GET });
  }

  /**
   * Fetch static content
   * @returns API Request
   */
  getStaticContent() {
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.STATIC.CONTENT}`;
    return axios({ url, method: HTTP_REQUEST_METHODS.GET });
  }
}
