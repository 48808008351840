/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useToast } from '@context/Toast';
import { animationStyle, appToast } from '@utils/Constant';
import AnimateLoad from '@part/AnimateLoad';

function AppToast({ children }) {
  const { toasts } = useToast();
  if (!toasts) {
    return <></>;
  }

  const SuccessToast = ({ message, hide }, i) => (
    <AnimateLoad
      style={`${hide ? animationStyle.FADE_OUT : animationStyle.FADE_IN}`}
      key={`alert_${message}_${i}`}
    >
      <div className="flex items-center text-ed-white max-w-sm w-full bg-ed-lt-mint shadow-md rounded-lg overflow-hidden mx-auto">
        <div className="w-10 border-r px-2">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
            />
          </svg>
        </div>

        <div className="flex items-center px-2 py-3">
          <div className="mx-3">
            <p>{message}</p>
          </div>
        </div>
      </div>
    </AnimateLoad>
  );

  const ErrorToast = ({ message, hide }, i) => (
    <AnimateLoad
      style={`${hide ? animationStyle.FADE_OUT : animationStyle.FADE_IN}`}
      key={`alert_${message}_${i}`}
    >
      <div className="flex items-center text-ed-white max-w-sm w-full bg-ed-red shadow-md rounded-lg overflow-hidden mx-auto">
        <div className="w-10 border-r px-2">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
            />
          </svg>
        </div>

        <div className="flex items-center px-2 py-3">
          <div className="mx-3">
            <p>{message}</p>
          </div>
        </div>
      </div>
    </AnimateLoad>
  );

  const WarningToast = ({ message, hide }, i) => (
    <AnimateLoad
      style={`${hide ? animationStyle.FADE_OUT : animationStyle.FADE_IN}`}
      key={`alert_${message}_${i}`}
    >
      <div className="flex items-center text-bg-white max-w-sm w-full bg-ed-lt-olive shadow-md rounded-lg overflow-hidden mx-auto">
        <div className="w-10 border-r px-2">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01"
            />
          </svg>
        </div>

        <div className="flex items-center px-2 py-3">
          <div className="mx-3">
            <p>{message}</p>
          </div>
        </div>
      </div>
    </AnimateLoad>
  );

  const InfoToast = ({ message, hide }, i) => (
    <AnimateLoad
      style={`${hide ? animationStyle.FADE_OUT : animationStyle.FADE_IN}`}
      key={`alert_${message}_${i}`}
    >
      <div className="flex items-center text-ed-white max-w-sm w-full bg-ed-blue shadow-md rounded-lg overflow-hidden mx-auto">
        <div className="w-10 border-r px-2">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>

        <div className="flex items-center px-2 py-3">
          <div className="mx-3">
            <p>{message}</p>
          </div>
        </div>
      </div>
    </AnimateLoad>
  );

  const showToast = (al, i) => {
    let defaultToast = <></>;
    switch (al.type) {
      case appToast.SUCCESS:
        defaultToast = SuccessToast(al, i);
        break;
      case appToast.ERROR:
        defaultToast = ErrorToast(al, i);
        break;
      case appToast.WARNING:
        defaultToast = WarningToast(al, i);
        break;

      default:
        // appToast.INFO
        defaultToast = InfoToast(al, i);
        break;
    }
    return defaultToast;
  };

  return (
    <div className="relative w-full h-full">
      <div className="absolute right-20 top-10 z-10 grid grid-cols-1 gap-2 justify-center items-center">
        {toasts.map(showToast)}
      </div>
      {children}
    </div>
  );
}

AppToast.propTypes = {
  children: PropTypes.node.isRequired,
};

// HOC
export const withToast = (Component) => (props) => (
  <AppToast>
    {/* All props are passed through to the Component being wrapped */}
    <Component {...props} />
  </AppToast>
);

export default AppToast;
