import * as React from 'react';
import PropTypes from 'prop-types';

function CircleRound({ width, height, pathFill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
      <g stroke={pathFill} fill="none">
        <circle cx={8} cy={8} r={8} stroke="none" />
        <circle cx={8} cy={8} r={7.5} />
      </g>
    </svg>
  );
}

CircleRound.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  pathFill: PropTypes.string,
};

CircleRound.defaultProps = {
  width: 16,
  height: 16,
  pathFill: '#6d7787',
};

export default CircleRound;
