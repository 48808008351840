import React from 'react';
import { useNavigate } from 'react-router-dom';
import { withAnimate } from '@part/AnimateLoad';
import { useLayout } from '@context/Layout';

import Input from '@core/Input';
import {
  appIcons,
  appListViewTypes,
  appRoute,
  buttonLooks,
  buttonTypes,
  inputViewSizes,
} from '@utils/Constant';
import Button from '@core/Button';
import PartnerViewCard from '@part/Card/PartnerViewCard';
import SelectedPartnerCard from '@part/Card/SelectedPartnerCard';
import Accordion from '@part/Accordion';

import './style.scss';
import PartnerListViewCard from '@part/Card/PartnerListViewCard';
import { usePartner } from '@context/Partner';
import { useLanguage } from '@context/Language';
import { LANGUAGE } from '@utils/Constant/language';

function PartnerPage() {
  const { isSM } = useLayout();

  const {
    filter,
    appliedFilter,
    openFilter,
    viewPartnerList,
    selectedPartner,
    searchPartner,
    viewPartnerFaq,
    selectedPartnerId,
  } = usePartner();

  const navigate = useNavigate();

  const { translate } = useLanguage();

  const navigateToDetails = (id) => navigate(appRoute.PARTNER_DETAIL(id));

  const blockView = () => (
    <div className="grid grid-cols-2 mdPlus:grid-cols-3">
      {viewPartnerList.map((partner) => (
        <button
          className="col-span-1"
          key={`partner_block_${partner.partnerName}`}
          type="button"
          onClick={() => navigateToDetails(partner.partnerId)}
        >
          <PartnerViewCard
            {...{
              name: partner.partnerName,
              categoryName: partner.categoryName,
              logo: partner.partnerLogo,
              imgStyle: { maxHeight: '64px' },
            }}
          />
        </button>
      ))}
    </div>
  );

  const listView = () => (
    <div className="grid grid-flow-row">
      {viewPartnerList.map((partner) => (
        <button
          className=""
          key={`partner_list_${partner.partnerName}`}
          type="button"
          onClick={() => navigateToDetails(partner.partnerId)}
        >
          <PartnerListViewCard
            {...{
              name: partner.partnerName,
              categoryName: partner.categoryName,
              logo: partner.partnerLogo,
            }}
          />
        </button>
      ))}
    </div>
  );

  const getView = () =>
    appliedFilter.view === appListViewTypes.LIST_VIEW ? listView() : blockView();

  const search = ({ target }) => searchPartner(target.value);

  const getFaq = () => {
    const searchPartnerFaq = viewPartnerFaq.find((faq) => faq.partnerId === selectedPartnerId);
    if (searchPartnerFaq) {
      return (
        <Accordion
          {...{
            data: searchPartnerFaq.faqList
              .slice(0, 4)
              .map((faq) => ({ title: faq.title, body: faq.answer, active: false })),
          }}
        />
      );
    }
    return <></>;
  };

  return (
    <>
      <div className="flex-center bg-ed-cold-white">
        <div className="app-ed-page ">
          <div className="app-ed-page-content w-full py-16">
            <div className="sm:pb-8">
              <div className="grid grid-cols-12 gap-y-4">
                <section className="col-span-12 sm:col-span-5 md:col-span-6 text-left">
                  <h2 className="text-24 text-ed-gray text-ed-bold">
                    {translate(LANGUAGE.partnerOverview)}
                  </h2>
                  <h3
                    className="text-18 text-ed-gray text-ed-bold pt-6 pb-3 block sm:hidden"
                    style={{ position: 'relative', zIndex: 2 }}
                  >
                    {translate(LANGUAGE.partnerForNextVoucher)}
                  </h3>
                </section>
                <section className="col-span-12 sm:col-span-7 md:col-span-6 text-start partner-list hidden md:block">
                  <div className="w-full flex items-start justify-between">
                    <div className="w-9/12">
                      <div className="form-input">
                        <Input
                          type="text"
                          icon
                          iconComponent={appIcons.SEARCH}
                          viewSize={inputViewSizes.SM}
                          value={filter.searchText}
                          name="search"
                          placeholder={translate(LANGUAGE.search)}
                          onChange={search}
                        />
                      </div>
                    </div>
                    <div className="w-2/12 flex-center-end">
                      <Button
                        type={buttonTypes.FAB}
                        className="flex bg-ed-white text-ed-gray h-12 w-12 md:px-3 md:py-3  flex-center rounded-full"
                        icon
                        look={buttonLooks.CIRCLE}
                        iconComponent={appIcons.FILTER}
                        svgProps={{ width: 20, height: 20 }}
                        onClick={openFilter}
                      />
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className="grid grid-cols-6 md:grid-cols-12 gap-y-4 md:px-0 sm:px-24">
              <section className="col-span-12 xs:col-span-6 sm:col-span-5 md:col-span-6 partner-overview text-left pr-0 lg:pr-24 md:pr-24 sm:pr-0">
                <div className="pb-5 pt-5 xs:pt-0">
                  <h2
                    className="text-18 text-ed-gray text-ed-bold pb-3 hidden sm:block"
                    style={{ zIndex: 1, position: 'relative' }}
                  >
                    {translate(LANGUAGE.partnerForNextVoucher)}
                  </h2>
                  {selectedPartner && (
                    <div
                      className="h-fit-content w-full selected-default-partner"
                      key={`partner_list_${selectedPartner.partnerName}`}
                    >
                      <SelectedPartnerCard
                        {...{
                          name: selectedPartner.partnerName,
                          categoryName: selectedPartner.categoryName,
                          logo: selectedPartner.partnerLogo,
                          isSelected: true,
                          onClick: () => navigateToDetails(selectedPartner.partnerId),
                          backgroundClass: 'shade',
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="py-8 hidden md:flex flex-col">
                  {/* <Accordion /> */}
                  {getFaq()}
                  {selectedPartner && (
                    <div className="flex-1 py-5 flex-center">
                      <Button
                        type={buttonTypes.DEFAULT}
                        className="relative bg-ed-blue text-ed-regular text-white text-18 px-8 py-2 flex-center "
                        label={translate(LANGUAGE.loadMoreFaq)}
                        icon
                        look={buttonLooks.PILL}
                        onClick={() => navigateToDetails(selectedPartner.partnerId)}
                      />
                    </div>
                  )}
                </div>
                <section className="partner-list block md:hidden">
                  <div className="w-full flex items-center justify-between">
                    <div className="w-10/12 pt-3 pr-2">
                      <div className="form-input">
                        <Input
                          type="text"
                          icon
                          iconComponent={appIcons.SEARCH}
                          viewSize={inputViewSizes.DEFAULT}
                          value={filter.searchText}
                          name="search"
                          placeholder={translate(LANGUAGE.search)}
                          onChange={search}
                        />
                      </div>
                    </div>
                    <div className="w-2/12 flex-center-end">
                      <Button
                        type={buttonTypes.FAB}
                        className="flex bg-ed-white text-ed-gray h-12 w-12 md:px-3 md:py-3  flex-center rounded-full"
                        icon
                        look={buttonLooks.CIRCLE}
                        iconComponent={appIcons.FILTER}
                        svgProps={{ width: isSM ? 24 : 20, height: isSM ? 24 : 20 }}
                        onClick={openFilter}
                      />
                    </div>
                  </div>
                </section>
              </section>
              <section className="col-span-12 xs:col-span-6 xs:pl-0 sm:col-span-7 md:col-span-6 text-start partner-list sm:pl-10">
                {getView()}
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withAnimate(PartnerPage);
