/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { animationStyle } from '@utils/Constant';

function AnimateLoad({ children, style, delay }) {
  let className = 'animate-animated';

  // Add animation style
  if (style) className += ` ${style}`;

  // Add animation delay
  if (delay) className += ` ${delay}`;

  // Default animate class
  if (!style && !delay) className += ` ${animationStyle.FADE_IN}`;

  return <div {...{ className }}>{children}</div>;
}

AnimateLoad.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.string,
  delay: PropTypes.string,
};

AnimateLoad.defaultProps = {
  style: '',
  delay: '',
};

// HOC
export const withAnimate = (Component) => (props) => (
  <AnimateLoad>
    {/* All props are passed through to the Component being wrapped */}
    <Component {...props} />
  </AnimateLoad>
);

export default AnimateLoad;
