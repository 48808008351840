/* eslint-disable react/no-danger */
import { useLanguage } from '@context/Language';
import { appDynamicScripts, appRoute } from '@utils/Constant';
import { loadScript } from '@utils/Url';
import { sanitizedDOM } from '@utils/Validation';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function StaticPages() {
  const [staticPage, setStaticPage] = useState({});
  const [isStaticPage, setIsStaticPage] = useState({});

  const location = useLocation();

  const { staticPages } = useLanguage();

  const navigate = useNavigate();

  useEffect(() => {
    // console.log(location);
    if (location && location.pathname) {
      const searchStaticPage = staticPages.find((page) => page.route === location.pathname);
      setStaticPage(searchStaticPage || {});
      if (staticPages.length > 0 && !searchStaticPage) {
        setIsStaticPage({});
        navigate(appRoute.NOT_FOUND);
      } else {
        setIsStaticPage(searchStaticPage);
      }
    }
  }, [staticPages, location]);

  useEffect(() => {
    if (isStaticPage && isStaticPage.route === appRoute.DATA_PROTECTION) {
      loadScript({
        src: appDynamicScripts.DATA_PROTECTION_COOKIE,
        appendOnElement: 'appendScript',
        id: 'CookieDeclaration',
      });
    }
  }, [isStaticPage]);

  return (
    <div className="flex-center bg-ed-white">
      <div className="app-ed-page ">
        <div className="w-full">
          {staticPage && (
            <div dangerouslySetInnerHTML={{ __html: sanitizedDOM(staticPage.content) }} />
          )}
          <div className="container">
            <div id="appendScript" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaticPages;
