// Main App
// Initialize App with default landing page.
import { BrowserRouter } from 'react-router-dom';

import ContextManager from '@config/ContextManager';

import AppRoutes from '@config/AppRoutes';

import './App.css';

function App() {
  return (
    <>
      <BrowserRouter>
        <ContextManager>
          <AppRoutes />
        </ContextManager>
      </BrowserRouter>
    </>
  );
}

export default App;
