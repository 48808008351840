import { appIcons, appIconsPath } from '@utils/Constant';

import Image from '@core/Image';
import RightArrow from './RightArrow';
import CheckMark from './CheckMark';
import Eye from './Eye';

import CircleRound from './CircleRound';
import CircleTick from './CircleTick';

import CircleX from './CircleX';
import CircleExclamation from './CircleExclamation';
import Ban from './Ban';
import X from './X';
import Circle from './Circle';
import XRed from './XRed';
import BurgerMenu from './BurgerMenu';

export const getIcon = ({ iconComponent, pathFill = '', svgProps = { width: 24, height: 22 } }) => {
  let defaultContent = <></>;
  if (iconComponent) {
    switch (iconComponent) {
      case appIcons.BAN:
        defaultContent = <Ban {...{ pathFill, width: svgProps.width, height: svgProps.height }} />;
        break;
      case appIcons.BURGERMENU:
        defaultContent = <BurgerMenu {...{ pathFill, width: svgProps.width, height: svgProps.height }} />;
        break;
      case appIcons.CHECKMARK:
        defaultContent = (
          <CheckMark {...{ pathFill, width: svgProps.width, height: svgProps.height }} />
        );
        break;
      case appIcons.CIRCLE_EXCLAMATION:
        defaultContent = (
          <CircleExclamation {...{ pathFill, width: svgProps.width, height: svgProps.height }} />
        );
        break;
      case appIcons.CIRCLE_TICK:
        defaultContent = (
          <CircleTick {...{ pathFill, width: svgProps.width, height: svgProps.height }} />
        );
        break;

      case appIcons.CIRCLE:
        defaultContent = (
          <Circle {...{ pathFill, width: svgProps.width, height: svgProps.height }} />
        );
        break;
      case appIcons.CIRCLE_X:
        defaultContent = (
          <CircleX {...{ pathFill, width: svgProps.width, height: svgProps.height }} />
        );
        break;
      case appIcons.X:
        defaultContent = <X {...{ pathFill, width: svgProps.width, height: svgProps.height }} />;
        break;
      case appIcons.XRed:
        defaultContent = <XRed {...{ pathFill, width: svgProps.width, height: svgProps.height }} />;
        break;
      case appIcons.EYE:
        defaultContent = <Eye {...{ pathFill, width: svgProps.width, height: svgProps.height }} />;
        break;
      case appIcons.EYE_OFF:
        defaultContent = (
          <Eye {...{ pathFill, width: svgProps.width, height: svgProps.height, isOpen: false }} />
        );
        break;

      case appIcons.HOME:
        defaultContent = (
          <Image
            src={appIconsPath.HOME}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.HOME_ACTIVE:
        defaultContent = (
          <Image
            src={appIconsPath.HOME_ACTIVE}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.MY_ACCOUNT:
        defaultContent = (
          <Image
            src={appIconsPath.MY_ACCOUNT}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.MY_ACCOUNT_ACTIVE:
        defaultContent = (
          <Image
            src={appIconsPath.MY_ACCOUNT_ACTIVE}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.PARTNER:
        defaultContent = (
          <Image
            src={appIconsPath.PARTNER}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.PARTNER_ACTIVE:
        defaultContent = (
          <Image
            src={appIconsPath.PARTNER_ACTIVE}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.VOUCHER:
        defaultContent = (
          <Image
            src={appIconsPath.VOUCHER}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.VOUCHER_ACTIVE:
        defaultContent = (
          <Image
            src={appIconsPath.VOUCHER_ACTIVE}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.NO_VOUCHER_AVAILABLE:
        defaultContent = (
          <Image
            src={appIconsPath.NO_VOUCHER_AVAILABLE}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.RIGHT_ARROW:
        defaultContent = (
          <RightArrow {...{ pathFill, width: svgProps.width, height: svgProps.height }} />
        );
        break;
      case appIcons.FILTER:
        defaultContent = (
          <Image
            src={appIconsPath.FILTER}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.SEARCH:
        defaultContent = (
          <Image
            src={appIconsPath.SEARCH}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.SELECTED:
        defaultContent = (
          <Image
            src={appIconsPath.SELECTED}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;

      case appIcons.PLUS:
        defaultContent = (
          <Image
            src={appIconsPath.PLUS}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;

      case appIcons.MINUS:
        defaultContent = (
          <Image
            src={appIconsPath.MINUS}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.BLOCK:
        defaultContent = (
          <Image
            src={appIconsPath.BLOCK}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.BLOCK_ACTIVE:
        defaultContent = (
          <Image
            src={appIconsPath.BLOCK_ACTIVE}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;

      case appIcons.LIST:
        defaultContent = (
          <Image
            src={appIconsPath.LIST}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.LIST_ACTIVE:
        defaultContent = (
          <Image
            src={appIconsPath.LIST_ACTIVE}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.MENU_LIST_ACTIVE:
        defaultContent = (
          <Image
            src={appIconsPath.MENU_LIST_ACTIVE}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;

      case appIcons.DATABASE:
        defaultContent = (
          <Image
            src={appIconsPath.DATABASE}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.BALANCER:
        defaultContent = (
          <Image
            src={appIconsPath.BALANCER}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.APPLY_FILTER:
        defaultContent = (
          <Image
            src={appIconsPath.APPLY_FILTER}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.SORT:
        defaultContent = (
          <Image
            src={appIconsPath.SORT}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.SORT_DEC:
        defaultContent = (
          <Image
            src={appIconsPath.SORT}
            alt=""
            {...{
              style: { width: svgProps.width, height: svgProps.height, transform: 'scaleY(-1)' },
            }}
          />
        );
        break;
      case appIcons.SORT_ACTIVE:
        defaultContent = (
          <Image
            src={appIconsPath.SORT_ACTIVE}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.SORT_DEC_ACTIVE:
        defaultContent = (
          <Image
            src={appIconsPath.SORT_ACTIVE}
            alt=""
            {...{
              style: { width: svgProps.width, height: svgProps.height, transform: 'scaleY(-1)' },
            }}
          />
        );
        break;
      case appIcons.CLOSE_X:
        defaultContent = (
          <Image
            src={appIconsPath.CLOSE_X}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.INFO:
        defaultContent = (
          <Image
            src={appIconsPath.INFO}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.INFO_DARK:
        defaultContent = (
          <Image
            src={appIconsPath.INFO_DARK}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.APPLE:
        defaultContent = (
          <Image
            src={appIconsPath.APPLE}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.PLAYSTORE:
        defaultContent = (
          <Image
            src={appIconsPath.PLAYSTORE}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;

      case appIcons.COMMENT_Q:
        defaultContent = (
          <Image
            src={appIconsPath.COMMENT_Q}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      case appIcons.EDERN_RED:
        defaultContent = (
          <Image
            src={appIconsPath.EDERN_RED}
            alt=""
            {...{ style: { width: svgProps.width, height: svgProps.height } }}
          />
        );
        break;
      default:
        defaultContent = <></>;
        break;
    }
  }
  return defaultContent;

  // return <CheckMark {...{ pathFill, width: svgProps.width, height: svgProps.height }} />;

  // return <RightArrow {...{ pathFill, width: svgProps.width, height: svgProps.height }} />;
};

export default {
  RightArrow,
  CheckMark,
  Eye,
  CircleX,
  CircleRound,
  CircleTick,
  CircleExclamation,
  Ban,
  X,
  XRed,
};
