import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Storage from '@utils/Storage';
import {
  appAlert,
  appDefaultErrorCode,
  appRoute,
  appStaticContent,
  appStorage,
} from '@utils/Constant';
import { useAppConfig } from '@context/AppConfig';
import LanguageService from '@service/Language';
// import { useLoading } from '@context/Loading';
import { StatusCodes, validateResponse } from '@utils/ApiCodex';
import { keysToCamel, validateObject } from '@utils/Validation';
import { useAlert } from '@context/Alert';
import { LANGUAGE_TYPE } from '@utils/Constant/language';
import { useErrorHandler } from '@context/ErrorHandler';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  // Language selection state
  const [userLanguage, setUserLanguage] = useState(
    Storage.get(appStorage.i18n) || appStorage.DEFAULT_LANGUAGE,
  );
  // All selected language content goes here
  const [dictionary, setDictionary] = useState({});
  const [staticPages, setStaticPages] = useState([]);
  const [isStaticLoading, setStaticLoading] = useState(true);

  const { setFooterLinks } = useAppConfig();

  const { env, isEnvLoading } = useAppConfig();
  // const { showLoader, hideLoader } = useLoading();
  const { showAlert } = useAlert();
  const { setErrorStatusCode } = useErrorHandler();

  // /**
  //  * Grab the language json file
  //  * TODO: Load it form server after static content api is implemented.
  //  */
  // const fetchDictionary = () => {
  //   const languageService = new LanguageService(env);
  //   languageService
  //     .getLanguageDictionary(userLanguage)
  //     .then((result) => {
  //       if (result.status === StatusCodes.OK && result.data) setDictionary(result.data);
  //     })
  //     .catch((err) => {
  //       // throw new Error(err);
  //       if (err.response.status === StatusCodes.NOT_FOUND) {
  //         showAlert({ type: appAlert.ERROR, message: 'Language not found' });
  //       }
  //     });
  // };

  /**
   * Grab the language json
   */
  const fetchDictionary = () => {
    // showLoader();
    const languageService = new LanguageService(env);
    languageService
      .getStaticContent()
      .then((result) => {
        if (result.status === StatusCodes.OK && result.data && validateResponse(result)) {
          const { data, settings } = result.data;
          if (
            settings.maintenance_flag === '1' &&
            window.location.pathname !== appRoute.MAINTENANCE
          ) {
            Storage.clear();
            window.location.href = appRoute.MAINTENANCE;
          }
          setDictionary(data.app_content);

          setStaticPages(
            Object.keys(data.app_content || [])
              .filter((key) => data.app_content[key].type === appStaticContent.HTML)
              .map((key) => data.app_content[key]),
          );
          setFooterLinks(
            Object.keys(data.app_content || [])
              .filter((key) => `${key}`.includes(appStaticContent.FOOTER_LINK))
              .map((key) => keysToCamel(data.app_content[key])),
          );

          setStaticLoading(false);
          // hideLoader();
        } else {
          showAlert({ type: appAlert.ERROR, message: 'Language not found' });
        }
      })
      .catch((err) => {
        // throw new Error(err);
        if (err.response?.status === StatusCodes.NOT_FOUND) {
          showAlert({ type: appAlert.ERROR, message: 'Language not found' });
        } else {
          setErrorStatusCode(err?.response?.status || err?.status || appDefaultErrorCode.NOT_FOUND);
        }
      });
  };

  const translate = (key) => {
    // console.log(dictionary[key]);
    if (dictionary[key]?.type === LANGUAGE_TYPE.HTML && dictionary[key]?.title)
      return dictionary[key]?.title;
    if (dictionary[key]?.type === LANGUAGE_TYPE.TEXT && dictionary[key]?.content)
      return dictionary[key]?.content;
    // return key;
    return '';
    // dictionary[key] && dictionary[key]?.format === LANGUAGE_TYPE.HTML ? dictionary[key]?.content : key;
  };

  const getStaticPageRoute = (key) => {
    if (dictionary[key]?.type === LANGUAGE_TYPE.HTML && dictionary[key]?.route)
      return dictionary[key]?.route;
    return appRoute.NOT_FOUND;
  };

  const getLinkUrl = (key) => {
    if (dictionary[key]?.type === LANGUAGE_TYPE.LINK && dictionary[key]?.content)
      return dictionary[key]?.content;
    return appRoute.NOT_FOUND;
  };

  // Load once env is loaded
  useEffect(() => {
    if (!isEnvLoading && validateObject(env)) {
      // showLoader();
      fetchDictionary();
    }
  }, [isEnvLoading]);

  // On change of language refetch and update dictionary
  useEffect(() => {
    fetchDictionary();
  }, [userLanguage]);

  // Childs
  const renderContent = () => children;

  // Optimise the change detection with out re-rendereing component
  const contextPayload = useMemo(
    () => ({
      userLanguage,
      setUserLanguage,
      dictionary,
      translate,
      staticPages,
      getStaticPageRoute,
      getLinkUrl,
      isStaticLoading,
    }),
    [
      userLanguage,
      setUserLanguage,
      dictionary,
      translate,
      staticPages,
      getStaticPageRoute,
      getLinkUrl,
      isStaticLoading,
    ],
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <LanguageContext.Provider value={contextPayload}>{renderContent()}</LanguageContext.Provider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LanguageProvider;

export const useLanguage = () => useContext(LanguageContext);
