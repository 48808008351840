import React from 'react';
import PropTypes from 'prop-types';
import { themeColor } from '@theme/common/color';

function Circle({ width, height }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
      <circle cx={8} cy={8} r={8} fill={themeColor.edDkGray} />
    </svg>
  );
}

Circle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Circle.defaultProps = {
  width: 16,
  height: 16,
};

export default Circle;
