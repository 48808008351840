import { HTTP_REQUEST_METHODS } from '@utils/ApiCodex';
import { appAPIHeaders, appAPIUri } from '@utils/Url';
import axios from 'axios';

export default class PartnerService {
  env = {};

  /**
   * Set env from app loading with server.
   * @param {JSON} env APP Envs
   */
  constructor(env) {
    this.env = env;
  }

  /**
   * Fetch all user's partner
   * @returns API Request
   */
  getPartner() {
    // Req Url
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.PARTNER.USER_PARTNERS}`;

    // Default headers
    const headers = appAPIHeaders.DEFAULT_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.GET,
      url,
      headers,
    });
  }

  /**
   * Fetch Selected partner
   * @returns API Request
   */
  getSelectedPartner() {
    // Req Url
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.PARTNER.USER_SELECTION}`;

    // Default headers
    const headers = appAPIHeaders.DEFAULT_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.GET,
      url,
      headers,
    });
  }

  /**
   * Select new partner
   * @returns API Request
   */
  saveSelectedPartner(partnerId) {
    // Req Url
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.PARTNER.USER_SELECTION}`;

    // Default headers
    const headers = appAPIHeaders.DEFAULT_RAW_JSON;
    const data = {
      partner_id: parseInt(partnerId, 10),
      value: 0,
    };

    return axios({
      method: HTTP_REQUEST_METHODS.POST,
      url,
      headers,
      data,
    });
  }

  /**
   * Fetch partner faq
   * @returns API Request
   */
  partnerFaq(id) {
    // Req Url
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.PARTNER.FAQ(id)}`;

    // Default headers
    const headers = appAPIHeaders.DEFAULT_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.GET,
      url,
      headers,
    });
  }
}
