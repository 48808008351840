import React from 'react';

export function DummyComponent() {
  return (
    <div className="bg-ed-cold-white">
      <div className="app-ed-page-container">
        <section className="my-3">
          <div className="mt-5 mb-3 mt-0 mb-2">
            <h3 className="text-30 text-ed-gray text-ed-bold">Typography</h3>
          </div>
          <div className="m-0">
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
              <div className="">
                <div className="sticky top-0 mt-5 mb-3 mt-0 mb-2">
                  <h5 className="text-20 text-ed-gray text-ed-light underline">Font Size</h5>
                </div>
                <p className="text-12 text-ed-gray">.text-12</p>
                <p className="text-14 text-ed-gray">.text-14</p>
                <p className="text-14 text-ed-gray">.text-18</p>
                <p className="text-20 text-ed-gray">.text-20</p>
                <p className="text-22 text-ed-gray">.text-22</p>
                <p className="text-24 text-ed-gray">.text-24</p>
                <p className="text-26 text-ed-gray">.text-26</p>
                <p className="text-28 text-ed-gray">.text-28</p>
                <p className="text-30 text-ed-gray">.text-30</p>
                <p className="text-32 text-ed-gray">.text-32</p>
                <p className="text-35 text-ed-gray">.text-35</p>
                <p className="text-36 text-ed-gray">.text-36</p>
                <p className="text-40 text-ed-gray">.text-40</p>
                <p className="text-42 text-ed-gray">.text-42</p>
                <p className="text-48 text-ed-gray">.text-48</p>
                <p className="text-50 text-ed-gray">.text-50</p>
                <p className="text-58 text-ed-gray">.text-58</p>
                <p className="text-60 text-ed-gray">.text-60</p>
                <p className="text-64 text-ed-gray">.text-64</p>
                <p className="text-67 text-ed-gray">.text-67</p>
                <p className="text-72 text-ed-gray">.text-72</p>
                <p className="text-80 text-ed-gray">.text-80</p>
                <p className="text-96 text-ed-gray">.text-96</p>
              </div>
              <div className="grid grid-cols-1 xxl:grid-cols-2 gap-4">
                <div>
                  <div className="sticky top-0 mt-5 mb-3 mt-0 mb-2">
                    <h5 className="text-20 text-ed-gray text-ed-light underline">Font Color</h5>
                  </div>
                  <p className="text-14 text-ed-red">.text-ed-red</p>
                  <p className="text-14 text-ed-lt-cobald bg-ed-gray">.text-ed-lt-cobald</p>
                  <p className="text-14 text-ed-lt-mint bg-ed-gray">.text-ed-lt-mint</p>
                  <p className="text-14 text-ed-lt-olive bg-ed-gray">.text-ed-lt-olive</p>
                  <p className="text-14 text-ed-lt-violet bg-ed-gray">.text-ed-lt-violet</p>
                  <p className="text-14 text-ed-white bg-ed-gray">.text-ed-white</p>
                  <p className="text-14 text-ed-cold-white bg-ed-gray">.text-ed-cold-white</p>
                  <p className="text-14 text-ed-gray">.text-ed-gray</p>
                  <p className="text-14 text-ed-lt-gray bg-black">.text-ed-lt-gray</p>
                  <p className="text-14 text-ed-dk-gray">.text-ed-dk-gray</p>
                  <p className="text-14 text-ed-blue">.text-ed-blue</p>
                </div>
                <div>
                  <div className="sticky top-0 mt-5 mb-3 mt-0 mb-2">
                    <h5 className="text-20 text-ed-gray text-ed-light underline">
                      Background Color
                    </h5>
                  </div>
                  <p className="p-2 my-2 bg-ed-red" />
                  <p className="text-14 text-ed-regular text-ed-gray">.bg-ed-red</p>
                  <p className="p-2 my-2 bg-ed-lt-cobald" />
                  <p className="text-14 text-ed-regular text-ed-gray">.bg-ed-lt-cobald</p>
                  <p className="p-2 my-2 bg-ed-lt-mint" />
                  <p className="text-14 text-ed-regular text-ed-gray">.bg-ed-lt-mint</p>
                  <p className="p-2 my-2 bg-ed-lt-olive" />
                  <p className="text-14 text-ed-regular text-ed-gray">.bg-ed-lt-olive</p>
                  <p className="p-2 my-2 bg-ed-lt-violet" />
                  <p className="text-14 text-ed-regular text-ed-gray">.bg-ed-lt-violet</p>
                  <p className="p-2 my-2 bg-ed-white bg" />
                  <p className="text-14 text-ed-regular text-ed-gray">.bg-ed-white bg</p>
                  <p className="p-2 my-2 bg-ed-cold-white" />
                  <p className="text-14 text-ed-regular text-ed-gray">.bg-ed-cold-white</p>
                  <p className="p-2 my-2 bg-ed-gray" />
                  <p className="text-14 text-ed-regular text-ed-gray">.bg-ed-gray</p>
                  <p className="p-2 my-2 bg-ed-lt-gray" />
                  <p className="text-14 text-ed-regular text-ed-gray">.bg-ed-lt-gray</p>
                  <p className="p-2 my-2 bg-ed-dk-gray" />
                  <p className="text-14 text-ed-regular text-ed-gray">.bg-ed-dk-gray</p>
                  <p className="p-2 my-2 bg-ed-blue" />
                  <p className="text-14 text-ed-regular text-ed-gray">.bg-ed-blue</p>
                </div>

                <div>
                  <div className="sticky top-0 mt-5 mb-3 mt-0 mb-2">
                    <h5 className="text-20 text-ed-gray text-ed-light underline">Fonts</h5>
                  </div>
                  <p className="text-14 text-ed-bold text-ed-gray">Ubuntu Bold</p>
                  <p className="text-14 text-ed-regular text-ed-gray">.text-ed-bold</p>
                  <p className="text-14 text-ed-italic text-ed-gray">Ubuntu Italic</p>
                  <p className="text-14 text-ed-regular text-ed-gray">.text-ed-italic</p>
                  <p className="text-14 text-ed-light text-ed-gray">Ubuntu Light</p>
                  <p className="text-14 text-ed-regular text-ed-gray">.text-ed-light</p>
                  <p className="text-14 text-ed-medium text-ed-gray">Ubuntu Medium</p>
                  <p className="text-14 text-ed-regular text-ed-gray">.text-ed-medium</p>
                  <p className="text-14 text-ed-regular text-ed-gray">Ubuntu Regular</p>
                  <p className="text-14 text-ed-regular text-ed-gray">.text-ed-regular</p>
                </div>
                <div>
                  <div className="sticky top-0 mt-5 mb-3 mt-0 mb-2">
                    <h5 className="text-20 text-ed-gray text-ed-light underline">Paragraph</h5>
                  </div>

                  <p className="text-ed-gray text-ed-bold text-18">Title</p>
                  <p className="text-14 text-ed-regular text-ed-gray">
                    .text-ed-gray .text-ed-bold .text-18
                  </p>
                  <br />
                  <p className="text-14 text-ed-light text-ed-gray">Text Large</p>
                  <p className="text-14 text-ed-regular text-ed-gray">
                    .text-14 .text-ed-light .text-ed-gray
                  </p>
                  <br />
                  <p className="text-14 text-ed-regular text-ed-gray">Text Small</p>
                  <p className="text-14 text-ed-regular text-ed-gray">
                    .text-14 .text-ed-regular .text-ed-gray
                  </p>
                </div>
              </div>
              <div className="">
                <div className="sticky top-0 mt-5 mb-3 mt-0 mb-2">
                  <h5 className="text-20 text-ed-gray text-ed-light underline underline">
                    Headline / Title
                  </h5>
                </div>
                <div className="">
                  <div className="m-0 border-1">
                    <div className="flex flex-col items-start">
                      <p className="text-72 text-ed-gray text-ed-light">Display 1</p>
                      <p className="text-20 text-ed-light text-ed-gray pl-1">
                        .text-72 .text-ed-gray .text-ed-light
                      </p>
                    </div>
                    <div className="flex flex-col items-start">
                      <p className="text-64 text-ed-gray text-ed-light">Display 2</p>
                      <p className="text-20 text-ed-light text-ed-gray pl-1">
                        .text-64 .text-ed-gray .text-ed-light
                      </p>
                    </div>
                    <div className="flex flex-col items-start">
                      <p className="text-48 text-ed-gray text-ed-light">Display 3</p>
                      <p className="text-20 text-ed-light text-ed-gray pl-1">
                        .text-48 .text-ed-gray .text-ed-light
                      </p>
                    </div>
                    <div className="flex flex-col items-start">
                      <p className="text-36 text-ed-gray text-ed-light">Display 4</p>
                      <p className="text-20 text-ed-light text-ed-gray pl-1">
                        .text-36 .text-ed-gray .text-ed-light
                      </p>
                    </div>
                    <div className="flex flex-col items-start">
                      <p className="text-30 text-ed-gray text-ed-light">Display 5</p>
                      <p className="text-20 text-ed-light text-ed-gray pl-1">
                        .text-30 .text-ed-gray .text-ed-light
                      </p>
                    </div>
                    <div className="flex flex-col items-start">
                      <p className="text-24 text-ed-gray text-ed-light">Display 6</p>
                      <p className="text-20 text-ed-light text-ed-gray pl-1">
                        .text-24 .text-ed-gray .text-ed-light
                      </p>
                    </div>
                  </div>
                  <div className="m-0 border-1">
                    <div className="flex flex-col items-start">
                      <h1 className="text-64 text-ed-gray text-ed-bold">Heading 1</h1>
                      <p className="text-20 text-ed-light text-ed-gray pl-1">
                        .text-64 .text-ed-gray .text-ed-bold
                      </p>
                    </div>
                    <div className="flex flex-col items-start">
                      <h2 className="text-48 text-ed-gray text-ed-bold">Heading 2</h2>
                      <p className="text-20 text-ed-light text-ed-gray pl-1">
                        .text-48 .text-ed-gray .text-ed-bold
                      </p>
                    </div>
                    <div className="flex flex-col items-start">
                      <h3 className="text-36 text-ed-gray text-ed-bold">Heading 3</h3>
                      <p className="text-20 text-ed-light text-ed-gray pl-1">
                        .text-36 .text-ed-gray .text-ed-bold
                      </p>
                    </div>
                    <div className="flex flex-col items-start">
                      <h4 className="text-30 text-ed-gray text-ed-bold">Heading 4</h4>
                      <p className="text-20 text-ed-light text-ed-gray pl-1">
                        .text-30 .text-ed-gray .text-ed-bold
                      </p>
                    </div>
                    <div className="flex flex-col items-start">
                      <h5 className="text-24 text-ed-gray text-ed-bold">Heading 5</h5>
                      <p className="text-20 text-ed-light text-ed-gray pl-1">
                        .text-24 .text-ed-gray .text-ed-bold
                      </p>
                    </div>
                    <div className="flex flex-col items-start">
                      <h6 className="text-20 text-ed-gray text-ed-bold">Heading 6</h6>
                      <p className="text-20 text-ed-light text-ed-gray pl-1">
                        .text-20 .text-ed-gray .text-ed-bold
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="my-5">
          <div className="mt-5 mb-3 mt-0 mb-2">
            <h3 className="text-30 text-ed-gray text-ed-bold">Spacing</h3>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="border-b border-gray-200 overflow-hidden relative">
              <h5 className="text-20 text-ed-gray text-ed-light underline mb-5">Margin</h5>

              <div className="overflow-y-auto scrollbar-w-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray scrolling-touch h-[500px]">
                <table className="w-full text-left border-collapse">
                  <thead>
                    <tr>
                      <th className="z-20 sticky top-0 text-sm font-semibold text-gray-600 p-0">
                        <div className="pb-2 pr-2 border-b border-gray-200">Class</div>
                      </th>
                      <th className="z-20 sticky top-0 text-sm font-semibold text-gray-600 p-0">
                        <div className="pb-2 pl-2 border-b border-gray-200">Properties</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="align-baseline">
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap"
                      >
                        m-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre"
                      >
                        margin: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        m-auto
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: auto;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -m-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin: -24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 0px; margin-right: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 1px; margin-right: 1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 0.125rem; margin-right: 0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 0.25rem; margin-right: 0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 0.375rem; margin-right: 0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 0.5rem; margin-right: 0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 0.625rem; margin-right: 0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 0.75rem; margin-right: 0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 0.875rem; margin-right: 0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 1rem; margin-right: 1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 1.25rem; margin-right: 1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 1.5rem; margin-right: 1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 1.75rem; margin-right: 1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 2rem; margin-right: 2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 2.25rem; margin-right: 2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 2.5rem; margin-right: 2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 2.75rem; margin-right: 2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 3rem; margin-right: 3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 3.5rem; margin-right: 3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 4rem; margin-right: 4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 5rem; margin-right: 5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 6rem; margin-right: 6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 7rem; margin-right: 7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 8rem; margin-right: 8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 9rem; margin-right: 9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 10rem; margin-right: 10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 11rem; margin-right: 11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 12rem; margin-right: 12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 13rem; margin-right: 13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 14rem; margin-right: 14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 15rem; margin-right: 15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 16rem; margin-right: 16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 18rem; margin-right: 18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 20rem; margin-right: 20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 24rem; margin-right: 24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mx-auto
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: auto; margin-right: auto;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 0px; margin-right: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -1px; margin-right: -1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -0.125rem; margin-right: -0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -0.25rem; margin-right: -0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -0.375rem; margin-right: -0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -0.5rem; margin-right: -0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -0.625rem; margin-right: -0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -0.75rem; margin-right: -0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -0.875rem; margin-right: -0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -1rem; margin-right: -1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -1.25rem; margin-right: -1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -1.5rem; margin-right: -1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -1.75rem; margin-right: -1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -2rem; margin-right: -2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -2.25rem; margin-right: -2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -2.5rem; margin-right: -2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -2.75rem; margin-right: -2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -3rem; margin-right: -3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -3.5rem; margin-right: -3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -4rem; margin-right: -4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -5rem; margin-right: -5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -6rem; margin-right: -6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -7rem; margin-right: -7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -8rem; margin-right: -8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -9rem; margin-right: -9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -10rem; margin-right: -10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -11rem; margin-right: -11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -12rem; margin-right: -12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -13rem; margin-right: -13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -14rem; margin-right: -14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -15rem; margin-right: -15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -16rem; margin-right: -16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -18rem; margin-right: -18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -20rem; margin-right: -20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mx-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -24rem; margin-right: -24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 0px; margin-bottom: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 1px; margin-bottom: 1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 0.125rem; margin-bottom: 0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 0.25rem; margin-bottom: 0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 0.375rem; margin-bottom: 0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 0.5rem; margin-bottom: 0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 0.625rem; margin-bottom: 0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 0.75rem; margin-bottom: 0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 0.875rem; margin-bottom: 0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 1rem; margin-bottom: 1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 1.25rem; margin-bottom: 1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 1.5rem; margin-bottom: 1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 1.75rem; margin-bottom: 1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 2rem; margin-bottom: 2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 2.25rem; margin-bottom: 2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 2.5rem; margin-bottom: 2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 2.75rem; margin-bottom: 2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 3rem; margin-bottom: 3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 3.5rem; margin-bottom: 3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 4rem; margin-bottom: 4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 5rem; margin-bottom: 5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 6rem; margin-bottom: 6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 7rem; margin-bottom: 7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 8rem; margin-bottom: 8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 9rem; margin-bottom: 9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 10rem; margin-bottom: 10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 11rem; margin-bottom: 11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 12rem; margin-bottom: 12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 13rem; margin-bottom: 13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 14rem; margin-bottom: 14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 15rem; margin-bottom: 15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 16rem; margin-bottom: 16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 18rem; margin-bottom: 18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 20rem; margin-bottom: 20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 24rem; margin-bottom: 24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        my-auto
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: auto; margin-bottom: auto;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 0px; margin-bottom: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -1px; margin-bottom: -1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -0.125rem; margin-bottom: -0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -0.25rem; margin-bottom: -0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -0.375rem; margin-bottom: -0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -0.5rem; margin-bottom: -0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -0.625rem; margin-bottom: -0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -0.75rem; margin-bottom: -0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -0.875rem; margin-bottom: -0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -1rem; margin-bottom: -1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -1.25rem; margin-bottom: -1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -1.5rem; margin-bottom: -1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -1.75rem; margin-bottom: -1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -2rem; margin-bottom: -2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -2.25rem; margin-bottom: -2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -2.5rem; margin-bottom: -2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -2.75rem; margin-bottom: -2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -3rem; margin-bottom: -3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -3.5rem; margin-bottom: -3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -4rem; margin-bottom: -4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -5rem; margin-bottom: -5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -6rem; margin-bottom: -6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -7rem; margin-bottom: -7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -8rem; margin-bottom: -8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -9rem; margin-bottom: -9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -10rem; margin-bottom: -10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -11rem; margin-bottom: -11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -12rem; margin-bottom: -12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -13rem; margin-bottom: -13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -14rem; margin-bottom: -14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -15rem; margin-bottom: -15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -16rem; margin-bottom: -16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -18rem; margin-bottom: -18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -20rem; margin-bottom: -20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -my-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -24rem; margin-bottom: -24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mt-auto
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: auto;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mt-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-top: -24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mr-auto
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: auto;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mr-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-right: -24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        mb-auto
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: auto;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -mb-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-bottom: -24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        ml-auto
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: auto;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        -ml-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        margin-left: -24rem;
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="border-b border-gray-200 overflow-hidden relative">
              <h5 className="text-20 text-ed-gray text-ed-light underline mb-5">Padding</h5>

              <div className="overflow-y-auto scrollbar-w-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray scrolling-touch h-[500px]">
                <table className="w-full text-left border-collapse">
                  <thead>
                    <tr>
                      <th className="z-20 sticky top-0 text-sm font-semibold text-gray-600 bg-white p-0">
                        <div className="pb-2 pr-2 border-b border-gray-200">Class</div>
                      </th>
                      <th className="z-20 sticky top-0 text-sm font-semibold text-gray-600 bg-white p-0">
                        <div className="pb-2 pl-2 border-b border-gray-200">Properties</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="align-baseline">
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap"
                      >
                        p-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre"
                      >
                        padding: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        p-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding: 24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 0px; padding-right: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 1px; padding-right: 1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 0.125rem; padding-right: 0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 0.25rem; padding-right: 0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 0.375rem; padding-right: 0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 0.5rem; padding-right: 0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 0.625rem; padding-right: 0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 0.75rem; padding-right: 0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 0.875rem; padding-right: 0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 1rem; padding-right: 1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 1.25rem; padding-right: 1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 1.5rem; padding-right: 1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 1.75rem; padding-right: 1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 2rem; padding-right: 2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 2.25rem; padding-right: 2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 2.5rem; padding-right: 2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 2.75rem; padding-right: 2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 3rem; padding-right: 3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 3.5rem; padding-right: 3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 4rem; padding-right: 4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 5rem; padding-right: 5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 6rem; padding-right: 6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 7rem; padding-right: 7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 8rem; padding-right: 8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 9rem; padding-right: 9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 10rem; padding-right: 10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 11rem; padding-right: 11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 12rem; padding-right: 12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 13rem; padding-right: 13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 14rem; padding-right: 14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 15rem; padding-right: 15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 16rem; padding-right: 16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 18rem; padding-right: 18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 20rem; padding-right: 20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        px-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 24rem; padding-right: 24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 0px; padding-bottom: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 1px; padding-bottom: 1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 0.125rem; padding-bottom: 0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 0.25rem; padding-bottom: 0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 0.375rem; padding-bottom: 0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 0.5rem; padding-bottom: 0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 0.625rem; padding-bottom: 0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 0.75rem; padding-bottom: 0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 0.875rem; padding-bottom: 0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 1rem; padding-bottom: 1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 1.25rem; padding-bottom: 1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 1.5rem; padding-bottom: 1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 1.75rem; padding-bottom: 1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 2rem; padding-bottom: 2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 2.25rem; padding-bottom: 2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 2.5rem; padding-bottom: 2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 2.75rem; padding-bottom: 2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 3rem; padding-bottom: 3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 3.5rem; padding-bottom: 3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 4rem; padding-bottom: 4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 5rem; padding-bottom: 5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 6rem; padding-bottom: 6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 7rem; padding-bottom: 7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 8rem; padding-bottom: 8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 9rem; padding-bottom: 9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 10rem; padding-bottom: 10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 11rem; padding-bottom: 11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 12rem; padding-bottom: 12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 13rem; padding-bottom: 13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 14rem; padding-bottom: 14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 15rem; padding-bottom: 15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 16rem; padding-bottom: 16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 18rem; padding-bottom: 18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 20rem; padding-bottom: 20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        py-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 24rem; padding-bottom: 24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pt-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-top: 24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pr-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-right: 24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pb-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-bottom: 24rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-0
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 0px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-px
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 1px;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-0.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 0.125rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-1
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 0.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-1.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 0.375rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-2
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 0.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-2.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 0.625rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-3
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 0.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-3.5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 0.875rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-4
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 1rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-5
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 1.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-6
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 1.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-7
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 1.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-8
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 2rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-9
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 2.25rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-10
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 2.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-11
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 2.75rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-12
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 3rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-14
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 3.5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-16
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 4rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-20
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 5rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-24
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 6rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-28
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 7rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-32
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 8rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-36
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 9rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-40
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 10rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-44
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 11rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-48
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 12rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-52
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 13rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-56
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 14rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-60
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 15rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-64
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 16rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-72
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 18rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-80
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 20rem;
                      </td>
                    </tr>
                    <tr>
                      <td
                        translate="no"
                        className="py-2 pr-2 font-mono text-xs text-violet-600 whitespace-nowrap border-t border-gray-200"
                      >
                        pl-96
                      </td>
                      <td
                        translate="no"
                        className="py-2 pl-2 font-mono text-xs text-light-blue-600 whitespace-pre border-t border-gray-200"
                      >
                        padding-left: 24rem;
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="app-ed-page-container bg-ed-white">
        <div className="mt-5 mb-3 mt-0 mb-2">
          <h3 className="text-30 text-ed-gray text-ed-bold">Static Page Container</h3>
        </div>
        <div className="">
          <h6 className="text-20 text-ed-gray text-ed-bold">.app-ed-page-container</h6>
        </div>
      </div>

      {/* https://gutschein.edenred.de/personal-adds */}
      <div>
        <div className="banner-image w-full">
          <img
            className="banner-image w-full"
            src="https://gutschein-admin.edenred.de/sites/default/files/styles/ct_banner/public/2021-10/banner-image_4.jpg?itok=O5otAAQH"
            alt=""
          />
        </div>
        <div className="app-ed-page-container">
          <h5 className="text-24 text-ed-dk-gray text-ed-regular pb-5">Personal Adds</h5>
          <h5 className="text-24 text-ed-gray text-ed-bold pb-5">Personalized Ads</h5>
          <p className="text-14 text-ed-gray text-ed-light leading-tight">
            Insofern Sie einwilligen, dass wir Ihre personenbezogenen Daten zum Zwecke der
            personalisierten Werbung verarbeiten, verarbeiten wir zusätzlich zu den bereits
            genannten Daten auch Daten über ihr Interaktionsverhalten mit den von uns
            bereitgestellten Diensten.
          </p>
          <p className="text-14 text-ed-gray text-ed-light leading-tight">
            Auf diese Art und Weise bestimmen wir Werbeanzeigen, die für sie basierend auf Ihrem
            bisherigen Verhalten von Interesse sein könnten und präsentieren Ihnen diese Werbung im
            Rahmen der Nutzung unserer App.
          </p>
          <p className="text-14 text-ed-gray text-ed-light leading-tight">
            Die in diesem Zusammenhang erhobenen Daten wird Edenred Deutschland nicht an Dritte
            weiter geben oder verkaufen.
          </p>
        </div>
      </div>

      {/* https://gutschein.edenred.de/about-us */}
      <div>
        <div className="banner-image w-full">
          <img
            className="banner-image w-full"
            src="https://gutschein-admin.edenred.de/sites/default/files/styles/ct_banner/public/2021-10/banner-image.jpeg?itok=LgTpSFUT"
            alt=""
          />
        </div>
        <div className="app-ed-page-container">
          <h5 className="text-24 text-ed-dk-gray text-ed-regular pb-5">About US</h5>
          <h5 className="text-24 text-ed-gray text-ed-bold pb-5">Edenred. Vibe with us.</h5>
          <p className="text-14 text-ed-gray text-ed-light leading-tight">
            Edenred ist ein Pionier, ein Technologieführer und der alltägliche Begleiter für
            Menschen, die in mehr als 46 Ländern arbeiten. Unsere 10.000 Mitarbeiter setzen sich
            dafür ein, die Arbeitswelt für alle zu einem besseren, sichereren, effizienteren und
            benutzerfreundlicheren Ort zu machen. Edenred Deutschland zählt aktuell 160 Mitarbeiter.
          </p>
        </div>
      </div>

      {/* https://gutschein.edenred.de/data-protection */}
      <div className="app-ed-page-container">
        <h5 className="text-24 text-ed-dk-gray text-ed-regular pb-5">Data Protection</h5>
        <h5 className="text-24 text-ed-gray text-ed-bold pb-5">Datenschutz</h5>
        <h5 className="text-24 text-ed-gray text-ed-bold pb-5">
          Datenschutzerklärung Edenred Deutschland GmbH – Voucher Service
        </h5>
        <h6 className="text-14 text-ed-gray text-ed-bold pb-5">Übersicht</h6>
        <p className="text-14 text-ed-gray text-ed-bold leading-tight py-2">
          <strong>
            I. Informationen gemäß Art. 14 DSGVO aufgrund der Erhebung und Verarbeitung von
            personenbezogenen Daten
          </strong>
        </p>
        <p className="text-14 text-ed-gray text-ed-bold leading-tight py-2">
          <strong>II. Verarbeitungen von personenbezogenen Daten bei Verwendung der Dienste</strong>
        </p>
        <p className="text-14 text-ed-gray text-ed-light leading-tight py-2">
          A. Datenverarbeitung auf der Website
        </p>
        <p className="text-14 text-ed-gray text-ed-light leading-tight py-2">
          B. Datenverarbeitung in der App
        </p>
        <p className="text-14 text-ed-gray text-ed-bold leading-tight py-2">
          <strong>III. Weitere Informationen und Kontakt</strong>
        </p>

        <h6 className="text-14 text-ed-gray text-ed-bold py-5">
          <strong>
            I. Informationen gemäß Art. 14 DSGVO aufgrund der Erhebung und Verarbeitung von
            personenbezogenen Daten
          </strong>
        </h6>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>
            Datenschutzerklärung (Privacy Policy): Datenschutzinformationen nach Art. 14 DSGVO
          </strong>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          In Zusammenhang mit der Verarbeitung der von Ihrem Unternehmen (Arbeitgeber)
          bereitgestellten personenbezogenen Daten wurden zwecks der Erstellung und Verwaltung Ihres
          Nutzerkontos auf unserem Voucher Portal Ihre personenbezogenen Daten erhoben.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          Bitte beachten Sie hierzu die nachstehenden Datenschutzhinweise unter I, sollten Sie auf
          die Website und App zugreifen, gelten entsprechend die Teile II. und III. dieses
          Datenschutzhinweises entsprechend zusätzlich:&nbsp;
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Name und Kontaktdaten des Verantwortlichen</strong>
          &nbsp;
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          Edenred Deutschland GmbH
          <br />
          Claudius-Keller-Straße 3C
          <br />
          81669 München
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Kontaktdaten des Datenschutzbeauftragten</strong>
          &nbsp;
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          Edenred Deutschland GmbH&nbsp;
          <br />
          z.H. Datenschutzbeauftragter
          <br />
          Claudius-Keller-Str. 3C
          <br />
          81669 München Deutschland
          <br />
          <a href="mailto:dpo.deutschland@edenred.com">dpo.deutschland@edenred.com</a>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          <strong>Zwecke der Datenverarbeitung</strong>
          <br />
          Wir verarbeiten Ihre personenbezogenen Daten, um Ihnen das von Ihrem Arbeitgeber bestellte
          Voucher Portal Programm&nbsp; bereit zu stellen.&nbsp;
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          <strong>Rechtsgrundlage der Datenverarbeitung</strong>
          <br />
          Die Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten ist Art. 6 Abs. 1
          S. 1 lit. f DSGVO.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          <strong>Kategorien personenbezogener Daten</strong>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-4">
          <strong>Wir verarbeiten folgende Kategorien personenbezogener Daten von Ihnen:</strong>
        </p>

        <ul className="list-disc pb-5 pl-10">
          <li className="text-14 text-ed-gray text-ed-light leading-tight">Anrede&nbsp;</li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">Vorname&nbsp;</li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">Nachname</li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            E-Mail Adresse (privat oder beruflich)
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">Arbeitgeber</li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">Mitarbeiter ID</li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            aufzuladendes Guthaben/Voucher Betrag
            <br />
            &nbsp;
          </li>
        </ul>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          <strong>Empfänger der Daten</strong> <br />
          Keine&nbsp;
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Dauer der Speicherung</strong>
          <br />
          <br />
          Ihre personenbezogenen Daten werden:
        </p>

        <ul className="list-disc pb-5 pl-10">
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            für die Dauer der geschäftlichen Beziehung mit dem bestellenden Unternehmen
            gespeichert.&nbsp;
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Darüber hinaus werden Ihre personenbezogenen Daten solange gespeichert, wie es
            erforderlich ist das Ihnen zur Verfügung stehende Guthaben zur Nutzung bereit zu
            stellen.
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Im Übrigen sind wir aufgrund gesetzlicher Aufbewahrungspflichten nach § 14b UStG
            verpflichtet, Gutscheine einschl. der in ihnen enthaltenen personenbezogenen Daten für
            eine Frist von 10 Jahren aufzubewahren.
          </li>
        </ul>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          <strong>Quelle der personenbezogenen Daten</strong>
          <br />
          Ihre personenbezogenen Daten erhalten wir vom bestellenden Unternehmen bzw. Ihrem
          Arbeitgeber.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          <strong>Rechte der betroffenen Person</strong>
          <br />
          Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie betroffene Person i.S.d.
          DSGVO und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          <strong>Auskunftsrecht</strong>
          <br />
          Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob
          personenbezogene Daten, die Sie betreffen, von ihm verarbeitet werden.
          <br />
          Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende
          Informationen Auskunft verlangen:
        </p>

        <ul className="list-disc pb-5 pl-10">
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            die Kategorien von personenbezogenen Daten, welche verarbeitet werden;
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden
            personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder,
            falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der
            Speicherdauer;
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden
            personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den
            Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen
            Daten nicht bei der betroffenen Person erhoben werden;
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß
            Art. 22 Abs. 1 und 4 DSGVO und zumindest in diesen Fällen – aussagekräftige
            Informationen über die involvierte Logik sowie die Tragweite und die angestrebten
            Auswirkungen einer derartigen Verarbeitung für die betroffene Person.
          </li>
        </ul>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden
          personenbezogenen Daten in ein Drittland oder an eine internationale Organisation
          übermittelt werden.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          <strong>Recht auf Berichtigung</strong>
          <br />
          Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem
          Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen,
          unrichtig oder unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich
          vorzunehmen.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          <strong>Recht auf Einschränkung der Verarbeitung</strong>
          <br />
          Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie
          betreffenden personenbezogenen Daten verlangen:
        </p>

        <ul className="list-disc pb-5 pl-10">
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen Daten für eine Dauer
            bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der
            personenbezogenen Daten zu überprüfen;
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten
            ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten
            verlangen;
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht
            länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen benötigen, oder
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben
            und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber
            Ihren Gründen überwiegen.
          </li>
        </ul>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen
          diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur
          Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte
          einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen
          öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt,
          werden Sie von dem Verantwortlichen unterrichtet, bevor die Einschränkung aufgehoben wird.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          <strong>Recht auf Löschung</strong>
          <br />
          Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen
          Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten
          unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
        </p>

        <ul className="list-disc pb-5 pl-10">
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben
            oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen
            keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Sie legen gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer
            rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
            erforderlich, dem der Verantwortliche unterliegt.
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
            Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben
          </li>
        </ul>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          <strong>Widerspruchsmöglichkeit</strong>
          <br />
          Sie haben grds. jederzeit die Möglichkeit, der Verarbeitung der personenbezogenen Daten zu
          widersprechen.
          <br />
          Für die vorliegende Verarbeitung ist ein Widerspruch jedoch nicht ohne Weiteres möglich.
          Das Widerspruchsrecht betrifft die Verarbeitungen, die auf Art.6 Abs.1 S.1 lit.f) DSGVO
          gestützt werden. Bei der vorliegenden Verarbeitung handelt es sich jedoch um eine
          Verarbeitung auf Grundlage von Art.6 Abs.1 S.1 lit.b) DSGVO.
          <br />
          So ist der Widerspruch während des Bestehens der Verpflichtung zur Leistungserbringung
          seitens des bestellenden Unternehmens Ihnen gegenüber, nicht ohne Weiteres möglich.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          <strong>Weitere Informationen und Kontakte</strong>
          <br />
          Wenn Sie weitere Fragen zum Thema „Datenschutz bei der verantwortlichen Stelle” haben,
          wenden Sie sich an den Datenschutzbeauftragten unseres Unternehmens. Sie können erfragen,
          welche Ihrer Daten bei uns gespeichert sind. Darüber hinaus können Sie Auskünfte,
          Löschungs- und Berichtigungswünsche zu Ihren Daten und gerne auch Anregungen jederzeit
          mittels&nbsp;
          <a href="https://privacyportal-de.onetrust.com/webform/3eee82c0-9eb6-4614-b723-8d597fdcce8d/fbd0be12-6e96-45a8-a9c0-837d8fa8980d">
            dieses Formulars
          </a>
          &nbsp;oder per Brief oder E-Mail an folgende Adresse senden:
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          <strong>Edenred Deutschland GmbH</strong>
          <br />
          z.H. Datenschutzbeauftragter
          <br />
          Claudius-Keller-Str. 3C
          <br />
          81669 München Deutschland
          <br />
          E-Mail:&nbsp;
          <a href="mailto:dpo.deutschland@edenred.com">dpo.deutschland@edenred.com</a>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">&nbsp;</p>

        <h6 className="text-14 text-ed-gray text-ed-bold py-5">
          II. Verarbeitungen von personenbezogenen Daten bei Verwendung der Dienste
        </h6>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <br />
          (Abruf und Nutzung unserer Webseite und APP)
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Die&nbsp;<strong>Edenred Deutschland GmbH</strong>&nbsp;nimmt als Anbieterin der
          WebSite&nbsp;<strong>gutschein.edenred.de </strong>und der
          <strong> App ´´MeinGutschein´´</strong>&nbsp;und verantwortliche Stelle&nbsp;die
          Verpflichtung zum Datenschutz sehr ernst und gestaltet ihre Website und ihre App so, dass
          nur so wenige personenbezogene&nbsp;Daten wie nötig erhoben, verarbeitet und genutzt
          werden. Unter keinen Umständen werden personenbezogene Daten&nbsp;zu Werbezwecken an
          Dritte vermietet oder verkauft. Ohne die ausdrückliche Einwilligung des Besuchers werden
          keine&nbsp;personenbezogenen Daten für Werbe- oder Marketingzwecke genutzt.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Die detaillierte Verarbeitung personenbezogener Daten bei der Verwendung der Dienste,
          sowie durch die Verwendung von Cookies wird unten gesondert dargestellt.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          <br />
          Zugriff auf personenbezogene Daten haben bei&nbsp;
          <strong>Edenred Deutschland GmbH</strong>&nbsp;nur solche Personen, die diese
          Daten&nbsp;zur Durchführung ihrer Aufgaben innerhalb der verantwortlichen Stelle
          benötigen, die über die gesetzlichen Bestimmungen&nbsp;zum Datenschutz informiert sind und
          sich gemäß den geltenden gesetzlichen Bestimmungen verpflichtet haben,
          diese&nbsp;einzuhalten.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Allgemeine Angaben zu den Rechtsgrundlagen</strong>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der
          betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung
          (DSGVO) als Rechtsgrundlage für die Verarbeitung personenbezogener Daten.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages,
          dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1
          lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur
          Durchführung vorvertraglicher Maßnahmen erforderlich sind.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen
          Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit.
          c DSGVO als Rechtsgrundlage.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen
          natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient
          Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder
          eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten
          des Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als
          Rechtsgrundlage für die Verarbeitung.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          Die Erhebung, Verarbeitung, Nutzung und Übermittlung der erhobenen personenbezogenen Daten
          erfolgt&nbsp;nach Art. 6 Abs. 1 lit. Art. 6 Abs. 1 lit. b DSGVO und Art.6 Abs. 1 lit. f
          DSGVO.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Allgemeine Angaben zur Datenlöschung und Speicherdauer</strong>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald
          der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus dann erfolgen,
          wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen
          Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt,
          vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch
          die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine
          Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine
          Vertragserfüllung besteht.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Allgemeine Verarbeitung ihrer personenbezogenen Daten</strong>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Im Rahmen der Nutzung unserer Dienste als Website oder App, verarbeiten wir Ihre
          Accountdaten, um die unseren Kunden gegenüber vertraglich zugesicherten Dienstleistungen
          zu erbringen. Die Rechtsgrundlage für diese Verarbeitung besteht in unserem berechtigten
          Interesse, darin diese Dienstleistung wie vertraglich festgelegt zu erbringen gem. Art. 6
          Abs.1 lit. f DSGVO.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Für weitere Datenverarbeitungen finden die im Folgenden dargestellten hinweise Anwendung.
          Bezüglich der Besonderheiten bei der Verwendung von App und Website sehen lesen Sie bitte
          die Teile A. und B., dieser Datenschutzerklärung.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          Wir verarbeiten ihre personenbezogenen Daten grundsätzlich gemäß den rechtlichen Vorgaben
          der DSGVO. Dementsprechend verarbeiten wir Ihre Daten nur basierend auf einer wirksamen
          Rechtsgrundlage.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight ">
          <strong>Zweckänderungen der Verarbeitung und Datennutzung</strong>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Da sich auf Grund des technischen Fortschritts und organisatorischer Änderungen die
          eingesetzten Verarbeitungsverfahren&nbsp;ändern/weiterentwickeln können, behalten wir uns
          vor, die vorliegende Datenschutzerklärung gemäß den&nbsp;neuen technischen
          Rahmenbedingungen weiterzuentwickeln. Wir bitten Sie deshalb, die Datenschutzerklärung
          von&nbsp;<strong>Edenred Deutschland GmbH</strong>&nbsp;von Zeit zu Zeit zu überprüfen.
          <br />
          <br />
          <strong>
            Übermittlung und Verarbeitung Ihrer personenbezogenen Daten in Staaten außerhalb des
            Europäischen Wirtschaftsraumes
          </strong>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Es findet keine Übermittlung Ihrer personenbezogenen Daten in Länder außerhalb des
          Europäischen Wirtschaftsraumes&nbsp;(Im Folgenden EWR) statt.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Insofern Sie die Website besuchen, können dennoch personenbezogene Daten in Staaten
          außerhalb des EWR übermittelt werden. Soweit Sie sich in Facebook eingeloggt haben oder
          einen Twitter-Account&nbsp;besitzen, personenbezogene Daten in die USA exportiert werden.
          Zu näheren Erläuterungen und zu Möglichkeiten diesen&nbsp;Datenexport zu verhindern, lesen
          Sie bitte den Gliederungspunkt „Verwendung von Facebook Social Plugins“,
          bzw.&nbsp;„Verwendung von Twitter“ der vorliegenden Datenschutzerklärung.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Soweit Sie sich bei Google eingeloggt haben oder einen YouTube-Account besitzen, können
          personenbezogene Daten&nbsp;in die USA exportiert werden. Zu näheren Erläuterungen und zu
          Möglichkeiten diesen Datenexport zu verhindern,&nbsp;beachten Sie bitte den
          Gliederungspunkt „Verwendung von YouTube-Plugins“.
          <br />
          Darüber hinaus erfolgt eine Übermittlung von personenbezogenen Daten an Google im Rahmen
          der Nutzung des Analyseprogramms Google Analytics. Lesen Sie&nbsp;dazu bitte den Punkt
          „Verwendung von Analyseprogrammen“ der vorliegenden Datenschutzerklärung.&nbsp;Die von der
          verantwortlichen Stelle eingeschalteten Dienstleister haben ihren Sitz und betreiben ihre
          IT-Infrastruktur ausschließlich innerhalb des EWR. Dies gilt auch für eine eventuelle
          Nutzung von cloudbasierten Diensten. Mit den&nbsp;Dienstleistern bestehen Verträge, die
          den Datenschutz- und Datensicherheits-Vorgaben der DSGVO entsprechen.&nbsp;Auch im Falle
          der Einschaltung von externen Dienstleistern bleibt die Edenred Deutschland GmbH die für
          die Verarbeitung&nbsp;Verantwortliche.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <br />

          <strong>Nutzung und Weitergabe personenbezogener Daten</strong>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          Die im Rahmen der Webseite und App der verantwortlichen Stelle erhobenen personenbezogenen
          Daten werden ohne Ihre&nbsp;Einwilligung nur zur Vertragsabwicklung und Bearbeitung Ihrer
          Anfragen genutzt. Darüber hinaus erfolgt eine Nutzung&nbsp;Ihrer Daten für Zwecke der
          Werbung (z.B. Newsletter) und Marktforschung der verantwortlichen Stelle nur, wenn Sie
          hierzu zuvor Ihre&nbsp;Einwilligung erteilt haben. Im Übrigen findet keine Weitergabe an
          sonstige Dritte statt. Ihre jeweilige Einwilligung können Sie selbstverständlich jederzeit
          mit Wirkung für die Zukunft widerrufen.
          <br />
          <br />
          <a>Erneuern oder ändern Sie Ihre Cookie-Einwilligung</a>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Newsletter</strong>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Sie haben die Möglichkeit im Rahmen der Website und der App den von uns angebotenen
          Newsletter zu abonnieren. Insofern Sie den Newsletter abonnieren, verwenden wir Ihre
          Kontaktdaten (E-Mail-Adresse) sowie Ihren Namen und Anrede, um Sie über Angebote und
          Aktionen bezüglich unserer Dienstleistungen per E-Mail zu informieren.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Hierzu haben Sie die Möglichkeit in Ihren Account-Einstellungen bei der Verwendung unserer
          Dienste über ein Opt-In den Newsletter zu abonnieren.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Sie haben jederzeit die Möglichkeit die so erteilte Einwilligung mit Wirkung für die
          Zukunft zu widerrufen. Hierfür müssen Sie lediglich das Opt-In Rückgängig machen.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Die Rechtsgrundlage besteht somit in ihrer Einwilligung nach Art. 6 Abs.1 lit. a DSGVO.
          Aufgrund der Tatsache, dass Sie unsere Dienste über einen Account nutzen, für dessen
          Betrieb ihr Name, Anrede und ihre E-Mail-Adresse notwendig sind, verarbeiten wir diese
          Daten auch nach einem Widerruf ihrer Einwilligung zum Zwecke der Anbietung unserer
          Services weiter.
        </p>

        <h6 className="text-14 text-ed-gray text-ed-bold py-5">
          <strong>A. Datenverarbeitung auf der Website</strong>
        </h6>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          Zuzüglich der vorangegangenen Informationen gelten die folgenden Ausführungen bezüglich
          der Datenverarbeitung auf unserer Website.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Erfassung von allgemeinen Daten und Informationen</strong>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Unsere Website erfasst mit jedem Aufruf der Website durch eine betroffene Person oder ein
          automatisiertes System eine Reihe von allgemeinen Daten und Informationen. Diese
          allgemeinen Daten und Informationen werden in den Logfiles des Servers gespeichert.
          Erfasst werden können die (1) verwendeten Browsertypen und Versionen, (2) das vom
          zugreifenden System verwendete Betriebssystem, (3) die Website, von welcher ein
          zugreifendes System auf unsere Website gelangt (sogenannte Referrer), (4) die
          Unterwebseiten, welche über ein zugreifendes System auf unserer Website angesteuert
          werden, (5) das Datum und die Uhrzeit eines Zugriffs auf die Website, (6) eine
          Internet-Protokoll-Adresse (IP-Adresse), (7) der Internet-Service-Provider des
          zugreifenden Systems und (8) sonstige ähnliche Daten und Informationen, die der
          Gefahrenabwehr im Falle von Angriffen auf unsere informationstechnologischen Systeme
          dienen.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          Bei der Nutzung dieser allgemeinen Daten und Informationen ziehen wir keine Rückschlüsse
          auf die betroffene Person. Diese Informationen werden vielmehr benötigt, um (1) die
          Inhalte unserer Website korrekt auszuliefern, (2) die Inhalte unserer Website sowie die
          Werbung für diese zu optimieren, (3) die dauerhafte Funktionsfähigkeit unserer
          informationstechnologischen Systeme und der Technik unserer Website zu gewährleisten sowie
          (4) um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur Strafverfolgung
          notwendigen Informationen bereitzustellen. Diese anonym erhobenen Daten und Informationen
          werden durch die Muster GmbH daher einerseits statistisch und ferner mit dem Ziel
          ausgewertet, den Datenschutz und die Datensicherheit in unserem Unternehmen zu erhöhen, um
          letztlich ein optimales Schutzniveau für die von uns verarbeiteten personenbezogenen Daten
          sicherzustellen. Die anonymen Daten der Server-Logfiles werden getrennt von allen durch
          eine betroffene Person angegebenen personenbezogenen Daten gespeichert.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Externe Links</strong>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          Zu Ihrer Information finden Sie auf unseren Seiten Links, die auf Seiten Dritter
          verweisen. Soweit dies nicht offensichtlich erkennbar ist, weisen wir Sie darauf hin, dass
          es sich um einen externen Link handelt. Die verantwortliche Stelle hat keinerlei Einfluss
          auf den Inhalt und die Gestaltung dieser Seiten anderer Anbieter. Die Garantien dieser
          Datenschutzerklärung gelten daher für externe Anbieter nicht.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>
            Datenschutzbestimmungen zu Einsatz und Verwendung von Google Analytics (mit
            Anonymisierungsfunktion)
          </strong>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">Allgemeines</p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Wir haben auf dieser Website die Komponente Google Analytics (mit Anonymisierungsfunktion)
          integriert. Google Analytics ist ein Web-Analyse-Dienst. Web-Analyse ist die Erhebung,
          Sammlung und Auswertung von Daten über das Verhalten von Besuchern von Webseiten. Ein
          Web-Analyse-Dienst erfasst unter anderem Daten darüber, von welcher Website eine
          betroffene Person auf eine Website gekommen ist (sogenannte Referrer), auf welche
          Unterseiten der Website zugegriffen oder wie oft und für welche Verweildauer eine
          Unterseite betrachtet wurde.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Die Edenred Deutschland GmbH ist sich über die Übermittlung ihrer personenbezogenen Daten
          in ein Drittland bewusst und hat geeignete Garantien gem. Art. 46 DSGVO eingesetzt, um
          eine rechtmäßige und sichere Verarbeitung ihrer personenbezogenen Daten zu gewährleisten.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Betreibergesellschaft der Google Analytics-Komponente ist die Google Inc., 1600
          Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">&nbsp;</p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Anonymisierung der IP-Adresse
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Wir verwenden für die Web-Analyse über Google Analytics den Zusatz „gat.anonymizeIp“.
          Dabei handelt es sich um eine Funktion zur Kürzung der IP-Adresse. Dementsprechend wird
          Ihre IP-Adresse vor der Übermittlung aus einem Mitgliedstaat oder einem anderen
          Vertragsstaat des Abkommens über den Europäischen Wirtschaftsraum in die USA anonymisiert.
          In Ausnahmefällen findet eine Anonymisierung der IP-Adresse erst in den USA statt.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">&nbsp;</p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">Auftragsverarbeitung</p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Der Zweck der Google-Analytics-Komponente ist die Analyse der Besucherströme auf unserer
          Website. Google nutzt die gewonnenen Daten und Informationen unter anderem dazu, die
          Nutzung unserer Website auszuwerten, um für uns Online-Reports, welche die Aktivitäten auf
          unserer Website aufzeigen, zusammenzustellen, und um weitere mit der Nutzung unserer
          Website in Verbindung stehenden Dienstleistungen zu erbringen.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Google Analytics setzt ein Cookie auf dem informationstechnologischen System der
          betroffenen Person. Was Cookies sind, wurde oben bereits erläutert. Mit Setzung des
          Cookies wird Google eine Analyse der Benutzung unserer Website ermöglicht. Durch jeden
          Aufruf einer der Einzelseiten dieser Website, die durch den für die Verarbeitung
          Verantwortlichen betrieben wird und auf welcher eine Google-Analytics-Komponente
          integriert wurde, wird der Internetbrowser auf dem informationstechnologischen System der
          betroffenen Person automatisch durch die jeweilige Google-Analytics-Komponente veranlasst,
          Daten zum Zwecke der Online-Analyse an Google zu übermitteln. Im Rahmen dieses technischen
          Verfahrens erhält Google Kenntnis über personenbezogene Daten, wie der IP-Adresse der
          betroffenen Person, die Google unter anderem dazu dienen, die Herkunft der Besucher und
          Klicks nachzuvollziehen und in der Folge Provisionsabrechnungen zu ermöglichen.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Mittels des Cookies werden personenbezogene Informationen, beispielsweise die
          Zugriffszeit, der Ort, von welchem ein Zugriff ausging und die Häufigkeit der Besuche
          unserer Website durch die betroffene Person, gespeichert. Bei jedem Besuch unserer Website
          werden diese personenbezogenen Daten, einschließlich der IP-Adresse des von der
          betroffenen Person genutzten Internetanschlusses, an Google in den Vereinigten Staaten von
          Amerika übertragen. Diese personenbezogenen Daten werden durch Google in den Vereinigten
          Staaten von Amerika gespeichert. Google gibt diese über das technische Verfahren erhobenen
          personenbezogenen Daten unter Umständen an Dritte weiter.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Wir haben mit Google diesbezüglich einen Auftragsverarbeitungsvertrag geschlossen und
          beachten hierbei die gesetzlichen Vorgaben der DSGVO sowie die Vorgaben der deutschen
          Datenschutzbehörden bezüglich der Nutzung von Google Analytics.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">&nbsp;</p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Widerspruch gegen die Setzung von Cookies
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Die betroffene Person kann die Setzung von Cookies durch unsere Website, wie oben bereits
          dargestellt, jederzeit mittels einer entsprechenden Einstellung des genutzten
          Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen.
          Eine solche Einstellung des genutzten Internetbrowsers würde auch verhindern, dass Google
          ein Cookie auf dem informationstechnologischen System der betroffenen Person setzt. Zudem
          kann ein von Google Analytics bereits gesetzter Cookie jederzeit über den Internetbrowser
          oder andere Softwareprogramme gelöscht werden.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">&nbsp;</p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Widerspruch mittels Browser-Add-On
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Ferner besteht für die betroffene Person die Möglichkeit, einer Erfassung der durch Google
          Analytics erzeugten, auf eine Nutzung dieser Website bezogenen Daten sowie der
          Verarbeitung dieser Daten durch Google zu widersprechen und eine solche zu verhindern.
          Hierzu muss die betroffene Person ein Browser-Add-On unter dem Link
          https://tools.google.com/dlpage/gaoptout herunterladen und installieren. Dieses
          Browser-Add-On teilt Google Analytics über JavaScript mit, dass keine Daten und
          Informationen zu den Besuchen von Websites an Google Analytics übermittelt werden dürfen.
          Die Installation des Browser-Add-Ons wird von Google als Widerspruch gewertet. Wird das
          informationstechnologische System der betroffenen Person zu einem späteren Zeitpunkt
          gelöscht, formatiert oder neu installiert, muss durch die betroffene Person eine erneute
          Installation des Browser-Add-Ons erfolgen, um Google Analytics zu deaktivieren. Sofern das
          Browser-Add-On durch die betroffene Person oder einer anderen Person, die ihrem
          Machtbereich zuzurechnen ist, deinstalliert oder deaktiviert wird, besteht die Möglichkeit
          der Neuinstallation oder der erneuten Aktivierung des Browser-Add-Ons. Wird der Nutzung
          von Google Analytics widersprochen besteht die Möglichkeit, dass einige Funktionen der
          Website gegebenenfalls nicht mehr vollumfänglich genutzt werden können.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Weiterführende Informationen
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Weitere Informationen und die geltenden Datenschutzbestimmungen von Google können unter
          <a href="https://www.google.de/intl/de/policies/privacy/">
            https://www.google.de/intl/de/policies/privacy/
          </a>
          und unter
          <a href="https://www.google.com/analytics/terms/de.html">
            https://www.google.com/analytics/terms/de.html
          </a>
          abgerufen werden. Google Analytics wird unter diesem Link
          <a href="https://marketingplatform.google.com/intl/de/about/analytics/">
            https://marketingplatform.google.com/intl/de/about/analytics/
          </a>
          genauer erläutert.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">&nbsp;</p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Rechtsgrundlage:</strong>
        </p>

        <ul className="list-disc pb-5 pl-10">
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Art. 6 Abs.1 lit. a DSGVO (Einwilligung)
          </li>
        </ul>
        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Speicherzwecke</strong>
        </p>

        <ul className="list-disc pb-5 pl-10">
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Eine Web-Analyse wird überwiegend zur Optimierung einer Website und zur
            Kosten-Nutzen-Analyse von Internetwerbung eingesetzt.
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Zweck an der Setzung von Dritt-Cookies ist durch Analyse Ihres Nutzerverhaltens unser
            Angebot für Sie zu verbessern. In der Regel findet lediglich eine pseudonymisierte
            Datenübermittlung an die Drittparteien statt.
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Im Übrigen haben Sie es selbst in der Hand, eine Übermittlung von Dritt-Cookies durch
            eine entsprechende Einstellung im Cookie Banner oder innerhalb Ihres Internetbrowsers zu
            verhindern.
          </li>
        </ul>
        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Speicherdauer</strong>
        </p>

        <ul className="list-disc pb-5 pl-10">
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Dritt-Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer
            Seite übermittelt.
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Die Speicherung erfolgt bis der Zweck der Verarbeitung entfällt oder Sie Ihre
            Einwilligung widerrufen. Daher haben Sie als Nutzer auch die volle Kontrolle über die
            Verwendung von Dritt-Cookies
          </li>
        </ul>
        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Widerspruch/Beseitigungsmöglichkeit</strong>
        </p>

        <ul className="list-disc pb-5 pl-10">
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die
            Übertragung von Dritt-Cookies deaktivieren oder einschränken. Bereits gespeicherte
            Dritt-Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen.
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Die Übermittlung von Flash-Cookies lässt sich nicht über die Einstellungen des Browsers,
            jedoch durch Änderungen der Einstellung des Flash Players unterbinden.
          </li>
        </ul>
        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Google Tag Manager</strong>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Google Tag Manager ist eine Lösung, mit der wir Website-Tags über eine Oberfläche
          verwalten können. Das Tool Tag Manager selbst (das die Tags implementiert) ist eine
          cookielose Domain und erfasst keine personenbezogenen Daten. Das Tool sorgt für die
          Auslösung anderer Tags, die ihrerseits unter Umständen Daten erfassen. Google Tag Manager
          greift nicht auf diese Daten zu. Wenn auf Domain-oder Cookie-Ebene eine Deaktivierung
          vorgenommen wurde, bleibt diese für alle Tracking-Tags bestehen, die mit Google Tag
          Manager implementiert werden. Weitere Informationen:
          <a href="https://tagmanager.google.com/">https://tagmanager.google.com/</a>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">&nbsp;</p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Google Analytics Remarketing</strong>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Diese Website verwendet die Remarketing-Funktion der Google Inc. („Google“). Diese
          Funktion dient dazu, Besuchern der Website im Rahmen des Google-Werbenetzwerks
          interessenbezogene Werbeanzeigen zu präsentieren. Der Browser des Websitebesuchers
          speichert sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die
          es ermöglichen, den Besucher wiederzuerkennen, wenn dieser Websites aufruft, die dem
          Werbenetzwerk von Google angehören. Auf diesen Seiten können dem Besucher dann
          Werbeanzeigen präsentiert werden, die sich auf Inhalte beziehen, die der Besucher zuvor
          auf Websites aufgerufen hat, die die Remarketing Funktion von Google verwenden. Nach
          eigenen Angaben erhebt Google bei diesem Vorgang keine personenbezogenen Daten.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Wir sind uns der Übermittlung ihrer personenbezogenen Daten in ein Drittland bewusst und
          hat geeignete Garantien gem. Art. 46 DSGVO eingesetzt, um eine rechtmäßige und sichere
          Verarbeitung ihrer personenbezogenen Daten zu gewährleisten.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Sollten Sie die Funktion Remarketing von Google dennoch nicht wünschen, können Sie diese
          grundsätzlich deaktivieren, indem Sie die entsprechenden Einstellungen unter
          <a href="https://www.google.com/settings/ads">https://www.google.com/settings/ads</a>
          &nbsp; vornehmen.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Alternativ können Sie den Einsatz von Cookies für interessenbezogene Werbung über die
          Werbenetzwerkinitiative deaktivieren, indem Sie den Anweisungen unter
          <a href="https://www.networkadvertising.org/managing/opt_out.asp">
            https://www.networkadvertising.org/managing/opt_out.asp
          </a>
          &nbsp; folgen.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Weiterführende Informationen zu Google Remarketing und die Datenschutzerklärung von Google
          können Sie einsehen unter:
          <a href="https://www.google.com/privacy/ads/">https://www.google.com/privacy/ads/</a>.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <br />

          <strong>Rechtsgrundlage:</strong>
        </p>

        <ul className="list-disc pb-5 pl-10">
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Art. 6 Abs.1 lit. a DSGVO (Einwilligung)
          </li>
        </ul>
        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Speicherzwecke</strong>
        </p>

        <ul className="list-disc pb-5 pl-10">
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Der Zweck dieser Funktion dient dazu, Besuchern der Website im Rahmen des
            Google-Werbenetzwerks interessenbezogene Werbeanzeigen zu präsentieren.
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Im Übrigen haben Sie es selbst in der Hand, eine Übermittlung von Dritt-Cookies durch
            eine entsprechende Einstellung im Cookie Banner oder innerhalb Ihres Internetbrowsers zu
            verhindern.
          </li>
        </ul>
        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Speicherdauer</strong>
        </p>

        <ul className="list-disc pb-5 pl-10">
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Dritt-Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer
            Seite übermittelt.
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Die Speicherung erfolgt bis der Zweck der Verarbeitung entfällt oder Sie Ihre
            Einwilligung widerrufen. Daher haben Sie als Nutzer auch die volle Kontrolle über die
            Verwendung von Dritt-Cookies
          </li>
        </ul>
        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Widerspruch/Beseitigungsmöglichkeit</strong>
        </p>

        <ul className="list-disc pb-5 pl-10">
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die
            Übertragung von Dritt-Cookies deaktivieren oder einschränken. Bereits gespeicherte
            Dritt-Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen.
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Die Übermittlung von Flash-Cookies lässt sich nicht über die Einstellungen des Browsers,
            jedoch durch Änderungen der Einstellung des Flash Players unterbinden.
          </li>
        </ul>

        <h6 className="text-14 text-ed-gray text-ed-bold py-5">
          <strong>B. Datenverarbeitung in der App</strong>
          Zuzüglich der unter I. und II. erläuterten Angaben zur Datenverarbeitung gilt der
          nachfolgende Hinweis, insofern Sie die Mobile App verwenden.
        </h6>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Technische Daten</strong>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Bestimmte Daten werden bereits automatisch verarbeitet, sobald Sie die App nutzen. Im
          Folgenden haben wir aufgelistet, welche personenbezogenen Daten für Sie verarbeitet
          werden.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Beim Herunterladen erhobene Daten
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Wenn Sie die App herunterladen, werden bestimmte erforderliche Informationen an den von
          Ihnen ausgewählten App-Store (z.B. Google Play oder Apple App Store) übermittelt;
          insbesondere können der Benutzername, die E-Mail-Adresse, die Kundenummer Ihres Kontos,
          der Zeitpunkt des Herunterladens, Zahlungsinformationen und die individuelle
          Geräte-Identifikationsnummer verarbeitetet werden. Die Verarbeitung dieser Daten erfolgt
          ausschließlich durch den jeweiligen App-Store und liegt außerhalb unserer Kontrolle.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Automatisch erhobene Daten
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Im Rahmen der Nutzung der App erheben wir automatisch bestimmte Daten, die für die Nutzung
          der App erforderlich sind. Dazu gehören regelmäßig:
        </p>

        <ul className="list-disc pb-5 pl-10">
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Die interne Geräte-ID,
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Die Version ihres Betriebssystems,
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Sowie der Zeitpunkt des Zugriffs.
          </li>
        </ul>
        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Diese Daten werden automatisch an uns übermittelt, aber nicht gespeichert,
        </p>

        <ul className="list-disc pb-5 pl-10">
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            um Ihnen den Dienst und die damit verbundenen Funktionen zur Verfügung zu stellen;
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            um die Funktionen und Leistungsmerkmale der App zu verbessern; und
          </li>
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            um Missbrauch und Fehlfunktonen zu verhindern und zu beheben.
          </li>
        </ul>
        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          Diese Datenverarbeitung beruht auf unserem berechtigten Interesse gem. Art. 6 Abs.1 lit. f
          DSGVO. Wir haben ein berechtigtes Interesse daran, die Funktionsfähigkeit und den
          fehlerfreien Betrieb der App sicherzustellen und einen markt- und interessengerechten
          dienst anbieten zu können, wobei dieses berechtigte Interesse Ihren Rechten und
          Interessen, die den Schutz Ihrer personenbezogenen Daten erfordern, im Sinne von Art. 6
          Abs.1 lit. f DSGVO überwiegt.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Berechtigungen</strong>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Für die vorliegende App sind die folgenden Berechtigungen erforderlich:
        </p>

        <ul className="list-disc pb-5 pl-10">
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Internetzugang: Erforderlich, um unseren Dienst zu verwenden und auf ihren Account
            zuzugreifen.
          </li>
        </ul>
        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Die folgende Berechtigung können Sie uns freiwillig erteilen:
        </p>

        <ul className="list-disc pb-5 pl-10">
          <li className="text-14 text-ed-gray text-ed-light leading-tight">
            Push-Notifications: Über Push-Notifications können wir Sie auf ihrem Smartphone über
            entsprechende Neuigkeiten und Fristen informieren.
          </li>
        </ul>
        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          Die von Ihnen akzeptierten Berechtigungen werden in der App als personenbezogene Daten
          gespeichert. Die Rechtsgrundlage für die erforderlichen Berechtigungen liegt dabei in
          unserem berechtigten Interesse eine funktionsfähige App anzubieten und ihre Vorlieben
          bezüglich der Notification zu berücksichtigen(Art. 6 Abs.1 lit. f DSGVO). Wir verarbeiten
          diese personenbezogenen Daten so lange, bis Sie die App deinstallieren.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Personalized Ads</strong>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Insofern Sie einwilligen, dass wir Ihre personenbezogenen Daten zum Zwecke der
          personalisierten Werbung verarbeiten, verarbeiten wir zusätzlich zu den bereits genannten
          Daten auch Daten über ihr Interaktionsverhalten mit den von uns bereitgestellten Diensten.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Auf diese Art und Weise bestimmen wir Werbeanzeigen, die für sie basierend auf Ihrem
          bisherigen Verhalten von Interesse sein könnten und präsentieren Ihnen diese Werbung im
          Rahmen der Nutzung unserer App.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          Die in diesem Zusammenhang erhobenen Daten wird Edenred Deutschland nicht an Dritte weiter
          geben oder verkaufen.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Log-In Daten</strong>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Um die Nutzung der App anbieten zu können und die Zugangsbeschränkung zu ihrem
          Nutzer-account und somit die Sicherheit die Services für Sie als unseren Kunden
          sicherzustellen, verarbeiten wir entsprechend Ihre Log-In-Informationen (E-Mail-Adresse,
          Passwort).
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Sie sind jederzeit in der Lage in den Optionen Ihr Passwort zurückzusetzen und anzupassen.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">&nbsp;</p>

        <h6 className="text-14 text-ed-gray text-ed-bold py-5">
          <strong>III. Weitere Informationen und Kontakt</strong>
        </h6>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pb-5">
          Wenn Sie weitere Fragen zum Thema „Datenschutz bei der verantwortlichen Stelle” haben,
          wenden Sie sich an den Datenschutzbeauftragten unseres Unternehmens. Sie können erfragen,
          welche Ihrer Daten bei uns gespeichert sind. Darüber hinaus können Sie Auskünfte,
          Löschungs- und Berichtigungswünsche zu Ihren Daten und gerne auch Anregungen jederzeit
          mittels&nbsp;
          <a href="https://privacyportal-de.onetrust.com/webform/3eee82c0-9eb6-4614-b723-8d597fdcce8d/fbd0be12-6e96-45a8-a9c0-837d8fa8980d">
            dieses Formulars
          </a>
          &nbsp;oder per Brief oder E-Mail an folgende Adresse senden:
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          <strong>Edenred Deutschland GmbH</strong>
          <br />
          z.H. Datenschutzbeauftragter
          <br />
          Claudius-Keller-Str. 3C
          <br />
          81669 München Deutschland
          <br />
          E-Mail:&nbsp;
          <a href="mailto:dpo.deutschland@edenred.com">dpo.deutschland@edenred.com</a>
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight pt-5 pb-2">
          <strong>Cookies</strong>
          <br />
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight">
          Diese Webseite verwendet Cookies. Wir verwenden Cookies, um Inhalte und Anzeigen zu
          personalisieren, Funktionen für soziale Medien anbieten zu können und die Zugriffe auf
          unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung
          unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter. Unsere
          Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie
          ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt
          haben. Sie können Ihre Einwilligung jederzeit in der Datenschutzerklärungändern oder
          widerrufen.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight py-2">
          Cookies sind kleine Textdateien, die von Webseiten verwendet werden, um die
          Benutzererfahrung effizienter zu gestalten.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight py-2">
          Laut Gesetz können wir Cookies auf Ihrem Gerät speichern, wenn diese für den Betrieb
          dieser Seite unbedingt notwendig sind. Für alle anderen Cookie-Typen benötigen wir Ihre
          Erlaubnis.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight py-2">
          Diese Seite verwendet unterschiedliche Cookie-Typen. Einige Cookies werden von
          Drittparteien platziert, die auf unseren Seiten erscheinen.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight py-2">
          Sie können Ihre Einwilligung jederzeit von der Cookie-Erklärung auf unserer Website ändern
          oder widerrufen.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight py-2">
          Erfahren Sie in unserer Datenschutzrichtlinie mehr darüber, wer wir sind, wie Sie uns
          kontaktieren können und wie wir personenbezogene Daten verarbeiten.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight py-2">
          Bitte geben Sie Ihre Einwilligungs-ID und das Datum an, wenn Sie uns bezüglich Ihrer
          Einwilligung kontaktieren.
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight py-2">
          Aus technischen Gründen kann nach einer Änderung oder einem Widerruf der Einwilligung ein
          Neuladen der Website erforderlich sein, damit bestimmte Cookies blockiert bzw. eingesetzt
          werden (abhängig von Ihrer Wahl).
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight py-2">
          Ihre Einwilligung trifft auf die folgenden Domains zu: gutschein.edenred.de
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight py-2">
          Ihr aktueller Zustand: Alle zulassen. Ihre Einwilligungs-ID:
          LId1Rj5TMXitoREOFXnp3m2JPnY9LqWySqeKLo+r9UNrblW+FQZ8XA== Einwilligungsdatum: Dienstag, 2.
          Juli 2024 um 10:27:58 MESZ
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight py-2">
          Einwilligung ändern | Einwilligung widerrufen
        </p>

        <p className="text-14 text-ed-gray text-ed-light leading-tight py-2">
          Die Cookie-Erklärung wurde das letzte Mal am 08.06.24 von Cookiebot aktualisiert:
        </p>
      </div>
    </div>
  );
}

export default DummyComponent;
