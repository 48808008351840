import { HTTP_REQUEST_METHODS } from '@utils/ApiCodex';
import { appAPIHeaders, appAPIUri } from '@utils/Url';
import axios from 'axios';

export default class OnboardService {
  env = {};

  /**
   * Set env from app loading with server.
   * @param {JSON} env APP Envs
   */
  constructor(env) {
    this.env = env;
  }

  /**
   * Fetch deeplink url details
   * @param {seting} redirectUrl Url from email
   * @returns API Request
   */
  getUserUid(redirectUrl) {
    // Req Url
    let url = `${this.env.REACT_APP_SERVER}${appAPIUri.ONBOARDING.USERUID}`;
    // Attatch code and verifier
    if (redirectUrl) url += `?url=${redirectUrl}`;
    // Default headers
    const headers = appAPIHeaders.DEFAULT_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.GET,
      url,
      headers,
    });
  }

  /**
   * Fetch deeplink url details
   * @param {seting} redirectUrl Url from email
   * @returns API Request
   */
  getVerifyEmail(id) {
    // Req Url
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.ONBOARDING.VERIFY_EMAIL(id)}`;
    // Default headers
    const headers = appAPIHeaders.DEFAULT_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.GET,
      url,
      headers,
    });
  }

  /**
   * Process user onboarding.
   * @param {Object} data Onboard user data
   * @returns API Request
   */
  processUserOnboard(data) {
    // Req Url
    const url = `${this.env.REACT_APP_API_SERVER}${appAPIUri.ONBOARDING.ONBOARD}`;

    // Default headers
    const headers = appAPIHeaders.DEFAULT_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.POST,
      url,
      headers,
      data,
    });
  }

  /**
   * fetch user onboarding information.
   * @param {Object} associationId Onboard user assoc id
   * @returns API Request
   */
  getOnboardUserPersonalInfo(associationId) {
    // Req Url
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.ONBOARDING.USER_INFO(associationId)}`;

    // Default headers
    const headers = appAPIHeaders.DEFAULT_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.GET,
      url,
      headers,
    });
  }

  /**
   * Store user onboarding information.
   * @param {String} associationId Onboard user assoc id
   * @param {Object} data Onboard user data
   * @returns API Request
   */
  saveOnboardUserPersonalInfo(associationId, data) {
    // Req Url
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.ONBOARDING.USER_INFO(associationId)}`;

    // Default headers
    const headers = appAPIHeaders.DEFAULT_RAW_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.POST,
      url,
      headers,
      data,
    });
  }

  /**
   * Store user  information.
   * @param {Object} data Onboard user data
   * @returns API Request
   */
  saveOnboardUserInfo(data) {
    // Req Url
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.ONBOARDING.SAVE_USER_INFO}`;

    // Default headers
    const headers = appAPIHeaders.DEFAULT_RAW_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.POST,
      url,
      headers,
      data,
    });
  }

  /**
   * Store user audit information.
   * @param {Object} data Audit data
   * @returns API Request
   */
  saveUserAudit(data) {
    // Req Url
    const url = `${this.env.REACT_APP_SERVER}${appAPIUri.AUDIT}`;

    // Default headers
    const headers = appAPIHeaders.DEFAULT_RAW_JSON;

    return axios({
      method: HTTP_REQUEST_METHODS.POST,
      url,
      headers,
      data,
    });
  }
}
