/* eslint-disable no-console */
import { createContext, useMemo, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from '@utils/Router/withRouter';
import { appAlert } from '@utils/Constant';

// A context to load all app configuration from server
const AlertContext = createContext();

// The top level component that will wrap our app's core features
export function AlertProviderPre({ children }) {
  const [alert, setAlert] = useState([]);

  // Render the children as normal
  const renderContent = () => children;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (alert.length > 0) {
      const timer = setTimeout(
        () => setAlert((extAlerts) => extAlerts.slice(1)),
        appAlert.DEFAUT_TIMEOUT,
      );
      return () => clearTimeout(timer);
    }
  }, [alert]);

  // const showAlert = useCallback(
  //   (al) => {
  //     setAlert((extAlerts) => [
  //       ...extAlerts || {},
  //       { type: al.type || appAlert.INFO, message: al.message },
  //     ]);
  //   },
  //   [setAlert],
  // );

  const showAlert = (al) => {
    if (alert.length > 0) {
      const newAlerts = [...alert, { type: al?.type || appAlert.INFO, message: al?.message }];
      setAlert(newAlerts);
    } else {
      setAlert([{ type: al?.type || appAlert.INFO, message: al?.message }]);
    }
  };

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(
    () => ({ alert, setAlert, showAlert }),
    [alert, setAlert, showAlert],
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <AlertContext.Provider value={contextPayload}>{renderContent()}</AlertContext.Provider>;
}

AlertProviderPre.propTypes = {
  children: PropTypes.node,
};

AlertProviderPre.defaultProps = {
  children: null,
};

export const AlertProvider = withRouter(AlertProviderPre);
export default AlertProvider;

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useAlert = () => useContext(AlertContext);
