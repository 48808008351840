import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useMyAccount } from '@context/MyAccount';
import { defaultMyAccountView } from '@utils/Constant';
import { withAnimate } from '@part/AnimateLoad';
import { LANGUAGE } from '@utils/Constant/language';
import { useLanguage } from '@context/Language';

import PersonalData from './PersonalData';
import PasswordReset from './PasswordReset';
import NotificationSetting from './NotificationSetting';
import Logout from './Logout';

import './style.scss';

function MyAccount() {
  const { myAccountNav, myAccountView, setMyAccountView } = useMyAccount();
  const Location = useLocation();
  const { translate } = useLanguage();
  const navList = () =>
    myAccountNav.map((nav) => (
      <Link to={nav.to} key={`my_nav_${nav.title}`}>
        <p
          className={`text-18 ${
            nav.to === myAccountView ? 'text-ed-red' : 'text-ed-gray'
          } text-ed-light pb-5`}
        >
          {translate(nav.title)}
        </p>
      </Link>
    ));

  const checkNav = (location) => {
    if (location && location.pathname) {
      const updateView = myAccountNav.find((nav) => nav.to === location.pathname);
      if (updateView && defaultMyAccountView[updateView.view]) {
        setMyAccountView(updateView.to);
      }
    }
  };

  const renderContent = () => {
    let defaultContent = <></>;
    switch (myAccountView) {
      case defaultMyAccountView.PERSONAL_INFO:
        defaultContent = <PersonalData />;
        break;
      case defaultMyAccountView.PASSWORD_RESET:
        defaultContent = <PasswordReset />;
        break;
      case defaultMyAccountView.NOTIFICATION_SETTINGS:
        defaultContent = <NotificationSetting />;
        break;
      case defaultMyAccountView.LOGOUT:
        defaultContent = <Logout />;
        break;
      default:
        defaultContent = <PersonalData />;
        break;
    }
    return defaultContent;
  };

  useEffect(() => {
    checkNav(Location);
  }, [Location]);

  return (
    <div className="flex-center bg-ed-cold-white bg-ed-my-account">
      <div className="app-ed-page ">
        <div className="app-ed-page-content w-full py-16">
          <div className="pb-8">
            <h2 className="text-24 text-ed-gray text-ed-bold">{translate(LANGUAGE.myAccount)}</h2>
          </div>
          <div className="grid grid-cols-12">
            <div className="col-span-4 md:col-span-3 text-left hidden sm:block">{navList()}</div>
            <div className="col-span-8 md:col-span-9 md:-ml-5">{renderContent()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAnimate(MyAccount);
